<div class="pt-3 pb-3">
    <div class="container-fluid">
        <div class="formSection">
            <div class="col-lg-12" [formGroup]="emailForm">
                <div class="row">
                    <!-- <div class="col-lg-6 text-start" formArrayName="emailCC"
                            *ngFor="let data of emailCCType.controls;let i = index">
                            <div class="row mb-3" [formGroupName]="i">
                                <div class="col-lg-11">
                                    <input class="form-control w-100" formControlName="email" type="text"
                                        placeholder="Email CC {{i + 1}}">
                                </div>
                                <div class="col-lg-1 three">
                                    <i class="fa fa-minus" id="removeEmail{{i}}" (click)="removeEmail(i)"
                                        *ngIf="i !== emailCCType.controls.length - 1"></i>
                                    <i class="fa fa-plus" id="addEmails" (click)="addEmail(emailCCType?.controls, i)"
                                        *ngIf="i === emailCCType.controls.length - 1"></i>
                                </div>
                                <div *ngIf="data?.get('email')?.touched && data?.get('email')?.errors">
                                    <span class="p-error" *ngIf="data?.get('email')?.hasError('email')">
                                        Please enter a valid email
                                    </span>
                                </div>
                            </div>
                        </div> -->
                    <div class="col-lg-12 text-start mb-3">
                        <input class="form-control w-100" formControlName="recepientEmail" type="text" placeholder="To">
                        <div
                            *ngIf="emailForm?.get('recepientEmail')?.touched && emailForm?.get('recepientEmail')?.errors">
                            <span class="p-error" *ngIf="emailForm?.get('recepientEmail')?.hasError('required')">
                                Email ID is required
                            </span>
                            <span class="p-error" *ngIf="emailForm?.get('recepientEmail')?.hasError('recepientEmail')">
                                Please enter a valid email
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-12 text-start mb-3">
                        <input class="form-control w-100" formControlName="subject" type="text" placeholder="Subject">
                        <div *ngIf="emailForm?.get('subject')?.touched && emailForm?.get('subject')?.errors">
                            <span class="p-error" *ngIf="emailForm?.get('subject')?.hasError('required')">
                                Subject is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <quill-editor [modules]="moduleConfig" [(ngModel)]="emailContent">
            </quill-editor>
            <div class="col-lg-12 text-center mt-3">
                <insurance-button btnWidth="150" label="Send Email" (onClick)="sendEmail()" [loading]="submitting">
                </insurance-button>
            </div>
            <div *ngIf="emailError">
                <span class="p-error" *ngIf="emailForm?.get('subject')?.hasError('required')">
                    Error in sending Mail
                </span>
            </div>
        </div>
    </div>
</div>

<insurance-popup [displayModal]="mailSuccess" iconBgColor="#e5f5ec" iconClass="pi pi-check" iconColor="#27AE60"
    message="Congrats" [closable]="false">
    <div>
        <h3>Mail sent Successfully</h3>
        <insurance-button label="OK" buttonId="resetAppraisalSubmit" (onClick)="resetForm()" btnWidth="130">
        </insurance-button>
    </div>
</insurance-popup>