import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.css']
})
export class QuoteListComponent implements OnChanges {

  @Input() noOfQuotes = 0;
  @Input() quoteProposalData: any;
  @Input() quoteSelection = false;
  @Input() disableSelection = false;
  @Input() mobilePopup = false;
  @Input() selectedQuote: any;
  @Output() selectedQuoteData = new EventEmitter();
  tableHeaderSelect: any = {
    display: {
      fullPayMonthlyPremium: false,
      payPlanMonthlyPremiumTotal: false,
      payPlanMonthlyPremiumDownpayment: false,
      payPlanMonthlyPremiumPerMonth: false,
      enrolledInMercuryGo: false,
      enrolledInSnapshot: false,
      enrolledInRightTrack: false,
      enrolledInIntelliDrive: false,
      paidInFull: false,
      eftPayPlanMonthlyPremium: false,
      initialEftInstallment: false,
      eftTotalCostPremium: false,
      ccPayPlanMonthlyPremium: false,
      initialCcInstallment: false,
      ccTotalCostPremium: false,
      twoPayPlan: false,
      twoPayTotalCostPremium: false,
      fourPayPlan: false,
      fourPayTotalPremium: false,
      vehiclesCovered: false,
      coverageABodilyInjuryPerPersonPerAccident: false,
      coverageAPropoertyDamagePerAccident: false,
      coverageBMedicalPayments: false,
      coverageCUninsuredMotoristPerPersonPerAccident: false,
      coverageCUninsuredMotorist: false,
      guaranteedValue: false,
      coverageDComprehensiveCoverage: false,
      coverageDComprehensiveCoverageDeductible: false,
      coverageDCollisionCoverage: false,
      coverageDCollisionCoverageDeductible: false,
      personalInjuryProtectionLimit: false,
      pipWorkExclusion: false,
      pipDeductible: false,
      pipAppliesTo: false,
      ultraCoverage: false,
      accidentMinorViolationForgiveness: false,
      responsibleDrivingPlan: false,
      extendedPip: false,
      additionalPip: false,
      rentalCarCoverage: false,
      roadsideAssistanceAndTowing: false,
      gapCoverage: false,
      originalEquipmentManufacturer: false,
      enhancedPackage: false
    }
  };
  premiumHighlight: any;
  autoPDFGenerators: any = {
    headerData: [
      'essentialCoverage',
      'uninsuredMotoristCoverage',
      'physicalDamageCoverage',
      'additionalCoverages',
      'navigationalTerritory',
    ],
    singleData: [
      'package',
      'coverageAPersonBI',
      'coverageAAccidentPD',
      'coverageAAccidentBI'
    ],
    currencyData: [
      'paidInFull',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'eftPayPlanMonthlyPremium',
      'initialInstallmentEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremimPerMonth'
    ],
    multiCarData: [
      'coverageDComprehensiveCoverage',
      'coverageDComprehensiveCoverageDeductible',
      'coverageDCollisionCoverage',
      'coverageDCollisionCoverageDeductible',
      'guaranteedValue',
      'rentalCarCoverage',
      'roadsideAssistanceAndTowing',
    ]
  }
  checkFields = {
    coverageABodilyInjuryPerPersonPerAccident: false,
    coverageAPropoertyDamagePerAccident: false,
    coverageBMedicalPayments: false,
    coverageCUninsuredMotoristPerPersonPerAccident: false,
    coverageCUninsuredMotorist: false,
    guaranteedValue: [],
    coverageDComprehensiveCoverage: [],
    coverageDComprehensiveCoverageDeductible: [],
    coverageDCollisionCoverage: [],
    coverageDCollisionCoverageDeductible: [],
    personalInjuryProtectionLimit: false,
    pipWorkExclusion: false,
    pipDeductible: false,
    pipAppliesTo: false,
    ultraCoverage: false,
    accidentMinorViolationForgiveness: false,
    responsibleDrivingPlan: false,
    extendedPip: false,
    additionalPip: false,
    rentalCarCoverage: [],
    roadsideAssistanceAndTowing: [],
    gapCoverage: false,
    originalEquipmentManufacturer: false,
    enhancedPackage: false
  }
  autoTableHeader: any = {
    package: {
      name: 'Package Name',
      main: 'packageName',
      sub: 'otherPackageName',
    },
    carrierName: 'Carrier Name',
    policyTerm: 'Policy Term',
    enrolledInMercuryGo: 'Enrolled in MercuryGO',
    enrolledInSnapshot: 'Enrolled in Snapshot',
    enrolledInRightTrack: 'Enrolled in RightTrack',
    enrolledInIntelliDrive: 'Enrolled in IntelliDrive',
    fullPayMonthlyPremium: 'Full Pay Monthly Premium',
    payPlanMonthlyPremiumTotal: 'Pay Plan Monthly Premium Total',
    payPlanMonthlyPremiumDownpayment: 'Pay Plan Monthly Premium Downpayment',
    payPlanMonthlyPremiumPerMonth: 'Pay Plan Monthly Premium Per Month',
    paidInFull: 'Paid in Full',
    eftPayPlanMonthlyPremium: 'EFT Pay Plan Monthly Premium',
    initialEftInstallment: 'Initial EFT Installment',
    eftTotalCostPremium: 'EFT Total Cost Premium',
    ccPayPlanMonthlyPremium: 'CC Pay Plan Monthly Premium',
    initialCcInstallment: 'Initial CC Installment',
    ccTotalCostPremium: 'CC Total Cost Premium',
    twoPayPlan: '2-Pay Plan**',
    twoPayTotalCostPremium: '2-Pay Total Cost Premium',
    fourPayPlan: '4-Pay Plan**',
    fourPayTotalPremium: '4 Pay Total Premium',
    vehiclesCovered: 'Vehicles Covered',
    essentialCoverage: 'Essential Coverage',
    coverageABodilyInjuryPerPersonPerAccident: 'Coverage A - Bodily Injury (per person/per accident)',
    coverageAPropoertyDamagePerAccident: 'Coverage A - Property Damage per accident',
    coverageBMedicalPayments: 'Coverage B - Medical Payments',
    uninsuredMotoristCoverage: 'Uninsured Motorist Coverage',
    coverageCUninsuredMotoristPerPersonPerAccident: 'Coverage C - Uninsured Motorist (per person/per accident)',
    coverageCUninsuredMotorist: 'Coverage C - Uninsured Motorist',
    physicalDamageCoverage: 'Physical Damage Coverage',
    guaranteedValue: 'Guaranteed Value',
    coverageDComprehensiveCoverage: 'Coverage D - Comprehensive Coverage',
    coverageDComprehensiveCoverageDeductible: 'Coverage D - Comprehensive Coverage Deductible',
    coverageDCollisionCoverage: 'Coverage D - Collision Coverage',
    coverageDCollisionCoverageDeductible: 'Coverage D - Collision Coverage Deductible',
    pipCoverage: 'PIP Coverage',
    personalInjuryProtectionLimit: 'Personal Injury Protection (PIP) Limit',
    pipWorkExclusion: 'PIP Work Exclusion',
    pipDeductible: 'PIP Deductible',
    pipAppliesTo: 'PIP Applies to',
    additionalCoverages: 'Additional Coverages',
    ultraCoverage: 'Ultra-Coverage (Accident Forgiveness)',
    accidentMinorViolationForgiveness: 'Accident & Minor Violation Forgiveness',
    responsibleDrivingPlan: 'Responsible Driving Plan',
    extendedPip: 'Extended PIP',
    additionalPip: 'Additional PIP',
    rentalCarCoverage: 'Rental Car Coverage',
    roadsideAssistanceAndTowing: 'Roadside Assistance and Towing',
    gapCoverage: 'Gap Coverage',
    originalEquipmentManufacturer: 'Original Equipment Manufacturer',
    enhancedPackage: 'Enhanced Package'
  };

  ngOnChanges(): void {
    if (this.quoteProposalData) {
      this.showTable(this.quoteProposalData?.quoteList);
      this.checkDifference(this.quoteProposalData?.quoteList);
    }
  }

  showTable(val: any) {
    let quoteLength = val?.length;
    for (const row in this.tableHeaderSelect.display) {
      for (let index = 0; index < quoteLength; index++) {
        if (val[index][row]?.length) {
          this.tableHeaderSelect.display[row] = true
          break;
        }
      }
    }
  }

  async checkDifference(value: any) {
    this.premiumHighlight = [];
    const data = value;
    const length = data?.length ? data?.length : 0;
    const singleData = [...this.autoPDFGenerators.singleData];
    singleData.shift();
    const multiCarData = this.autoPDFGenerators?.multiCarData || [];
    for (let index = 0; index < length; index++) {
      this.premiumHighlight.push({ ...this.checkFields });
      if (index > 0) {
        for (const key in this.checkFields) {
          if (singleData?.includes(key)) {
            const main = this.autoTableHeader[key]['main'];
            const sub = this.autoTableHeader[key]['sub'];
            this.premiumHighlight[index][key] = (data[index]?.[main] + data[index]?.[sub]) != (data[0]?.[main] + data[0]?.[sub]) && true;
          } else if (multiCarData.includes(key)) {
            const refArrayLength = data[0]?.[key]?.length;
            const checkArrayLength = data[index]?.[key]?.length;
            this.premiumHighlight[index][key] = [];
            for (let j = 0; j < checkArrayLength; j++) {
              this.premiumHighlight[index][key].push(refArrayLength ? data[index]?.[key]?.at(j) !== data[0]?.[key]?.at(j) : !!refArrayLength)
            }
          } else {
            this.premiumHighlight[index][key] = (data[index]?.[key] != data[0]?.[key]) && true
          }
        }
      }
    }
  }

  selectQuote(quote: any) {
    this.selectedQuoteData.emit({ ...quote })
  }

}
