import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import * as orderPageData from '../carriers.data';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css'],
})
export class OrderPageComponent implements OnInit {
  bookingOrder: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    phoneCode: [''],
    phone: [''],
    amount: [null, [Validators.required]],
    purpose: '',
    policyStartDate: ['', [Validators.required]],
    paymentMode: ['', [Validators.required]],
    paymentMethod: ['', [Validators.required]],
    packageName: [''],
    carrier: [''],
    selectedProposalId: [''],
    selectedProposalQuoteId: [''],
    driverPhone: this.fb.array([this.newDriverNumber()]),
    effectiveDateUpdateRequest: [false],
    updatedPolicyEffectiveDate: ['']
  });
  startDate = new Date();
  merchantId = '';
  campaignId = '';
  dataRes: { [key: string]: any } = {};
  paymentMethodOption = orderPageData.paymentMethodOption;
  manualPaymentMethodOption: any;
  selectedCarrierData: any[] = [];
  selectQuoteButtonValues: any[] = [];
  selectedQuoteColumn: { [key: string]: string } = {};
  selectedQuoteAmount = 0;
  submitting = false;
  transactionId = '';
  displayDialog = false;
  parsedTableDatas: any[] = [];
  campaignData: any;

  constructor(
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public wizzipayServiceAPI: WizzipayService,
    public router: Router,
    private datepipe: DatePipe
  ) { }

  get driverPhone(): FormArray {
    return this.bookingOrder.get('driverPhone') as FormArray;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      this.campaignId = params['campaignId'];
      this.getData();
    });
  }

  addDriver(control: any, index: number) {
    control[index].get('number').markAllAsTouched();
    if (control[index].get('number').invalid) {
      this.wizzipayServiceAPI.snackbar('Please fill this number to add more');
      return;
    }
    const array = this.bookingOrder.get('driverPhone') as FormArray;
    array.push(this.newDriverNumber());
  }

  removeDriver(i: number) {
    const array = this.bookingOrder.get('driverPhone') as FormArray;
    array.removeAt(i);
  }

  newDriverNumber(): FormGroup {
    return this.fb.group({
      number: [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
    });
  }

  getData() {
    this.wizzipayServiceAPI
      .getdata(this.merchantId, this.campaignId)
      .subscribe((res) => {
        this.wizzipayServiceAPI
          .getQuoteSelectionData(this.campaignId)
          .subscribe((selectionRes) => {
            this.campaignData = { ...res?.data, ...selectionRes?.data };
            let count = 0;
            // FIND CARRIER
            this.selectedCarrierData = orderPageData.carrierTyes.filter(
              (car: any) => {
                car.package = car.package.filter((pack: any) => {
                  return pack.carrier.includes(res.data.carrier);
                });
                console.log('car', car)
                return car.package.length > 0;
              }
            );
            console.log('selected caree', this.selectedCarrierData)
            // ASSIGNING VALUES TO 'SELECT THIS QUOTE' BUTTON START
            let payload: { [key: string]: string } = {};
            //let test:any =[]
            res.data.package[0]?.headers.forEach((val: any, baseindex: any) => {
              payload = {
                type: val,
              };
              res.data.package[0]?.colPackage.forEach((colVal: any, index: any) => {
                this.selectedCarrierData.forEach((data: any) => {
                  data.package.forEach((pack: any) => {
                    if (colVal?.replace(/\s/g, '')?.toLowerCase() === pack.value?.replace(/\s/g, '')?.toLowerCase()) {
                      payload[pack.key] = res.data.package[0]?.colValues[baseindex][index];
                    }
                  });
                });
              });
              this.selectQuoteButtonValues.push(payload)
            })
            console.log('calculate value', this.selectQuoteButtonValues)
            // res.data.package.forEach(
            //   (element: any) => {
            //     let payload: { [key: string]: string } = {};
            //     payload = {
            //       type: element.name,
            //     };
            //     count++;
            //     for (const key in element) {
            //       this.selectedCarrierData.forEach((data: any, index) => {
            //         data.package.forEach((pack: any) => {
            //           if (key == pack.value) {
            //             payload[pack.key] = element[key];
            //           }
            //         });
            //       });
            //     }
            //     this.selectQuoteButtonValues.push(payload);
            //     console.log('button', this.selectQuoteButtonValues)
            //   }
            // );
            // res.data.package.forEach(
            //   (element: { [x: string]: string; name: string }) => {
            //     let payload: { [key: string]: string } = {};
            //     payload = {
            //       type: element.name,
            //     };
            //     count++;
            //     for (const key in element) {
            //       this.selectedCarrierData.forEach((data: any, index) => {
            //         data.package.forEach((pack: any) => {
            //           if (key == pack.value) {
            //             payload[pack.key] = element[key];
            //           }
            //         });
            //       });
            //     }
            //     this.selectQuoteButtonValues.push(payload);
            //     console.log('button', this.selectQuoteButtonValues)
            //   }
            // );
            // ASSIGNING VALUES TO 'SELECT THIS QUOTE' BUTTON END

            if (res.data.package.length > 0) {
              // QUOTE TABLE DATAS START
              // const payload: { [key: string]: any } = {};
              // var headers: string[] = [];
              // const colPackage: string[] = []; //Table Package Name
              // const colValues: string[][] = [];
              // res.data.package.forEach(
              //   (sub: { [key: string]: string }, index: number) => {
              //     const rowValues: string[] = [];
              //     for (const key in res.data.package[0]) {
              //       if (key == 'name') {
              //         headers.push(sub.name);
              //       } else if (index == 0) {
              //         colPackage.push(key);
              //       }
              //       if (key != 'name') {
              //         rowValues.push(sub[key]);
              //       }
              //     }
              //     colValues.push(rowValues);
              //   }
              // );
              // payload['headers'] = headers;
              // payload['colPackage'] = colPackage;
              // payload['colValues'] = colValues;
              // this.parsedTableDatas.push(payload);
              this.parsedTableDatas = res.data.package;
              // QUOTE TABLE DATAS END
            }
            // QUOTE TABLE DATAS END

            this.dataRes = res.data;
            // this.patchData(res.data);
            this.patchQuoteSelection();
          });
      });
  }

  patchQuoteSelection() {
    this.bookingOrder.patchValue({
      firstName: this.campaignData?.customer?.firstName,
      lastName: this.campaignData?.customer?.lastName,
      email: this.campaignData?.customer?.email,
      phoneCode: this.campaignData?.customer?.dialCode,
      phone: this.campaignData?.customer?.number,
    });
    this.bookingOrder.patchValue({
      policyStartDate: this.datepipe.transform(
        this.campaignData?.policyStartDate,
        'MM/dd/yyyy'
      ),
    });
    if (this.campaignData?.effectiveDateUpdateRequest) {
      this.checkPolicyDate(this.campaignData?.effectiveDateUpdateRequest);
      this.bookingOrder.patchValue({
        updatedPolicyEffectiveDate: this.datepipe.transform(
          this.campaignData?.effectiveDateRequestList?.at(-1)?.requestedEffectiveDate,
          'MM/dd/yyyy'
        )
      });
    }
    if (this.campaignData?.driverPhone?.length > 0) {
      this.bookingOrder.setControl(
        'driverPhone',
        this.resDriverNumber(this.campaignData?.driverPhone)
      );
    }
    if (this.campaignData?.quoteType === 'Manual') {
      const selectedData = this.campaignData?.proposalList?.at(0)?.quoteList?.filter((element: any) => (element?.otherPackageName === this.campaignData?.paymentDetails?.selectedPackage) || (element?.packageName === this.campaignData?.paymentDetails?.selectedPackage))?.at(0);
      this.quoteSelected(selectedData);
    } else {
      const selectQuoteButtonValues: any = this.selectQuoteButtonValues.find(
        (element) => element.type === this.campaignData?.paymentDetails?.selectedPackage
      );
      this.selectedQuoteColumn = selectQuoteButtonValues;
    }
    this.bookingOrder.patchValue({
      paymentMethod: this.campaignData?.paymentDetails?.paymentMethod,
      paymentMode: this.campaignData?.paymentDetails?.paymentMode,
      amount: this.campaignData?.paymentDetails?.selectedQuoteAmount,
      packageName: this.campaignData?.paymentDetails?.selectedPackage,
      carrier: this.campaignData?.carrier,
      selectedProposalId: this.campaignData?.paymentDetails?.selectedProposalId,
      selectedProposalQuoteId: this.campaignData?.paymentDetails?.selectedProposalQuoteId
    });
  }

  patchData(data: any) {
    this.bookingOrder.patchValue({
      firstName: data?.customer?.firstName,
      lastName: data?.customer?.lastName,
      email: data?.customer?.email,
      phoneCode: data?.customer?.dialCode,
      phone: data?.customer?.number,
    });
    if (
      ['Quote Completed', 'Quote Payment Ready'].includes(data?.status)
    ) {
      this.bookingOrder.patchValue({
        paymentMethod: data?.paymentHistory?.paymentMethod,
        paymentMode: data?.paymentHistory?.paymentMode,
        amount: data?.paymentHistory?.amount / 100,
        packageName: data?.paymentHistory?.packageName,
      });

      if (data?.paymentHistory?.driverPhone.length > 0) {
        this.bookingOrder.setControl(
          'driverPhone',
          this.resDriverNumber(data?.paymentHistory?.driverPhone)
        );
      }
    } else {
      if (data?.driverPhone?.length > 0) {
        this.bookingOrder.setControl(
          'driverPhone',
          this.resDriverNumber(data?.driverPhone)
        );
      }
    }
    if (data?.paymentHistory?.policyStartDate) {
      if (data?.paymentHistory?.policyStartDate === data?.policyStartDate) {
        this.bookingOrder.patchValue({
          policyStartDate: this.datepipe.transform(
            data?.paymentHistory?.policyStartDate,
            'MM/dd/yyyy'
          ),
        });
      } else {
        this.bookingOrder.patchValue({
          updatedPolicyEffectiveDate: this.datepipe.transform(
            data?.paymentHistory?.policyStartDate,
            'MM/dd/yyyy'
          ),
          policyStartDate: this.datepipe.transform(
            data?.policyStartDate,
            'MM/dd/yyyy'
          ),
          effectiveDateUpdateRequest: true
        });
      }
    } else {
      this.bookingOrder.patchValue({
        policyStartDate: this.datepipe.transform(
          data?.policyStartDate,
          'MM/dd/yyyy'
        ),
      });
    }
  }

  checkPolicyDate(val: any) {
    this.bookingOrder.patchValue({ effectiveDateUpdateRequest: val });
    if (val) {
      this.bookingOrder?.get('updatedPolicyEffectiveDate')?.setValidators([Validators.required]);
    } else {
      this.bookingOrder?.get('updatedPolicyEffectiveDate')?.patchValue('');
      this.bookingOrder?.get('updatedPolicyEffectiveDate')?.clearValidators();
    }
    this.bookingOrder?.get('updatedPolicyEffectiveDate')?.updateValueAndValidity();
  }

  resDriverNumber(driverNum: Array<{ [key: string]: string }>): FormArray {
    const formArray = new FormArray([]);
    driverNum.forEach((element) => {
      formArray.push(
        this.fb.group({
          number: element.number,
        })
      );
    });
    return formArray;
  }

  returnZero() {
    return 0;
  }

  trackByFn(item: number) {
    return item;
  }

  // SELECT THIS QUOTE BUTTON FUNCTION START
  onSelect(item: { [x: string]: string; type: string }) {
    this.selectedQuoteColumn = item;
    this.bookingOrder.patchValue({ packageName: item.type });
    if (
      this.bookingOrder.value.paymentMethod &&
      this.bookingOrder.value.paymentMode
    ) {
      this.getAmount(
        this.bookingOrder.value.paymentMethod,
        this.bookingOrder.value.paymentMode
      );
    }
  }
  // SELECT THIS QUOTE BUTTON FUNCTION END

  selectedQuote: any;
  quoteSelected(selectedData: any) {
    this.selectedQuoteColumn = selectedData;
    // const amount = selectedData?.paidInFull ||
    //   selectedData?.twoPayPlan ||
    //   selectedData?.twoPayTotalCostPremium ||
    //   selectedData?.fourPayPlan ||
    //   selectedData?.fourPayTotalPremium ||
    //   selectedData?.eftPayPlanMonthlyPremium ||
    //   selectedData?.initialEftInstallment ||
    //   selectedData?.eftTotalCostPremium ||
    //   selectedData?.ccPayPlanMonthlyPremium ||
    //   selectedData?.initialCcInstallment ||
    //   selectedData?.ccTotalCostPremium ||
    //   selectedData?.fullPayMonthlyPremium ||
    //   selectedData?.payPlanMonthlyPremiumTotal ||
    //   selectedData?.payPlanMonthlyPremiumDownpayment ||
    //   selectedData?.payPlanMonthlyPremimPerMonth
    this.bookingOrder.patchValue({
      packageName: selectedData?.otherPackageName || selectedData?.packageName,
      carrier: selectedData?.carrierName,
      selectedProposalId: this.dataRes?.proposalList?.length ? this.dataRes?.proposalList?.at(0)?.proposalId : '',
      selectedProposalQuoteId: selectedData?.proposalQuoteId || ''
    });
    this.selectedCarrierData = orderPageData.manualAutoPayment?.filter((x: any) => x?.carrier?.includes(selectedData?.carrierName));
    this.manualPaymentMethodOption = this.manualCarrierFilter.filter((x: any) => x?.carrier === selectedData?.carrierName)?.[0]?.paymentMethod?.map((x: string) => {
      return { name: x, value: x }
    });
    this.checkManualPayment();
    console.log('selected data', selectedData, this.selectedCarrierData, this.manualPaymentMethodOption);
  }

  // SELECT PAYMENT METHOD FUNCTION START
  selectMethod(item: string) {
    if (this.campaignData?.quoteType === 'Manual') {
      this.checkManualPayment();
      return;
    }
    if (
      this.bookingOrder.value.packageName &&
      this.bookingOrder.value.paymentMode && this.campaignData?.quoteType !== 'Manual'
    ) {
      this.getAmount(item, this.bookingOrder.value.paymentMode);
    }
  }
  // SELECT PAYMENT METHOD FUNCTION END

  // SELECT PAYMENT MODE FUNCTION START
  selectPayment(value: string) {
    if (this.campaignData?.quoteType === 'Manual') {
      this.checkManualPayment();
      return;
    }
    if (
      this.bookingOrder.value.packageName &&
      this.bookingOrder.value.paymentMethod && this.campaignData?.quoteType !== 'Manual'
    ) {
      this.getAmount(this.bookingOrder.value.paymentMethod, value);
    }
  }
  // SELECT PAYMENT MODE FUNCTION END

  manualCarrierFilter = [
    {
      carrier: "Bristol West",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'fullPayMonthlyPremium',
          'Installment': 'payPlanMonthlyPremiumDownpayment'
        },
        {
          "Full Pay Premium": 'fullPayMonthlyPremium',
          'Installment': 'payPlanMonthlyPremiumDownpayment'
        }
      ]
    },
    {
      carrier: "Hagerty",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'fullPayMonthlyPremium',
        },
        {
          "Full Pay Premium": 'fullPayMonthlyPremium',
        }
      ]
    },
    {
      carrier: "Mercury",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'eftTotalCostPremium',
          'Installment': 'initialEftInstallment'
        },
        {
          "Full Pay Premium": 'ccTotalCostPremium',
          'Installment': 'initialCcInstallment'
        }
      ]
    },
    {
      carrier: "Metlife (Foremost)",
      paymentMethod: ['Electronic Funds Transfer'],
      paymentMode: [
        {
          "Full Pay Premium": 'paidInFull',
          'Installment': 'initialEftInstallment'
        }
      ]
    },
    {
      carrier: "Progressive",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'eftTotalCostPremium',
          'Installment': 'initialEftInstallment'
        },
        {
          "Full Pay Premium": 'ccTotalCostPremium',
          'Installment': 'initialCcInstallment'
        }
      ]
    },
    {
      carrier: "Progressive with Snapshot",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'eftTotalCostPremium',
          'Installment': 'initialEftInstallment'
        },
        {
          "Full Pay Premium": 'ccTotalCostPremium',
          'Installment': 'initialCcInstallment'
        }
      ]
    },
    {
      carrier: "Safeco",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'eftTotalCostPremium',
          'Installment': 'initialEftInstallment'
        },
        {
          "Full Pay Premium": 'ccTotalCostPremium',
          'Installment': 'initialCcInstallment'
        }
      ]
    },
    {
      carrier: "Travelers",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'eftTotalCostPremium',
          'Installment': 'initialEftInstallment'
        },
        {
          "Full Pay Premium": 'ccTotalCostPremium',
          'Installment': 'initialCcInstallment'
        }
      ]
    },
    {
      carrier: "Vault",
      paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      paymentMode: [
        {
          "Full Pay Premium": 'paidInFull',
          '2 - Pay Plan': 'twoPayTotalCostPremium',
          '4 - Pay Plan': 'fourPayTotalPremium',
        },
        {
          "Full Pay Premium": 'paidInFull',
          '2 - Pay Plan': 'twoPayTotalCostPremium',
          '4 - Pay Plan': 'fourPayTotalPremium',
        }
      ]
    }
  ]

  checkManualPayment() {
    if (this.bookingOrder.value?.paymentMode) {
      this.selectedCarrierData.every((option: any) => option.value !== this.bookingOrder.value?.paymentMode)
        && this.bookingOrder?.get('paymentMode')?.patchValue('');
    }
    if (this.bookingOrder.value?.paymentMethod) {
      this.manualPaymentMethodOption.every((option: any) => option.value !== this.bookingOrder.value?.paymentMethod)
        && this.bookingOrder?.get('paymentMethod')?.patchValue('');
    }
    if (this.bookingOrder.value.paymentMode && this.bookingOrder.value.paymentMethod) {
      const carrierData: any = this.manualCarrierFilter.filter((x: any) => x?.carrier === this.selectedQuoteColumn?.carrierName);
      const index = carrierData?.[0]?.paymentMethod.indexOf(this.bookingOrder.value.paymentMethod)
      const amount = this.selectedQuoteColumn?.[`${carrierData?.[0]?.paymentMode?.[index]?.[this.bookingOrder.value.paymentMode]}`]
      const amountInNumber = parseFloat(amount?.replace(/\$|,/g, ''));
      const finalAmount = this.selectedQuoteColumn?.carrierName === 'Vault' && this.bookingOrder.value.paymentMethod === 'Credit Card'
        ? 1.0299 * amountInNumber : amountInNumber;
      this.bookingOrder.patchValue({
        amount: parseFloat(finalAmount?.toString())
          ?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
      });
    }
  }

  getAmount(paymentMethod: string, paymentMode: string) {
    console.log('payment method', paymentMethod)
    console.log('paymentMode', paymentMode)
    const filterByMode = this.selectedCarrierData.find(
      (res: any) => res.paymentMode === paymentMode
    );
    console.log('filter ', filterByMode)
    console.log('this.selectedQuoteColumn ', this.selectedQuoteColumn)
    filterByMode.package.forEach((res: any) => {
      if (res.paymentMethod.includes(paymentMethod)) {
        console.log('testingggg ', res.key, this.selectedQuoteColumn[res.key])
        const amountInNumber = parseFloat(this.selectedQuoteColumn[res.key].replace(/\$|,/g, ''));
        const finalAmount = this.campaignData?.carrier === 'Vault' && paymentMethod === 'Credit Card'
          ? 1.0299 * amountInNumber : amountInNumber;
        this.bookingOrder.patchValue({
          amount: parseFloat(finalAmount?.toString())
            ?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
        });
      }
    });
  }

  submitBooking() {
    this.submitting = true;
    this.bookingOrder.markAllAsTouched();
    if (this.bookingOrder.invalid) {
      this.submitting = false;
      this.wizzipayServiceAPI.snackbar('Please fill all the fields');
      return;
    }
    if (this.bookingOrder.invalid) {
      this.submitting = false;
      this.wizzipayServiceAPI.snackbar('Please fill all the fields');
      return;
    }
    const payload = {
      userDetails: {
        firstName: this.bookingOrder.value.firstName,
        lastName: this.bookingOrder.value.lastName,
        phoneCode: '+' + this.bookingOrder.value.phoneCode,
        phoneNumber: this.bookingOrder.value.phone,
        email: this.bookingOrder.value.email,
      },
      paymentDetails: {
        tripName: this.bookingOrder.value.purpose,
        amount: this.bookingOrder.value.amount,
      },
      packageName: this.bookingOrder.value.packageName,
      merchantId: this.merchantId,
      campaignId: this.campaignId,
      driverPhone: this.bookingOrder.value.driverPhone,
      policyStartDate: `${new Date(this.bookingOrder.value.updatedPolicyEffectiveDate || this.bookingOrder.value.policyStartDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
      paymentMode: this.bookingOrder.value.paymentMode,
      paymentMethod: this.bookingOrder.value.paymentMethod,
    };
    let quote: any = {
      effectiveDateUpdateRequest: false,
      effectiveDateRequestList: this.campaignData?.effectiveDateRequestList || [],
      paymentDetails: {
        paymentMode: this.bookingOrder.value.paymentMode,
        selectedPackage: this.bookingOrder.value.packageName,
        paymentMethod: this.bookingOrder.value.paymentMethod,
        selectedQuoteAmount: `${this.bookingOrder.value.amount}`,
        selectedProposalId: this.bookingOrder.value?.selectedProposalId || '',
        selectedProposalQuoteId: this.bookingOrder.value?.selectedProposalQuoteId || ''
      },
      carrier: this.bookingOrder.value.carrier
    }
    if (this.bookingOrder.value.effectiveDateUpdateRequest) {
      quote['effectiveDateUpdateRequest'] = true;
      quote['effectiveDateRequestList'].push({
        currentEffectiveDate: `${new Date(this.bookingOrder.value.policyStartDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
        requestedEffectiveDate: `${new Date(this.bookingOrder.value.updatedPolicyEffectiveDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
        updatedBy: 'Customer'
      })
    }
    if (this.campaignData?.status === 'Quote Selection Email Sent') {
      quote['statusChange'] = true;
      quote['status'] = 'Quote Selection Finalized';
    }
    this.wizzipayServiceAPI
      .updateOrder(quote, this.merchantId, this.campaignId)
      .subscribe(
        () => {
          if (this.bookingOrder.value.effectiveDateUpdateRequest) {
            // <p>Address: ${this.cData?.address?.address}</p>
            const url = this.campaignData?.quoteType === 'Manual'
              ? `${environment.autoPortalURL}/merchant/manual/${this.merchantId}/${this.campaignId}`
              : `${environment.autoPortalURL}/merchant/setup/${this.merchantId}/${this.campaignId}`;
            const btnText = `<table align="center" cellpadding="0" cellspacing="0">
              <tr>
                <td style="text-align: center;">
                  <div style="text-align: center;">
                  <!--[if mso]>
            <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word"
                          href="${url}"
                          style="height:auto;v-text-anchor:middle;width:400px" arcsize="10%" strokecolor="#007cba"
                          fillcolor="#007cba">
              <w:anchorlock/>
              <center style="color:#ffffff;font-family:sans-serif;font-size:20px;">&nbsp; Configure Auto Quote &nbsp;</center>
            </v:roundrect>
            <![endif]-->
            <!--[if !mso]><!-- -->
            <a href="${url}"
               style="background-color:#007cba;border-radius:6px;color:#ffffff;display:inline-block;font-family:sans-serif;font-size:20px;line-height:36px;text-align:center;text-decoration:none;width:fit-content;-webkit-text-size-adjust:none;">
               &nbsp; Configure Auto Quote &nbsp; 
            </a>
            <!--<![endif]-->
                  </div>
                </td>
              </tr>
            </table>`
            const jsonData = JSON.stringify({
              recepientEmail: [this.campaignData?.agent?.email],
              emailCC: [],
              emailSubject: 'Policy Effective Date Update Alert - Auto',
              emailBody: `<p>Hi</p>
              <p>Effective date has been changed by the client , To reveal payment information, please resubmit the quote by clicking Configure in dashboard. Once submitted you will receive a popup, the popup will determine whether the quote request goes to client for approval ?</p>
              <h4>Customer Info :</h4>
              <p>Name: ${this.campaignData?.customer?.firstName} ${this.campaignData?.customer?.lastName}</p>
              <p>Email Address: <a href = "mailto: ${this.campaignData?.customer?.email}">${this.campaignData?.customer?.email}</a></p>
              <p>Phone Number: ${this.campaignData?.driverPhone?.at(0)?.number}</p>
              <p>Insurance Type: Auto</p>
              <p>Click the below button to redirect to the Configure Auto Quote page:</p>
              ${btnText}`,
              quotePDFAttachment: false,
              filePath: [],
              summaryPDFAttchment: false,
              otherAttachment: false
            });
            const emailFormData = new FormData();
            emailFormData.append('content', new Blob([jsonData], { type: 'application/json' }), 'file.json');
            this.wizzipayServiceAPI.sendCommonEmail(emailFormData).subscribe({
              next: () => {
                this.displayDialog = true;
                this.submitting = false;
                this.bookingOrder.reset();
              },
              error: () => {
                this.wizzipayServiceAPI.snackbar('Error');
                this.submitting = false;
              },
            });
          } else {
            this.displayDialog = true;
            this.submitting = false;
            this.bookingOrder.reset();
          }
        },
        (err) => {
          this.submitting = false;
          this.wizzipayServiceAPI.snackbar(err.message);
        }
      );

    //
    return;
    this.wizzipayServiceAPI.orderData(payload).subscribe(
      (res) => {
        if (res.status == true) {
          this.transactionId = res?.data?.transactionId;
          this.displayDialog = true;
          this.submitting = false;
          this.bookingOrder.reset();
        }
      },
      (err) => {
        if (err) {
          this.submitting = false;
          this.wizzipayServiceAPI.snackbar('Error');
        }
      }
    );
  }

  routeToPayment() {
    this.router.navigate([
      `/checkout/${this.merchantId}/${this.campaignId}`,
    ]);
  }

  //Mobile - Section
  displayMobilePopup = false;
  mobilePopupQuoteData: any;
  viewDetails(index: number) {
    this.displayMobilePopup = true;
    this.mobilePopupQuoteData = {
      quoteList: []
    }
    this.mobilePopupQuoteData['quoteList'].push(this.campaignData?.proposalList[0]?.quoteList[index]);
  }

  closeMobileDialog() {
    this.displayMobilePopup = false;
  }
}
