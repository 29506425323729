import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-info',
  templateUrl: './email-info.component.html',
  styleUrls: ['./email-info.component.css']
})
export class EmailInfoComponent implements OnInit {
  emailForm: FormGroup = this.fb.group({
    //emailCC: this.fb.array([this.newEmail()]),
    recepientEmail: ['', [Validators.required, Validators.email]],
    subject: ['', [Validators.required]],
    customer: this.fb.group({
      firstName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],
      ],
      lastName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
    }),
    address: this.fb.group({
      fullAddress: [''],
      streetNumber: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      city: ['', [Validators.required, Validators.pattern('[a-z A-Z.s]+')]],
      state: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
      ],
      country: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
      ],
      zip: ['', [Validators.required, Validators.pattern(/^[0-9 ]{1,7}$/)]],
    }),
  });
  emailError = false;
  userInfo: any;
  mailSuccess = false;
  backOfficeURL = environment.autoPortalURL;

  public moduleConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'], // remove formatting button
      ['link'],
    ],
  };
  emailContent = {
    body: '',
    btnData: ''
  };
  submitting = false;
  merchantId = '';

  constructor(
    public fb: FormBuilder,
    public stepper: MatStepper,
    private apiService: WizzipayService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
    });
    const data: any = sessionStorage.getItem('userGenInfo');
    this.userInfo = JSON.parse(data)
    this.emailContent.body = `Hi  ${this.userInfo?.customer?.firstName} ${this.userInfo?.customer?.lastName},<p>Please click the link to start Auto quote.</p><br><br><br>`;
    this.emailForm.patchValue({
      subject: 'HH Insurance - Auto Quote URL',
      recepientEmail: this.userInfo?.customer?.emailAddress
    });
  }

  back() {
    this.stepper.previous()
  }

  closePopup() {
    this.mailSuccess = false;
    this.stepper.reset();
    this.router.navigate([`/merchant/leads/${this.merchantId}`]);
  }

  sendEmail() {
    this.submitting = true;
    const emailCC = this.userInfo?.customer?.emailAddressCC?.map((x: any) => x?.emailCC);
    let payload = { ...this.userInfo };
    payload.customer.emailAddressCC = emailCC;
    this.apiService.saveCustomerData(this.userInfo?.customer)
      .subscribe({
        next: (customerRes) => {
          this.apiService.saveAgentData({ agent: this.userInfo?.agent })
            .subscribe({
              next: (agentRes) => {
                this.apiService.createAutoQuote(customerRes?.customerId, {
                  agentIdList: agentRes?.agentId,
                  source: 'Exclusive',
                  quoteIdType: 'Auto_Quote',
                  quoteType: 'Manual',
                  parentQuoteId: ''
                }).subscribe({
                  next: (quoteRes) => {
                    const btnText = `<table align="center" cellpadding="0" cellspacing="0">
                      <tr>
                        <td style="text-align: center;">
                          <div style="text-align: center;">
                          <!--[if mso]>
                    <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word"
                                  href="${this.backOfficeURL}/customer/autolead?id=${customerRes?.customerId}&qid=${quoteRes?.data?.quoteId}"
                                  style="height:auto;v-text-anchor:middle;width:400px" arcsize="10%" strokecolor="#007cba"
                                  fillcolor="#007cba">
                      <w:anchorlock/>
                      <center style="color:#ffffff;font-family:sans-serif;font-size:20px;">&nbsp; Start Auto Quote &nbsp;</center>
                    </v:roundrect>
                    <![endif]-->
                    <!--[if !mso]><!-- -->
                    <a href="${this.backOfficeURL}/customer/autolead?id=${customerRes?.customerId}&qid=${quoteRes?.data?.quoteId}"
                       style="background-color:#007cba;border-radius:6px;color:#ffffff;display:inline-block;font-family:sans-serif;font-size:20px;line-height:36px;text-align:center;text-decoration:none;width:fit-content;-webkit-text-size-adjust:none;">
                       &nbsp; Start Auto Quote &nbsp; 
                    </a>
                    <!--<![endif]-->
                          </div>
                        </td>
                      </tr>
                    </table>`
                    const emailData: any = {
                      recepientEmail: [this.emailForm.value?.recepientEmail],
                      emailCC: emailCC,
                      emailSubject: this.emailForm.value?.subject,
                      emailBody: this.emailContent.body?.replace('</p>', `</p>${btnText}`),
                      quotePDFAttachment: false,
                      quotePDFAttachmentPath: {},
                      summaryPDFAttchment: false,
                      otherAttachment: false
                    }
                    const fileJson = new File([JSON.stringify(emailData)], "file.json", { type: "application/json" });
                    const formData = new FormData();
                    formData.append('content', fileJson, "file.json");
                    this.apiService
                      .sendCommonEmail(formData)
                      .subscribe({
                        next: () => {
                          this.apiService.updateAutoStatus(customerRes?.customerId,
                            quoteRes?.data?.quoteId, { status: 'Quote Email Sent' })
                            .subscribe({
                              next: () => {
                                sessionStorage.clear();
                                this.submitting = false;
                                this.mailSuccess = true;
                              },
                              error: () => this.showError()
                            });
                        },
                        error: () => this.showError()
                      });
                  },
                  error: () => this.showError()
                });
              },
              error: () => this.showError()
            });
        },
        error: () => this.showError()
      });
  }

  showError() {
    this.submitting = false;
    this.apiService.snackbar('Error');
  }
}
