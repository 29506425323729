import { Component, NgZone, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  userInfo: FormGroup = this.fb.group({
    customer: this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)],],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],],
      middleName: [''],
      emailAddress: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)]],
      dob: [''],
      namePrefix: '',
      nameSuffix: '',
      gender: '',
      maritalStatus: '',
      educationLevel: '',
      industry: '',
      occupation: '',
      ssn: '',
      address: this.fb.group({
        streetNumber: ['', [Validators.required, Validators.pattern(/^-?\d*\.?\d*$/)]],
        streetName: ['', [Validators.required]],
        city: ['', [Validators.required, Validators.pattern('[a-z A-Z.s]+')]],
        state: [
          '',
          [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
        ],
        country: [
          '',
          [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
        ],
        zip: ['', [Validators.required, Validators.pattern(/^[0-9 ]{1,7}$/)]],
        latitude: '',
        longitude: '',
        address: ''
      }),
      priorAddress: this.fb.group({
        streetNumber: '',
        streetName: '',
        city: '',
        country: '',
        zip: '',
        latitude: '',
        longitude: '',
        address: '',
        state: ''
      }),
      dependents: this.fb.array([]),
      emailAddressCC: this.fb.array([this.newEmail()]),
    }),
    agent: this.fb.array([this.agentListForm({ phoneNumber: '(727) 498-5551' })]),
    salesForce: ['']
  });

  addr: any;
  addrKeys: any;
  agentList = [
    // {
    //   emailAddress: "dev@hhinsgroup.com",
    //   firstName: "Jake",
    //   lastName: "Holehouse",
    //   name: 'Jake Holehouse',
    //   phoneNumber: "7274985551",
    // },
    // {
    //   emailAddress: "dev@hhinsgroup.com",
    //   firstName: "Landon",
    //   lastName: "Cassidy",
    //   name: 'Landon Cassidy',
    //   phoneNumber: "7274985551",
    // },
    // {
    //   emailAddress: "scd@hhinsgroup.com",
    //   firstName: "Sean",
    //   lastName: "DeGraw",
    //   name: 'Sean DeGraw',
    //   phoneNumber: "7274985551",
    // }
  ]

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private zone: NgZone,
    public stepper: MatStepper,
    private service: WizzipayService
  ) {
    this.getAgentList();
  }

  get address(): FormGroup {
    return this.userInfo?.get('customer')?.get('address') as FormGroup;
  }

  // get agent(): FormGroup {
  //   return this.userInfo.get('agent') as FormGroup;
  // }

  get customer(): FormGroup {
    return this.userInfo?.get('customer') as FormGroup;
  }

  get multiCustomer(): FormArray {
    return this.userInfo?.get('customer')?.get('dependents') as FormArray;
  }

  get multiAgent(): FormArray {
    return this.userInfo?.get('agent') as FormArray;
  }

  get secondaryEmail(): FormArray {
    return this.userInfo?.get('customer')?.get('emailAddressCC') as FormArray;
  }

  ngOnInit(): void {
    this.customer.patchValue({ dob: new Date() })
    const data: any = sessionStorage.getItem('userGenInfo');
    if (data) {
      this.patchData(data);
    }
  }

  getAgentData() {
    this.service.getAgentList().subscribe({
      next: (agentRes) => {
        for (const agent of agentRes?.data) {
          agent['name'] = `${agent?.firstName} ${agent?.lastName}`;
        }
        this.agentList = agentRes?.data;
      },
      error: () => { }
    })
  }

  getAgentList() {
    this.service.getAgentList().subscribe({
      next: (agentList) => this.agentList = agentList?.agents,
      error: () => this.service.snackbar('Error')
    })
  }

  selectedAgent(agentName: any) {
    const agentForm = this.multiAgent;
    const agent: any = this.agentList?.filter((x: any) => x?.fullName === agentName)?.[0];
    agent.phoneNumber = agent?.phoneNumber
      ? agent?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
    agent['email'] = agent?.emailAddress || '';
    this.multiAgent.at(agentForm.length - 1).patchValue(agent);
  }

  async patchData(data: any) {
    let userInfo = JSON.parse(data);
    // userInfo.phoneNumber = userInfo?.phoneNumber
    //   ? `(${userInfo?.phoneNumber?.substr(0, 3)}) ${userInfo?.phoneNumber?.substr(3, 3)}-${userInfo?.phoneNumber?.substr(6, 4)}`
    //   : '';
    // if (userInfo?.phoneNumber) {
    //   const usNumber = userInfo?.phoneNumber.split(/(\d{3})(\d{3})(\d{4})/);
    //   userInfo.phoneNumber = '(' + usNumber[1] + ') ' + usNumber[2] + '-' + usNumber[3];
    // } else {
    //   userInfo.phoneNumber = '';
    // }
    let userInfoData: any = { ...userInfo };
    if (userInfoData?.customer?.emailAddressCC?.length > 1) {
      this.resEmail(userInfoData?.customer?.emailAddressCC)
    }
    userInfoData.customer.phoneNumber = await this.phoneNumberConversion(userInfoData?.customer?.phoneNumber);
    const depLength = userInfoData?.customer?.dependents?.length;
    for (let index = 0; index < depLength; index++) {
      this.pushCustomer();
      userInfoData.customer.dependents.at(index).phoneNumber
        = await this.phoneNumberConversion(userInfoData?.customer.dependents.at(index).phoneNumber);
    };
    const agentLength = userInfoData.agent?.length;
    for (let index = 0; index < agentLength; index++) {
      userInfoData.agent.at(index).phoneNumber
        = await this.phoneNumberConversion(userInfoData.agent.at(index).phoneNumber);
      index != 0 && this.pushAgent();
    };
    this.userInfo.patchValue(userInfoData);
  }

  resEmail(val: any) {
    const emailLength = val?.length;
    for (let i = 1; i < emailLength; i++)
      this.addEmailCC();
  }

  addEmailCC() {
    const items = this.secondaryEmail;
    items.push(this.newEmail());
  }

  async phoneNumberConversion(val: string) {
    let phoneNumber = '';
    if (val) {
      const USNumber = val?.split(/(\d{3})(\d{3})(\d{4})/);
      phoneNumber = '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3];
    }
    return phoneNumber;
  }

  addDriver(control: any, index: number) {
    control[index].get('emailCC').markAllAsTouched();
    if (control[index].get('emailCC').value === '') {
      this.service.snackbar('Please fill this email to add more');
      return;
    } else if (control[index].get('emailCC').invalid) {
      this.service.snackbar('Please enter a valid email to add more');
      return;
    }
    const array = this.secondaryEmail;
    array.push(this.newEmail());
  }

  removeDriver(i: number) {
    const array = this.secondaryEmail;
    array.removeAt(i);
  }

  newEmail(): FormGroup {
    return this.fb.group({
      emailCC: ['', [Validators.pattern(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      )]],
    });
  }

  pushCustomer() {
    const items = this.multiCustomer;
    items.push(this.customerList());
  }

  addCustomer() {
    if (
      this.customer?.get('firstName')?.invalid
      || this.customer?.get('lastName')?.invalid
      || this.customer?.get('phoneNumber')?.invalid
      || this.customer?.get('emailAddress')?.invalid
      || this.multiCustomer?.invalid
    ) {
      this.customer?.get('firstName')?.markAsTouched();
      this.customer?.get('lastName')?.markAsTouched();
      this.customer?.get('phoneNumber')?.markAsTouched();
      this.customer?.get('emailAddress')?.markAsTouched();
      this.multiCustomer?.markAllAsTouched();
      this.service.snackbar('Please fill all the fields')
      return;
    }
    const items = this.multiCustomer;
    items.push(this.customerList());
  }

  addAgent() {
    if (this.multiAgent.invalid) {
      this.multiAgent?.markAllAsTouched();
      this.service.snackbar('Please fill all the fields')
      return;
    }
    this.pushAgent();
  }

  pushAgent() {
    const items = this.multiAgent;
    items.push(this.agentListForm({}));
  }

  removeCustomer(i: number) {
    const array = this.multiCustomer;
    array.removeAt(i);
  }

  removeAgent(i: number) {
    const array = this.multiAgent;
    array.removeAt(i);
  }

  customerList(): FormGroup {
    return this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],],
      emailAddress: ['', [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
      phoneNumber: ['', [Validators.pattern(/^[0-9-()_ ]{14}$/)]],
      namePrefix: '',
      nameSuffix: '',
      middleName: '',
      gender: '',
      maritalStatus: '',
      educationLevel: '',
      industry: '',
      occupation: '',
      relationship: '',
      ssn: ''
    });
  }

  agentListForm(val: any): FormGroup {
    return this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],],
      emailAddress: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
      phoneNumber: [val?.phoneNumber || '', [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)]],
      companyName: [''],
      agentType: ['HH Agent']
    });
  }

  setAddress(addrObj: any) {
    this.address?.get('address')?.patchValue(addrObj.address);
    this.zone.run(() => {
      this.addr = addrObj;
      this.addrKeys = Object.keys(addrObj);
      this.addressMaptoForm();
    });
  }

  async addressMaptoForm() {
    this.address?.get('streetNumber')?.patchValue(this.addr.street_number);
    this.address?.get('city')?.patchValue(this.addr.city);
    this.addr.state === 'Florida'
      ? this.address?.get('state')?.patchValue('FL')
      : this.address?.get('state')?.patchValue(this.addr.state);
    this.address?.get('country')?.patchValue(this.addr.country);
    this.address?.get('zip')?.patchValue(this.addr.ZIP_code);
    this.address?.get('streetName')
      ?.patchValue(this.addr.street_address);
    this.address?.get('latitude')?.patchValue(this.addr.lat);
    this.address?.get('longitude')?.patchValue(this.addr.lng);
  }
  loadingStatus = false;
  floridatValidation = [
    'fl',
    'florida'
  ];
  invalidAddress = false;
  next() {
    this.loadingStatus = true;
    if (this.userInfo.invalid) {
      this.userInfo.markAllAsTouched();
      this.service.snackbar('Please fill all the fields');
      this.loadingStatus = false;
      return;
    }
    let userData: any = this.userInfo.value;
    userData.customer['phoneNumber'] = userData?.customer?.phoneNumber?.replace(/[^\d]/g, '');
    const depLength = userData.customer.dependents?.length;
    for (let index = 0; index < depLength; index++) {
      userData.customer.dependents.at(index).phoneNumber
        = userData.customer.dependents.at(index).phoneNumber?.replace(/[^\d]/g, '');
    };
    const agentLength = userData.agent?.length;
    for (let index = 0; index < agentLength; index++) {
      userData.agent.at(index).phoneNumber
        = userData.agent.at(index).phoneNumber?.replace(/[^\d]/g, '');
    };
    // if (this.floridatValidation?.includes(userData?.customer?.address?.state?.trim()?.toLowerCase())) {
    sessionStorage.setItem('userGenInfo', JSON.stringify(userData));
    this.stepper.next();
    // } else {
    //   this.invalidAddress = true;
    // }
    this.loadingStatus = false;
  }
}
