<div class="col-lg-12">
    <div class="row">
        <ng-container *ngIf="autoData?.autoDetails">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-2 autoHeaders" [ngClass]="listType=='autoList' ? 'bgBlue': 'bgWhite'"
                    (click)="listType = 'autoList'">
                    Auto List
                </div>
                <div class="col-lg-2 autoHeaders" [ngClass]="listType=='driverList' ? 'bgBlue': 'bgWhite'"
                    (click)="listType = 'driverList'">
                    Driver List
                </div>
                <div class="col-lg-2 autoHeaders" [ngClass]="listType=='autoPolicy' ? 'bgBlue': 'bgWhite'"
                    (click)="listType = 'autoPolicy'">
                    Auto Policy
                </div>
                <div class="col-lg-3 autoHeaders" [ngClass]="listType=='carrierList' ? 'bgBlue': 'bgWhite'"
                    (click)="listType = 'carrierList'">
                    Carrier List
                </div>
                <div class="col-lg-3 autoHeaders" [ngClass]="listType=='priorDec' ? 'bgBlue': 'bgWhite'"
                    (click)="listType = 'priorDec'">
                    Prior Dec Page
                </div>
            </div>
        </div>
        <div class="col-lg-12 pt-3 text-start" *ngIf="listType=='autoPolicy'">
            <div class="row">
                <ng-container *ngFor="let data of autoFieldsData?.autoPolicy; let i = index">
                    <div class="col-3 pb-3">
                        <strong>{{data?.fieldName}}</strong>
                    </div>
                    <div class="col-3 pb-3">
                        : {{data?.value}}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-lg-12 pt-3 text-start" *ngIf="listType=='driverList'">
            <div class="row">
                <ng-container *ngFor="let data of autoFieldsData?.driverList; let i = index">
                    <h6>Driver {{i+1}}:</h6>
                    <ng-container *ngFor="let list of data; let j = index">
                        <div class="col-2 pb-3">
                            <strong>{{list?.fieldName}}</strong>
                        </div>
                        <div class="col-4 pb-3">
                            : {{list?.value}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="col-lg-12 pt-3 text-start" *ngIf="listType=='autoList'">
            <div class="row">
                <ng-container *ngFor="let data of autoFieldsData?.autoList; let i = index">
                    <h6>Auto {{i+1}}:</h6>
                    <ng-container *ngFor="let list of data; let j = index">
                        <div class="col-2 pb-3">
                            <strong>{{list?.fieldName}}</strong>
                        </div>
                        <div class="col-4 pb-3">
                            : {{list?.value}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="col-lg-12 pt-3 text-start" *ngIf="listType=='priorDec'">
            <div class="row justify-content-md-center">
                <div class="col-lg-6 mt-3">
                    <div class="row">
                        <div class="col-lg-2">
                            <strong>File Name</strong>
                        </div>
                        <div class="col-lg-10">
                            : {{autoData?.autoDetails?.priorDecPage?.name || 'Not Uploaded'}}
                        </div>
                        <div class="col-lg-2">
                            <strong>File Size</strong>
                        </div>
                        <div class="col-lg-10">
                            : {{autoData?.autoDetails?.priorDecPage?.size || 'Not Uploaded'}}
                        </div>
                        <div class="col-lg-2">
                            <strong>Download</strong>
                        </div>
                        <div class="col-lg-10">
                            : <a *ngIf="autoData?.priorDeclarationPDF" class="btn btn-type-two px-2"
                                href="{{autoData?.priorDeclarationPDF}}" target="_blank" type="button">
                                <div><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Prior Dec Page
                                </div>
                            </a>
                            <span *ngIf="!autoData?.priorDeclarationPDF">Not Uploaded</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <div class="row">
                        <div class="col-lg-4">
                            <strong>Customer Id</strong>
                        </div>
                        <div class="col-lg-8">
                            : {{autoData?.customerId}}
                        </div>
                        <div class="col-lg-4">
                            <strong>Quote Id</strong>
                        </div>
                        <div class="col-lg-8">
                            : {{autoData?.quoteId}}
                        </div>
                        <div class="col-lg-4">
                            <strong>Lead Id</strong>
                        </div>
                        <div class="col-lg-8">
                            : {{autoData?.carriers[0]?.leadID}}
                        </div>
                    </div>
                </div>
                <!-- <div class="col-4 pb-3" *ngFor="let data of autoFieldsData?.autoPolicy; let i = index">
                    <strong>{{data?.fieldName}}</strong> : {{data?.value}}
                </div> -->
            </div>
        </div>
        <div class="col-lg-12 pt-3 text-start" *ngIf="listType=='carrierList'">
            <div class="row justify-content-md-center">
                <!-- <div class="col-4 pb-3" *ngFor="let data of autoFieldsData?.autoPolicy; let i = index">
                    <strong>{{data?.fieldName}}</strong> : {{data?.value}}
                </div> -->
                <div class="col-lg-10 mt-3">
                    <div class="table-responsive">
                        <table class="table table-bordered mb-0">
                            <thead class="text-center">
                                <tr class="row100 head">
                                    <th>S.No</th>
                                    <th>Carrier</th>
                                    <th>Premium</th>
                                    <th>Lead ID</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="autoData?.carriers?.length > 0" class="text-center">
                                <ng-container *ngFor="let data of autoData?.carriers; let i = index">
                                    <tr class="row100">
                                        <td class="text-center">
                                            {{i+1}}
                                        </td>
                                        <td class="text-center">
                                            {{data?.carrier}}
                                        </td>
                                        <td>{{data?.premium}}</td>
                                        <td>{{data?.leadID}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="autoData?.carriers?.length == 0">
                                <tr>
                                    <td colspan="4" class="text-center">
                                        <h6 class="mb-0">No Carriers found</h6>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!autoData?.autoDetails">
        <div class="col-lg-12">
            <h5>Auto Details not found</h5>
        </div>
    </ng-container>
    </div>
</div>