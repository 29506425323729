<div class="container-fluid">
  <div class="row row-height row-eq-height">
    <div class="col-sm-12 mt-2 text-center">
      <h2 class="mobile-header">Insurance Quote Options</h2>
      <p *ngIf="dataRes?.status !== 'Quote Payment Ready' && dataRes?.status !== 'completed'">Click on the quote you
        like and
        fill out the preferred payment details on the right.</p>
    </div>
    <div class="col-lg-7 background-image px-0 quote-section">
      <div class="content-left-wrapper">
        <div class="col-md-12">
          <div class="p-3 w-100">
            <h6>{{dataRes?.insuranceType}} Insurance Option</h6>
            <div class="tableHeight">
              <ng-container *ngIf="campaignData?.quoteType === 'Automated'">
                <table class="table table-bordered mb-0">
                  <thead>
                    <th>Package</th>
                    <th [ngClass]="{'columnColor': column == selectedQuoteColumn?.type}"
                      *ngFor="let column of parsedTableDatas[0]?.headers">
                      {{column}}
                    </th>
                  </thead>
                  <tbody>
                    <tr *ngIf="dataRes?.status !== 'Quote Payment Ready' && dataRes?.status !== 'completed'">
                      <td class="firstCol">Select any one</td>
                      <ng-container *ngFor="let item of selectQuoteButtonValues">
                        <td [ngClass]="{'columnColor': item.type == selectedQuoteColumn?.type}">
                          <button id="select" (click)="onSelect(item)" class="btn btn-type-two w-100" type="button">
                            <span>Select this quote</span>
                          </button>
                        </td>
                      </ng-container>
                    </tr>
                    <tr *ngFor="let row of parsedTableDatas[0]?.colPackage; index as i">
                      <td>{{row}}</td>
                      <td [ngClass]="{'columnColor': column == selectedQuoteColumn?.type}"
                        *ngFor="let column of parsedTableDatas[0]?.headers; index as j">
                        {{parsedTableDatas[0]?.colValues[j][i]}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <ng-container *ngIf="campaignData?.quoteType === 'Manual'">
                <app-quote-list [noOfQuotes]="campaignData?.proposalList[0]?.quoteList?.length"
                  [quoteProposalData]="campaignData?.proposalList[0]" [quoteSelection]="true"
                  [selectedQuote]="selectedQuoteColumn"
                  [disableSelection]="campaignData?.status ==='Quote Payment Ready'|| campaignData?.status ==='completed'"
                  (selectedQuoteData)="quoteSelected($event)"></app-quote-list>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-12 py-3">
      <div class="row">
        <div class="col-6">
          <a style="font-size: 12px" class="five text-start">Insurance list</a>
        </div>
        <div class="col-6">
          <a style="font-size: 12px" class="five text-end">Proposal List</a>
        </div>
      </div>
    </div> -->
    <div class="col-12 mobile-quote">
      <p class="status">Auto</p>
      <div class="list-scroll">
        <ul class="list-items px-2" *ngFor="let quotes of campaignData?.proposalList[0]?.quoteList; index as i">
          <div>
            <li>
              <div class="content"
                [ngClass]="{'selected': quotes?.proposalQuoteId === selectedQuoteColumn?.proposalQuoteId}">
                <!-- <p class="headAlign"> <span class="head pl-2" matTooltip="Apples to Apples">Apples to Apples
                    </span>
                    <-- <span><button mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                        <mat-icon (click)="viewDetails()">
                          remove_red_eye</mat-icon>
                      </button></span>  --
                  <a style="font-size: 10px" class="five">View Quote</a>
                  </p> -->
                <div class="col-12">
                  <div class="row">
                    <div class="col-8">
                      <p class="headAlign"> <span class="head px-2"
                          matTooltip="{{quotes?.otherPackageName || quotes?.packageName}}">{{quotes?.otherPackageName
                          || quotes?.packageName}}
                        </span>
                        <!-- <span><button mat-icon-button [matMenuTriggerFor]="menu"
                            aria-label="Example icon-button with a menu">
                            <mat-icon (click)="viewDetails()">
                              remove_red_eye</mat-icon>
                          </button></span>  -->

                      </p>
                    </div>
                    <div class="col-4 view-quote">
                      <a style="font-size: 12px" class="five" (click)="viewDetails(i)">View Quote</a>
                    </div>
                  </div>
                </div>
                <div class="drawerCursor">
                  <!-- <p class="para px-2" matTooltip="{{quotes?.carrierName}}">{{quotes?.carrierName}}
                  </p> -->
                  <table class="table table-bordered table-wid mb-5 mt-4 mobile-table">
                    <tbody>
                      <tr *ngIf="quotes?.fullPayMonthlyPremium">
                        <td>
                          Full Pay Monthly Premium
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.fullPayMonthlyPremium}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.payPlanMonthlyPremiumDownpayment">
                        <td>
                          Pay Plan Monthly Premium Downpayment
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.payPlanMonthlyPremiumDownpayment}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.eftTotalCostPremium">
                        <td>
                          EFT Total Cost Premium
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.eftTotalCostPremium}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.initialEftInstallment">
                        <td>
                          Initial EFT Installment
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.initialEftInstallment}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.ccTotalCostPremium">
                        <td>
                          CC Total Cost Premium
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.ccTotalCostPremium}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.initialCcInstallment">
                        <td>
                          Initial CC Installment
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.initialCcInstallment}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.paidInFull && quotes?.carrierName === 'Vault'">
                        <td>
                          Paid in Full
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.paidInFull}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.twoPayTotalCostPremium">
                        <td>
                          2-Pay Total Cost Premium
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.twoPayTotalCostPremium}}</span></td>
                      </tr>
                      <tr *ngIf="quotes?.fourPayTotalPremium">
                        <td>
                          4 Pay Total Premium
                        </td>
                        <td class="highLightBlue"><span>{{quotes?.fourPayTotalPremium}}</span></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div class="col-12 bottom-section">
                  <p class="headAlign"><span class="time">
                      <button class="statusBtn" (click)="quoteSelected(quotes)">Select this quote</button></span>
                  </p>
                </div>
              </div>
            </li>
          </div>
          <mat-menu #menu="matMenu" xPosition="before">
            <ng-container *ngFor="let status of [{name:'Hello'}]">
              <button mat-menu-item>
                <!-- <mat-icon class="statusIcon" [ngClass]="{'lost':status?.name=='Lost','abandoned':status?.name=='Abandoned','won':status?.name=='Won'}">autorenew</mat-icon> -->
                <mat-icon class="statusIcon {{status?.name}}">autorenew</mat-icon>
                <span class="statusList {{status?.name}}">{{status?.name}}</span>
              </button>
            </ng-container>
          </mat-menu>
        </ul>
        <div *ngIf="false">
          <ul class="noContent">
            <p class="text-center">No data found.</p>
          </ul>
        </div>


      </div>
    </div>
    <div class="col-lg-5 payment-section"
      [ngStyle]="{'pointer-events': (dataRes?.status === 'Quote Payment Ready' || dataRes?.status === 'completed') ? 'none' : 'auto'}">
      <div class="container my-auto py-5">
        <div class="row">
          <div class="col-lg-12 col-xl-12 mx-auto">
            <form class="row justify-content-center" [formGroup]="bookingOrder">
              <div class="col-lg-12 text-end">
                <div class="logoImgStyle mb-3">
                  <img src="assets/insurance.png" width="100%">
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input inputId="firstName" label="First Name" formControlName="firstName" [readOnly]="true">
                </insurance-input>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input inputId="lastName" label="Last Name" formControlName="lastName" [readOnly]="true">
                </insurance-input>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input inputId="email" label="Email Address" formControlName="email" [readOnly]="true">
                </insurance-input>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input-date inputId="policyStartDate" label="Effective Date" [minDate]="startDate"
                  formControlName="policyStartDate" [readOnly]="true">
                </insurance-input-date>
                <div
                  *ngIf="bookingOrder?.get('policyStartDate')?.touched && bookingOrder?.get('policyStartDate')?.errors">
                  <span class="p-error" *ngIf="bookingOrder?.get('policyStartDate')?.hasError('required')">
                    Date is required
                  </span>
                </div>
              </div>
              <div class="col-lg-12 mb-1">
                <h6 class="mb-0">All drivers phone numbers</h6>
              </div>
              <div class="col-lg-6 col-md-6" formArrayName="driverPhone"
                *ngFor="let data of driverPhone.controls;let i = index">
                <div class="row mb-3" [formGroupName]="i">
                  <div class=""
                    [ngClass]="[(dataRes?.status !== 'Quote Payment Ready' && dataRes?.status !== 'completed') ? 'col-11' : 'col-12']">
                    <insurance-input-mask inputId="number" label="Driver Phone {{i + 1}}" formControlName="number">
                    </insurance-input-mask>
                  </div>
                  <div class="col-sm-1 col-1 iconStyle"
                    *ngIf="dataRes?.status !== 'Quote Payment Ready' && dataRes?.status !== 'completed'">
                    <i class="fa fa-minus" id="removeEmbryoDetails{{i}}" (click)="removeDriver(i)"
                      *ngIf="i !== driverPhone.controls.length - 1"></i>
                    <i class="fa fa-plus" id="addEmbryoDetails" (click)="addDriver(driverPhone?.controls, i)"
                      *ngIf="i === driverPhone.controls.length - 1"></i>
                  </div>
                  <div *ngIf="data?.get('number')?.touched && data?.get('number')?.errors">
                    <span class="p-error" *ngIf="data?.get('number')?.hasError('required')">
                      Phone number is Required
                    </span>
                    <span class="p-error" *ngIf="data?.get('number')?.hasError('pattern')">
                      Please enter a valid phone number
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-select
                  [dataList]="campaignData?.quoteType === 'Manual' ? manualPaymentMethodOption : paymentMethodOption"
                  dataLabel="name" dataValue="name" inputId="paymentMethod" label="Payment Method"
                  formControlName="paymentMethod" (onClick)="selectMethod($event)">
                </insurance-select>
                <div *ngIf="bookingOrder?.get('paymentMethod')?.touched && bookingOrder?.get('paymentMethod')?.errors">
                  <span class="p-error" *ngIf="bookingOrder?.get('paymentMethod')?.hasError('required')">
                    Payment method is required
                  </span>
                </div>
                <div
                  *ngIf="selectedQuoteColumn?.carrierName === 'Vault' && bookingOrder.value.paymentMethod === 'Credit Card'">
                  <span class="p-error">
                    All Credit Card transactions will incur a 2.99% processing fee
                  </span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-select [dataList]="selectedCarrierData" dataLabel="paymentMode" dataValue="paymentMode"
                  label="Payment Mode" formControlName="paymentMode" (onClick)="selectPayment($event)">
                </insurance-select>
                <div *ngIf="bookingOrder?.get('paymentMode')?.touched && bookingOrder?.get('paymentMode')?.errors">
                  <span class="p-error" *ngIf="bookingOrder?.get('paymentMode')?.hasError('required')">
                    Payment mode is required
                  </span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input inputId="amount" label="Amount" formControlName="amount" [readOnly]="true">
                </insurance-input>
                <div class="p-error"
                  *ngIf="bookingOrder?.get('amount')?.hasError('required') && bookingOrder?.get('amount')?.touched">
                  Please select amount
                </div>
              </div>
              <!--
                                <insurance-input inputId="amount" label="Amount" formControlName="amount" [readOnly]="true">
                </insurance-input>
              -->
              <div class="col-lg-6 col-md-6 mb-3" *ngIf="bookingOrder.value.effectiveDateUpdateRequest">
                <insurance-input-date inputId="updatedPolicyEffectiveDate" label="Updated Effective Date"
                  [minDate]="startDate" formControlName="updatedPolicyEffectiveDate"
                  [readOnly]="dataRes?.status === 'Quote Payment Ready' && dataRes?.status === 'completed'">
                </insurance-input-date>
                <div
                  *ngIf="bookingOrder?.get('updatedPolicyEffectiveDate')?.touched && bookingOrder?.get('updatedPolicyEffectiveDate')?.errors">
                  <span class="p-error" *ngIf="bookingOrder?.get('updatedPolicyEffectiveDate')?.hasError('required')">
                    Date is required
                  </span>
                </div>
              </div>
              <ng-container *ngIf="dataRes?.status !== 'Quote Payment Ready' && dataRes?.status !== 'completed'">
                <div class="col-lg-12 mb-3 text-danger"><i class="pe-2 fa fa-exclamation-circle"
                    aria-hidden="true"></i>Changing effective date may result in changes in premium. Your agent will
                  contact you regarding any changes in premium.</div>
                <div class="col-lg-12 mb-3">
                  <insurance-checkbox formControlName="effectiveDateUpdateRequest" (onClick)="checkPolicyDate($event)"
                    label=" I need coverage starting on a different date than the effective date shown above."
                    [multiCheckBox]="false">
                  </insurance-checkbox>
                </div>
                <div class="col-lg-12 text-center">
                  <insurance-button btnWidth="150" (onClick)="submitBooking()" label="Submit" [loading]="submitting"
                    [disabled]="submitting">
                  </insurance-button>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modalWithClose">
  <insurance-popup [displayModal]="displayDialog" [showHeader]="false" iconBgColor="#e1f3fc" iconClass="pi pi-info"
    iconColor="#005b88" message="Quote Selection Confirmed">
    <div class="mt-3">
      <h6 class="mb-2">Thank you for placing your order. You should expect to receive a confirmation email shortly with
        your proof of insurance and a receipt. Our team will reach out with any further questions.</h6>
      <insurance-button btnWidth="200" (onClick)="routeToPayment()" label="Proceed To Payment">
      </insurance-button>
    </div>
  </insurance-popup>
</div>

<div class="mobile-popup-screen" *ngIf="displayMobilePopup">
  <insurance-popup header="View Quote" [displayModal]="displayMobilePopup" (closeAction)="closeMobileDialog()"
    customClass="pop90" [closable]="true">
    <div class="col-12 mobile-popup-view" *ngIf="displayMobilePopup">
      <app-quote-list [quoteProposalData]="mobilePopupQuoteData" [quoteSelection]="false" [disableSelection]="true"
        [mobilePopup]="true"></app-quote-list>
    </div>
  </insurance-popup>
</div>