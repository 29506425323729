import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import * as sharedRulesData from '../../../../../shared/carrier.data';
import { v4 as uuid } from 'uuid';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quote-data',
  templateUrl: './quote-data.component.html',
  styleUrls: ['./quote-data.component.css']
})
export class QuoteDataComponent implements OnInit {
  rulesData = sharedRulesData;
  dropDownData: any = [];
  quoteProposalData = this.fb.group({
    proposalId: [uuid()],
    proposalName: [''],
    quoteList: this.fb.array([]),
  });
  loadingStatus = false;
  carrierListData = [];
  insuranceData: any;
  insuranceNumber: any;
  noOfQuotes: any;

  get getQuoteProposalData(): FormArray {
    return this.quoteProposalData.get('quoteList') as FormArray;
  }

  getVehicle(index: number): FormArray {
    return this.getQuoteProposalData?.['controls']?.[index]?.get('vehiclesCovered') as FormArray
  }

  getFormArray(formArrayName: string, index: number): FormArray {
    return this.getQuoteProposalData?.['controls']?.[index]?.get(formArrayName) as FormArray
  }

  packData = [
    'Basic Coverage',
    'Standard Coverage',
    'Enhanced Coverage',
    'Premier Enhanced Coverage',
    'Apples-to-Apples',
    'Other',
  ];
  packageListData = [];
  displayDialog = false;
  merchantId = '';
  agentAlert = {
    show: false,
    yesLoader: false,
    noLoader: false,
    value: '',
    field: '',
    index: 0
  };
  popupMessage = {
    question: 'Would you like to change the carrier?',
    note: 'Note: Carrier changes require confirmation from the customer'
  };

  constructor(private fb: FormBuilder, public stepper: MatStepper,
    private apiService: WizzipayService,
    private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      const campaignId = sessionStorage.getItem('campaignId');
      campaignId && this.getData(this.merchantId, campaignId);
    });
    this.packageListData = await this.createDropdownData(this.packData);
  }

  quoteData: any;

  getData(merchantId: string, campaignId: string) {
    this.apiService.getdata(merchantId, campaignId).subscribe((res) => {
      this.apiService.getQuoteSelectionData(campaignId)
        .subscribe((selectionRes) => {
          if (res.data) {
            this.quoteData = { ...res?.data, ...selectionRes?.data };
            this.setQuoteFields(res?.data, false);
          }
        });
    });
  }

  async createDropdownData(val: any) {
    let arrayData: any = [];
    val?.forEach((element: any) => {
      const data = {
        name: element,
        value: element,
        id: element,
      };
      arrayData.push({ ...data });
    });
    return arrayData;
  }

  testPackage(packageName: string, index: number) {
    const items = this.getQuoteProposalData
    const data = items.value;
    const length = data.length;
    if (length > 1) {
      const packageNames = data.map((control: any) => control?.packageName);
      const otherPackageNames = data.map((control: any) => control?.otherPackageName);
      const packageList = [];
      for (let index = 0; index < length; index++) {
        packageList.push(packageNames?.at(index) === 'Other'
          ? otherPackageNames?.at(index)?.toLowerCase()
          : packageNames?.at(index)?.toLowerCase())
      }
      for (let index = 0; index < length; index++) {
        const newArray = [...packageList.slice(0, index), ...packageList.slice(index + 1)];
        if (packageList[index] && newArray.includes(packageList[index])) {
          packageNames?.at(index) === 'Other'
            ? this.setValidationError('otherPackageName', index, true)
            : this.setValidationError('packageName', index, true);
        } else {
          packageNames?.at(index) === 'Other'
            ? this.setValidation(['otherPackageName'], index)
            : this.setValidation(['packageName'], index);
          // packageNames?.at(index) === 'Other'
          //   ? otherPackageNames?.at(index) && this.setValidationError('otherPackageName', index, false)
          //   : this.setValidationError('packageName', index, false);
        }

      }
    }
    return;
    // packageName === 'Other'
    //   ? this.setValidation(['otherPackageName'], index)
    //   : this.removeValidation(['otherPackageName'], index);
    // const items = this.getQuoteProposalData
    // const data = items.value;
    if (data?.length > 1) {
      const inspect = [...data];
      inspect.fill('');
      for (let i = 0; i < data.length; i++) {
        if (i < data?.length - 1) {
          const check = this.checkPackage(i, i + 1, data);
          if (check) {
            this.setValidationError('otherPackageName', i, true);
            this.setValidationError('packageName', i, true);
            this.setValidationError('otherPackageName', i + 1, true);
            this.setValidationError('packageName', i + 1, true);
            inspect[i] = 'invalid';
            inspect[i + 1] = 'invalid';
          } else {
            if (!inspect[i]) {
              this.setValidationError('otherPackageName', i, false);
              this.setValidationError('packageName', i, false);
            }
            if (!inspect[i + 1]) {
              this.setValidationError('otherPackageName', i + 1, false);
              this.setValidationError('packageName', i + 1, false);
            }
          }
        } else {
          const check1 = this.checkPackage(i, 0, data);
          if (check1) {
            this.setValidationError('otherPackageName', i, true);
            this.setValidationError('packageName', i, true);
            this.setValidationError('otherPackageName', 0, true);
            this.setValidationError('packageName', 0, true);
            inspect[i] = 'invalid';
            inspect[0] = 'invalid';
          } else {
            if (!inspect[i]) {
              this.setValidationError('otherPackageName', i, false);
              this.setValidationError('packageName', i, false);
            }
            if (!inspect[0]) {
              this.setValidationError('otherPackageName', 0, false);
              this.setValidationError('packageName', 0, false);
            }
          }
        }
      }
    }
  }

  checkPackage(i: number, j: number, data: any) {
    const firstPack = data[i]?.otherPackageName ? data[i]?.otherPackageName : data[i]?.packageName;
    const secondPack = data[j]?.otherPackageName ? data[j]?.otherPackageName : data[j]?.packageName;
    if (firstPack && secondPack)
      return firstPack === secondPack;
    return false;
  }

  async setValidationError(controlName: string, index: number, setError: boolean) {
    setError
      ? this.getQuoteProposalData['controls']
        ?.[index]
        ?.get(controlName)
        ?.setErrors({ incorrect: setError })
      : this.getQuoteProposalData['controls']?.[index]?.get(controlName)?.setErrors(null);
    this.getQuoteProposalData['controls']?.[index]?.get(controlName)?.markAsTouched();
  }

  obj: any = {
    policyTerm: '',
    // fullPayMonthlyPremium: '',
    // payPlanMonthlyPremiumTotal: '',
    // payPlanMonthlyPremiumDownpayment: '',
    // payPlanMonthlyPremimPerMonth: '',
    // paidInFull: '',
    // twoPayPlan: '',
    // twoPayTotalCostPremium: '',
    // fourPayPlan: '',
    // fourPayTotalPremium: '',
    // eftPayPlanMonthlyPremium: '',
    // initialInstallmentEftInstallment: '',
    // initialCcInstallment: '',
    // eftTotalCostPremium: '',
    // ccPayPlanMonthlyPremium: '',
    // ccTotalCostPremium: '',
    // additionalPersonalInjuryProtection: '',
    // coverageABodilyInjury: '',
    // bodilyInjuryandPropertyDamagePerAccidentBi: '',
    // bodilyInjuryandPropertyDamagePerAccidentPd: '',
    // coverageBMedicalPayments: '',
    // coverageBMedicalPaymentsCurrency: '',
    // coverageCUninsuredMotoristStackingorNonStacking: '',
    // coverageCUninsuredMotoristPerAccident: '',
    // coverageCUninsuredMotoristPerPerson: '',
    // uninsuredMotorist: '',
    // coverageDComprehensiveCoverage: '',
    // coverageDCollisionCoverage: '',
    // coverageDCollisionCoverageDeductible: '',
    // guaranteedValue: '',
    // personalInjuryProtectionLimit: '',
    // pipApplies: '',
    // pipWorkExclusion: '',
    // pipDeductible: '',
    // extendedPersonalInjuryProtection: '',
    // rentalCarCoverage: '',
    // roadsideAssistanceandTowing: '',
    // gapCoverage: '',
    // coverageDComprehensiveCoverageDeductible: '',
    // bodilyInjuryandPropertyDamagePerPersonBiCurrency: '',
    // bodilyInjuryandPropertyDamagePerAccidentPdCurrency: '',
    // bodilyInjuryandPropertyDamagePerAccidentBiCurrency: '',
    // ultraCoverage: '',
    // responsibleDrivingPlan: '',
    // accidentMinorViolationForgiveness: '',
    enrolledInMercuryGo: '',
    enrolledInSnapshot: '',
    enrolledInRightTrack: '',
    enrolledInIntelliDrive: '',
    fullPayMonthlyPremium: '',
    payPlanMonthlyPremiumTotal: '',
    payPlanMonthlyPremiumDownpayment: '',
    payPlanMonthlyPremiumPerMonth: '',
    paidInFull: '',
    eftPayPlanMonthlyPremium: '',
    initialEftInstallment: '',
    eftTotalCostPremium: '',
    ccPayPlanMonthlyPremium: '',
    initialCcInstallment: '',
    ccTotalCostPremium: '',
    twoPayPlan: '',
    twoPayTotalCostPremium: '',
    fourPayPlan: '',
    fourPayTotalPremium: '',
    coverageABodilyInjuryPerPersonPerAccident: '',
    coverageAPropoertyDamagePerAccident: '',
    coverageBMedicalPayments: '',
    coverageCUninsuredMotoristPerPersonPerAccident: '',
    coverageCUninsuredMotorist: '',
    guaranteedValue: [''],
    coverageDComprehensiveCoverage: [''],
    coverageDComprehensiveCoverageDeductible: [''],
    coverageDCollisionCoverage: [''],
    coverageDCollisionCoverageDeductible: [''],
    personalInjuryProtectionLimit: '',
    pipWorkExclusion: '',
    pipDeductible: '',
    pipAppliesTo: '',
    extendedPip: '',
    rentalCarCoverage: [''],
    roadsideAssistanceAndTowing: [''],
    gapCoverage: '',
    originalEquipmentManufacturer: '',
    ultraCoverage: '',
    accidentMinorViolationForgiveness: '',
    responsibleDrivingPlan: '',
    additionalPip: '',
    enhancedPackage: ''
  };

  proposalLabelChange(value: string, i: number) {
    const items = this.getQuoteProposalData;
    let arr = Array(items?.length).fill({ vehiclesCovered: value });
    console.log('arrrr', arr, value)
    items.patchValue(arr);
    // this.vesselChange.emit({ vehicle: value, index: i });
  }

  setQuoteFields(val: any, clone: boolean) {
    console.log('autoooo set auto fields', val)
    const items = this.getQuoteProposalData;
    const length = val?.proposalList?.[0]?.quoteList?.length || +val?.noOfQuotes;
    items.clear();
    // while (items.length !== 0) {
    //   items.removeAt(0);
    // }
    for (let i = 0; i < length; i++) {
      this.addQuote('onLoad');
    }
    // if (val?.value?.quoteList?.length) {
    //   this.getQuoteProposalData.patchValue(val?.value?.quoteList);
    // }
    this.quoteProposalData.patchValue({
      proposalId: val?.proposalList?.[0]?.proposalId
    })
    const quoteList = val?.proposalList?.length ? [...val?.proposalList?.[0]?.quoteList] : [];
    // this.setFormArrays(quoteList[0]);
    quoteList?.forEach((element: any, index: number) => {
      const data = { ...element }
      // data.carrierName &&
      this.getCarrierData(data, index, 'onLoad');
      if (clone) {
        // delete data.id;
        data.proposalQuoteId = uuid();
      }
      this.patchData(data, index);
    });
  }

  setFormArrays(data: any) {
    const items = this.getQuoteProposalData;
    const vehicleLength = data?.vehiclesCovered?.length;
    items?.controls?.forEach((element: AbstractControl) => {
      const el = element.get('vehiclesCovered') as FormArray;
      for (let index = 1; index < vehicleLength; index++) {
        el.push(this.vehiclesCoveredForm());
      }
    });
  }

  confirmClone() {
    const items = this.quoteProposalData.get('quoteList') as FormArray;
    const autoData = this.insuranceData.toArray();
    const data = autoData?.filter((x: any) => (x?.insuranceNumber == this.insuranceNumber && x?.proposalNumber == 0));
    while (items.length !== 0) {
      items.removeAt(0);
    }
    this.setQuoteFields(data?.at(0)?.quoteProposalData, true);
    // const length = data?.at(0)?.quoteProposalData?.value?.quoteList?.length || this.noOfQuotes;
    // for (let i = 0; i < length; i++) {
    //   this.addQuote();
    // }
    // this.quoteProposalData.patchValue(data?.at(0)?.quoteProposalData?.value);
    this.displayDialog = false;
  }

  test() {
    this.displayDialog = true;
  }

  cloneQuote(i: number) {
    const items = this.getQuoteProposalData;
    let quote: any = items?.value;
    const data = { ...items?.value?.at(0) };
    delete data?.packageName;
    delete data?.otherPackageName;
    data.proposalQuoteId = uuid();
    quote[i] = data;
    this.getCarrierData(data, i, 'onClick');
    this.patchData(data, i);
  }

  addQuote(type: string, vehicles?: any) {
    const items = this.getQuoteProposalData;
    items.push(this.quoteList());
    if (type === 'onClick' && vehicles?.length) {
      const vehicle = items.at(items.length - 1)?.get('vehiclesCovered') as FormArray
      for (let index = 1; index < vehicles?.length; index++) {
        vehicle.push(this.vehiclesCoveredForm())
      }
      vehicle.patchValue(vehicles);
    }
  }

  removeQuote(index: number) {
    const array = this.getQuoteProposalData;
    if ((this.quoteData?.paymentDetails?.selectedPackage === array?.value?.at(index)?.packageName) || this.quoteData?.paymentDetails?.selectedPackage === array?.value?.at(index)?.otherPackageName) {
      this.deleteAlert.show = true;
      this.deleteAlert.index = index;
      return;
    }
    this.removeQuoteConfirm(index);
  }

  removeQuoteConfirm(index: number) {
    const array = this.getQuoteProposalData;
    const vehicles = array.at(0)?.get('vehiclesCovered')?.value;
    array.removeAt(index);
    if (array?.length === 0) {
      this.addQuote('onClick', vehicles);
    }
  }

  removeQuoteSelection() {
    this.deleteAlert.yesLoader = true;
    const campaignId = sessionStorage.getItem('campaignId');
    this.apiService
      .updateOrder({
        carrier: this.quoteData?.carrier || '', paymentDetails: {
          selectedPackage: '',
          selectedQuoteAmount: '',
          paymentMode: this.quoteData.paymentDetails.paymentMode,
          paymentMethod: this.quoteData.paymentDetails.paymentMethod,
        }
      }, this.merchantId, campaignId)
      .subscribe(
        () => {
          this.removeQuoteConfirm(this.deleteAlert.index);
          this.deleteAlert.show = false;
          this.deleteAlert.yesLoader = false;
        },
        (err) => {
          this.deleteAlert.yesLoader = false;
          this.apiService.snackbar(err.message);
        }
      );
  }

  quoteList(): FormGroup {
    return this.fb.group({
      proposalQuoteId: [uuid()],
      packageName: ['', [Validators.required]],
      otherPackageName: [''],
      carrierName: ['', [Validators.required]],
      policyTerm: ['', [Validators.required]],
      // fullPayMonthlyPremium: [''],
      // payPlanMonthlyPremiumTotal: [''],
      // payPlanMonthlyPremiumDownpayment: [''],
      // payPlanMonthlyPremimPerMonth: [''],
      // paidInFull: [''],
      // twoPayPlan: [''],
      // twoPayTotalCostPremium: [''],
      // fourPayPlan: [''],
      // fourPayTotalPremium: [''],
      // eftPayPlanMonthlyPremium: [''],
      // initialInstallmentEftInstallment: [''],
      // initialCcInstallment: [''],
      // eftTotalCostPremium: [''],
      // ccPayPlanMonthlyPremium: [''],
      // ccTotalCostPremium: [''],
      // additionalPersonalInjuryProtection: [''],
      vehiclesCovered: new FormArray([this.vehiclesCoveredForm()]),
      // coverageABodilyInjury: [''],
      // bodilyInjuryandPropertyDamagePerAccidentBi: [''],
      // bodilyInjuryandPropertyDamagePerAccidentPd: [''],
      // coverageBMedicalPayments: [''],
      // coverageBMedicalPaymentsCurrency: [''],
      // coverageCUninsuredMotoristStackingorNonStacking: [''],
      // coverageCUninsuredMotoristPerAccident: [''],
      // coverageCUninsuredMotoristPerPerson: [''],
      // uninsuredMotorist: [''],
      // coverageDComprehensiveCoverage: [''],
      // coverageDCollisionCoverage: [''],
      // coverageDCollisionCoverageDeductible: [''],
      // guaranteedValue: [''],
      // personalInjuryProtectionLimit: [''],
      // pipApplies: [''],
      // pipWorkExclusion: [''],
      // pipDeductible: [''],
      // extendedPersonalInjuryProtection: [''],
      // rentalCarCoverage: [''],
      // roadsideAssistanceandTowing: [''],
      // gapCoverage: [''],
      // coverageDComprehensiveCoverageDeductible: [''],
      // bodilyInjuryandPropertyDamagePerPersonBiCurrency: [''],
      // bodilyInjuryandPropertyDamagePerAccidentPdCurrency: [''],
      // bodilyInjuryandPropertyDamagePerAccidentBiCurrency: [''],
      // ultraCoverage: [''],
      // responsibleDrivingPlan: [''],
      // accidentMinorViolationForgiveness: [''],
      enrolledInMercuryGo: [''],
      enrolledInSnapshot: [''],
      enrolledInRightTrack: [''],
      enrolledInIntelliDrive: [''],
      fullPayMonthlyPremium: [''],
      payPlanMonthlyPremiumTotal: [''],
      payPlanMonthlyPremiumDownpayment: [''],
      payPlanMonthlyPremiumPerMonth: [''],
      paidInFull: [''],
      eftPayPlanMonthlyPremium: [''],
      initialEftInstallment: [''],
      eftTotalCostPremium: [''],
      ccPayPlanMonthlyPremium: [''],
      initialCcInstallment: [''],
      ccTotalCostPremium: [''],
      twoPayPlan: [''],
      twoPayTotalCostPremium: [''],
      fourPayPlan: [''],
      fourPayTotalPremium: [''],
      coverageABodilyInjuryPerPersonPerAccident: [''],
      coverageAPropoertyDamagePerAccident: [''],
      coverageBMedicalPayments: [''],
      coverageCUninsuredMotoristPerPersonPerAccident: [''],
      coverageCUninsuredMotorist: [''],
      guaranteedValue: new FormArray([this.currencyFieldForm()]),
      // coverageDComprehensiveCoverage: [''],
      coverageDComprehensiveCoverage: new FormArray([this.vehiclesCoveredForm()]),
      coverageDComprehensiveCoverageDeductible: new FormArray([this.vehiclesCoveredForm()]),
      coverageDCollisionCoverage: new FormArray([this.vehiclesCoveredForm()]),
      coverageDCollisionCoverageDeductible: new FormArray([this.vehiclesCoveredForm()]),
      personalInjuryProtectionLimit: [''],
      pipWorkExclusion: [''],
      pipDeductible: [''],
      pipAppliesTo: [''],
      extendedPip: [''],
      rentalCarCoverage: new FormArray([this.vehiclesCoveredForm()]),
      roadsideAssistanceAndTowing: new FormArray([this.vehiclesCoveredForm()]),
      gapCoverage: [''],
      originalEquipmentManufacturer: [''],
      ultraCoverage: [''],
      accidentMinorViolationForgiveness: [''],
      responsibleDrivingPlan: [''],
      additionalPip: [''],
      enhancedPackage: ['']
    });
  }

  currencyFieldForm(): FormControl {
    return new FormControl('', [Validators.required, Validators.pattern(/^[0-9,$.]+$/)]);
  }

  changeCarrier(val: string, index: number) {
    const quote = this.getQuoteProposalData;
    if (this.quoteData?.carrier) {
      if ((this.quoteData?.paymentDetails?.selectedPackage === quote?.value?.at(index)?.packageName) || (this.quoteData?.paymentDetails?.selectedPackage === quote?.value?.at(index)?.otherPackageName) && this.quoteData?.carrier !== val) {
        this.popupMessage.question = 'Would you like to change the carrier?';
        this.popupMessage.note = 'Note: Carrier changes require confirmation from the customer';
        this.agentAlert.value = val;
        this.agentAlert.index = index;
        this.agentAlert.field = 'carrier';
        this.agentAlert.show = true;
      }
    }
  }

  alertNo() {
    const quote = this.getQuoteProposalData;
    if (this.agentAlert.field === 'carrier') {
      quote?.at(this.agentAlert.index)?.patchValue({ carrierName: this.quoteData?.carrier });
    }
    this.agentAlert.field = this.agentAlert.value = '';
    this.agentAlert.show = this.agentAlert.noLoader = false;
    this.agentAlert.index = 0;
  }

  alertYes() {
    const quote = this.getQuoteProposalData;
    this.agentAlert.yesLoader = true;
    if (this.agentAlert.field === 'carrier') {
      quote?.at(this.agentAlert.index)?.patchValue({ carrierName: this.agentAlert.value })
    }
    this.saveData();
  }

  next() {
    this.loadingStatus = true;
    if (this.quoteProposalData.invalid) {
      this.quoteProposalData.markAllAsTouched();
      document
        .getElementById('tableData')
        ?.scrollIntoView({ behavior: 'smooth' });
      this.apiService.snackbar('Fill all the Mandatory Fields');
      this.loadingStatus = false;
      return;
    }
    this.apiService
      .updateOrder({
        proposalList: [this.quoteProposalData.value],
        noOfQuotes: this.getQuoteProposalData?.length,
        status: 'Quote Proposal Submitted',
        statusChange: this.quoteData?.status === 'Quote Process Initiated'
      }, this.merchantId, sessionStorage.getItem('campaignId'))
      .subscribe(
        () => {
          this.loadingStatus = false;
          this.apiService.snackbar('Offer Updated');
          this.stepper.next();
        },
        (err) => {
          this.loadingStatus = false;
          this.apiService.snackbar(err.message);
        }
      );
  }

  backLoader = false;
  loader = {
    saveLater: false
  };

  saveForLater() {
    this.loader.saveLater = true;
    this.save();
  }

  save() {
    this.apiService
      .updateOrder({
        proposalList: [this.quoteProposalData.value],
        noOfQuotes: this.getQuoteProposalData?.length
      }, this.merchantId, sessionStorage.getItem('campaignId'))
      .subscribe(
        () => {
          this.loader.saveLater = false;
          this.deleteAlert.show = this.deleteAlert.yesLoader = false;
          this.agentAlert.field = this.agentAlert.value = '';
          this.agentAlert.show = this.agentAlert.noLoader = false;
          this.agentAlert.index = 0;
          this.apiService.snackbar('Quote data saved successfully');
        },
        (err) => {
          this.backLoader = false;
          this.apiService.snackbar(err.message);
        }
      );
  }

  deleteAlert = {
    show: false,
    yesLoader: false,
    noLoader: false,
    index: 0
  }

  saveData() {
    const campaignId = sessionStorage.getItem('campaignId')
    this.apiService
      .updateOrder({
        carrier: '',
        // status: 'Quote Proposal Submitted',
        paymentDetails: {
          selectedPackage: '',
          selectedQuoteAmount: '',
          paymentMode: this.quoteData.paymentDetails.paymentMode,
          paymentMethod: this.quoteData.paymentDetails.paymentMethod,
        }
      }, this.merchantId, campaignId)
      .subscribe(
        () => {
          this.save();
        },
        (err) => {
          this.deleteAlert.yesLoader = false;
          this.apiService.snackbar(err.message);
        }
      );
  }

  back() {
    this.backLoader = true;
    this.apiService
      .updateOrder({
        proposalList: [this.quoteProposalData.value],
        noOfQuotes: this.getQuoteProposalData?.length
      }, this.merchantId, sessionStorage.getItem('campaignId'))
      .subscribe(
        () => {
          this.backLoader = false;
          this.apiService.snackbar('Offer Updated');
          this.stepper.previous();
        },
        (err) => {
          this.backLoader = false;
          this.apiService.snackbar(err.message);
        }
      );
  }

  vehiclesCoveredForm(): FormControl {
    return new FormControl('', Validators.required);
  }

  coverageABodilyInjuryChange(coverageA: string, index: number) {
    const coverageValue = coverageA.includes('CSL') ? coverageA : '';
    const items = this.getQuoteProposalData;
    items['controls'][index].patchValue({
      coverageAPropoertyDamagePerAccident: coverageValue,
      coverageCUninsuredMotoristPerPersonPerAccident: coverageValue
    })
  }

  coverageCChange(coverageC: string, index: number) {
    if (coverageC === 'Rejected') {
      const items = this.getQuoteProposalData;
      items['controls'][index].patchValue({
        coverageCUninsuredMotorist: 'N/A'
      })
    }
  }

  addVehiclesCovered(index: number) {
    const items = this.getQuoteProposalData;
    items?.controls?.forEach((element: AbstractControl) => {
      const el = element.get('vehiclesCovered') as FormArray;
      el.push(this.vehiclesCoveredForm())
      // const covD = element.get('coverageDComprehensiveCoverage') as FormArray;
      // covD.push(this.vehiclesCoveredForm())
    });
    this.updateMultiVehicleForm(index);
    // const items = this.getQuoteProposalData.controls?.[index]?.get('vehiclesCovered') as FormArray;
    // items.push(this.vehiclesCoveredForm());
  }

  multiDesign = [
    {
      carrier: ['Bristol West', 'Mercury', 'Progressive', 'Safeco', 'Travelers', 'Vault'],
      fields: [
        'coverageDComprehensiveCoverage',
        'coverageDComprehensiveCoverageDeductible',
        'coverageDCollisionCoverage',
        'coverageDCollisionCoverageDeductible',
        'rentalCarCoverage',
        'roadsideAssistanceAndTowing'
      ],
      removeFields: [
        'guaranteedValue',
      ]
    },
    {
      carrier: ['Hagerty'],
      fields: [
        'guaranteedValue',
        'coverageDComprehensiveCoverage',
        'coverageDComprehensiveCoverageDeductible',
        'coverageDCollisionCoverage',
        'coverageDCollisionCoverageDeductible',
        'roadsideAssistanceAndTowing'
      ],
      removeFields: [
        'rentalCarCoverage',
      ]
    }
  ]

  updateMultiVehicleForm(index: number) {
    const items = this.getQuoteProposalData;
    items.controls.forEach((element: AbstractControl) => {
      const filterRule = this.multiDesign.filter((x: any) => x.carrier.includes(element.value.carrierName));
      const vehicle = element.get('vehiclesCovered') as FormArray;
      console.log('vehicle ==>', vehicle)
      if (filterRule.length) {
        filterRule[0].fields.forEach((field) => {
          const formField = element.get(field) as FormArray;
          console.log('formField ==>', formField)
          console.log('vehicle.length > formField.length ==>', vehicle.length > formField.length)
          if (vehicle.length > formField.length) {
            for (let index = formField.length; index < vehicle.length; index++) {
              formField.push(field === 'guaranteedValue' ? this.currencyFieldForm() : this.vehiclesCoveredForm())
            }
          }
        })
        console.log('filterRule[0].removeFields ==>', filterRule[0].removeFields)
        filterRule[0].removeFields.forEach((field) => {
          const formField = element.get(field) as FormArray;
          formField.clear();
          console.log('formField ==>', formField)
        });
      }
    })
  }

  deleteVehicle(index: number, vehicleIndex: number) {
    // const items = this.getQuoteProposalData.controls?.[index]?.get('vehiclesCovered') as FormArray;
    // items.removeAt(vehicleIndex);
    const items = this.getQuoteProposalData;
    items?.controls?.forEach((element: AbstractControl) => {
      const el = element.get('vehiclesCovered') as FormArray;
      el.removeAt(vehicleIndex);
    });
    this.deleteMultiVehicleForm(vehicleIndex);
  }

  deleteMultiVehicleForm(index: number) {
    const items = this.getQuoteProposalData;
    items.controls.forEach((element: AbstractControl) => {
      const filterRule = this.multiDesign.filter((x: any) => x.carrier.includes(element.value.carrierName));
      if (filterRule.length) {
        filterRule[0].fields.forEach((field) => {
          const formField = element.get(field) as FormArray;
          formField.removeAt(index);
        });
      }
    })
  }

  getCarrierData(carrierData: any, index: number, selectionType: string) {
    console.log('carrierName1', carrierData, carrierData?.carrierName, selectionType, index);
    // this.updateMultiVehicleForm(index);
    this.changeCarrier(carrierData?.carrierName, index);
    this.setDropDown(carrierData, index, selectionType);
  }

  patchData(value: any, index: number) {
    console.log('test ==>', value)
    const items = this.getQuoteProposalData;
    // const data: any = {};
    // for (const key in value) {
    //   if (typeof value[key] === 'string') {
    //     data[key] = value[key];
    //   }
    // }
    items['controls'][index]?.patchValue(value);
  }

  async setDropDown(carrierData: any, index: number, selectionType: string) {
    console.log('carrierName2', carrierData, carrierData?.carrierName, selectionType);
    const items = this.getQuoteProposalData;
    selectionType == 'onLoad' &&
      items['controls'][index]?.get('carrierName')?.patchValue(carrierData?.carrierName || '');
    const carrierRulesData = this.rulesData.autoCarrierData.filter(
      (e) => carrierData?.carrierName === e.carrierName
    );
    console.log('carrierRulesData', carrierRulesData, selectionType);
    const vehicleLength = carrierData?.vehiclesCovered?.length;
    const vehicle = items['controls'][index].get('vehiclesCovered') as FormArray;
    console.log('vehicleLength ==>', vehicleLength)
    if (selectionType == 'onLoad') {
      for (let index = 1; index < vehicleLength; index++) {
        console.log('test for ==>', index)
        vehicle.push(this.vehiclesCoveredForm());
      }
    }
    const filterRule = this.multiDesign.filter((x: any) => x.carrier.includes(carrierData.carrierName));
    let defaultValues: any = { ...carrierRulesData[0]?.defaultValues };
    console.log('{defaultValues} ==>', { ...defaultValues })
    const defaultValueData = { ...defaultValues };
    // const vehicle = element.get('vehiclesCovered') as FormArray;
    console.log('vehicle ==>', vehicle)
    if (filterRule.length) {
      filterRule[0].fields.forEach((field) => {
        if (field in defaultValues) {
          const fieldValue = defaultValueData[field];
          console.log('Array(vehicleLength) ==>', vehicleLength, field, fieldValue, Array(vehicleLength).fill(fieldValue))
          defaultValues[field] = Array(vehicleLength).fill(fieldValue);
        }
        const formField = items['controls'][index].get(field) as FormArray;
        console.log('formField ==>', formField)
        console.log('vehicle.length > formField.length ==>', vehicleLength > formField.length)
        if (vehicleLength > formField.length) {
          for (let index = formField.length; index < vehicleLength; index++) {
            formField.push(field === 'guaranteedValue' ? this.currencyFieldForm() : this.vehiclesCoveredForm())
          }
        }
      })
      console.log('filterRule[0].removeFields ==>', filterRule[0].removeFields)
      filterRule[0].removeFields.forEach((field) => {
        const formField = items['controls'][index].get(field) as FormArray;
        formField.clear();
        console.log('formField ==>', formField)
      });
    }
    this.dropDownData[index] = carrierRulesData[0]?.dropDownData;
    console.log('defaultValues ==>', defaultValues)
    this.patchData(defaultValues, index);
    // selectionType === 'onClick' &&
    //   this.patchData({ ...this.obj, ...carrierRulesData[0]?.defaultValues }, index);
    this.setValidation(carrierRulesData[0]?.fields, index);
    this.setCurrencyValidation(carrierRulesData[0]?.currencyFields, index);
    this.removeValidation(carrierRulesData[0]?.removeFields, index);
  }

  setValidation(value: any, index: number) {
    const items = this.getQuoteProposalData;
    value?.forEach((element: any) => {
      items['controls'][index]
        ?.get(element)
        ?.setValidators([Validators.required]);
      items['controls'][index]?.get(element)?.updateValueAndValidity();
    });
  }

  removeValidation(value: any, index: number) {
    const items = this.getQuoteProposalData;
    value?.forEach((element: any) => {
      console.log('items[controls][index]?.get(element) ==>', element, typeof items['controls'][index]?.get(element)?.value)
      items['controls'][index]?.get(element)?.patchValue('');
      items['controls'][index]?.get(element)?.clearValidators();
      items['controls'][index]?.get(element)?.updateValueAndValidity();
    });
  }

  async setCurrency(formControl: string, val: any, index: number) {
    const items = this.getQuoteProposalData;
    let amount = val?.replace(/[$,]/g, '');
    const currencyAmt =
      amount &&
      parseFloat(amount)?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    currencyAmt &&
      currencyAmt != '$NaN' &&
      items['controls'][index].get(formControl)?.patchValue(currencyAmt);
  }

  async setCurrencyArray(formControl: string, val: any, index: number, j: number) {
    const items = this.getFormArray(formControl, index);
    let amount = val?.replace(/[$,]/g, '');
    const currencyAmt =
      amount &&
      parseFloat(amount)?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    currencyAmt &&
      currencyAmt != '$NaN' &&
      items['controls'][j].patchValue(currencyAmt);
  }

  // saveData() {
  //   if (this.getQuoteProposalData.invalid) {
  //     console.log('true');
  //   } else {
  //     console.log('false');
  //   }
  //   // const formData = this.getQuoteProposalData.getRawValue();
  //   // console.log(formData, 'formdata');
  //   // sessionStorage.setItem('formValues', JSON.stringify(formData));
  // }

  getQuoteData() {
    let data: any = sessionStorage.getItem('formValues');
    data && JSON.parse(data)?.forEach((element: any, index: number) => {
      element.carrierName &&
        this.getCarrierData(element, index, 'onLoad');
      console.log(element.carrierName, 'element34567890');
      // this.patchData(element, index);
    });
  }

  // autopopulate() {
  //   const items = this.getQuoteProposalData?.value.at(0).get('vehiclesCovered');
  //   console.log(items, 'items');
  //   //items['controls']?.get('carrierName')?;
  //   this.getQuoteProposalData.at(0).get('paidInFull')?.valueChanges.subscribe((e:any) => {
  //     console.log(e, 'value1111');
  //   });
  // }
  setCurrrencyValidation(e: any, formContol: String, index: number) {
    const items = this.getQuoteProposalData;
    if (e === 'CURRENCY') {
      items['controls'][index]
        ?.get('formContol')
        ?.setValidators([Validators.required]);
      items['controls'][index]?.get('formContol')?.updateValueAndValidity();
    } else {
      items['controls'][index]?.get('formContol')?.clearValidators();
      items['controls'][index]?.get('formContol')?.updateValueAndValidity();
    }
  }

  async setCurrencyValidation(val: any, index: number) {
    const items = this.getQuoteProposalData;
    val?.forEach((element: any) => {
      items['controls'][index]?.get(element)?.setValidators([Validators.required, Validators.pattern(/^[0-9,$.]+$/)]);
      items['controls'][index]?.get(element)?.updateValueAndValidity();
    });
  }
}
