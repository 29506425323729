<div class="container d-flex justify-content-center align-items-center h-100">
    <div class="row">
        <div class="col-lg-12">
            <div class="jumbotron text-center">
                <img src="assets/insurance.png" width="180px">
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-8">
                    <form class="row" [formGroup]="email" (keydown)="keyDownFunction($event)">
                        <div class="col-lg-12 mb-3">
                            <insurance-input inputId="emailAddress" label="Email Address"
                                formControlName="emailAddress"></insurance-input>
                            <div class="p-error"
                                *ngIf="email?.get('emailAddress')?.hasError('required') && email?.get('emailAddress')?.touched">
                                Email Address is required
                            </div>
                            <span class="p-error"
                                *ngIf="email?.get('emailAddress')?.hasError('pattern') && email?.get('emailAddress')?.touched">
                                Please enter a valid email
                            </span>
                        </div>
                        <div class="col-lg-12 mb-3">
                            <insurance-input-password inputId="password" label="Password" formControlName="password">
                            </insurance-input-password>
                            <div class="p-error"
                                *ngIf="email?.get('password')?.hasError('required') && email?.get('password')?.touched">
                                Password is required
                            </div>
                        </div>
                    </form>
                    <div class="col-lg-12 text-center">
                        <insurance-button btnWidth="150" label="Login" (onClick)="submit()" [loading]="submitting"
                            [disabled]="submitting">
                        </insurance-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>