import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-auto',
  templateUrl: './create-auto.component.html',
  styleUrls: ['./create-auto.component.css'],
})
export class CreateAutoComponent implements OnInit {
  emailForm: FormGroup = this.fb.group({
    //emailCC: this.fb.array([this.newEmail()]),
    recepientEmail: ['', [Validators.required, Validators.email]],
    subject: ['', [Validators.required]],
  });
  public moduleConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'], // remove formatting button
      ['link'],
    ],
  };
  emailContent: any;
  submitting = false;
  emailError = false;
  mailSuccess = false;
  backOfficeURL = environment.autoURL;

  constructor(
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public service: WizzipayService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.emailContent = `Hi  ,<p>Please click the link below to start auto quote.</p><br><br><br>`;
    this.emailForm.patchValue({
      subject: 'HH Insurance - Auto Quote URL',
    });
  }

  resetForm() {
    this.emailForm.reset();
    this.emailForm.patchValue({
      subject: 'HH Insurance - Auto Quote URL',
      recepientEmail: '',
    });
    this.emailContent = `Hi  ,<p>Please click the link below to start auto quote.</p><br><br><br>`;
    this.emailForm
      .get('recepientEmail')
      ?.setValidators([Validators.required, Validators.email]);
    this.emailForm.get('recepientEmail')?.updateValueAndValidity();
    this.emailForm.get('subject')?.setValidators([Validators.required]);
    this.emailForm.get('subject')?.updateValueAndValidity();
    this.mailSuccess = false;
  }

  sendEmail() {
    this.submitting = true;
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      this.submitting = false;
      return;
    }

    this.emailError = false;
    // const emails: any[] = [];
    // this.emailForm.value.emailCC.forEach((element: any) => {
    //   emails.push(element.email);
    // });

    const autoPayload = {
      email: this.emailForm.value.recepientEmail,
      emailData: this.emailContent,
    };
    this.service.createAutoQuote('', autoPayload).subscribe({
      next: (res) => {
        let buttonContent =
          this.emailContent +
          `<p style='text-align:center'><a style='background-color: #007cba;border-radius: 6px;font-size: 12px;font-weight: bold;line-height: 20px;color: #fff;text-decoration: none;padding: 11px 26px;cursor: pointer;' href='${this.backOfficeURL}/autoquote/createquote?id=${res?.data?.customerId}&qid=${res?.data?.quoteId}&renewal=true'>Start Auto Quote</a></p>`;
        const payload = {
          customerId: res?.data?.customerId,
          emailContent: buttonContent,
          recepientEmail: this.emailForm.value.recepientEmail,
          emailSubject: this.emailForm.value.subject,
        };
        this.service.sendEmail(payload).subscribe({
          next: () => {
            const autoStatusPayload = {
              status: 'Auto Email Sent',
            };
            this.service
              .updateAutoStatus(
                res?.data?.customerId,
                res?.data?.quoteId,
                autoStatusPayload
              )
              .subscribe({
                next: () => {
                  this.submitting = false;
                  this.mailSuccess = true;
                },
                error: () => {
                  this.emailError = true;
                  this.submitting = false;
                  this.service.snackbar('Network Error');
                },
              });
            // this.resetForm();
            // this.service.snackbar('Mail Sent Successfully');
          },
          error: () => {
            this.emailError = true;
            this.submitting = false;
            this.service.snackbar('Network Error');
          },
        });
        // this.resetForm();
        // this.service.snackbar('Mail Sent Successfully');
      },
      error: () => {
        this.emailError = true;
        this.submitting = false;
        this.service.snackbar('Network Error');
      },
    });
  }
}
