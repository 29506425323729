import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { EmailInfoComponent } from './components/email-info/email-info.component';
import { UserInfoComponent } from './components/user-info/user-info.component';

@Component({
  selector: 'app-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrls: ['./create-lead.component.css']
})
export class CreateLeadComponent implements OnDestroy {
  step = [
    { label: 'Customer Info', component: UserInfoComponent },
    { label: 'Email Preview', component: EmailInfoComponent },
  ];
  emailForm: FormGroup = this.fb.group({
    recepientEmail: ['', [Validators.required, Validators.email]],
    subject: ['', [Validators.required]],
    customer: this.fb.group({
      firstName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],
      ],
      lastName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
    }),
    address: this.fb.group({
      fullAddress: [''],
      streetNumber: ['', [Validators.required]],
      streetAddress: ['', [Validators.required]],
      city: ['', [Validators.required, Validators.pattern('[a-z A-Z.s]+')]],
      state: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
      ],
      country: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
      ],
      zip: ['', [Validators.required, Validators.pattern(/^[0-9 ]{1,7}$/)]],
    }),
  });
  public moduleConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'], // remove formatting button
      ['link'],
    ],
  };
  emailContent: any;
  submitting = false;
  emailError = false;
  mailSuccess = false;
  backOfficeURL = ``;
  addr: any;
  addrKeys: any;

  constructor(
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public router: Router,
    private zone: NgZone,
  ) { }

  get customer(): FormGroup {
    return this.emailForm.get('customer') as FormGroup;
  }

  get address(): FormGroup {
    return this.emailForm.get('address') as FormGroup;
  }

  ngOnInit(): void {
    this.emailContent = `Hi  ,<p>Please click the link below to start HO quote.</p><br><br><br>`;
    this.emailForm.patchValue({
      subject: 'HH Insurance - HO Quote URL',
    });
  }

  setAddress(addrObj: any) {
    this.emailForm.get('address')?.get('fullAddress')?.patchValue(addrObj.address);
    this.zone.run(() => {
      this.addr = addrObj;
      this.addrKeys = Object.keys(addrObj);
      this.addressMaptoForm();
    });
  }

  async addressMaptoForm() {
    this.emailForm?.get('address')?.get('streetNumber')?.patchValue(this.addr.street_number);
    this.emailForm?.get('address')?.get('city')?.patchValue(this.addr.city);
    this.addr.state == 'Florida'
      ? this.emailForm?.get('address')?.get('state')?.patchValue('FL')
      : this.emailForm?.get('address')?.get('state')?.patchValue(this.addr.state);
    this.emailForm?.get('address')?.get('country')?.patchValue(this.addr.country);
    this.emailForm?.get('address')?.get('zip')?.patchValue(this.addr.ZIP_code);
    this.emailForm
      ?.get('address')?.get('streetAddress')
      ?.patchValue(this.addr.street_address);
  }

  resetForm() {
    this.emailForm.reset();
    this.emailForm.patchValue({
      subject: 'HH Insurance - HO Quote URL',
      recepientEmail: '',
    });
    this.emailContent = `Hi  ,<p>Please click the link below to start HO quote.</p><br><br><br>`;
    this.emailForm
      .get('recepientEmail')
      ?.setValidators([Validators.required, Validators.email]);
    this.emailForm.get('recepientEmail')?.updateValueAndValidity();
    this.emailForm.get('subject')?.setValidators([Validators.required]);
    this.emailForm.get('subject')?.updateValueAndValidity();
    this.mailSuccess = false;
  }

  sendEmail() {
    this.submitting = true;
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      this.submitting = false;
      return;
    }
    this.emailError = false;
    const HOPayload = {
      email: this.emailForm.value.recepientEmail,
      emailData: this.emailContent,
    };
  }

  ngOnDestroy(): void {
    sessionStorage.clear();
  }

}
