import { Component, OnInit } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as checkoutPageData from '../carriers.data';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  merchantId = '';
  transactionId = '';
  package = {
    headers: ['Apples to Apples'],
    colPackage: [
      'Policy Term',
      'Paid in Full',
      '2 - Pay Plan',
      'EFT Pay Plan Monthly Premium**',
      'Initial Installment',
      'EFT Total Cost Premium',
      'CC Pay Plan Monthly Premium**',
      'Initial Installment',
      'CC Total Cost Premium',
      'Vehicles Covered',
      'Essential Coverage',
      'Coverage A- Bodily Injury',
      'Coverage A- Bodily Injury',
      'Coverage A- Property Damage',
      'Coverage B- Medical Payments',
      'Uninsured Motorist Coverage',
      'Coverage C- Uninsured Motorist',
      'Coverage C- Uninsured Motorist\r– Stacking or Non-Stacking',
      'Physical Damage Coverage',
      'Coverage D- Comprehensive\rCoverage',
      'Coverage D- Collision Coverage',
      'PIP Coverage',
      'Personal Injury Protection (PIP)\rLimit',
      'Personal Injury Protection Work\rExclusion',
      'Personal Injury Protection\rDeductible',
      'Personal Injury Protection\rDeductible Applies To',
      'Additional Coverages',
      'Extended Personal Injury\rProtection',
      'Rental Car Coverage',
      'Roadside Assistance and Towing',
      'Gap Coverage',
    ],
    colValues: [
      [
        '12-Month',
        '$ 2,467.00',
        '$ 1,234.00 per payment',
        '$ 233.10 per month',
        '$ 231.99',
        '$ 2,785.00',
        '$ 249.26 per month',
        '$ 246.15',
        '$ 2,955.00',
        '2010 PORSCHE PANAMERA\r2016 CADILLAC ESCALADE2020',
        '',
        '$ 100,000 per person BI',
        '$ 300,00 per accident BI',
        '$ 100,00 per accident PD',
        '$10,000',
        '',
        '$ 50,000 per person\r$ 100,000 per accident',
        'Non-Stacking',
        '',
        'Included\r$ 1,000 Deductible',
        'Included\r$ 1,000 Deductible',
        '',
        '$ 10,000',
        'Work loss included',
        '$ 10,000',
        'Named Insured/\rSpouse/Dependent/\rRelatives',
        '',
        'Available',
        'Included\r$40 per day (30-day\rmax)',
        'Included',
        'Available',
      ],
    ],
  };
  bankDetails: FormGroup = this.fb.group({
    accountName: [
      '',
      [Validators.required, Validators.pattern(/^[a-zA-Z'-'\s]{1,50}$/)],
    ],
    accountType: ['', [Validators.required]],
    routingNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{9}$/)]],
    accountNumber: ['', [Validators.required, Validators.pattern(/^\d{6,20}$/)]],
  });
  creditDetails: FormGroup = this.fb.group({
    expiry: ['', [Validators.required]],
    cardName: [
      '',
      [Validators.required, Validators.pattern(/^[a-zA-Z'-'\s]{1,50}$/)],
    ],
    cardNumber: [
      '',
      [Validators.required, Validators.pattern(/^[0-9-() ]{16}$/)],
    ],
    cvv: ['', [Validators.required, Validators.pattern(/^[0-9-() ]{3}$/)]],
    zipCode: ['', [Validators.required, Validators.pattern(/^[0-9-() ]{5}$/)]],
  });
  startDate = new Date();
  environmentURL = '';
  orderpage = '';
  dataRes: { [key: string]: any } = {};
  transactionAmount = '';
  quoteDatas: any = [];
  submittingCredit = false;
  submittingBank = false;
  displayDialog = false;
  carrierTyes = checkoutPageData.carrierCheckOutTypes;
  accountTypes = [
    { name: 'Checking Account', value: 'Checking Account' },
    { name: 'Savings Account', value: 'Savings Account' },
  ]

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public wizzipayServiceAPI: WizzipayService,
    // private stripeService: StripeService,
    public datePipe: DatePipe,
    public router: Router
  ) {
    this.environmentURL = environment.autoPortalURL;
  }

  campaignId = '';

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['destinationId'];
      this.campaignId = params['campaignId'];
      this.getData(params['campaignId']);
    });
  }
  campaignData: any;

  getData(id: string): void {
    this.wizzipayServiceAPI
      .getQuoteSelectionData(id)
      .subscribe((res) => {
        // this.wizzipayServiceAPI
        //   .getdata(this.merchantId, this.campaignId)
        //   .subscribe((campaignRes) => {
        this.campaignData = res?.data;
        // this.campaignData = {...this.campaignData, ...campaignRes?.data}
        // this.wizzipayServiceAPI.transactiondata(id).subscribe((res) => {
        this.orderpage = `${this.environmentURL}/orderPage/${this.merchantId}/${this.campaignId}`;
        // if(this.campaignData?.package.length) {
        //   const index = this.campaignData?.package?.at(0)?.headers?.indexOf('Enhanced Coverage');
        //   console.log('index', index)
        // }
        this.dataRes = res.data;
        // this.dataRes['status'] = 'open';
        const filterdata = this.carrierTyes.filter(
          (e) =>
            e.paymentMode == this.dataRes.paymentMode &&
            e.paymentMethod.includes(this.dataRes.paymentMethod)
        );
        this.transactionAmount = this.parseAmount(this.dataRes?.paymentDetails?.selectedQuoteAmount);
        this.dataRes?.paymentHistory?.transactionId && this.dataRes?.status !== 'Quote Payment Ready'
          && this.getPaymentInfo(this.dataRes?.paymentHistory?.transactionId, this.dataRes?.paymentDetails?.paymentMethod);
        // QUOTE TABLE DATAS START
        // for (const key in res.data.selectedPackage) {
        //   const payload: { [key: string]: any } = {};
        //   let count = 0;
        //   if (key == 'name') {
        //     payload['Package'] = 'Package';
        //   } else {
        //     payload['Package'] = key;
        //   }
        //   payload['object' + count++] = res.data.selectedPackage[key];
        //   payload['selected'] = key === filterdata[0].value ? true : false;
        //   this.quoteDatas.push(payload);
        // }
        this.quoteDatas = res?.data?.quoteType === 'Manual'
          ? res?.data?.proposalList[0]?.quoteList?.filter((x: any) => (x?.otherPackageName || x?.packageName) === res?.data?.paymentDetails?.selectedPackage)
          : res.data?.selectedPackage;
        // QUOTE TABLE DATAS END
        // this.stripeService.setKey(
        //   'pk_test_51IBQBuJ5nu0RIKQjhuxsfYWs3HSmlgBgLWhDZ3rEqC85qq46Ub7KUmyIVA5LxmYHbpEGjtp91glGUajOqlrpnNs200vBHThqOj',
        //   {
        //     stripeAccount: 'acct_1JO2EEQp4PW00lsy',
        //   }
        // );
      });
    // });
  }

  getPaymentInfo(transactionId: string, paymentMethod: string) {
    this.wizzipayServiceAPI.getCardDetails(transactionId).subscribe((res) => {
      if (paymentMethod === 'Electronic Funds Transfer' && res?.data?.ib) {
        this.bankDetails.patchValue(res?.data?.ib);
        return;
      }
      if (paymentMethod === 'Credit Card' && res?.data?.cc) {
        this.creditDetails.patchValue(res?.data?.cc);
        const [month, year] = res?.data?.cc?.expiryDate.split('/');
        const date = new Date().getDate();
        const dateObject = new Date(parseInt(year), parseInt(month) - 1, date);
        this.creditDetails.get('expiry')?.patchValue(dateObject);
      }
    });
  }

  parseAmount(val: string): string {
    let amount = val?.replace(/[$,]/g, "")
    if (amount) {
      return (parseFloat(amount))?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    } else {
      return '';
    }
  }

  returnZero() {
    return 0;
  }

  // createToken(): void {
  //   this.submittingBank = true;
  //   this.bankDetails.markAllAsTouched();
  //   if (this.bankDetails.invalid) {
  //     this.submittingBank = false;
  //     this.wizzipayServiceAPI.snackbar('Please fill all the fields');
  //     return;
  //   }
  //   let payload: any = {};
  //   if (this.bankDetails.value.accountNumber) {
  //     payload = {
  //       country: 'US',
  //       currency: 'usd',
  //       routing_number: '110000000',
  //       account_number: this.bankDetails.value.accountNumber,
  //       account_holder_name: this.bankDetails.value.accountName,
  //       account_holder_type: this.bankDetails.value.accountType,
  //     };
  //   } else {
  //     payload = {
  //       country: 'US',
  //       currency: 'usd',
  //       routing_number: '110000000',
  //       account_number: this.bankDetails.value.accountNumber,
  //       account_holder_name: this.bankDetails.value.accountName,
  //       account_holder_type: this.bankDetails.value.accountType,
  //     };
  //   }
  //   if (payload) {
  //     this.stripeService.createToken('bank_account', payload).subscribe(
  //       (res: any) => {
  //         if (res.token) {
  //           this.bank(res);
  //         } else if (res.error) {
  //           this.wizzipayServiceAPI.snackbar(res.error.message);
  //         }
  //       },
  //       (err) => {
  //         if (err) {
  //           this.submittingBank = false;
  //           this.wizzipayServiceAPI.snackbar('Error');
  //         }
  //       }
  //     );
  //   }
  // }

  // bank(data: { token: { id: string; bank_account: { id: string } } }) {
  //   const payload = {
  //     token: data.token.id,
  //     transactionId: this.transactionId,
  //     account: data.token.bank_account.id,
  //   };
  //   this.wizzipayServiceAPI.bankData(payload).subscribe(
  //     (res) => {
  //       if (res) {
  //         this.submittingBank = false;
  //         this.wizzipayServiceAPI.snackbar(res.message);
  //       }
  //     },
  //     (err) => {
  //       if (err) {
  //         this.submittingBank = false;
  //         this.wizzipayServiceAPI.snackbar('Error');
  //       }
  //     }
  //   );
  // }

  submitBank(): void {
    this.submittingBank = true;
    this.bankDetails.markAllAsTouched();
    if (this.bankDetails.invalid) {
      this.submittingBank = false;
      this.wizzipayServiceAPI.snackbar('Please fill all the fields');
      return;
    }
    const transactionPayload = {
      userDetails: {
        firstName: this.campaignData?.customer?.firstName,
        lastName: this.campaignData?.customer?.lastName,
        phoneCode: '+1',
        phoneNumber: this.campaignData?.driverPhone?.at(0)?.number,
        email: this.campaignData?.customer?.email,
      },
      custId: this.campaignData?.commonCustomerId,
      paymentDetails: {
        tripName: '',
        amount: +this.campaignData?.paymentDetails?.selectedQuoteAmount?.replace(/[^\d.]/g, ''),
      },
      packageName: this.campaignData?.paymentDetails?.selectedPackage,
      merchantId: this.merchantId,
      campaignId: this.campaignId,
      driverPhone: this.campaignData?.driverPhone,
      policyStartDate: this.campaignData?.policyStartDate,
      paymentMode: this.campaignData?.paymentDetails?.paymentMode,
      paymentMethod: this.campaignData?.paymentDetails?.paymentMethod,
    };
    const payload = {
      paymentType: 'IB',
      routingNumber: this.bankDetails.value.routingNumber,
      accountNumber: this.bankDetails.value.accountNumber,
      accountName: this.bankDetails.value.accountName,
      accountType: this.bankDetails.value.accountType,
    };
    this.wizzipayServiceAPI.orderData(transactionPayload).subscribe(
      (transactionRes) => {
        this.wizzipayServiceAPI
          .postTransaction(payload, transactionRes?.data?.transactionId)
          .subscribe(
            (res) => {
              if (res) {
                this.submittingBank = false;
                this.displayDialog = true;
              }
            },
            (err) => {
              if (err) {
                this.submittingBank = false;
                this.wizzipayServiceAPI.snackbar('Error');
              }
            }
          );
      },
      (err) => {
        if (err) {
          this.submittingBank = false;
          this.wizzipayServiceAPI.snackbar('Error');
        }
      }
    );
  }

  submitCredit() {
    this.submittingCredit = true;
    this.creditDetails.markAllAsTouched();
    if (this.creditDetails.invalid) {
      this.submittingCredit = false;
      this.wizzipayServiceAPI.snackbar('Please fill all the fields');
      return;
    }
    const transactionPayload = {
      userDetails: {
        firstName: this.campaignData?.customer?.firstName,
        lastName: this.campaignData?.customer?.lastName,
        phoneCode: '+1',
        phoneNumber: this.campaignData?.driverPhone?.at(0)?.number,
        email: this.campaignData?.customer?.email,
      },
      custId: this.campaignData?.commonCustomerId,
      paymentDetails: {
        tripName: '',
        amount: +this.campaignData?.paymentDetails?.selectedQuoteAmount?.replace(/[^\d.]/g, ''),
      },
      packageName: this.campaignData?.paymentDetails?.selectedPackage,
      merchantId: this.merchantId,
      campaignId: this.campaignId,
      driverPhone: this.campaignData?.driverPhone,
      policyStartDate: this.campaignData?.policyStartDate,
      paymentMode: this.campaignData?.paymentDetails?.paymentMode,
      paymentMethod: this.campaignData?.paymentDetails?.paymentMethod,
    };
    const payload = {
      paymentType: 'CC',
      cardName: this.creditDetails.value.cardName,
      cardNumber: this.creditDetails.value.cardNumber,
      cvv: this.creditDetails.value.cvv,
      expiryDate: this.datePipe.transform(
        this.creditDetails.value.expiry,
        'MM/yyyy'
      ),
      amount: this.dataRes?.amount,
      zipCode: this.creditDetails.value.zipCode,
    };
    this.wizzipayServiceAPI.orderData(transactionPayload).subscribe(
      (transactionRes) => {
        this.wizzipayServiceAPI
          .postTransaction(payload, transactionRes?.data?.transactionId)
          .subscribe(
            (res) => {
              if (res) {
                this.submittingCredit = false;
                this.displayDialog = true;
              }
            },
            (err) => {
              if (err) {
                this.submittingCredit = false;
                this.wizzipayServiceAPI.snackbar('Error');
              }
            }
          );
      },
      (err) => {
        if (err) {
          this.submittingCredit = false;
          this.wizzipayServiceAPI.snackbar('Error');
        }
      }
    );
  }

  routeBack() {
    window.location.href = 'https://hhinsgroup.com/';
  }
}
