export const carrierTyes = [
  {
    paymentMode: 'Paid in Full',
    package: [
      {
        value: 'Paid in Full',
        key: 'fullPayment',
        carrier: ['Mercury', 'Metlife', 'Progressive', 'Safeco', 'Vault', 'Bristol West', 'Hagerty'],
        paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      },
      {
        value: 'Paid in Full EFT',
        key: 'fullPaymentEFT',
        carrier: ['Travelers'],
        paymentMethod: ['Electronic Funds Transfer'],
      },
      {
        value: 'Paid in Full CC',
        key: 'fullPaymentCC',
        carrier: ['Travelers'],
        paymentMethod: ['Credit Card'],
      },
    ],
  },
  {
    paymentMode: '2 - Pay Plan',
    package: [
      {
        value: '2 - Pay Plan',
        key: 'twoPayPlan',
        carrier: ['Vault'],
        paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      },
    ],
  },
  {
    paymentMode: '4 - Pay Plan',
    package: [
      {
        value: '4 - Pay Plan',
        key: 'fourPayPlan',
        carrier: ['Vault'],
        paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
      },
    ],
  },
  {
    paymentMode: 'Installment',
    package: [
      {
        value: 'EFT Pay Plan Monthly Premium**',
        key: 'EFTInstallValue',
        carrier: ['Mercury', 'Metlife', 'Progressive', 'Safeco', 'Travelers', 'Bristol West'],
        paymentMethod: ['Electronic Funds Transfer'],
      },
      {
        value: 'CC Pay Plan Monthly Premium**',
        key: 'CCInstallValue',
        carrier: ['Mercury', 'Progressive', 'Safeco', 'Travelers', 'Bristol West'],
        paymentMethod: ['Credit Card'],
      },
    ],
  },
  // {
  //   paymentMode: 'Full Payment',
  //   package: [
  //     {
  //       value: 'EFT Total Cost Premium',
  //       key: 'EFTValue',
  //       carrier: ['Mercury', 'Metlife', 'Progressive', 'Safeco', 'Travelers'],
  //       paymentMethod: ['Electronic Funds Transfer'],
  //     },
  //     {
  //       value: 'CC Total Cost Premium',
  //       key: 'CCValue',
  //       carrier: ['Mercury', 'Progressive', 'Safeco', 'Travelers'],
  //       paymentMethod: ['Credit Card'],
  //     },
  //   ],
  // },
];

export const paymentMethodOption = [
  {
    name: 'Electronic Funds Transfer',
    value: 'Electronic Funds Transfer',
    selected: false,
  },
  {
    name: 'Credit Card',
    value: 'Credit Card',
    selected: false,
  },
];

export const carrierCheckOutTypes = [
  {
    value: 'Paid in Full',
    paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
    paymentMode: 'Paid in Full',
  },
  {
    value: '2 - Pay Plan',
    paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
    paymentMode: '2 - Pay Plan',
  },
  {
    value: '4 - Pay Plan',
    paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
    paymentMode: '4 - Pay Plan',
  },
  {
    value: 'Paid in Full EFT',
    paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
    paymentMode: 'Paid in Full EFT',
  },
  {
    value: 'Paid in Full CC',
    paymentMethod: ['Electronic Funds Transfer', 'Credit Card'],
    paymentMode: 'Paid in Full CC',
  },
  {
    value: 'EFT Total Cost Premium',
    paymentMethod: ['Electronic Funds Transfer'],
    paymentMode: 'Full Payment',
  },
  {
    value: 'CC Total Cost Premium',
    paymentMethod: ['Credit Card'],
    paymentMode: 'Full Payment',
  },
  {
    value: 'EFT Pay Plan Monthly Premium**',
    paymentMethod: ['Electronic Funds Transfer'],
    paymentMode: 'Installment',
  },
  {
    value: 'CC Pay Plan Monthly Premium**',
    paymentMethod: ['Credit Card'],
    paymentMode: 'Installment',
  },
];


export const manualAutoPayment = [
  {
    paymentMode: 'Full Pay Premium',
    value: 'Full Pay Premium',
    carrier: ['Bristol West', 'Mercury', 'Hagerty', 'Metlife (Foremost)', 'Progressive', 'Progressive with Snapshot', 'Safeco', 'Travelers', 'Vault']
  },
  {
    paymentMode: '2 - Pay Plan',
    value: '2 - Pay Plan',
    carrier: ['Vault']
  },
  {
    paymentMode: '4 - Pay Plan',
    value: '4 - Pay Plan',
    carrier: ['Vault']
  },
  {
    paymentMode: 'Installment',
    value: 'Installment',
    carrier: ['Bristol West', 'Mercury', 'Metlife (Foremost)', 'Progressive', 'Progressive with Snapshot', 'Safeco', 'Travelers']
  },
]

export const manualPaymentMethod = [
  {
    name: 'Electronic Funds Transfer',
    value: 'Electronic Funds Transfer',
    carrier: ['Bristol West', 'Hagerty', 'Mercury **With Go**', 'Metlife (Foremost)', 'Progressive', 'Progressive with Snapshot', 'Safeco with RightTrack', 'Travelers with Intellidrive', 'Vault']
  },
  {
    name: 'Credit Card',
    value: 'Credit Card',
    carrier: ['Bristol West', 'Hagerty', 'Mercury **With Go**', 'Progressive', 'Progressive with Snapshot', 'Safeco with RightTrack', 'Travelers with Intellidrive', 'Vault']
  },
]