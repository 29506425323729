import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class WizzipayService {
  environment = environment;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public ngxService: NgxUiLoaderService
  ) { }

  snackbar(message: string, duration?: any) {
    return this.snackBar.open(message, 'close', {
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      duration: duration || 1500,
      panelClass: ['snackbarStyle'],
    });
  }
  deleteRecords(quoteIdList: string): Observable<any> {
    return this.http.delete(`${this.environment.autoURL}/api/v1/autoquote/dashboard/delete?quoteIds=${quoteIdList}`)
  }
  login(data: any): Observable<any> {
    return this.http.post(
      // 'https://hhinsured.agileclouds.com/api/v1/backend/account/login',
      ///api/insurance/admin/login
      // `${this.environment.autoPaymentServiceURL}/account/login`,
      `${this.environment.autoURL}/api/v1/common/auth/login`,
      data
    );
  }

  register(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/account/register`,
      data
    );
  }

  getcampaignData(
    id: any,
    sort: any,
    status: any,
    limit: any,
    offset: any,
    value: string
  ): Observable<any> {
    return this.http.get(
      `${this.environment.autoPaymentServiceURL}/campaign/${id}?sort=${sort}&status=${status}&offset=${offset}&limit=${limit}&value=${value}`
    );
  }

  getMerchantData(id: any): Observable<any> {
    return this.http.get(
      `${this.environment.autoPaymentServiceURL}/merchant/${id}`
    );
  }

  archieveOffer(merchantId: any, campaignId: any): Observable<any> {
    return this.http.put(
      `${this.environment.autoPaymentServiceURL}/campaign/${merchantId}/${campaignId}/archive`,
      ''
    );
  }

  getCardDetails(id: any): Observable<any> {
    return this.http.get(
      `${this.environment.autoPortalURL}/api/vault/users/${id}`
    );
  }

  deleteQuote(merchantId: any, data: any): Observable<any> {
    return this.http.delete(
      `${this.environment.autoPaymentServiceURL}/campaign/${merchantId}?ids=${data}`
    );
  }

  // Order Setup

  parseFiles(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPortalURL}/api/parser/file`,
      data
    );
  }

  uploadFiles(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/file/upload`,
      data
    );
  }

  getFiles(id: any): Observable<any> {
    return this.http.get(
      `${this.environment.autoPaymentServiceURL}/file/${id}`
    );
  }
  ///campaign/64bfd0c5b6d72ab8c44bafc6/payments

  getQuoteSelectionData(campaignId: any): Observable<any> {
    return this.http.get(
      `${this.environment.autoPaymentServiceURL}/campaign/${campaignId}/payments`
    );
  }

  sumbitOrder(data: any, merchantId: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/campaign/${merchantId}`,
      data
    );
  }

  getdata(merchantId: any, campaignId: any): Observable<any> {
    return this.http.get(
      `${this.environment.autoPaymentServiceURL}/campaign/${merchantId}/${campaignId}`
    );
  }

  updateOrder(data: any, merchantId: any, campaignId: any): Observable<any> {
    return this.http.put(
      `${this.environment.autoPaymentServiceURL}/campaign/${merchantId}/${campaignId}`,
      data
    );
  }

  email(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/notification/campaign`,
      data
    );
  }

  sms(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/notification/campaign/message`,
      data
    );
  }

  orderData(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/payment/pay`,
      data
    );
  }

  transactiondata(id: any): Observable<any> {
    return this.http.get(
      `${this.environment.autoPaymentServiceURL}/payment/pay/${id}`
    );
  }

  bankData(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/payment/pay/ach`,
      data
    );
  }

  postTransaction(data: any, id: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoPaymentServiceURL}/payment/pay/${id}`,
      data
    );
  }

  // TRANSACTION API

  getAllTransaction(): Observable<any> {
    return this.http.get(`${this.environment.transactionURL}/transaction`);
  }

  getTransaction(id: any): Observable<any> {
    return this.http.get(
      `${this.environment.transactionURL}/transaction/${id}`
    );
  }

  approveTransaction(id: any, data: any): Observable<any> {
    return this.http.post(
      `${this.environment.transactionURL}/transaction/${id}/approve`,
      data
    );
  }
  getAutoLeads(
    pageSize: any,
    pageNumber: any,
    sort: any,
    sortOrder: any,
    searchValue: any,
    archive: boolean,
    status: any
  ): Observable<any> {
    //http://54.226.98.169/10&pageNumber=0&sortBy=&sortorder=&searchKeyWord=&isArchived=false&quoteStatus=
    return this.http.get(
      // `${this.environment.autoURL}/api/v1/common/dashbaord/data/auto?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sort ? sort : ''}&sortOrder=${sortOrder ? sortOrder : ''}&searchKeyWord=${searchValue ? searchValue : ''}&isArchived=false&quoteStatus=${status ? status : ''}`
      `${this.environment.autoURL}/api/v1/common/dashbaord/data?appName=auto_quote&pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sort || ''}&sortorder=${sortOrder || ''}&searchKeyWord=${searchValue || ''}&isArchived=${archive}&quoteStatus=${status || ''}`
    );
  }

  sendQuoteSMS(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/sms/messages`,
      data
    );
  }


  // Auto Quote

  // getAutoLeads1(pageNumber: number): Observable<any> {
  // return this.http.get(
  //   `${this.environment.autoURL}/api/v1/autoquote/autoportalleads?pageSize=10&pageNumber=${pageNumber}`
  // );
  //   return this.http.get(
  //     `${this.environment.autoURL}/api/v1/autoquote/dashboard/data?pageSize=10&pageNumber=${pageNumber}&sortBy=customerName&sortorder=&searchKeyWord=&isArchived=false&quoteStatus=`
  //   )
  // }

  getAutoDetails(customerId: string, quoteId: string): Observable<any> {
    return this.http.get(
      this.environment.autoURL +
      `/api/v1/autoquote/autodata/${customerId}/${quoteId}`
    );
  }

  sendEmail(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/hhinsurance/underwriting/sendEmail`,
      data
    );
  }

  createAutoQuote(customerId: string, data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/autoquote/exclusive?cId=${customerId}`,
      data
    );
  }

  getLeadData(customerId: any, quoteId: any): Observable<any> {
    return this.http.get(
      `${this.environment.autoURL}/api/v1/hhinsurance/autoquote/initautodata/${customerId}/${quoteId}`
    );
  }

  getClientInfo(customerId: string): Observable<any> {
    return this.http.get(
      `${this.environment.autoURL}/api/v1/hhinsurance/autoquote/qrclientinfo/${customerId}`
    );
  }

  getLenderData(customerId: string): Observable<any> {
    return this.http.get(
      `${this.environment.autoURL}/api/v1/hhinsurance/autoquote/storeNewLenderData/${customerId}`
    );
  }

  saveLenderData(customerId: string, data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/customer/save/${customerId}`,
      data
    );
  }

  submitSummary(data: any, customerId: any, quoteId: any): Observable<any> {
    return this.http.put(
      `${this.environment.autoURL}/api/v1/hhinsurance/autoquote/initautodata/${customerId}/${quoteId}`,
      data
    );
  }

  submitNewAutoQuote(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/hhinsurance/autoquote/initautodata/exclusiveauto`,
      data
    );
  }

  updateAutoStatus(customerId: any, quoteId: any, data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/autoquote/update/status/${customerId}/${quoteId}`,
      data
    );
  }

  saveCustomerData(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/common/customer/save`,
      data
    );
  }

  getCustomerData(customerId: string): Observable<any> {
    return this.http.get(
      `${this.environment.autoURL}/api/v1/common/customer/fetch/${customerId}`
    );
  }

  saveAgentData(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/common/agent/save`,
      data
    );
  }

  sendCommonEmail(data: any): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/common/email`,
      data
    );
  }
  //

  getAgentList(): Observable<any> {
    return this.http.get(
      `${this.environment.autoURL}/api/v1/common/agent/menu`
    );
  }

  uploadFile(customerId: string, quoteId: string, data: any, fileName: string): Observable<any> {
    return this.http.post(
      `${this.environment.autoURL}/api/v1/document/upload?cId=${customerId}&qId=${quoteId}&portal=auto_proposal&docType=proposal&fileName=${fileName}&temp=false`,
      data
    );
  }

}
