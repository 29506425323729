import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[tabIndex]',
})
export class TabIndexDirective {
    @Input() tabIndex = 0;

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();

            const tabbableElements = Array.from(
                this.el.nativeElement.parentNode.querySelectorAll('[tabindex]')
            );

            const currentIndex = tabbableElements.findIndex(
                (el: any) => el === this.el.nativeElement
            );

            if (currentIndex !== -1) {
                const nextIndex = (currentIndex + (event.shiftKey ? -1 : 1)) % tabbableElements.length;
                const nextElement: any = tabbableElements[nextIndex];
                nextElement.focus();
            }
        }
    }
}
