<table class="table table-bordered table-wid mb-5 mt-4" [ngClass]="{'mobile-table': mobilePopup}">
    <thead>
        <th style="padding-left: 0.5rem;" class="tableHeaders">Package</th>
        <th class="text-center" *ngFor="let data of quoteProposalData.quoteList; let i = index"
            [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
            {{data?.otherPackageName ? data?.otherPackageName : data?.packageName}}
        </th>
    </thead>
    <tbody id="tableData">
        <tr [ngStyle]="{'pointer-events': quoteSelection ? 'auto' : 'none'}" *ngIf="!disableSelection">
            <td class="firstCol">Select any one</td>
            <ng-container *ngFor="let item of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == item?.proposalQuoteId)}">
                    <button id="select" class="btn btn-type-two w-100" type="button" (click)="selectQuote(item)">
                        <span>Select this quote</span>
                    </button>
                </td>
            </ng-container>
        </tr>
        <tr>
            <td class="tableHeaders">Carrier Name</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    {{data?.carrierName}}
                </td>
            </ng-container>
        </tr>
        <tr>
            <td class="tableHeaders">Policy Term</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    {{data?.policyTerm || ''}}</td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.enrolledInMercuryGo">
            <td class="tableHeaders">Enrolled in MercuryGO</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    {{data?.enrolledInMercuryGo || ''}}</td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.enrolledInSnapshot">
            <td class="tableHeaders">Enrolled in Snapshot</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    {{data?.enrolledInSnapshot || ''}}</td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.enrolledInRightTrack">
            <td class="tableHeaders">Enrolled in RightTrack</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    {{data?.enrolledInRightTrack || ''}}</td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.enrolledInIntelliDrive">
            <td class="enrolledInIntelliDrive">Enrolled in IntelliDrive</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    {{data?.enrolledInIntelliDrive || ''}}</td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.fullPayMonthlyPremium">
            <td class="tableHeaders">Full Pay Monthly Premium</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.fullPayMonthlyPremium , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.fullPayMonthlyPremium || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.payPlanMonthlyPremiumTotal">
            <td class="tableHeaders">Pay Plan Monthly Premium Total</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.payPlanMonthlyPremiumTotal , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.payPlanMonthlyPremiumTotal || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.payPlanMonthlyPremiumDownpayment">
            <td class="tableHeaders">Pay Plan Monthly Premium Downpayment</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.payPlanMonthlyPremiumDownpayment , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.payPlanMonthlyPremiumDownpayment || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.payPlanMonthlyPremiumPerMonth">
            <td class="tableHeaders">Pay Plan Monthly Premium Per Month</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.payPlanMonthlyPremiumPerMonth , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.payPlanMonthlyPremiumPerMonth || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.paidInFull">
            <td class="tableHeaders highLightBlue">Paid in Full</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.paidInFull , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.paidInFull || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.eftPayPlanMonthlyPremium">
            <td class="tableHeaders">EFT Pay Plan Monthly Premium**</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.eftPayPlanMonthlyPremium , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.eftPayPlanMonthlyPremium || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.initialEftInstallment">
            <td class="tableHeaders">Initial EFT Installment</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.initialEftInstallment , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.initialEftInstallment || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.eftTotalCostPremium">
            <td class="tableHeaders">EFT Total Cost Premium</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.eftTotalCostPremium , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.eftTotalCostPremium || ''}}</span>
                </td>
            </ng-container>
        </tr>

        <tr *ngIf="tableHeaderSelect.display.ccPayPlanMonthlyPremium">
            <td class="tableHeaders">CC Pay Plan Monthly Premium**</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.ccPayPlanMonthlyPremium , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.ccPayPlanMonthlyPremium || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.initialCcInstallment">
            <td class="tableHeaders">Initial CC Installment</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.initialCcInstallment , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.initialCcInstallment || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.ccTotalCostPremium">
            <td class="tableHeaders">CC Total Cost Premium</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.ccTotalCostPremium , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.ccTotalCostPremium || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.twoPayPlan">
            <td class="tableHeaders">2-Pay Plan**</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.twoPayPlan , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.twoPayPlan || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.twoPayTotalCostPremium">
            <td class="tableHeaders">2-Pay Total Cost Premium</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.twoPayTotalCostPremium , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.twoPayTotalCostPremium || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.fourPayPlan">
            <td class="tableHeaders">4-Pay Plan**</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.fourPayPlan , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.fourPayPlan || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.fourPayTotalPremium">
            <td class="tableHeaders">4 Pay Total Premium</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightBlue' : data?.fourPayTotalPremium , 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.fourPayTotalPremium || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.vehiclesCovered">
            <td class="tableHeaders">Vehicles Covered</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container class="py-3" *ngFor="let vehicle of data?.vehiclesCovered; let j = index">
                        <span *ngIf="data?.vehiclesCovered?.length>1">Vehicle {{j+1}} -
                            {{vehicle}}<span *ngIf="j !== data?.vehiclesCovered?.length"><br></span></span>
                        <span *ngIf="data?.vehiclesCovered?.length == 1">{{vehicle}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr>
            <td class="tableHeaders tableMainHead" [attr.colspan]="quoteProposalData?.quoteList?.length + 1">
                <span>Essential
                    Coverage</span>
            </td>
            <!-- <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td class="removeBorder">
                </td>
            </ng-container> -->
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageABodilyInjuryPerPersonPerAccident">
            <td class="tableHeaders">Coverage A - Bodily Injury (per person/per accident)</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageABodilyInjuryPerPersonPerAccident, 'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{data?.coverageABodilyInjuryPerPersonPerAccident || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageAPropoertyDamagePerAccident">
            <td class="tableHeaders">Coverage A - Property Damage per accident</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageAPropoertyDamagePerAccident,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.coverageAPropoertyDamagePerAccident || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageBMedicalPayments">
            <td class="tableHeaders">Coverage B - Medical Payments</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageBMedicalPayments,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.coverageBMedicalPayments || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr>
            <td class="tableHeaders tableMainHead" [attr.colspan]="quoteProposalData?.quoteList?.length + 1">
                <span>Uninsured Motorist Coverage</span>
            </td>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageCUninsuredMotoristPerPersonPerAccident">
            <td class="tableHeaders">Coverage C - Uninsured Motorist (per person/per accident)</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageCUninsuredMotoristPerPersonPerAccident,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.coverageCUninsuredMotoristPerPersonPerAccident || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageCUninsuredMotorist">
            <td class="tableHeaders">Coverage C - Uninsured Motorist</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageCUninsuredMotorist,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.coverageCUninsuredMotorist || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr>
            <td class="tableHeaders tableMainHead" [attr.colspan]="quoteProposalData?.quoteList?.length + 1">
                <span>Physical Damage Coverage</span>
            </td>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.guaranteedValue">
            <td class="tableHeaders">Guaranteed Value</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container *ngFor="let val of data?.guaranteedValue; let j= index">
                        <span *ngIf="data?.guaranteedValue?.length>1"><strong>Vehicle {{j+1}} -
                                {{data?.vehiclesCovered[j]}}</strong><br></span>
                        <span style="display: block;"
                            [ngClass]="{'highLightYellow': premiumHighlight[i]?.guaranteedValue?.at(j)}">
                            {{val || ''}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageDComprehensiveCoverage">
            <td class="tableHeaders">Coverage D - Comprehensive Coverage</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container *ngFor="let val of data?.coverageDComprehensiveCoverage; let j = index">
                        <span *ngIf="data?.coverageDComprehensiveCoverage?.length>1"><strong>Vehicle {{j+1}} -
                                {{data?.vehiclesCovered[i]}}</strong><br></span>
                        <span style="display: block;"
                            [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageDComprehensiveCoverage?.at(j)}">
                            {{val || ''}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageDComprehensiveCoverageDeductible">
            <td class="tableHeaders">Coverage D - Comprehensive Coverage Deductible</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container *ngFor="let val of data?.coverageDComprehensiveCoverageDeductible; let j= index">
                        <span *ngIf="data?.coverageDComprehensiveCoverageDeductible?.length>1"><strong>Vehicle {{j+1}} -
                                {{data?.vehiclesCovered[j]}}</strong><br></span>
                        <span style="display: block;"
                            [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageDComprehensiveCoverageDeductible?.at(j)}">{{val
                            || ''}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageDCollisionCoverage">
            <td class="tableHeaders">Coverage D - Collision Coverage</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container *ngFor="let val of data?.coverageDCollisionCoverage; let j= index">
                        <span *ngIf="data?.coverageDCollisionCoverage?.length>1"><strong>Vehicle {{j+1}} -
                                {{data?.vehiclesCovered[j]}}</strong><br></span>
                        <span style="display: block;"
                            [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageDCollisionCoverage?.at(j)}">{{val
                            || ''}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.coverageDCollisionCoverageDeductible">
            <td class="tableHeaders">Coverage D - Collision Coverage Deductible</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container *ngFor="let val of data?.coverageDCollisionCoverageDeductible; let j= index">
                        <span *ngIf="data?.coverageDCollisionCoverageDeductible?.length>1"><strong>Vehicle {{j+1}} -
                                {{data?.vehiclesCovered[j]}}</strong><br></span>
                        <span style="display: block;"
                            [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageDCollisionCoverageDeductible?.at(j)}">{{val
                            || ''}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr>
            <td class="tableHeaders tableMainHead" [attr.colspan]="quoteProposalData?.quoteList?.length + 1">
                <span>PIP Coverage</span>
            </td>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.personalInjuryProtectionLimit">
            <td class="tableHeaders">Personal Injury Protection (PIP) Limit</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.personalInjuryProtectionLimit,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.personalInjuryProtectionLimit || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.pipWorkExclusion">
            <td class="tableHeaders">PIP Work Exclusion</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.pipWorkExclusion,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.pipWorkExclusion || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.pipDeductible">
            <td class="tableHeaders">PIP Deductible</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.pipDeductible,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.pipDeductible || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.pipAppliesTo">
            <td class="tableHeaders">PIP Applies to</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.pipAppliesTo,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.pipAppliesTo || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr>
            <td class="tableHeaders tableMainHead" [attr.colspan]="quoteProposalData?.quoteList?.length + 1">
                <span>Additional Coverages</span>
            </td>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.ultraCoverage">
            <td class="tableHeaders">Ultra-Coverage (Accident Forgiveness)</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.ultraCoverage,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.ultraCoverage || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.accidentMinorViolationForgiveness">
            <td class="tableHeaders">Accident & Minor Violation Forgiveness</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.accidentMinorViolationForgiveness,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.accidentMinorViolationForgiveness || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.responsibleDrivingPlan">
            <td class="tableHeaders">Responsible Driving Plan</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.responsibleDrivingPlan,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.responsibleDrivingPlan || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.extendedPip">
            <td class="tableHeaders">Extended PIP</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.extendedPip,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.extendedPip || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.additionalPip">
            <td class="tableHeaders">Additional PIP</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.additionalPip,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.additionalPip || ''}}</span>
                </td>
            </ng-container>
        </tr>


        <tr *ngIf="tableHeaderSelect.display.rentalCarCoverage">
            <td class="tableHeaders">Rental Car Coverage</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container *ngFor="let val of data?.rentalCarCoverage; let j= index">
                        <span *ngIf="data?.rentalCarCoverage?.length>1"><strong>Vehicle {{j+1}} -
                                {{data?.vehiclesCovered[j]}}</strong><br></span>
                        <span style="display: block;"
                            [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageDComprehensiveCoverage?.at(j)}">
                            {{val || ''}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.roadsideAssistanceAndTowing">
            <td class="tableHeaders">Roadside Assistance and Towing</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <ng-container *ngFor="let val of data?.roadsideAssistanceAndTowing; let j= index">
                        <span *ngIf="data?.roadsideAssistanceAndTowing?.length>1"><strong>Vehicle {{j+1}} -
                                {{data?.vehiclesCovered[j]}}</strong><br></span>
                        <span style="display: block;"
                            [ngClass]="{'highLightYellow': premiumHighlight[i]?.coverageDComprehensiveCoverage?.at(j)}">{{val
                            || ''}}</span>
                    </ng-container>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.gapCoverage">
            <td class="tableHeaders">Gap Coverage</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.gapCoverage,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.gapCoverage || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.originalEquipmentManufacturer">
            <td class="tableHeaders">Original Equipment Manufacturer</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.originalEquipmentManufacturer,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.originalEquipmentManufacturer || ''}}</span>
                </td>
            </ng-container>
        </tr>
        <tr *ngIf="tableHeaderSelect.display.enhancedPackage">
            <td class="tableHeaders">Enhanced Package</td>
            <ng-container *ngFor="let data of quoteProposalData.quoteList; let i = index">
                <td
                    [ngClass]="{'highLightYellow': premiumHighlight[i]?.enhancedPackage,'columnColor': (quoteSelection && selectedQuote?.proposalQuoteId == data?.proposalQuoteId)}">
                    <span>{{ data?.enhancedPackage || ''}}</span>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>