<main class="d-flex mb-3">
    <div class="card formSection">
        <form [formGroup]="userInfo" autocomplete="off">
            <div class="card-header">
                <div class="jumbotron text-center">
                    <h1 class="display-6">Customer Information</h1>
                </div>
            </div>
            <div class="card-body">
                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="row justify-content-center">
                                    <div class="col-lg-4">
                                        <h1 class="subHeader">Personal Info</h1>
                                    </div>
                                    <div class="col-8 text-end">
                                        <span class="px-2" *ngIf="multiCustomer?.value?.length == 0">Would you like to
                                            add more applicants ?</span>
                                        <i class="fa fa-plus faIcon" id="addEmbryoDetails" (click)="addCustomer()"
                                            *ngIf="multiCustomer?.value?.length == 0"></i>
                                    </div>
                                    <ng-container formGroupName="customer">
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input inputId="firstName" label="First Name"
                                                formControlName="firstName"></insurance-input>
                                            <div
                                                *ngIf="customer?.get('firstName')?.touched && customer?.get('firstName')?.errors">
                                                <span class="p-error"
                                                    *ngIf="customer?.get('firstName')?.hasError('required')">
                                                    First name is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="customer?.get('firstName')?.hasError('pattern')">
                                                    Please enter a valid first name
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input inputId="lastName" label="Last Name"
                                                formControlName="lastName"></insurance-input>
                                            <div
                                                *ngIf="customer?.get('lastName')?.touched && customer?.get('lastName')?.errors">
                                                <span class="p-error"
                                                    *ngIf="customer?.get('lastName')?.hasError('required')">
                                                    Last name is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="customer?.get('lastName')?.hasError('pattern')">
                                                    Please enter a valid last name
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input inputId="emailAddress" label="Email"
                                                formControlName="emailAddress">
                                            </insurance-input>
                                            <div
                                                *ngIf="customer?.get('emailAddress')?.touched && customer?.get('emailAddress')?.errors">
                                                <span class="p-error"
                                                    *ngIf="customer?.get('emailAddress')?.hasError('required')">
                                                    Email is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="customer?.get('emailAddress')?.hasError('pattern')">
                                                    Please enter a valid email
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input-mask inputId="number" label="Phone Number"
                                                formControlName="phoneNumber">
                                            </insurance-input-mask>
                                            <div
                                                *ngIf="customer?.get('phoneNumber')?.touched && customer?.get('phoneNumber')?.errors">
                                                <span class="p-error"
                                                    *ngIf="customer?.get('phoneNumber')?.hasError('required')">
                                                    Phone Number is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="customer?.get('phoneNumber')?.hasError('pattern')">
                                                    Please enter a valid Phone Number
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container formGroupName="customer">
                                        <div class="col-lg-12 col-md-12 mb-3" formArrayName="emailAddressCC"
                                            *ngFor="let data of secondaryEmail.controls;let i = index">
                                            <div class="row" [formGroupName]="i">
                                                <div class="col-sm-11 col-10">
                                                    <insurance-input inputId="emailCC" label="Email CC  {{i + 1}}"
                                                        formControlName="emailCC">
                                                    </insurance-input>
                                                </div>
                                                <div class="col-sm-1 col-2 three">
                                                    <i class="fa fa-minus faIcon" id="removeEmbryoDetails{{i}}"
                                                        (click)="removeDriver(i)"
                                                        *ngIf="secondaryEmail.controls.length > 1"></i>
                                                    <i class="fa fa-plus faIcon" id="addEmbryoDetails"
                                                        (click)="addDriver(secondaryEmail.controls, i)"
                                                        *ngIf="(i === secondaryEmail.controls.length - 1) && (secondaryEmail.controls.length != 2)"></i>
                                                </div>
                                                <div
                                                    *ngIf="data?.get('emailCC')?.touched && data?.get('emailCC')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="data?.get('emailCC')?.hasError('required')">
                                                        Email is Required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="data?.get('emailCC')?.hasError('pattern')">
                                                        Please enter a valid Email
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <ng-container *ngIf="multiCustomer?.value?.length" formGroupName="customer">
                                <ng-container formArrayName="dependents"
                                    *ngFor="let data of multiCustomer.controls;let i = index">
                                    <div class="col-6" [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-6">
                                                <h1 class="subHeader">Co-Applicant {{i+1}}</h1>
                                            </div>
                                            <div class="col-6 text-end">
                                                <i class="fa fa-trash-o px-2 faIcon" id="removeEmbryoDetails{{i}}"
                                                    (click)="removeCustomer(i)"></i>
                                                <i class="fa fa-plus faIcon" id="addEmbryoDetails"
                                                    (click)="addCustomer()"
                                                    *ngIf="(multiCustomer?.value?.length-1) == i && (multiCustomer?.value?.length) < 4"></i>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <insurance-input inputId="firstName" label="First Name"
                                                    formControlName="firstName">
                                                </insurance-input>
                                                <div class="p-error"
                                                    *ngIf="data?.get('firstName')?.hasError('required') && data?.get('firstName')?.touched">
                                                    First Name is Required!
                                                </div>
                                                <div class="p-error"
                                                    *ngIf="data?.get('firstName')?.hasError('pattern')">
                                                    Please enter a valid First Name
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <insurance-input inputId="lastName" label="Last Name"
                                                    formControlName="lastName">
                                                </insurance-input>
                                                <div class="p-error"
                                                    *ngIf="data?.get('lastName')?.hasError('required') && data?.get('lastName')?.touched">
                                                    Last Name is Required!
                                                </div>
                                                <div class="p-error" *ngIf="data?.get('lastName')?.hasError('pattern')">
                                                    Please enter a valid Last Name
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <insurance-input inputId="clientEmailAddress" label="Email Address"
                                                    formControlName="emailAddress">
                                                </insurance-input>
                                                <div class="p-error"
                                                    *ngIf="data?.get('emailAddress')?.hasError('required') && data?.get('emailAddress')?.touched">
                                                    Email is Required!
                                                </div>
                                                <div class="p-error"
                                                    *ngIf="data?.get('emailAddress')?.hasError('pattern')">
                                                    Please enter a valid Email
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <insurance-input-mask inputId="phoneNumber" label="Phone Number"
                                                    formControlName="phoneNumber" maxlength="14">
                                                </insurance-input-mask>
                                                <div class="p-error"
                                                    *ngIf="data?.get('phoneNumber')?.hasError('required') && data?.get('phoneNumber')?.touched">
                                                    Phone Number is Required!
                                                </div>
                                                <div class="p-error"
                                                    *ngIf="data?.get('phoneNumber')?.hasError('pattern')">
                                                    Please enter a valid Phone Number
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <div class="col-lg-6" formGroupName="customer">
                                <div class="row" formGroupName="address">
                                    <div class="col-lg-12">
                                        <h1 class="subHeader">Property Address</h1>
                                    </div>
                                    <div class="col-lg-12 mb-3">
                                        <insurance-google-map-input-no-btn inputId="address" name="priorPropertyAddress"
                                            inputlabel="Google Address" formControlName="address"
                                            (getAddress)="setAddress($event)">
                                        </insurance-google-map-input-no-btn>
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <insurance-input inputId="streetNumber" label="Street No"
                                            formControlName="streetNumber">
                                        </insurance-input>
                                        <div
                                            *ngIf="address?.get('streetNumber')?.touched && address?.get('streetNumber')?.errors">
                                            <span class="p-error"
                                                *ngIf="address?.get('streetNumber')?.hasError('required')">
                                                Street Number is required
                                            </span>
                                            <span class="p-error"
                                                *ngIf="address?.get('streetNumber')?.hasError('pattern')">
                                                Please enter a valid Street Number
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <insurance-input inputId="streetName" label="Street Name"
                                            formControlName="streetName">
                                        </insurance-input>
                                        <div
                                            *ngIf="address?.get('streetName')?.touched && address?.get('streetName')?.errors">
                                            <span class="p-error"
                                                *ngIf="address?.get('streetName')?.hasError('required')">
                                                Street Address is required
                                            </span>
                                            <span class="p-error"
                                                *ngIf="address?.get('streetName')?.hasError('pattern')">
                                                Please enter a valid Street Address
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <insurance-input inputId="city" label="City" formControlName="city">
                                        </insurance-input>
                                        <div *ngIf="address?.get('city')?.touched && address?.get('city')?.errors">
                                            <span class="p-error" *ngIf="address?.get('city')?.hasError('required')">
                                                City is required
                                            </span>
                                            <span class="p-error" *ngIf="address?.get('city')?.hasError('pattern')">
                                                Please enter a valid City
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <insurance-input inputId="state" label="State" formControlName="state">
                                        </insurance-input>
                                        <div *ngIf="address?.get('state')?.touched && address?.get('state')?.errors">
                                            <span class="p-error" *ngIf="address?.get('state')?.hasError('required')">
                                                State is required
                                            </span>
                                            <span class="p-error" *ngIf="address?.get('state')?.hasError('pattern')">
                                                Please enter a valid State
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <insurance-input inputId="country" label="Country" formControlName="country">
                                        </insurance-input>
                                        <div
                                            *ngIf="address?.get('country')?.touched && address?.get('country')?.errors">
                                            <span class="p-error" *ngIf="address?.get('country')?.hasError('required')">
                                                Country is required
                                            </span>
                                            <span class="p-error" *ngIf="address?.get('country')?.hasError('pattern')">
                                                Please enter a valid Country
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-3">
                                        <insurance-input inputId="zip" label="ZIP" formControlName="zip">
                                        </insurance-input>
                                        <div *ngIf="address?.get('zip')?.touched && address?.get('zip')?.errors">
                                            <span class="p-error" *ngIf="address?.get('zip')?.hasError('required')">
                                                ZIP code is required
                                            </span>
                                            <span class="p-error" *ngIf="address?.get('zip')?.hasError('pattern')">
                                                Please enter a valid ZIP code
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="Divider">
                                    <hr />
                                    <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                        Agent Details
                                    </p>
                                </div>
                            </div>
                            <div class="col-auto d-flex align-items-center"><strong>Select agent from the list :
                                </strong></div>
                            <div class="col-4 mb-3">
                                <insurance-select-search [dataList]="agentList" dataLabel="fullName"
                                    dataValue="fullName" label="Agent List" (onClick)="selectedAgent($event)">
                                </insurance-select-search>
                            </div>
                            <div class="col-lg-12 mb-3">
                                <div class="row justify-content-center">
                                    <!--<div class="col-6">
                                        <div class="row">
                                            <div class="col-12 text-end my-3">
                                                <span class="px-2" *ngIf="multiAgent?.value?.length == 0">Would you like
                                                    to
                                                    add more agents ?</span>
                                                <i class="fa fa-plus faIcon" id="addEmbryoDetails" (click)="addAgent()"
                                                    *ngIf="multiAgent?.value?.length == 0"></i>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentfirstName" label="First Name"
                                                    formControlName="firstName"></insurance-input>
                                                <div
                                                    *ngIf="agent?.get('firstName')?.touched && agent?.get('firstName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('firstName')?.hasError('required')">
                                                        Agent first name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('firstName')?.hasError('pattern')">
                                                        Please enter a valid agent first name
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentlastName" label="Last Name"
                                                    formControlName="lastName"></insurance-input>
                                                <div
                                                    *ngIf="agent?.get('lastName')?.touched && agent?.get('lastName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('lastName')?.hasError('required')">
                                                        Agent last name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('lastName')?.hasError('pattern')">
                                                        Please enter a valid agent last name
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentEmail" label="Email"
                                                    formControlName="emailAddress">
                                                </insurance-input>
                                                <div
                                                    *ngIf="agent?.get('emailAddress')?.touched && agent?.get('emailAddress')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('emailAddress')?.hasError('required')">
                                                        Agent email is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('emailAddress')?.hasError('patern')">
                                                        Please enter a valid agent email
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input-mask inputId="agentNumber" label="Phone"
                                                    formControlName="phoneNumber">
                                                </insurance-input-mask>
                                                <div
                                                    *ngIf="agent?.get('phoneNumber')?.touched && agent?.get('phoneNumber')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('phoneNumber')?.hasError('required')">
                                                        Agent phone number is Required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('phoneNumber')?.hasError('pattern')">
                                                        Please enter a valid agent phone number
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <ng-container *ngIf="multiAgent?.value?.length">
                                        <ng-container formArrayName="agent"
                                            *ngFor="let data of multiAgent.controls;let i = index">
                                            <div class="col-6" [formGroupName]="i">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <h1 class="subHeader" *ngIf="i==0">Agent</h1>
                                                        <h1 class="subHeader" *ngIf="i!=0">Co-Agent {{i}}</h1>
                                                    </div>
                                                    <div class="col-6 text-end">
                                                        <i class="fa fa-trash-o px-2 faIcon" *ngIf="i!=0"
                                                            id="removeEmbryoDetails{{i}}" (click)="removeAgent(i)"></i>
                                                        <i class="fa fa-plus faIcon" id="addEmbryoDetails"
                                                            (click)="addAgent()"
                                                            *ngIf="(multiAgent?.value?.length-1) == i && (multiAgent?.value?.length) < 5"></i>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="firstName" label="First Name"
                                                            formControlName="firstName">
                                                        </insurance-input>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('firstName')?.hasError('required') && data?.get('firstName')?.touched">
                                                            First Name is Required!
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('firstName')?.hasError('pattern')">
                                                            Please enter a valid First Name
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="lastName" label="Last Name"
                                                            formControlName="lastName">
                                                        </insurance-input>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('lastName')?.hasError('required') && data?.get('lastName')?.touched">
                                                            Last Name is Required!
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('lastName')?.hasError('pattern')">
                                                            Please enter a valid Last Name
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="clientEmailAddress"
                                                            label="Email Address" formControlName="emailAddress">
                                                        </insurance-input>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('emailAddress')?.hasError('required') && data?.get('emailAddress')?.touched">
                                                            Email is Required!
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('emailAddress')?.hasError('pattern')">
                                                            Please enter a valid Email
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input-mask inputId="phoneNumber" label="Phone Number"
                                                            formControlName="phoneNumber" maxlength="14">
                                                        </insurance-input-mask>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('phoneNumber')?.hasError('required') && data?.get('phoneNumber')?.touched">
                                                            Phone Number is Required!
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="data?.get('phoneNumber')?.hasError('pattern')">
                                                            Please enter a valid Phone Number
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <!-- <div class="row justify-content-center">
                                    <div class="col-lg-6 col-md-6 mb-3">
                                        <insurance-input-date dateId="policyEffectiveDate"
                                            label="Effective Date" formControlName="policyEffectiveDate"
                                            [minDate]="startDate">
                                        </insurance-input-date>
                                        <div
                                            *ngIf="productDetail?.get('policyEffectiveDate')?.touched && productDetail?.get('policyEffectiveDate')?.errors">
                                            <span class="p-error"
                                                *ngIf="productDetail?.get('policyEffectiveDate')?.hasError('required')">
                                                Policy Start Date is Required
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="Divider">
                                    <hr />
                                    <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                        Salesforce Details
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <insurance-input inputId="salesForce" label="Link to SF Quote Level"
                                    formControlName="salesForce">
                                </insurance-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 text-end my-3">
                                <insurance-button buttonId="clientBtn" label="Continue" btnWidth="200"
                                    (onClick)="next()" [loading]="loadingStatus" [disabled]="loadingStatus">
                                </insurance-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</main>

<insurance-popup [displayModal]="invalidAddress" iconBgColor="#e1f3fc" iconClass="pi pi-exclamation-circle"
    iconColor="#005b88" message="Invalid Address" [closable]="false">
    <div>
        <h6>Please enter an address within the state of Florida</h6>
        <insurance-button label="OK" buttonId="resetAppraisalSubmit" (onClick)="invalidAddress = false" btnWidth="130">
        </insurance-button>
    </div>
</insurance-popup>