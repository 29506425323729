// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const env = require('../../node-env');
export const environment = {
  production: false,
  autoPortalURL: `${env.AUTO_PORTAL}`,
  autoPaymentServiceURL: `${env.AUTO_PORTAL}/api/v1/backend`,
  transactionURL: `${env.AUTO_PORTAL}/api/payment`,
  autoURL: env.AUTO_QUOTE_URL,
  merchantId: env.MERCHANT_ID,
  date: env.DATE,
  envLabel: env.ENV_LABEL
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
