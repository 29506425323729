<div class="pt-3 pb-3">
    <div class="container-fluid">
        <div class="formSection">
            <div class="section">
                <div class="sectOne">
                    <div class="sectOne_1">
                        <insurance-checkbox [formControl]="deleteSelect" inputId="selectAll" [multiCheckBox]="false"
                            (onClick)="selectAll($event)">
                        </insurance-checkbox>
                    </div>

                    <div class="sectOne_2" *ngIf="selectedQuote.length > 0">
                        <i class="fa fa-trash-o del-icon" aria-hidden="true" (click)="delete()"></i>
                    </div>

                    <div class="sectOne_3">
                        <div class="form-group has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input class="form-control mb-3 w-500" type="text" [formControl]="searchText"
                                (click)="setPage()" placeholder="Search">
                        </div>
                        <button *ngIf="searchText?.value" class="searchBtn" (click)="clearSearch()">Clear
                            Search</button>
                    </div>
                </div>
                <div class="sectTwo">
                    <div class="btn-group float-end">
                        <button type="button" class="btn btn-secondary w-150" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{btnLable}}
                        </button>
                        <div class="btn-group float-end" *ngIf="statusDisplay">
                            <button type="button" class="btn btn-secondary dropdown-toggle" style="width: fit-content;
                    background: #007cba !important;" data-bs-toggle="dropdown" aria-expanded="false">
                                {{statusBtnLabel}}
                            </button>
                            <ul class="dropdown-menu">
                                <li style="cursor: pointer;">
                                    <a *ngFor="let data of statusTypes"
                                        [ngStyle]="{'background-color': (data.label === 'Clear Sort') ? '#db524e' : 'transparent'}"
                                        class="dropdown-item" (click)="statusSort(data.label, data.sort)">
                                        {{data.label}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <button type="button" [disabled]="stopSort" (click)="sortOrder()" class="btn btn-secondary">
                            <span class="fa fa-stack">
                                <i class="fa fa-caret-up" [ngStyle]="{'color': asc ? '#fff' : '#ffffff85'}"
                                    aria-hidden="true"></i>
                                <i class="fa fa-caret-down" [ngStyle]="{'color': dsc ? '#fff' : '#ffffff85'}"
                                    aria-hidden="true"></i>
                            </span>
                        </button>
                        <ul class="dropdown-menu">
                            <li class="cp">
                                <a *ngFor="let data of sortTypes"
                                    [ngStyle]="{'background-color': (data.label === 'Clear Sort') ? '#db524e' : 'transparent'}"
                                    class="dropdown-item" (click)="sort(data.label, data.sort, data.sortOrder)">
                                    {{data.label}}
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                        <thead class="text-center">
                            <tr class="row100 head">
                                <th></th>
                                <th>Archive</th>
                                <th class="text-right">#</th>
                                <th>Customer Name</th>
                                <th>Agent</th>
                                <th>Phone Number</th>
                                <th>Status</th>
                                <th>Prior Dec Page</th>
                                <!-- <th>Lead ID</th>
                                <th>Carrier Name</th>
                                <th class="text-center">Premium</th> -->
                                <th><i class="fa fa-envelope-o" aria-hidden="true"></i></th>
                                <th><i class="fa fa-comments-o" aria-hidden="true"></i></th>
                                <!-- <th>Auto Details</th> -->
                            </tr>
                        </thead>
                        <tbody *ngIf="tableData.length > 0" class="text-center">
                            <ng-container *ngFor="let data of tableData; let i = index">
                                <tr class="row100">
                                    <td class="text-center">
                                        <insurance-checkbox [(ngModel)]="data.checked" inputId="selectId{{i}}"
                                            [multiCheckBox]="false" (onClick)="selectLead($event,data?.quoteId)">
                                        </insurance-checkbox>
                                    </td>
                                    <td class="text-center">
                                        <button [disabled]="true" (click)="archieveLead(data)" class="btn btn-type-two"
                                            type="button">
                                            <span>
                                                <i class="fa fa-archive" aria-hidden="true">
                                                </i>
                                            </span>
                                        </button>
                                    </td>
                                    <td>{{i+1}}</td>
                                    <td>{{data?.customer?.firstName + ' ' + data?.customer?.lastName}}</td>
                                    <td>{{(data && data.agent && data.agent[0]) ? (data.agent[0].firstName + ' ' +
                                        data.agent[0].lastName) : 'N.A' }}</td>
                                    <td>{{data?.customer?.phoneNumber }}</td>
                                    <td><a *ngIf="data?.quoteStatus?.toLowerCase() === 'create quote proposal'"
                                            class="urlStyles" (click)="autoQuotePolicy(data)">{{data?.quoteStatus}}
                                        </a>
                                        <div *ngIf="!(data?.quoteStatus?.toLowerCase() === 'create quote proposal')">
                                            {{data?.quoteStatus || '-'}}</div>
                                    </td>
                                    <td class="text-center"><a *ngIf="data?.priordDecPdfUrl"
                                            class="btn btn-type-two px-2" href="{{data?.priordDecPdfUrl}}"
                                            target="_blank" type="button">
                                            <div><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Prior Dec Page
                                            </div>
                                        </a>
                                        <span *ngIf="!data?.priordDecPdfUrl">Not Uploaded</span>
                                    </td>
                                    <!-- <td>{{data?.quoteDetails[0]?.leadID || '-'}}</td>
                                    <td>{{data?.quoteDetails[0]?.carrier || '-'}}</td>
                                    <td>{{data?.quoteDetails[0]?.premium || '-'}}</td> -->
                                    <td class="text-center">
                                        <button [disabled]="(data?.quoteStatus.toLowerCase() !=='quote email sent')"
                                            (click)="emailPreview(data)" style="margin-bottom: 0;
                                    padding: 2px 10px;" class="btn login-btn btn-type-two" type="button">
                                            <span><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button [disabled]="(data?.quoteStatus.toLowerCase() !=='quote email sent')"
                                            (click)="smsPreview(data)" class="btn btn-type-two" type="button">
                                            <span><i class="fa fa-comments-o" aria-hidden="true"></i></span>
                                        </button>
                                    </td>
                                    <!-- <td class="text-center">
                                        <a *ngIf="data?.quoteStatus.toLowerCase() ==='auto quote premium'" class="urlStyles"
                                            (click)="autoDetails(data?.customerId, data?.quoteId)">Auto
                                            Details
                                        </a> 
                                        <span *ngIf="data?.quoteStatus.toLowerCase() !='auto quote premium'">-</span>
                                    </td> -->
                                </tr>
                            </ng-container>
                        </tbody>
                        <tbody *ngIf="tableData.length == 0">
                            <tr>
                                <td colspan="12" class="text-center">
                                    <h6 class="mb-0">No leads found</h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-12 text-end mt-2" *ngIf="tableData.length > 0">
                <mat-paginator [pageIndex]="currentPage" (page)="pageEvent = pageChange($event)"
                    [showFirstLastButtons]="true" [length]="totalCount" [pageSize]="pageSize"></mat-paginator>
            </div>
        </div>
    </div>
</div>

<insurance-popup header="Auto Details" [displayModal]="showAutoDetails" (closeAction)="closeDialog()"
    [style]="{width: '80vw'}">
    <app-auto-details [autoData]="autoDetailsData"></app-auto-details>
</insurance-popup>

<div class="emailPreview">
    <insurance-popup header="Email Preview" [displayModal]="emailDialog" (closeAction)="closeEmailDialog()">
        <div class="col-lg-12" [formGroup]="emailForm">
            <div class="row">
                <div class="col-lg-12 text-start mb-3">
                    <input class="form-control w-100" formControlName="recepientEmail" type="text" placeholder="To">
                    <div *ngIf="emailForm?.get('recepientEmail')?.touched && emailForm?.get('recepientEmail')?.errors">
                        <span class="p-error" *ngIf="emailForm?.get('recepientEmail')?.hasError('required')">
                            Email ID is required
                        </span>
                        <span class="p-error" *ngIf="emailForm?.get('recepientEmail')?.hasError('recepientEmail')">
                            Please enter a valid email
                        </span>
                    </div>
                </div>
                <div class="col-lg-12 text-start mb-3">
                    <input class="form-control w-100" formControlName="subject" type="text" placeholder="Subject">
                    <div *ngIf="emailForm?.get('subject')?.touched && emailForm?.get('subject')?.errors">
                        <span class="p-error" *ngIf="emailForm?.get('subject')?.hasError('required')">
                            Subject is required
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <quill-editor [modules]="moduleConfig" [(ngModel)]="emailContent">
        </quill-editor>
        <div class="col-lg-12 text-center mt-3">
            <insurance-button btnWidth="150" label="Send Email" (onClick)="sendEmail()" [loading]="submitting"
                [disabled]="submitting">
            </insurance-button>
        </div>
        <div *ngIf="emailError">
            <span class="p-error" *ngIf="emailForm?.get('subject')?.hasError('required')">
                Error in sending Mail
            </span>
        </div>
    </insurance-popup>
</div>

<insurance-popup [displayModal]="proposalCreation" message="Create Auto Proposal" [closable]="false">
    <!-- <h3>Mail sent Successfully</h3>   iconBgColor="#e5f5ec" iconClass="pi pi-check" iconColor="#27AE60" -->
    <div class="col-12 my-4 card quoteCard" [formGroup]="proposalForm">
        <p class="py-2">Select a type of Auto Proposal creation</p>
        <div class="row justify-content-center">
            <div class="col-4 mb-3" *ngFor="let data of proposalMethodList; let i = index">
                <insurance-radiobutton name="proposalMethod" formControlName="proposalMethod" value="{{data.value}}"
                    inputId="{{data.name}}" label="{{data.name}}">
                </insurance-radiobutton>
            </div>
        </div>
        <div *ngIf="proposalForm?.get('proposalMethod')?.touched && proposalForm?.get('proposalMethod')?.errors">
            <span class="p-error" *ngIf="proposalForm?.get('proposalMethod')?.hasError('required')">
                Number of Quotes is required
            </span>
        </div>
    </div>
    <insurance-button label="Create Proposal" [disabled]="!proposalForm.value.proposalMethod || proposalLoader"
        [loading]="proposalLoader" buttonId="resetAppraisalSubmit" (onClick)="createProposal()" btnWidth="250">
    </insurance-button>
</insurance-popup>

<insurance-popup header="SMS Preview" [displayModal]="smsDialog" (closeAction)="closeSMSDialog()">
    <div class="col-lg-12 text-start mb-3" [formGroup]="emailForm">
        <input class="form-control w-100" formControlName="subject" type="text" placeholder="Subject">
        <div *ngIf="emailForm?.get('subject')?.touched && emailForm?.get('subject')?.errors">
            <span class="p-error" *ngIf="emailForm?.get('subject')?.hasError('required')">
                Subject is required
            </span>
        </div>
    </div>
    <div class="col-lg-12 text-start" [formGroup]="emailForm">
        <insurance-text-area rows="8" inputId="smsPreview" label="SMS Preview" formControlName="smsMessage">
        </insurance-text-area>
        <div *ngIf="emailForm?.get('smsMessage')?.touched && emailForm?.get('smsMessage')?.errors">
            <span class="p-error" *ngIf="emailForm?.get('smsMessage')?.hasError('required')">
                SMS Content is required
            </span>
        </div>
    </div>
    <div class="col-lg-12 text-center mt-3">
        <insurance-button btnWidth="150" label="Send SMS" (onClick)="sendSMS()" [loading]="submitting"
            [disabled]="submitting">
        </insurance-button>
    </div>
    <div *ngIf="emailError">
        <span class="p-error">
            Error in sending SMS
        </span>
    </div>
</insurance-popup>