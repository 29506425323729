<main class="d-flex mb-3">
    <div class="card formSection">
        <div class="card-header">
            <div class="jumbotron text-center">
                <h1 class="display-6">Customer Quote Offer Page Setup</h1>
            </div>
        </div>
        <div class="card-body" id="targetRed">
            <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="row">
                        <form [formGroup]="productDetail">
                            <div class="col-lg-12 mb-64">
                                <div class="row justify-content-center scroll">
                                    <div class="col-lg-12 text-center">
                                        <div class="Divider">
                                            <hr />
                                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                                Insurance Details
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-3 mb-3 pe-none"
                                                *ngFor="let data of targetOptionList; let i = index">
                                                <insurance-radiobutton formControlName="insuranceType"
                                                    name="insuranceType" value="{{data.value}}" inputId="{{data.name}}"
                                                    label="{{data.name}}">
                                                </insurance-radiobutton>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-4 col-md-4 mt-3 mb-3">
                                        <insurance-input inputId="vesselCovered" label="Vessel Covered"
                                            formControlName="vesselCovered"></insurance-input>
                                        <div
                                            *ngIf="productDetail?.get('vesselCovered')?.touched && productDetail?.get('vesselCovered')?.errors">
                                            <span class="p-error"
                                                *ngIf="productDetail?.get('vesselCovered')?.hasError('required')">
                                                Vessel Covered value is required
                                            </span>
                                            <span class="p-error"
                                                *ngIf="productDetail?.get('vesselCovered')?.hasError('pattern')">
                                                Please enter a valid Vessel Name
                                            </span>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-8 col-md-12 my-3 card quoteCard">
                                        <p>Number of Quotes to include</p>
                                        <div class="row justify-content-center">
                                            <div class="col-lg-2 col-md-3 mb-3"
                                                *ngFor="let data of quoteOptionList; let i = index">
                                                <insurance-radiobutton name="quoteOption" formControlName="noOfQuotes"
                                                    value="{{data.value}}" inputId="{{data.name}}"
                                                    label="{{data.name}}">
                                                </insurance-radiobutton>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="productDetail?.get('noOfQuotes')?.touched && productDetail?.get('noOfQuotes')?.errors">
                                            <span class="p-error"
                                                *ngIf="productDetail?.get('noOfQuotes')?.hasError('required')">
                                                Number of Quotes is required
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-center mt-5">
                                        <div class="Divider">
                                            <hr />
                                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                                Customer Details
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-3" formGroupName="customer"
                                        [ngStyle]="{'pointer-events': policyEffectiveDateUpdate ? 'none' : 'auto'}">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-6">
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-12">
                                                        <h1 class="subHeader">Personal Info</h1>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 mb-3">
                                                        <insurance-input inputId="firstName" label="First Name"
                                                            formControlName="firstName"></insurance-input>
                                                        <ng-container
                                                            *ngIf="customer?.get('firstName')?.touched && customer?.get('firstName')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('firstName')?.hasError('required')">
                                                                First name is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('firstName')?.hasError('pattern')">
                                                                Please enter a valid first name
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 mb-3">
                                                        <insurance-input inputId="lastName" label="Last Name"
                                                            formControlName="lastName"></insurance-input>
                                                        <ng-container
                                                            *ngIf="customer?.get('lastName')?.touched && customer?.get('lastName')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('lastName')?.hasError('required')">
                                                                Last name is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('lastName')?.hasError('pattern')">
                                                                Please enter a valid last name
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 mb-3">
                                                        <insurance-input inputId="email" label="Email"
                                                            formControlName="email">
                                                        </insurance-input>
                                                        <ng-container
                                                            *ngIf="customer?.get('email')?.touched && customer?.get('email')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('email')?.hasError('required')">
                                                                Email is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('email')?.hasError('pattern')">
                                                                Please enter a valid email
                                                            </span>
                                                        </ng-container>
                                                        <!-- <span
                                                            *ngIf="customer?.get('email')?.touched && productDetail?.errors?.['emailDoNotMatch']"
                                                            class="p-error">Agent Email and Customer Email cannot be
                                                            same</span> -->
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 mb-3">
                                                        <insurance-input-mask inputId="number" label="Phone Number"
                                                            formControlName="phoneNumber">
                                                        </insurance-input-mask>
                                                        <ng-container
                                                            *ngIf="customer?.get('phoneNumber')?.touched && customer?.get('phoneNumber')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('phoneNumber')?.hasError('required')">
                                                                Phone Number is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="customer?.get('phoneNumber')?.hasError('pattern')">
                                                                Please enter a valid Phone Number
                                                            </span>
                                                        </ng-container>
                                                        <!-- <span
                                                            *ngIf="customer?.get('phoneNumber')?.touched && productDetail?.errors?.['phoneDoNotMatch']"
                                                            class="p-error">Agent phone and Customer phone cannot be
                                                            same</span> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="row" formGroupName="address">
                                                    <div class="col-lg-12">
                                                        <h1 class="subHeader">Property Address</h1>
                                                    </div>
                                                    <div class="col-lg-12 mb-3">
                                                        <insurance-google-map-input-no-btn inputId="address"
                                                            name="priorPropertyAddress" inputlabel="Google Address"
                                                            formControlName="address" (getAddress)="setAddress($event)">
                                                        </insurance-google-map-input-no-btn>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="streetNumber" label="Street No"
                                                            formControlName="streetNumber">
                                                        </insurance-input>
                                                        <div
                                                            *ngIf="address?.get('streetNumber')?.touched && address?.get('streetNumber')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="address?.get('streetNumber')?.hasError('required')">
                                                                Street Number is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="address?.get('streetNumber')?.hasError('pattern')">
                                                                Please enter a valid Street Number
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="streetName" label="Street Name"
                                                            formControlName="streetName">
                                                        </insurance-input>
                                                        <div
                                                            *ngIf="address?.get('streetName')?.touched && address?.get('streetName')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="address?.get('streetName')?.hasError('required')">
                                                                Street Address is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="address?.get('streetName')?.hasError('pattern')">
                                                                Please enter a valid Street Address
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="city" label="City"
                                                            formControlName="city">
                                                        </insurance-input>
                                                        <div
                                                            *ngIf="address?.get('city')?.touched && address?.get('city')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="address?.get('city')?.hasError('required')">
                                                                City is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="address?.get('city')?.hasError('pattern')">
                                                                Please enter a valid City
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="state" label="State"
                                                            formControlName="state">
                                                        </insurance-input>
                                                        <div
                                                            *ngIf="address?.get('state')?.touched && address?.get('state')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="address?.get('state')?.hasError('required')">
                                                                State is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="address?.get('state')?.hasError('pattern')">
                                                                Please enter a valid State
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="country" label="Country"
                                                            formControlName="country">
                                                        </insurance-input>
                                                        <div
                                                            *ngIf="address?.get('country')?.touched && address?.get('country')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="address?.get('country')?.hasError('required')">
                                                                Country is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="address?.get('country')?.hasError('pattern')">
                                                                Please enter a valid Country
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 mb-3">
                                                        <insurance-input inputId="zip" label="ZIP"
                                                            formControlName="zip">
                                                        </insurance-input>
                                                        <div
                                                            *ngIf="address?.get('zip')?.touched && address?.get('zip')?.errors">
                                                            <span class="p-error"
                                                                *ngIf="address?.get('zip')?.hasError('required')">
                                                                ZIP code is required
                                                            </span>
                                                            <span class="p-error"
                                                                *ngIf="address?.get('zip')?.hasError('pattern')">
                                                                Please enter a valid ZIP code
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-center">
                                        <div class="Divider">
                                            <hr />
                                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                                Agent Details
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex align-items-center"><strong>Select agent from the list :
                                        </strong></div>
                                    <div class="col-4 mb-3"
                                        [ngStyle]="{'pointer-events': policyEffectiveDateUpdate ? 'none' : 'auto'}">
                                        <insurance-select-search [dataList]="agentList" dataLabel="fullName"
                                            dataValue="fullName" label="Agent List" (onClick)="selectedAgent($event)">
                                        </insurance-select-search>
                                    </div>
                                    <div class="col-lg-12 mb-3">
                                        <div class="row justify-content-center" formGroupName="agent"
                                            [ngStyle]="{'pointer-events': policyEffectiveDateUpdate ? 'none' : 'auto'}">
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentfirstName" label="First Name"
                                                    formControlName="firstName"></insurance-input>
                                                <ng-container
                                                    *ngIf="agent?.get('firstName')?.touched && agent?.get('firstName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('firstName')?.hasError('required')">
                                                        Agent first name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('firstName')?.hasError('pattern')">
                                                        Please enter a valid agent first name
                                                    </span>
                                                </ng-container>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentlastName" label="Last Name"
                                                    formControlName="lastName"></insurance-input>
                                                <ng-container
                                                    *ngIf="agent?.get('lastName')?.touched && agent?.get('lastName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('lastName')?.hasError('required')">
                                                        Agent last name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('lastName')?.hasError('pattern')">
                                                        Please enter a valid agent last name
                                                    </span>
                                                </ng-container>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentEmail" label="Email"
                                                    formControlName="email">
                                                </insurance-input>
                                                <ng-container
                                                    *ngIf="agent?.get('email')?.touched && agent?.get('email')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('email')?.hasError('required')">
                                                        Agent email is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('email')?.hasError('pattern')">
                                                        Please enter a valid agent email
                                                    </span>
                                                </ng-container>
                                                <!-- <span
                                                    *ngIf="agent?.get('email')?.touched && productDetail?.errors?.['emailDoNotMatch']"
                                                    class="p-error">Agent Email and Customer Email cannot be
                                                    same</span> -->
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input-mask inputId="agentNumber" label="Phone"
                                                    formControlName="phoneNumber">
                                                </insurance-input-mask>
                                                <ng-container
                                                    *ngIf="agent?.get('phoneNumber')?.touched && agent?.get('phoneNumber')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('phoneNumber')?.hasError('required')">
                                                        Agent phone number is Required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('phoneNumber')?.hasError('pattern')">
                                                        Please enter a valid agent phone number
                                                    </span>
                                                </ng-container>
                                                <!-- <span
                                                    *ngIf="!agent?.get('phoneNumber')?.hasError('pattern') && agent?.get('phoneNumber')?.touched && productDetail?.errors?.['phoneDoNotMatch']"
                                                    class="p-error">Agent phone and Customer phone cannot be
                                                    same</span> -->
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <ng-container *ngIf="!policyEffectiveDateUpdate">
                                                    <insurance-input-date dateId="policyStartDate"
                                                        label="Effective Date" formControlName="policyStartDate"
                                                        [minDate]="startDate" [readOnly]="policyEffectiveDateUpdate">
                                                    </insurance-input-date>
                                                </ng-container>
                                                <ng-container *ngIf="policyEffectiveDateUpdate">
                                                    <insurance-input-date dateId="policyStartDate"
                                                        label="Effective Date" formControlName="policyStartDate"
                                                        [readOnly]="true">
                                                    </insurance-input-date>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="productDetail?.get('policyStartDate')?.touched && productDetail?.get('policyStartDate')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="productDetail?.get('policyStartDate')?.hasError('required')">
                                                        Policy Start Date is Required
                                                    </span>
                                                </ng-container>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3" *ngIf="policyEffectiveDateUpdate">
                                                <insurance-input-date dateId="updatedPolicyEffectiveDate"
                                                    label="Updated Effective Date"
                                                    formControlName="updatedPolicyEffectiveDate" [minDate]="startDate">
                                                </insurance-input-date>
                                                <ng-container
                                                    *ngIf="productDetail?.get('updatedPolicyEffectiveDate')?.touched && productDetail?.get('updatedPolicyEffectiveDate')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="productDetail?.get('updatedPolicyEffectiveDate')?.hasError('required')">
                                                        Policy Effective Date is Required
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-12 text-center">
                                        <div class="Divider">
                                            <hr />
                                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                                Salesforce Details
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <insurance-input inputId="salesForce" label="Link to SF Quote Level"
                                            formControlName="salesForce">
                                        </insurance-input>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-lg-12 text-center pt-3">
                                <insurance-button class="float-md-end mb-3" btnWidth="150" label="Proposal Quote"
                                    (onClick)="submit()" [loading]="submitting" [disabled]="submitting">
                                </insurance-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>