import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-summary',
  templateUrl: './lead-summary.component.html',
  styleUrls: ['./lead-summary.component.css']
})
export class LeadSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
