import { Component, OnDestroy } from '@angular/core';
import { SetUpComponent } from './components/set-up/set-up.component';
import { QuoteDataComponent } from './components/quote-data/quote-data.component';
import { PreviewComponent } from './components/preview/preview.component';

@Component({
  selector: 'app-manual-quote',
  templateUrl: './manual-quote.component.html',
  styleUrls: ['./manual-quote.component.css']
})
export class ManualQuoteComponent implements OnDestroy {

  step = [
    { heading: 'Proposal Setup', component: SetUpComponent },
    { heading: 'Proposal Quote', component: QuoteDataComponent },
    { heading: 'Proposal Preview', component: PreviewComponent },
  ];
  proposalSetupData: any;

  ngOnDestroy(): void {
    sessionStorage.clear();
  }

}
