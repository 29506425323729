import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-auto-leads',
  templateUrl: './auto-leads.component.html',
  styleUrls: ['./auto-leads.component.css'],
})
export class AutoLeadsComponent {
  pageSize = 10;
  totalCount = 0;
  currentPage = 0;
  pageEvent: any;
  tableData: any = [];
  showAutoDetails = false;
  autoDetailsData: any;
  leadListData: any;
  autoQuoteIP = environment.autoPortalURL;
  selectedQuote: any[] = [];

  // Email Form
  emailForm: FormGroup = this.fb.group({
    recepientEmail: ['', [Validators.required, Validators.email]],
    subject: ['', [Validators.required]],
    smsMessage: ['', [Validators.required]],
    phoneNumber: [''],
    recepientName: [''],
  });
  searchText = new FormControl('');
  deleteSelect = new FormControl(false);
  emailDialog = false;
  url: any;
  htmlQuillContent: any;
  attachmentFileURL = ``;
  emailContent: any;
  quoteStatus = '';
  customerId = '';
  submitting = false;
  emailError = false;
  btnLable = 'Sort By';
  statusDisplay = false;
  statusBtnLabel = 'Sort status by';
  sortOrderName = '';
  sortOrderType = '';
  sortType = '';
  pageOffset: any = 0;
  stopSort = true;
  asc = false;
  dsc = false;
  proposalCreation = false;
  proposalLoader = false;
  proposalForm: FormGroup = this.fb.group({
    customerId: [''],
    quoteId: [''],
    proposalMethod: ['', Validators.required]
  });
  proposalData: any;
  proposalMethodList = [
    {
      name: 'Manual Driven',
      value: 'Manual',
      selected: false,
    },
    {
      name: 'PDF Driven',
      value: 'Automated',
      selected: false,
    },
  ];

  sortTypes: any = [
    {
      label: 'Name',
      sort: 'customerName',
      sortOrder: 'asc',
    },
    // {
    //   label: 'Agent',
    //   sort: 'agentName',
    //   sortOrder: 'asc',
    // },
    {
      label: 'Status',
      sort: 'status',
      sortOrder: 'asc',
    },
  ];
  statusTypes: any = [
    {
      label: 'Quote Email Sent',
      sort: 'Quote Email Sent',
    },
    {
      label: 'Create Quote Proposal',
      sort: 'Create Quote Proposal',
    },
    {
      label: 'Quote Proposal Created',
      sort: 'Quote Proposal Created',
    },
    // {
    //   label: 'Renewal Quote Ready',
    //   sort: 'Renewal Quote Ready',
    // }
  ];

  public moduleConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'], // remove formatting button
      ['link'],
    ],
  };
  merchantId = '';

  constructor(public service: WizzipayService, public fb: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
    });
  }
  ngOnInit() {
    this.getTableData();
    this.searchText.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.searchText.value) {
          this.btnLable = 'Sort By';
          const isClearSort = this.sortTypes.some(function (el: { label: string }) {
            return el.label === 'Clear Sort';
          });
          if (isClearSort) {
            const payload = {
              label: 'Clear Sort',
              sort: '',
            };
            this.sortTypes.pop(payload);
          }
          this.pageOffset = 0;
          // const page = {
          //   limit: this.pageSize,
          //   offset: this.pageOffset,
          // };
          // this.pagination(page);
          this.currentPageIndex(this.pageOffset);
          this.getTableData('', '', '', this.searchText.value);
        } else if (this.searchText.value == '') {
          (this.btnLable === 'Sort By') && this.getTableData('', '', '', this.searchText.value);
        }
      });
  }
  selectAll(e: boolean) {
    if (e) {
      this.selectedQuote = [];
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i]['checked'] = true;
        this.selectedQuote.push(this.tableData[i]['quoteId']);
      }
    } else {
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i]['checked'] = false;
        const index = this.selectedQuote.indexOf(
          this.tableData[i]['quoteId']
        );
        if (index > -1) {
          this.selectedQuote.splice(index, 1);
        }
      }
    }
  }
  statusSort(type: any, sort: any) {
    this.statusBtnLabel = type;
    this.pageOffset = 0;
    const page = {
      limit: this.pageSize,
      offset: this.pageOffset,
    };
    this.pagination(page);
    this.currentPageIndex(this.pageOffset);
    // this.sortUsed = sort;
    // this.searchText.patchValue('');
    this.getTableData(this.sortOrderName, this.sortOrderType, this.statusBtnLabel, '');
  }
  getData(sort: string, sortOrderType: string, status: string, search: string) {

  }
  sort(type: string, sort: string, sortType: string) {
    this.pageOffset = 0;
    const page = {
      limit: this.pageSize,
      offset: this.pageOffset,
    };
    this.pagination(page);
    this.currentPageIndex(this.pageOffset);
    this.stopSort = false;
    this.sortOrderName = sort;
    this.sortOrderType = sortType;
    this.sortType = type;
    if (
      this.sortOrderName == 'customerName' ||
      this.sortOrderName == 'typeAsc' ||
      this.sortOrderName == 'status' ||
      this.sortOrderName == 'agentName'
    ) {
      this.searchText.reset('');
      this.btnLable = this.sortType;
      const isClearSort = this.sortTypes.some(function (el: { label: string }) {
        return el.label === 'Clear Sort';
      });
      if (!isClearSort) {
        const payload = {
          label: 'Clear Sort',
          sort: '',
          sortOrder: '',
        };
        this.sortTypes.push(payload);
      }
      this.asc = true;
      this.dsc = false;
      if (this.sortOrderName == 'status') {
        this.statusDisplay = true;
        return;
      }
    } else {
      if (this.sortType == 'Clear Sort') {
        this.btnLable = 'Sort By';
        const payload = {
          label: 'Clear Sort',
          sort: '',
          sortOrder: '',
        };
        this.sortTypes.pop(payload);
      }
      this.asc = false;
      this.dsc = false;
    }
    this.statusBtnLabel = '';
    this.statusDisplay = false;
    this.getTableData(this.sortOrderName, this.sortOrderType, '', '');
  }
  sortOrder() {
    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
    if (this.sortOrderType === 'asc') {
      this.asc = false;
      this.dsc = true;
      this.sortOrderType = 'desc';
    } else {
      this.asc = true;
      this.dsc = false;
      this.sortOrderType = 'asc';
    }
    this.getTableData(this.sortOrderName, this.sortOrderType, '', '');
  }
  pageChange1(e: any): void {
    this.pageOffset = e?.pageIndex;
    this.selectedQuote = [];
    this.deleteSelect.reset(false);
    this.currentPageIndex(e.pageIndex);
    const page = {
      limit: this.pageSize,
      offset: this.pageOffset,
    };
    this.pagination(page);
    this.getData(this.sortOrderName, this.sortOrderType, this.statusBtnLabel, this.searchText?.value);
  }

  currentPageIndex(index: number) {
    this.currentPage = index;
  }

  paginationOffset(currentPage: number, itemsPerPage: number): number {
    let offset = currentPage * itemsPerPage + 1;
    return (offset = offset < 0 ? offset : offset - 1);
  }

  pagination(e: { limit?: number; offset: number }) {
    this.pageOffset = e.offset ? e.offset : 0;
  }
  getTableData(sort?: string, sortOrderType?: string, status?: string, search?: string) {
    console.log("statuc", status)
    this.service.getAutoLeads(this.pageSize,
      this.pageOffset,
      sort,
      sortOrderType,
      search,
      false,
      status).subscribe((res) => {
        this.leadListData = res;
        this.totalCount = this.leadListData?.count;
        this.tableData = [];
        let tData = this.leadListData?.data;
        tData.forEach((element: any) => {
          // const payload = {
          //   name: (element?.firstName || '') + ' ' + (element?.lastName || ''),
          //   quoteDetails: element?.leadId ? element.leadId : [],
          //   priorDeclarationPDF: element?.priorDeclarationPDF,
          //   customerId: element?.customerId,
          //   quoteId: element?.quoteId,
          //   status: element?.quoteStatus,
          //   email: element?.emailId,
          // };
          this.tableData.push(element);
        });
      });
  }
  setPage() {
    this.currentPage = 0;
  }
  autoDetails(customerId: string, quoteId: string) {
    this.autoDetailsData = {};
    this.service.getAutoDetails(customerId, quoteId).subscribe((res) => {
      this.autoDetailsData = res[0] ? res[0] : {};
      console.log(res, 'autodetails');
    });
    this.showAutoDetails = true;
  }

  autoQuotePolicy(proposalData: any) {
    this.proposalCreation = true;
    // const url = `${this.autoQuoteIP}/autoquote/policyInfo?id=${customerId}&qid=${quoteId}`;
    // window.open(url, '_blank');
    this.proposalForm.patchValue(proposalData);
    this.proposalData = proposalData;
  }

  createProposal() {
    this.proposalLoader = true;
    const quote = {
      customer: {
        firstName: this.proposalData?.customer?.firstName,
        lastName: this.proposalData?.customer?.lastName,
        email: this.proposalData?.customer?.emailAddress
      },
      insuranceType: 'Auto',
      quoteIdType: 'Auto_Quote_Proposal',
      parentQuoteId: this.proposalData?.quoteId,
      quoteType: this.proposalForm.value?.proposalMethod,
      commonCustomerId: this.proposalData?.customerId,
      agentId: this.proposalData?.agentId,
      agent: {
        firstName: this.proposalData?.agent[0]?.firstName || '',
        lastName: this.proposalData?.agent[0]?.lastName || '',
        number: this.proposalData?.agent[0]?.phoneNumber || '',
        email: this.proposalData?.agent[0]?.emailAddress || ''
      },
      driverPhone: [
        {
          number: this.proposalData?.customer?.phoneNumber
        }
      ]
    }
    this.service.sumbitOrder(quote, this.merchantId).subscribe(
      (res) => {
        this.service.updateAutoStatus(this.proposalData?.customerId,
          this.proposalData?.quoteId, { status: 'Quote Proposal Created' })
          .subscribe({
            next: () => {
              this.proposalLoader = false;
              quote?.quoteType === 'Manual'
                ? this.router.navigate([`/merchant/manual/${this.merchantId}/${res?.data?._id}`])
                : this.router.navigate([`/merchant/setup/${this.merchantId}/${res?.data?._id}`]);
            },
            error: () => {
              this.proposalLoader = false;
              this.service.snackbar('Error');
            }
          });
      },
      (err) => {
        this.proposalLoader = false;
        this.service.snackbar(err.message);
      }
    );
  }

  pageChange(e: any) {
    console.log("this.searchText?.value", this.searchText?.value)
    this.pageOffset = e?.pageIndex;
    this.selectedQuote = [];
    this.deleteSelect.reset(false);
    this.currentPageIndex(e.pageIndex);
    // this.pageOffset = this.paginationOffset(e['pageIndex'], e['pageSize']);
    const page = {
      limit: this.pageSize,
      offset: this.pageOffset,
    };
    this.pagination(page);
    if (this.statusBtnLabel == 'Sort status by') {
      this.statusBtnLabel = '';
    }
    this.getTableData(this.sortOrderName, this.sortOrderType, this.statusBtnLabel, this.searchText?.value);
    // this.deleteSelect.reset(false);
    // this.getTableData(val?.pageIndex);
  }

  selectLead(val: any, quoteId: any) {
    if (!val) {
      this.selectedQuote = this.selectedQuote.filter(item => item !== quoteId);
    } else {
      this.selectedQuote.push(quoteId)
    }
  }

  archieveLead(val: any) { }

  btnData = '';

  emailPreview(value: any) {
    console.log('test-emaillllllllll', value);
    this.quoteStatus = this.attachmentFileURL = '';
    this.customerId = value?.customerId;
    let message = '';
    let buttonLabel = '';
    this.quoteStatus = value?.quoteStatus.toLowerCase();
    // this.attachmentFileURL = `${this.backOfficeURL}/api/v1/hhinsurance/dashboard/document/download/`;
    let editorStyle =
      "style='background-color: #007cba;border-radius: 6px;font-size: 12px;font-weight: bold;line-height: 20px;color: #fff;text-decoration: none;padding: 11px 26px;cursor: pointer;'";
    this.patchFormValue(
      value?.customer?.emailAddress,
      'HH Insurance - Auto Quote URL',
      '',
      '',
      ''
    );
    buttonLabel = 'Start Auto Quote';
    message =
      '<p>Please click the link to start Auto quote.</p><br><br><br>';
    this.url = `/customer/autolead?id=${value?.customerId}&qid=${value?.quoteId}`;
    this.btnData = `<table align="center" cellpadding="0" cellspacing="0">
    <tr>
      <td style="text-align: center;">
        <div style="text-align: center;">
        <!--[if mso]>
  <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word"
                href="${this.autoQuoteIP}/${this.url}"
                style="height:auto;v-text-anchor:middle;width:400px" arcsize="10%" strokecolor="#007cba"
                fillcolor="#007cba">
    <w:anchorlock/>
    <center style="color:#ffffff;font-family:sans-serif;font-size:20px;">&nbsp; ${buttonLabel} &nbsp;</center>
  </v:roundrect>
  <![endif]-->
  <!--[if !mso]><!-- -->
  <a href="${this.autoQuoteIP}/${this.url}"
     style="background-color:#007cba;border-radius:6px;color:#ffffff;display:inline-block;font-family:sans-serif;font-size:20px;line-height:36px;text-align:center;text-decoration:none;width:fit-content;-webkit-text-size-adjust:none;">
     &nbsp; ${buttonLabel} &nbsp; 
  </a>
  <!--<![endif]-->
        </div>
      </td>
    </tr>
  </table>`
    // this.quoteCount = res?.quoteCount;
    this.emailContent = `Hi ${value?.customer?.firstName} ${value?.customer?.lastName},<br><p>${message}</p><br>`;
    this.emailDialog = true;
    console.log(this.emailContent);
  }

  closeEmailDialog() {
    this.emailDialog = false;
    this.emailForm.reset();
    // const control = <FormArray>this.emailForm.controls['emailCC'];
    // for (let i = control.length - 1; i > 0; i--) {
    //   control.removeAt(i);
    // }
  }

  sendEmail() {
    this.submitting = true;
    this.emailError = false;
    // const payload = {
    //   customerId: this.customerId,
    //   emailContent: this.emailContent?.replace('</p>', `</p>${this.btnData}`),
    //   recepientEmail: this.emailForm.value.recepientEmail,
    //   emailSubject: this.emailForm.value.subject,
    // };
    const emailData: any = {
      recepientEmail: [this.emailForm.value?.recepientEmail],
      emailCC: [],
      emailSubject: this.emailForm.value?.subject,
      emailBody: this.emailContent?.replace('</p>', `</p>${this.btnData}`),
      quotePDFAttachment: false,
      quotePDFAttachmentPath: {},
      summaryPDFAttchment: false,
      otherAttachment: false
    }
    const fileJson = new File([JSON.stringify(emailData)], "file.json", { type: "application/json" });
    const formData = new FormData();
    formData.append('content', fileJson, "file.json");
    this.service.sendCommonEmail(formData).subscribe({
      next: () => {
        this.submitting = this.emailDialog = false;
      },
      error: () => {
        this.emailError = true;
        this.submitting = false;
        this.service.snackbar('Network Error');
      },
    });
  }

  patchFormValue(
    email: any,
    sub: any,
    smsContent: any,
    phone: any,
    name: string
  ) {
    this.emailForm.patchValue({
      recepientEmail: email,
      subject: sub,
      smsMessage: smsContent,
      phoneNumber: phone,
      recepientName: name
    });
  }
  smsDialog = false;

  smsPreview(val: any) {
    this.smsDialog = true;
    this.customerId = val?.customerId;
    let smsContent = '';
    smsContent = `Hi ${val?.customer?.firstName} ${val?.customer?.lastName},
    Please click the link below to start Auto quote.
    ${this.autoQuoteIP}/customer/autolead?id=${val?.customerId}&qid=${val?.quoteId}
        `
    this.patchFormValue(
      '',
      `HH Insurance - Auto Quote URL`,
      smsContent,
      val?.customer?.phoneNumber,
      val?.customer?.firstName + ' ' + val?.customer?.lastName
    );
  }

  closeSMSDialog() {
    this.smsDialog = false;
    this.patchFormValue(
      '',
      '',
      '',
      '',
      ''
    );
  }

  sendSMS() {
    this.submitting = true;
    this.emailError = false;
    if (this.emailForm?.get('subject')?.invalid || this.emailForm?.get('smsMessage')?.invalid) {
      this.emailForm?.get('subject')?.markAsTouched();
      this.emailForm?.get('smsMessage')?.markAsTouched();
      this.service.snackbar('Please fill all fields with valid value');
      return;
    }
    const payload = {
      body: this.emailForm.value?.smsMessage,
      phoneNumber: '+1' + this.emailForm.value?.phoneNumber,
      contactName: this.emailForm.value?.recepientName,
      subject: this.emailForm.value?.subject,
    };
    this.service.sendQuoteSMS(payload).subscribe({
      next: () => {
        this.submitting = this.smsDialog = false;
      },
      error: () => {
        this.emailError = true;
        this.submitting = false;
        this.service.snackbar('Network Error');
      },
    });
  }


  closeDialog() {
    this.showAutoDetails = false;
  }

  delete() {
    this.service
      .deleteRecords(this.selectedQuote.join(','))
      .subscribe({
        next: () => {
          this.selectedQuote = [];
          this.pageOffset = 0;
          this.currentPage = 0;
          this.searchText.reset('');
          this.deleteSelect.reset(false);
          // this.tableData?.forEach((element: any) =>
          //   element?.checked && (element?.transactionId && this.deleteCardDetails(element?.transactionId))
          // );
          this.getTableData(this.sortOrderName, this.sortOrderType, this.statusBtnLabel, this.searchText?.value);
          this.service.snackbar('Offer Deleted');
        },
        error: () => {
          this.service.snackbar('Error');
        }
      });
  }
  clearSearch() {
    this.searchText.patchValue('');
  }
}
