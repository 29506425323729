import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { environment } from 'src/environments/environment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ActivatedRoute, Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { EmailContentHelper } from '../../email-content.helper';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
const htmlToPdfmake = require('html-to-pdfmake');

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css'],
  providers: [EmailContentHelper]
})
export class PreviewComponent implements OnInit {
  proposalPreview: FormGroup = this.fb.group({
    emailSubject: ['HH Insurance - Private Passenger Automobile Proposal'],
    emailCC: this.fb.array([this.newEmail()]),
    smsSubject: ['HH Insurance - Private Passenger Automobile Proposal', [Validators.required]],
    smsMessage: ['', [Validators.required]],
    emailAddress: [''],
    campaignId: [''],
    packageDoc: this.fb.group({
      url: [''],
      name: [''],
      size: [''],
    }),
  });
  reviewConfirmation = [true, false, false, false];
  public moduleConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'], // remove formatting button
      ['link'],
    ],
  };
  emailContent = '';
  quoteProposalData: any;
  attachmentFileURL: any;
  attachPDFFile: any;
  loadingStatus = false;
  customerData: any;
  merchantId = '';
  autoTableHeader: any = {
    package: {
      name: 'Package Name',
      main: 'packageName',
      sub: 'otherPackageName',
    },
    carrierName: 'Carrier Name',
    policyTerm: 'Policy Term',
    enrolledInMercuryGo: 'Enrolled in MercuryGO',
    enrolledInSnapshot: 'Enrolled in Snapshot',
    enrolledInRightTrack: 'Enrolled in RightTrack',
    enrolledInIntelliDrive: 'Enrolled in IntelliDrive',
    fullPayMonthlyPremium: 'Full Pay Monthly Premium',
    payPlanMonthlyPremiumTotal: 'Pay Plan Monthly Premium Total',
    payPlanMonthlyPremiumDownpayment: 'Pay Plan Monthly Premium Downpayment',
    payPlanMonthlyPremiumPerMonth: 'Pay Plan Monthly Premium Per Month',
    paidInFull: 'Paid in Full',
    eftPayPlanMonthlyPremium: 'EFT Pay Plan Monthly Premium',
    initialEftInstallment: 'Initial EFT Installment',
    eftTotalCostPremium: 'EFT Total Cost Premium',
    ccPayPlanMonthlyPremium: 'CC Pay Plan Monthly Premium',
    initialCcInstallment: 'Initial CC Installment',
    ccTotalCostPremium: 'CC Total Cost Premium',
    twoPayPlan: '2-Pay Plan**',
    twoPayTotalCostPremium: '2-Pay Total Cost Premium',
    fourPayPlan: '4-Pay Plan**',
    fourPayTotalPremium: '4 Pay Total Premium',
    vehiclesCovered: 'Vehicles Covered',
    essentialCoverage: 'Essential Coverage',
    // coverageAPersonBI: {
    //   name: 'Coverage A- Bodily Injury and Property Damage per person BI',
    //   main: 'bodilyInjuryandPropertyDamagePerPersonBi',
    //   sub: 'bodilyInjuryandPropertyDamagePerPersonBiCurrency'
    // },
    // coverageAAccidentPD: {
    //   name: 'Coverage A - Bodily Injury and Property Damage per accident PD',
    //   main: 'bodilyInjuryandPropertyDamagePerAccidentPd',
    //   sub: 'bodilyInjuryandPropertyDamagePerAccidentPdCurrency'
    // },
    // coverageAAccidentBI: {
    //   name: 'Coverage A- Bodily Injury and Property Damage per accident BI',
    //   main: 'bodilyInjuryandPropertyDamagePerAccidentBi',
    //   sub: 'bodilyInjuryandPropertyDamagePerAccidentBiCurrency'
    // },
    // bodilyInjuryandPropertyDamagePerAccidentPd: 'Coverage A - Bodily Injury and Property Damage per accident PD',
    // bodilyInjuryandPropertyDamagePerAccidentBi: 'Coverage A- Bodily Injury and Property Damage per accident BI',
    coverageABodilyInjuryPerPersonPerAccident: 'Coverage A - Bodily Injury (per person/per accident)',
    coverageAPropoertyDamagePerAccident: 'Coverage A - Property Damage per accident',
    coverageBMedicalPayments: 'Coverage B - Medical Payments',
    uninsuredMotoristCoverage: 'Uninsured Motorist Coverage',
    coverageCUninsuredMotoristPerPersonPerAccident: 'Coverage C - Uninsured Motorist (per person/per accident)',
    coverageCUninsuredMotorist: 'Coverage C - Uninsured Motorist',
    physicalDamageCoverage: 'Physical Damage Coverage',
    guaranteedValue: 'Guaranteed Value',
    coverageDComprehensiveCoverage: 'Coverage D - Comprehensive Coverage',
    coverageDComprehensiveCoverageDeductible: 'Coverage D - Comprehensive Coverage Deductible',
    coverageDCollisionCoverage: 'Coverage D - Collision Coverage',
    coverageDCollisionCoverageDeductible: 'Coverage D - Collision Coverage Deductible',
    pipCoverage: 'PIP Coverage',
    personalInjuryProtectionLimit: 'Personal Injury Protection (PIP) Limit',
    pipWorkExclusion: 'PIP Work Exclusion',
    pipDeductible: 'PIP Deductible',
    pipAppliesTo: 'PIP Applies to',
    additionalCoverages: 'Additional Coverages',
    ultraCoverage: 'Ultra-Coverage (Accident Forgiveness)',
    accidentMinorViolationForgiveness: 'Accident & Minor Violation Forgiveness',
    responsibleDrivingPlan: 'Responsible Driving Plan',
    extendedPip: 'Extended PIP',
    additionalPip: 'Additional PIP',
    rentalCarCoverage: 'Rental Car Coverage',
    roadsideAssistanceAndTowing: 'Roadside Assistance and Towing',
    gapCoverage: 'Gap Coverage',
    originalEquipmentManufacturer: 'Original Equipment Manufacturer',
    enhancedPackage: 'Enhanced Package'
  };
  autoPDFGenerators: any = {
    headerData: [
      'essentialCoverage',
      'uninsuredMotoristCoverage',
      'physicalDamageCoverage',
      'additionalCoverages',
      'pipCoverage',
    ],
    singleData: [
      'package',
      // 'coverageAPersonBI',
      // 'coverageAAccidentPD',
      // 'coverageAAccidentBI'
    ],
    currencyData: [
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
    ],
    multiCarData: [
      'vehiclesCovered',
      'coverageDComprehensiveCoverage',
      'coverageDComprehensiveCoverageDeductible',
      'coverageDCollisionCoverage',
      'coverageDCollisionCoverageDeductible',
      'guaranteedValue',
      'rentalCarCoverage',
      'roadsideAssistanceAndTowing',
    ]
  };
  quoteData: any;
  premiumHighlight: any;
  checkFields = {
    vehiclesCovered: [],
    coverageABodilyInjuryPerPersonPerAccident: false,
    coverageAPropoertyDamagePerAccident: false,
    coverageBMedicalPayments: false,
    coverageCUninsuredMotoristPerPersonPerAccident: false,
    coverageCUninsuredMotorist: false,
    guaranteedValue: [],
    coverageDComprehensiveCoverage: [],
    coverageDComprehensiveCoverageDeductible: [],
    coverageDCollisionCoverage: [],
    coverageDCollisionCoverageDeductible: [],
    personalInjuryProtectionLimit: false,
    pipWorkExclusion: false,
    pipDeductible: false,
    pipAppliesTo: false,
    ultraCoverage: false,
    accidentMinorViolationForgiveness: false,
    responsibleDrivingPlan: false,
    extendedPip: false,
    additionalPip: false,
    rentalCarCoverage: [],
    roadsideAssistanceAndTowing: [],
    gapCoverage: false,
    originalEquipmentManufacturer: false,
    enhancedPackage: false
  }


  get emailCCType(): FormArray {
    return this.proposalPreview.get('emailCC') as FormArray;
  }

  get packageDoc(): FormGroup {
    return this.proposalPreview.get('packageDoc') as FormGroup;
  }

  constructor(public fb: FormBuilder,
    public stepper: MatStepper,
    private apiService: WizzipayService, private activatedRoute: ActivatedRoute,
    private router: Router,
    private helper: EmailContentHelper
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      const campaignId = sessionStorage.getItem('campaignId')
      campaignId && this.getData(this.merchantId, campaignId);
    });
  }

  showPopup = false;
  popupLoader = {
    yes: false,
    no: false
  }

  confirmWithoutMail() {
    this.popupLoader.no = true;
    this.finalSubmit(false);
  }

  confirmWithMail() {
    this.popupLoader.yes = true;
    this.finalSubmit(true)
  }

  getData(merchantId: string, campaignId: string) {
    this.apiService.getdata(merchantId, campaignId).subscribe((res) => {
      this.apiService
        .getQuoteSelectionData(campaignId)
        .subscribe((selectionRes) => {
          this.proposalPreview.patchValue({
            emailAddress: res?.data?.customer?.email,
            campaignId: res?.data?._id
          });
          this.quoteData = { ...res?.data, ...selectionRes?.data };
          this.checkDifference(res?.data);
          this.createSMSTemplate(res?.data);
          this.createEmailTemplate(res?.data);

        });
    });
  }

  createEmailTemplate(dataRes: any) {
    this.emailContent = `
    <img src="${environment.autoPortalURL}/assets/email_banner.PNG" width="100%"><br><br>
    Hi ${dataRes?.customer.firstName} ${dataRes?.customer.lastName},`;
    this.emailContent += this.helper.getEmailTemplate('auto');
  }

  createSMSTemplate(campaignData: any) {
    const smsContent = `Hi ${campaignData?.customer?.firstName} ${campaignData?.customer?.lastName},
    Thank you for the opportunity to earn your business. We shopped coverage across the full Florida marine insurance market to find our best recommended options. 
    Click here: ${environment.autoPortalURL}/quotify/${this.merchantId}/${campaignData?._id}`;
    this.proposalPreview.patchValue({
      smsMessage: smsContent,
    });
  }

  async checkDifference(value: any) {
    this.premiumHighlight = [];
    let data = value?.proposalList?.[0]?.quoteList;
    let length = data?.length ? data?.length : 0;
    let singleData = [...this.autoPDFGenerators.singleData];
    singleData.shift();
    const multiCarData = this.autoPDFGenerators?.multiCarData || [];
    if (length > 0) {
      for (let index = 0; index < length; index++) {
        this.premiumHighlight.push({ ...this.checkFields });
        if (index > 0) {
          for (const key in this.checkFields) {
            if (singleData?.includes(key)) {
              const main = this.autoTableHeader[key]['main'];
              const sub = this.autoTableHeader[key]['sub'];
              this.premiumHighlight[index][key] = (data[index]?.[main] + data[index]?.[sub]) != (data[0]?.[main] + data[0]?.[sub]);
            } else if (multiCarData.includes(key)) {
              const refArrayLength = data[0]?.[key]?.length;
              const checkArrayLength = data[index]?.[key]?.length;
              this.premiumHighlight[index][key] = [];
              for (let j = 0; j < checkArrayLength; j++) {
                this.premiumHighlight[index][key].push(refArrayLength ? data[index]?.[key]?.at(j) !== data[0]?.[key]?.at(j) : !!refArrayLength)
              }
            } else {
              this.premiumHighlight[index][key] = (data[index]?.[key] != data[0]?.[key]);
            }
          }
        }
      }
    }
    this.generatePDF(value);
  }


  async generatePDF(campaignData: any) {
    // let length = val?.proposalList[0]?.quoteList?.length;
    // let proposalData = val?.proposalList[0];
    // let quoteData = val?.proposalList[0]?.quoteList;
    //https://lh6.googleusercontent.com/23tVfo9cBsOd1o1NsBmqy3isaRANfQtXq_00iBh2chdSrLGbDtRE1nOgpkid7wkJt__kbiKuZs0uCJDsK3PRZj9dDIojzzT1LD6t2ktZi8gweOxnA1ESrPLXxBc9D5tqH9Caxl6A_QwsafkJxZrVwKWlP11lKKwMwYHCWrbQ0jtXIFd9Iab7OHJjHMdhlHp5aAb0-w
    // <img src="${environment.autoPortalURL}/assets/image1.jpg" width="624" height="364">
    let customerHtml = `<style>
    .style4{
      text-align:center;
      font-size:25px;
    }
    </style>
    <p align="center" style="text-align:center">
    <img src="${environment.autoPortalURL}/assets/image1.jpg" width="624" height="364">
    </p>
     <p align="center" style="text-align:center" class="style4">&nbsp;</p>
     <h2 align="center" style="text-align:center" class="style4">Auto Insurance Proposal</h2>
     <p align="center" style="text-align:center"><strong>Prepared For:&nbsp;</strong></p>
     <p align="center" style="text-align:center" class="style4">${campaignData?.customer?.firstName + ' ' + campaignData?.customer?.lastName
      }
     </p>
     <p align="center" style="text-align:center"><strong>Agency Name</strong>: HH Insurance Group</p>
     <p align="center" style="text-align:center"><strong>Agent Name</strong>: ${campaignData?.agent?.firstName + ' ' + campaignData?.agent?.lastName
      }</p>
     <p align="center" style="text-align:center"><strong>Phone Number:</strong> ${campaignData?.agent?.number
      }</p>
     <p align="center" style="text-align:center"><strong>Email Address:</strong>&nbsp;<a href="mailto:${campaignData?.agent?.email
      }">${campaignData?.agent?.email}</a>
     </p>
     <p style="text-indent: 50px" class="pdf-pagebreak-before">
    At HH Insurance, we are setting out to change the stereotype of
insurance companies in Florida. We strive to earn your business through the
principals of service, knowledge, and markets. In addition to delivering
incredible customer service, we have the most knowledgeable staff in the
industry. HH Insurance works with exclusive insurance markets that deliver you
better coverage at the lowest rates.
</p>
<p>
But, don’t take our word for it. Our customer testimonials best show our success:
<i>“The customer service of HH Insurance is the best of any insurance company I
have ever worked with. You get hands on personal attention and you are not
just a number placed into a cost calculator. I always get more coverage for the
same price I pay with other companies. Also, they are able to close loopholes
that myself as a consumer was unaware of. I hope more people get the
opportunity to see how insurance companies can be an asset.”</p>
<p>
“HH Insurance I’ll give a 20 if you’ll rewrite the scoring. Simply the best! In a tough
insurance market, your clients still come first. Truly Amazing, Thank You”
“Awesome customer service and prices... so good I referred my family and
friends too!”</p>
<p>
“As a new homeowner, I appreciate that you search to get me the best possible
price for my insurance. I also appreciate how easy you make the process of
securing and/or changing policies.”</p>
<p>
“I appreciate the ability to ask questions and discuss options. Insurance is
complicated and Jake has always been willing to discuss both the what and the
why.”</p>
<p>
“Entire Staff is very professional and quite helpful! Highly recommended”</p>
<p>
“Our agent was very knowledgeable and responsive. He answered our
questions, was proactive about what could reduce our insurance costs and he
offered us very good rates.”</p>
<p>
“HH always looks for opportunities to save me money on my premiums. HH is
always prompt with quotes and return calls. I recommend HH to everyone I
know because I am so happy with Jake and his team.”</i>
</p><p align="center" style="text-align:center" class="style5 pdf-pagebreak-before">Auto - Proposal</p>`;


    let tableHTML = ``;
    const data = this.createAutoTable(campaignData?.proposalList?.[0]);
    tableHTML += data;
    let htmlData = ``;
    htmlData = customerHtml + tableHTML;
    console.log('htmlData ==>', htmlData);
    let html = htmlToPdfmake(htmlData, {
      imagesByReference: true,
    });
    let documentDefinition = {
      content: html.content,
      images: html.images,
      styles: {
        style4: {
          fontSize: 18,
          bold: true,
        },
        style5: {
          fontSize: 16,
          bold: true,
        },
      },
      pageBreakBefore: function (currentNode: any) {
        return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      }
    };
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getDataUrl((dataUrl) => {
      const targetElement = document.querySelector('#iframeContainer');
      const iframe = document.createElement('iframe');
      iframe.src = dataUrl;
      iframe.width = '100%';
      iframe.height = '100%';
      targetElement?.appendChild(iframe);
    });

    pdfDocGenerator.getBlob((blob) => {
      this.attachPDFFile = new File([blob], 'Proposed Quotes.pdf', {
        type: blob.type,
      });
    });
  }

  createAutoTable(quote: any) {
    let quoteData: any = quote?.quoteList;
    let htmlData = `<table width="751" border="1" bgcolor="#FFFFFF"><tbody>`

    const length = quoteData?.length;
    for (const key in this.autoTableHeader) {
      let row = ``;
      if (this.autoPDFGenerators.headerData?.includes(key)) {
        row =
          row +
          `<tr><td style="font-size: 18px;font-weight: 600;" colspan="${length + 1
          }"><strong>${this.autoTableHeader[key]}</strong></td>`;
      } else {
        if (this.autoPDFGenerators.singleData?.includes(key)) {
          row = row + `<tr><td>${this.autoTableHeader[key]['name']}</td>`;
          let count: any = [];
          for (let i = 0; i < length; i++) {
            if (quoteData[i][this.autoTableHeader[key]['sub']]) {
              if (this.autoPDFGenerators.nextLineData?.includes(this.autoTableHeader[key]['sub'])) {
                row =
                  row +
                  `<td><span style="${this.premiumHighlight[i][key] && 'background-color:#efff5f'}">${quoteData[i][this.autoTableHeader[key]['main']] || ''}<br>${quoteData[i][this.autoTableHeader[key]['sub']] || ''
                  }</span></td>`
              } else {
                row =
                  row +
                  `<td><span style="${this.premiumHighlight[i][key] && 'background-color:#efff5f'}">${quoteData[i][this.autoTableHeader[key]['sub']] || ''
                  }</span></td>`
              }
            } else {
              row =
                row +
                `<td><span style="${this.premiumHighlight[i][key] && 'background-color:#efff5f'}">${quoteData[i][this.autoTableHeader[key]['main']] || ''}</span></td>`;
            }
            (quoteData[i][this.autoTableHeader[key]['main']] ||
              quoteData[i][this.autoTableHeader[key]['sub']]) &&
              count.push(quoteData[i][key]);
          }
          if (count.length == 0) {
            row = '';
          }
        } else {
          row += this.autoPDFGenerators.currencyData?.includes(key) ?
            `<tr><td><span style="background-color:#20fbf6">${this.autoTableHeader[key]}</span></td>` : `<tr><td>${this.autoTableHeader[key]}</td>`;
          let count: any = [];
          if (this.autoPDFGenerators.multiCarData?.includes(key)) {
            for (let j = 0; j < length; j++) {
              const arrLength = quoteData[j][key]?.length;
              if (arrLength) {
                if (arrLength > 1) {
                  row = row + `<td>`;
                  for (let m = 0; m < arrLength; m++) {
                    if (m > 0) {
                      row += `<br>`;
                    }
                    row = row + `<span>Vehicle ${m + 1}: </span><br><span style="${this.premiumHighlight[j][key][m] && 'background-color:#efff5f'}">${quoteData[j][key][m] || ''}</span>`;
                  }
                  row += `</td>`
                } else {
                  row = row + `<td><span style="${this.premiumHighlight[j][key] && 'background-color:#efff5f'}">${quoteData[j][key] || ''}</span></td>`;
                }
              } else {
                row = row + `<td></td>`;
              }
              arrLength && count.push(quoteData[j][key]);
            }
          } else {
            if (this.autoPDFGenerators.currencyData?.includes(key)) {
              for (let k = 0; k < length; k++) {
                row = row + `<td><span style="background-color:#20fbf6">${this.parseAmount(quoteData[k][key]) || ''}</span></td>`;
                quoteData[k][key] && count.push(quoteData[k][key]);
              }
            } else {
              for (let l = 0; l < length; l++) {
                const keyValue = (key == 'newBoatReplacement' && quoteData[l][key] == 'NOT AVAILABLE') ? '' : quoteData[l][key];
                row = row + `<td><span style="${this.premiumHighlight[l][key] && 'background-color:#efff5f'}">${keyValue || ''}</span></td>`;
                keyValue && count.push(quoteData[l][key]);
              }
            }
          }
          if (count.length == 0) {
            row = '';
          }
        }
      }
      row && (htmlData = htmlData + row + `</tr>`);
    }
    htmlData += `</tbody></table>`
    return htmlData;
  }

  parseAmount(val: string) {
    let amount = val?.replace(/[$,]/g, "")
    if (amount) {
      return (parseFloat(amount))?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    } else {
      return '';
    }
  }

  removeEmail(i: number) {
    const array = this.proposalPreview.get('emailCC') as FormArray;
    array.removeAt(i);
  }

  newEmail(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.email]],
    });
  }

  addEmail(control: any, index: number) {
    control[index].get('email').markAllAsTouched();
    if (
      control[index].get('email').invalid ||
      control[index].get('email').value === ''
    ) {
      // this.apiService.openSnackBar('Please fill a valid email to add more');
      return;
    }
    const array = this.proposalPreview.get('emailCC') as FormArray;
    array.push(this.newEmail());
  }

  checkFile() {
    setTimeout(() => {
      this.attachPDFFile ? this.next() : this.checkFile();
    }, 500);
  }

  back() {
    this.stepper.previous();
  }

  formatFileSize(fileSizeBytes: number): string {
    const units = ['B', 'KB', 'MB', 'GB'];
    let size = fileSizeBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return size.toFixed(2) + ' ' + units[unitIndex];
  }

  finalSubmit(sendEmail: boolean) {
    const emails: string[] = [];
    this.proposalPreview.value.emailCC.forEach((element: { email: string }) => {
      emails.push(element.email);
    });
    const btnData = `<table align="center" cellpadding="0" cellspacing="0">
    <tr>
      <td style="text-align: center;">
        <div style="text-align: center;">
        <!--[if mso]>
  <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word"
                href="${environment.autoPortalURL}/quotify/${this.merchantId}/${this.proposalPreview.value.campaignId}"
                style="height:auto;v-text-anchor:middle;width:400px" arcsize="10%" strokecolor="#007cba"
                fillcolor="#007cba">
    <w:anchorlock/>
    <center style="color:#ffffff;font-family:sans-serif;font-size:20px;">&nbsp; Proceed to order page &nbsp;</center>
  </v:roundrect>
  <![endif]-->
  <!--[if !mso]><!-- -->
  <a href="${environment.autoPortalURL}/quotify/${this.merchantId}/${this.proposalPreview.value.campaignId}"
     style="background-color:#007cba;border-radius:6px;color:#ffffff;display:inline-block;font-family:sans-serif;font-size:20px;line-height:36px;text-align:center;text-decoration:none;width:fit-content;-webkit-text-size-adjust:none;">
     &nbsp; Proceed to order page &nbsp; 
  </a>
  <!--<![endif]-->
        </div>
      </td>
    </tr>
  </table>`;
    const payload = {
      campaignId: this.proposalPreview.value.campaignId,
      emailContent: this.emailContent?.replace('</p>', `</p>${btnData}`),
      emailCC: emails,
      subject: this.proposalPreview.value.emailSubject,
    };
    const formData = new FormData();
    formData.append('files', this.attachPDFFile);
    this.apiService.uploadFiles(formData).subscribe(
      (res) => {
        let quotePayload: any = {
          packageDoc: {
            name: 'Proposed Quotes.pdf',
            size: this.formatFileSize(this.attachPDFFile?.size),
            url: res?.data?.url
          }
        }
        if (this.quoteData?.effectiveDateUpdateRequest) {
          quotePayload['effectiveDateUpdateRequest'] = false;
          quotePayload['policyStartDate'] = this.quoteData?.effectiveDateRequestList?.at(-1)?.requestedEffectiveDate;
        }
        if (sendEmail) {
          quotePayload['status'] = 'Quote Selection Email Sent';
          quotePayload['statusChange'] = true;
        }
        this.apiService
          .updateOrder(quotePayload, this.merchantId, sessionStorage.getItem('campaignId'))
          .subscribe(
            () => {
              const uploadFormData = new FormData();
              uploadFormData.append('file', this.attachPDFFile);
              this.apiService
                .uploadFile(this.quoteData?.commonCustomerId, this.quoteData?.quoteId, uploadFormData, 'Proposed Quotes.pdf')
                .subscribe({
                  next: () => {
                    if (sendEmail) {
                      this.apiService.email(payload).subscribe(
                        () => {
                          const smsPayload = {
                            campaignId: this.proposalPreview.value.campaignId,
                            textContent: this.proposalPreview.value.smsMessage,
                            subjec: this.proposalPreview.value.smsSubject,
                          };
                          this.apiService.sms(smsPayload).subscribe(
                            () => {
                              this.loadingStatus = false;
                              this.apiService.snackbar('Email & SMS sent successfully');
                              sessionStorage.clear();
                              this.router.navigate([`/merchant/campaignList/${this.merchantId}`]);
                            },
                            () => {
                              this.loadingStatus = false;
                              this.apiService.snackbar('Error');
                            }
                          );
                        },
                        () => {
                          this.loadingStatus = false;
                          this.apiService.snackbar('Error');
                        }
                      );
                    } else {
                      this.loadingStatus = false;
                      sessionStorage.clear();
                      this.router.navigate([`/merchant/campaignList/${this.merchantId}`]);
                    }
                  },
                  error: () => {
                    this.loadingStatus = false;
                    this.apiService.snackbar('Error');
                  }
                });
            },
            () => {
              this.loadingStatus = false;
              this.apiService.snackbar('Error');
            }
          );
      },
      () => {
        this.loadingStatus = false;
        this.apiService.snackbar('Error');
      }
    )
  }

  next() {
    this.loadingStatus = true;
    if (!this.attachPDFFile) {
      this.checkFile();
      return;
    }
    if (this.quoteData?.effectiveDateUpdateRequest && !this.quoteData?.paymentDetails?.selectedPackage) {
      console.log('1111111111')
      this.finalSubmit(true);
      return;
    }
    if (this.quoteData?.effectiveDateUpdateRequest && !(['Quote Completed', 'Quote Payment Ready'].includes(this.quoteData?.status))) {
      console.log('2222222222')
      this.finalSubmit(true);
      return;
    }
    if (this.quoteData?.effectiveDateUpdateRequest) {
      console.log('333333333333')
      this.showPopup = true;
      this.loadingStatus = false;
    } else {
      console.log('444444444444')
      this.finalSubmit(true);
    }

  }
}
