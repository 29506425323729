<main class="mb-3 w-100">
    <div class="card formSection">

        <div class="card-header">
            <div class="jumbotron text-center">
                <h1 class="display-6">Email Preview</h1>
            </div>
        </div>
        <div class="card-body mb-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <form [formGroup]="emailForm">

                            <div class="col-lg-12 text-start mb-3">
                                <input class="form-control w-100" formControlName="recepientEmail" type="text"
                                    placeholder="To">
                                <div
                                    *ngIf="emailForm?.get('recepientEmail')?.touched && emailForm?.get('recepientEmail')?.errors">
                                    <span class="p-error"
                                        *ngIf="emailForm?.get('recepientEmail')?.hasError('required')">
                                        Email ID is required
                                    </span>
                                    <span class="p-error" *ngIf="emailForm?.get('recepientEmail')?.hasError('email')">
                                        Please enter a valid email
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-12 text-start mb-3">
                                <input class="form-control w-100" formControlName="subject" type="text"
                                    placeholder="Subject">
                                <div *ngIf="emailForm?.get('subject')?.touched && emailForm?.get('subject')?.errors">
                                    <span class="p-error" *ngIf="emailForm?.get('subject')?.hasError('required')">
                                        Subject is required
                                    </span>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div>
                        <quill-editor [modules]="moduleConfig" [(ngModel)]="emailContent.body">
                        </quill-editor>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-3 px-3 py-5">
            <div class="row">
                <div class="col-6 text-start">
                    <insurance-button btnWidth="200" label="Edit Customer Info" [disabled]="submitting"
                        (onClick)="back()">
                    </insurance-button>
                </div>
                <div class="col-6 text-end">
                    <insurance-button btnWidth="150" label="Send Email" (onClick)="sendEmail()" [disabled]="submitting"
                        [loading]="submitting">
                    </insurance-button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="emailError">
            <span class="p-error" *ngIf="emailForm?.get('subject')?.hasError('required')">
                Error in sending Mail
            </span>
        </ng-container>
    </div>
</main>

<insurance-popup [displayModal]="mailSuccess" iconBgColor="#e5f5ec" iconClass="pi pi-check" iconColor="#27AE60"
    message="Congrats" [closable]="false">
    <div>
        <h3>Mail sent Successfully</h3>
        <insurance-button label="OK" buttonId="resetAppraisalSubmit" (onClick)="closePopup()" btnWidth="130">
        </insurance-button>
    </div>
</insurance-popup>