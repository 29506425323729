<div class="pt-3 pb-3">
    <div class="container-fluid">
        <div class="formSection">
            <div class="col-lg-12 pt-2 mb-3">
                <div class="row">
                    <div class="col-lg-12 float-right">
                        <i class="icofont-user-alt-2 pe-2 f18"></i>
                        <span class="email-label">{{merchantresponse}}</span>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="sectOne">
                    <div class="sectOne_1">
                        <insurance-checkbox [formControl]="deleteSelect" inputId="selectAll" [multiCheckBox]="false"
                            (onClick)="selectAll($event)">
                        </insurance-checkbox>
                    </div>
                    <div class="sectOne_2" *ngIf="selectedQuote.length > 0">
                        <i class="fa fa-trash-o del-icon" aria-hidden="true" (click)="delete()"></i>
                    </div>
                    <div class="sectOne_3">
                        <div class="form-group has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input class="form-control mb-3 w-500" (click)="setPage()" type="text"
                                [formControl]="searchText" placeholder="Search">
                        </div>
                        <button *ngIf="searchText?.value" class="searchBtn" (click)="clearSearch()">Clear
                            Search</button>
                    </div>
                </div>
                <div class="sectTwo">
                    <div class="btn-group float-end">
                        <button type="button" class="btn btn-secondary w-150" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{btnLable}}
                        </button>
                        <button type="button" [disabled]="stopSort" (click)="sortOrder()" class="btn btn-secondary">
                            <span class="fa fa-stack">
                                <i class="fa fa-caret-up" [ngStyle]="{'color': asc ? '#fff' : '#ffffff85'}"
                                    aria-hidden="true"></i>
                                <i class="fa fa-caret-down" [ngStyle]="{'color': dsc ? '#fff' : '#ffffff85'}"
                                    aria-hidden="true"></i>
                            </span>
                        </button>
                        <ul class="dropdown-menu">
                            <li class="cp">
                                <a *ngFor="let data of sortTypes"
                                    [ngStyle]="{'background-color': (data.label === 'Clear Sort') ? '#db524e' : 'transparent'}"
                                    class="dropdown-item" (click)="sort(data.label, data.sort)">
                                    {{data.label}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                        <thead>
                            <tr class="row100 head">
                                <th></th>
                                <th>#</th>
                                <th>Name</th>
                                <th>Insurance Type</th>
                                <th>Agent</th>
                                <th>Package Name</th>
                                <th>Effective Date</th>
                                <th>Driver Phone</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Live Customer URL
                                    (Action
                                    Url)</th>
                                <th>Edit and Preview Quote</th>
                                <th>Email</th>
                                <th>SMS</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="campaignResponse.length > 0">
                            <tr class="row100" *ngFor="let data of campaignResponse; let i = index">
                                <td class="text-center">
                                    <insurance-checkbox [(ngModel)]="data.checked" inputId="selectId{{i}}"
                                        [multiCheckBox]="false" (onClick)="selectQuote(data._id, i, $event)">
                                    </insurance-checkbox>
                                </td>
                                <td class="text-end">{{i + 1}}</td>
                                <td>{{(data?.name | titlecase) || '-'}}</td>
                                <td>{{(data?.insuranceType | titlecase) || '-'}}</td>
                                <td>{{(data?.agentName | titlecase) || '-'}}</td>
                                <td>{{(data?.packageName | titlecase) || '-'}}</td>
                                <td class="text-center">
                                    <ng-container *ngIf="data?.selectedDate && data?.policyStartDate">
                                        <div class="tooltipTest"
                                            [ngStyle]="{'background-color': (data?.selectedDate | date: 'MM/dd/yyyy') != (data?.policyStartDate | date: 'MM/dd/yyyy') ? '#cbecd9' : ''}">
                                            <span>{{data?.selectedDate | date: 'MM/dd/yyyy'}}</span>
                                            <ng-container
                                                *ngIf="(data?.selectedDate | date: 'MM/dd/yyyy') != (data?.policyStartDate | date: 'MM/dd/yyyy')">
                                                <span class="asterix">*</span>
                                                <span class="tooltiptext">
                                                    <ul>
                                                        <li><label>Proposed Date</label><span> :
                                                            </span>{{data?.policyStartDate |
                                                            date:
                                                            'MM/dd/yyyy'}}</li>
                                                        <li><label>Selected Date</label><span> :
                                                            </span>{{data?.selectedDate | date:
                                                            'MM/dd/yyyy'}}</li>
                                                    </ul>
                                                </span>

                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!data?.selectedDate && data?.policyStartDate">
                                        {{data?.policyStartDate | date: 'MM/dd/yyyy'}}
                                    </ng-container>
                                    <ng-container *ngIf="data?.selectedDate && !data?.policyStartDate">
                                        <div class="tooltipTest" [ngStyle]="{'background-color':'#cbecd9'}">
                                            <span>{{data?.selectedDate | date: 'MM/dd/yyyy'}}</span>
                                            <span class="asterix">*</span>
                                            <span class="tooltiptext">
                                                <ul>
                                                    <li><label>Proposed Date</label><span> : </span>-</li>
                                                    <li><label>Selected Date</label><span> : </span>{{data?.selectedDate
                                                        | date:
                                                        'MM/dd/yyyy'}}</li>
                                                </ul>
                                            </span>
                                        </div>
                                    </ng-container>
                                </td>
                                <td>
                                    <span *ngFor="let num of data?.driverPhone; let i=index">
                                        {{num.number}}{{i === data?.driverPhone.length- 1 ? '' :', '}}<br>
                                    </span>
                                </td>
                                <td class="text-end"> ${{(data?.amount | number) || 0}}</td>
                                <td>{{(data?.progress | titlecase) || '-'}}</td>
                                <td class="text-center"><a class="urlStyles" (click)="routeToOrder(data)">Order Page
                                    </a> <i class="cp fa fa-clone ps-2" (click)="callSnack('Copied to clipboard')"
                                        [cdkCopyToClipboard]="environmentURL+'/orderPage/'+ data?.merchantId + '/' + data?._id"
                                        aria-hidden="true"></i>
                                </td>
                                <td class="text-center">
                                    <ng-container *ngIf="(!createProposalLoader?.at(i))">
                                        <a class="urlStyles" (click)="createProposal(data?.data, i)">Create or Renewal
                                        </a>
                                    </ng-container>
                                    <i class="pi pi-spin pi-spinner" *ngIf="createProposalLoader?.at(i)"></i>
                                    <!-- <a class="urlStyles" (click)="routeToSetup(data)">Configure
                                    </a>
                                    <i class="fa fa-cog ps-2" aria-hidden="true"></i> -->
                                </td>
                                <td class="text-center"><button (click)="emailPreview(data)" class="btn btn-type-two "
                                        type="button" [disabled]="true">
                                        <span><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
                                    </button>
                                </td>
                                <td class="text-center">
                                    <button (click)="smsPreview(data)" class="btn btn-type-two" type="button"
                                        [disabled]="true">
                                        <span><i class="fa fa-comments-o" aria-hidden="true"></i></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="campaignResponse.length == 0">
                            <tr>
                                <td colspan="14" class="text-center">
                                    <h6 class="mb-0">No offers found</h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-12 text-end mt-2" *ngIf="campaignResponse.length > 0">
                <mat-paginator [pageIndex]="currentPage" (page)="pageEvent = pageChange($event)"
                    [showFirstLastButtons]="true" [length]="totalCount" [pageSize]="pageSize"></mat-paginator>
            </div>
        </div>
    </div>
</div>
<insurance-popup header="SMS Preview" [displayModal]="smsDialog" (closeAction)="closeSMSDialog()">
    <div class="col-lg-12 text-start mb-3" [formGroup]="emailForm">
        <input class="form-control w-100" formControlName="proposal" type="text" placeholder="Subject">
        <div *ngIf="emailForm?.get('proposal')?.touched && emailForm?.get('proposal')?.errors">
            <span class="p-error" *ngIf="emailForm?.get('proposal')?.hasError('required')">
                Subject is required
            </span>
        </div>
    </div>
    <div class="col-lg-12 text-start" [formGroup]="emailForm">
        <insurance-text-area rows="8" inputId="smsPreview" label="SMS Preview" formControlName="smsMessage">
        </insurance-text-area>
        <div *ngIf="emailForm?.get('smsMessage')?.touched && emailForm?.get('smsMessage')?.errors">
            <span class="p-error" *ngIf="emailForm?.get('smsMessage')?.hasError('required')">
                SMS Content is required
            </span>
        </div>
    </div>
    <div class="col-lg-12 text-center mt-3">
        <insurance-button btnWidth="150" label="Send SMS" (onClick)="sendSms()" [loading]="submitting">
        </insurance-button>
    </div>
</insurance-popup>
<div class="emailPreview">
    <insurance-popup header="Email Preview" [displayModal]="emailDialog" (closeAction)="closeEmailDialog()">
        <div class="col-lg-12" [formGroup]="emailForm">
            <div class="row">
                <div class="col-lg-6 text-start" formArrayName="emailCC"
                    *ngFor="let data of emailCCType.controls;let i = index">
                    <div class="row mb-3" [formGroupName]="i">
                        <div class="col-lg-11">
                            <input class="form-control w-100" formControlName="email" type="text"
                                placeholder="Email CC {{i + 1}}">
                        </div>
                        <div class="col-lg-1 three">
                            <i class="fa fa-minus" id="removeEmail{{i}}" (click)="removeEmail(i)"
                                *ngIf="i !== emailCCType.controls.length - 1"></i>
                            <i class="fa fa-plus" id="addEmails" (click)="addEmail(emailCCType.controls, i)"
                                *ngIf="i === emailCCType.controls.length - 1"></i>
                        </div>
                        <div *ngIf="data?.get('email')?.touched && data?.get('email')?.errors">
                            <span class="p-error" *ngIf="data?.get('email')?.hasError('email')">
                                Please enter a valid email
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-start  mb-3">
                    <input class="form-control w-100" formControlName="proposal" type="text" placeholder="Subject">
                    <div *ngIf="emailForm?.get('proposal')?.touched && emailForm?.get('proposal')?.errors">
                        <span class="p-error" *ngIf="emailForm?.get('proposal')?.hasError('required')">
                            Subject is required
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 text-start mb-3">
            <a class="btn btn-type-two px-2" href="{{attachmentFile.url}}" target="_blank" type="button">
                <div><i class="fa fa-file-pdf-o" aria-hidden="true"></i> {{attachmentFile.name}}</div>
            </a>
        </div>
        <quill-editor [modules]="moduleConfig" [(ngModel)]="emailContent">
        </quill-editor>
        <div class="col-lg-12 text-center mt-3">
            <insurance-button btnWidth="150" label="Send Email" (onClick)="sendEmail()" [loading]="submitting">
            </insurance-button>
        </div>
    </insurance-popup>
</div>