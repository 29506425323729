<form [formGroup]="generateQuote" autocomplete="off">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-lg-12 col-md-12 col-sm-12 my-4">
                <div class="row justify-content-md-center">
                    <div class="col-lg-12" [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                        <div class="row justify-content-md-center">
                            <div class="col-lg-12 text-center">
                                <div class="Divider">
                                    <hr />
                                    <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                        Auto Info
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-12 text-end"
                                *ngIf="!(generateQuote.get('autosList').value?.length >= 6)">
                                <i class="fa fa-plus iconStyle" (click)="addAuto(1)"> Add Auto</i>
                            </div>
                            <div class="col-lg-6 col-md-6" formArrayName="autosList"
                                *ngFor="let data of generateQuote.get('autosList').controls; let i = index">
                                <div class="row justify-content-md-center" [formGroupName]="i">
                                    <div class="col-lg-12" *ngIf="generateQuote.get('autosList').length > 1">
                                        <h6 class="mb-2 float-start">Auto {{i + 1}}
                                        </h6>
                                        <i class="pi pi-trash float-end pt-2 icoStyle" (click)="removeAuto(i)"></i>
                                    </div>
                                    <div class="col-md-6 col-sm-6 mt-4">
                                        <insurance-input inputId="vin" label="VIN" formControlName="vin" maxlength="20"
                                            (input)="vinValidation(i, generateQuote.get('autosList').value?.length);">
                                        </insurance-input>
                                        <ng-container *ngIf="data?.get('vin')?.touched && data?.get('vin')?.errors">
                                            <span class="p-error" *ngIf="data.get('vin').hasError('required')">
                                                VIN is Required!
                                            </span>
                                            <span class="p-error" *ngIf="data?.get('vin')?.hasError('pattern')">
                                                Please enter a valid VIN
                                            </span>
                                            <span class="p-error"
                                                *ngIf="data.get('vin').hasError('incorrect') && data.get('vin').touched">
                                                VIN already exists!
                                            </span>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 col-sm-6 mt-4">
                                        <insurance-input inputId="licensePlateNumber" label="License Plate Number"
                                            formControlName="licensePlateNumber" maxlength="20"
                                            (input)="licensePlateNumberValidation(i, generateQuote.get('autosList').value?.length);">
                                        </insurance-input>
                                        <ng-container
                                            *ngIf="data?.get('licensePlateNumber')?.touched && data?.get('licensePlateNumber')?.errors">
                                            <span class="p-error"
                                                *ngIf="data.get('licensePlateNumber').hasError('required')">
                                                License Plate Number is Required!
                                            </span>
                                            <span class="p-error"
                                                *ngIf="data?.get('licensePlateNumber')?.hasError('pattern')">
                                                Please enter a valid License Plate Number
                                            </span>
                                            <span class="p-error"
                                                *ngIf="data.get('licensePlateNumber').hasError('incorrect')">
                                                License Plate Number already exists!
                                            </span>
                                        </ng-container>
                                    </div>
                                    <div class="col-lg-12 pt-4">
                                        <insurance-checkbox formControlName="annualMileage"
                                            label="Low mileage (less than 7500 miles per year)" inputId="annualMileage"
                                            [multiCheckBox]="false" (onClick)="checkMileage($event, i)">
                                        </insurance-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12"
                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                        <div class="row justify-content-md-center">
                            <div class="col-lg-12 text-center mt-2">
                                <div class="Divider">
                                    <hr />
                                    <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                        Prior Declaration Page
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6 py-3">
                                <insurance-file-upload docType="your Prior Declaration Page" uploadId="priorDecUpload"
                                    url="{{autoQuoteIP}}/api/v1/document/upload?cId={{customerId}}&qId={{quoteId}}&portal=auto_quote&docType=priorDeclaration&fileName=&temp=false"
                                    (uploadedFileRes)="getRes($event)" (deletedFileRes)="delRes()"
                                    [filename]="priorDecPage.value.name" [filesize]="priorDecPage.value.size">
                                </insurance-file-upload>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 text-center" [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                        <div class="Divider">
                            <hr />
                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                Driver Info
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-md-center"
                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                        <div class="col-lg-12 text-end" *ngIf="!(generateQuote.get('driversList').value?.length >= 6)">
                            <i class="fa fa-plus iconStyle" (click)="addDriver(1)"> Add Driver</i>
                        </div>
                        <div class="col-lg-12 col-md-12" formArrayName="driversList"
                            *ngFor="let data of generateQuote.get('driversList').controls; let i = index">
                            <div class="row justify-content-md-center" [formGroupName]="i">
                                <div class="col-lg-12 mt-4">
                                    <h6 class="mb-2 float-start"
                                        *ngIf="generateQuote.get('driversList').value?.length != 1">Driver {{i + 1}}
                                    </h6>
                                    <i *ngIf="generateQuote.get('driversList').length > 1 && i>0"
                                        class="pi pi-trash float-end pt-2 icoStyle" (click)="removeDriver(i)"></i>
                                </div>
                                <div class="col-md-4 col-sm-4 mt-4">
                                    <insurance-input inputId="nameFirst" label="First Name" formControlName="nameFirst">
                                    </insurance-input>
                                    <ng-container
                                        *ngIf="data?.get('nameFirst')?.touched && data?.get('nameFirst')?.errors">
                                        <span class="p-error" *ngIf="data.get('nameFirst').hasError('required')">
                                            First Name is Required!
                                        </span>
                                        <span class="p-error" *ngIf="data?.get('nameFirst')?.hasError('pattern')">
                                            Please enter a valid first name
                                        </span>
                                    </ng-container>
                                </div>
                                <div class="col-md-4 col-sm-4 mt-4">
                                    <insurance-input inputId="nameLast" label="Last Name" formControlName="nameLast">
                                    </insurance-input>
                                    <ng-container
                                        *ngIf="data?.get('nameLast')?.touched && data?.get('nameLast')?.errors">
                                        <span class="p-error" *ngIf="data.get('nameLast').hasError('required')">
                                            Last Name is Required!
                                        </span>
                                        <span class="p-error" *ngIf="data?.get('nameLast')?.hasError('pattern')">
                                            Please enter a valid last name
                                        </span>
                                    </ng-container>
                                </div>
                                <div class="col-md-4 col-sm-4 mt-4">
                                    <insurance-input-date formControlName="dateOfBirth" [maxDate]="min"
                                        label="Date Of Birth" dateId="dateOfBirth" [readOnly]="readOnly">
                                    </insurance-input-date>
                                    <div class="p-error"
                                        *ngIf="data.get('dateOfBirth').hasError('required') && data.get('dateOfBirth').touched">
                                        Date Of Birth is Required!
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 mt-4">
                                    <insurance-input inputId="licenseNumber" label="Driver License Number"
                                        formControlName="licenseNumber" maxlength="20"
                                        (input)="licenseNumberValidation(i, generateQuote.get('driversList').value?.length);">
                                    </insurance-input>
                                    <ng-container
                                        *ngIf="data?.get('licenseNumber')?.touched && data?.get('licenseNumber')?.errors">
                                        <span class="p-error" *ngIf="data.get('licenseNumber').hasError('required')">
                                            Driver License Number is Required!
                                        </span>
                                        <span class="p-error" *ngIf="data?.get('licenseNumber')?.hasError('pattern')">
                                            Please enter a valid Driver License Number
                                        </span>
                                        <span class="p-error"
                                            *ngIf="data.get('licenseNumber').hasError('incorrect') && data.get('licenseNumber').touched">
                                            Driver License Number already exists!
                                        </span>
                                    </ng-container>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                                    <insurance-select selectId="educationLevel" label="Education Level"
                                        [dataList]="EducationLevelData" dataLabel="name" dataValue="value"
                                        formControlName="educationLevel" dataId="id">
                                    </insurance-select>
                                    <div class="p-error"
                                        *ngIf="data.get('educationLevel').hasError('required') && data.get('educationLevel').touched">
                                        Education Level is Required!
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                                    <insurance-select selectId="occupation" label="Occupation"
                                        [dataList]="OccupationData" dataLabel="name" dataValue="value"
                                        formControlName="occupation" dataId="id">
                                    </insurance-select>
                                    <div class="p-error"
                                        *ngIf="data.get('occupation').hasError('required') && data.get('occupation').touched">
                                        Occupation is Required!
                                    </div>
                                </div>
                                <ng-container *ngIf="i==0">
                                    <div class="col-lg-4 col-md-4 col-sm-4 mt-4"
                                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                                        <insurance-input inputId="clientEmailAddress" label="Email Address"
                                            formControlName="email" [readOnly]="readOnly">
                                        </insurance-input>
                                        <div class="p-error"
                                            *ngIf="data.get('email').hasError('required') && data.get('email').touched">
                                            Email is Required!
                                        </div>
                                        <div class="p-error" *ngIf="data.get('email').hasError('pattern')">
                                            Please enter a valid Email
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 mt-4"
                                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                                        <insurance-input-mask inputId="clientPhoneNumber" label="Phone Number"
                                            formControlName="phone" maxlength="14" [readOnly]="readOnly">
                                        </insurance-input-mask>
                                        <div class="p-error"
                                            *ngIf="data.get('phone').hasError('required') && data.get('phone').touched">
                                            Phone Number is Required!
                                        </div>
                                        <div class="p-error" *ngIf="data.get('phone').hasError('pattern')">
                                            Please enter a valid Phone Number
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-start mt-4">
                                        <h5 class="text-color-gray">Address Info</h5>
                                    </div>
                                    <div class="col-md-4 mt-4"
                                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                                        <insurance-input inputId="streetNumber" label="Street Number"
                                            formControlName="streetNumber" [readOnly]="readOnly"></insurance-input>
                                        <div class="p-error"
                                            *ngIf="data.get('streetNumber').hasError('required') && data.get('streetNumber').touched">
                                            Street Number is Required!
                                        </div>
                                        <div class="p-error"
                                            *ngIf="data.get('streetNumber').hasError('pattern') && data.get('streetNumber').touched">
                                            Please enter a valid Street Number
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4"
                                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                                        <insurance-input inputId="streetName" label="Street Name"
                                            formControlName="streetName" [readOnly]="readOnly"></insurance-input>
                                        <div class="p-error"
                                            *ngIf="data.get('streetName').hasError('required') && data.get('streetName').touched">
                                            Street Name is Required!
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4"
                                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                                        <insurance-input inputId="city" label="City" formControlName="city"
                                            [readOnly]="readOnly">
                                        </insurance-input>
                                        <ng-container *ngIf="data?.get('city')?.touched && data?.get('city')?.errors">
                                            <span class="p-error" *ngIf="data.get('city').hasError('required')">
                                                City is Required!
                                            </span>
                                            <span class="p-error" *ngIf="data?.get('city')?.hasError('pattern')">
                                                Please enter a valid city
                                            </span>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-4 mt-4 setRead">
                                        <insurance-input inputId="state" label="State" formControlName="state">
                                        </insurance-input>
                                        <ng-container *ngIf="data?.get('state')?.touched && data?.get('state')?.errors">
                                            <span class="p-error" *ngIf="data.get('state').hasError('required')">
                                                State is Required!
                                            </span>
                                            <span class="p-error" *ngIf="data?.get('state')?.hasError('pattern')">
                                                Please enter a valid state
                                            </span>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-4 mt-4 setRead">
                                        <insurance-input inputId="country" label="Country" formControlName="country">
                                        </insurance-input>
                                        <ng-container
                                            *ngIf="data?.get('country')?.touched && data?.get('country')?.errors">
                                            <span class="p-error" *ngIf="data.get('country').hasError('required')">
                                                Country is Required!
                                            </span>
                                            <span class="p-error" *ngIf="data?.get('country')?.hasError('pattern')">
                                                Please enter a valid country
                                            </span>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-4 mt-4"
                                        [ngStyle]="{'pointer-events': readOnly ? 'none' : 'auto'}">
                                        <insurance-input inputId="zip" label="ZIP Code" formControlName="zip"
                                            [readOnly]="readOnly">
                                        </insurance-input>
                                        <ng-container *ngIf="data?.get('zip')?.touched && data?.get('zip')?.errors">
                                            <span class="p-error" *ngIf="data.get('zip').hasError('required')">
                                                ZIP Code is Required!
                                            </span>
                                            <span class="p-error" *ngIf="data?.get('zip')?.hasError('pattern')">
                                                Please enter a valid zip code
                                            </span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center my-5">
                        <div class="col-md-6 col-sm-12 text-center">
                            <insurance-button buttonId="generateQuoteBtn"
                                label="{{readOnly == true ? 'Quote Submitted' : 'Submit Auto Quote'}}" btnWidth="200"
                                (onClick)="next()" [loading]="loadingStatus" [disabled]="loadingStatus || readOnly">
                            </insurance-button>
                            <!-- <insurance-button buttonId="generateQuoteBtn"
                                label="{{readOnly == true ? (quoteExist == true ? 'Next Auto Quote':'Add new Auto Quote') : 'Submit Auto Quote'}}"
                                btnWidth="200"
                                (onClick)="readOnly == true ? (quoteExist == true ? anotherAutoQuote():anotherAutoQuote()) : next()"
                                [loading]="loadingStatus" [disabled]="loadingStatus">
                            </insurance-button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<lock-screen [quoteCompleted]="readOnly"></lock-screen>
<insurance-popup [displayModal]="quoteSubmit" iconBgColor="#e5f5ec" iconClass="pi pi-check" iconColor="#27AE60"
    message="Congrats" [closable]="false">
    <div *ngIf="!showAnotherQuote">
        <h3>Your Auto Quote has been submitted</h3>
        <p>Thanks for quoting with HH Insurance. Representative will contact you soon</p>
        <insurance-button label="OK" buttonId="resetAppraisalSubmit"
            (onClick)="quoteSubmit = false;loadingStatus=false;readOnly=true" btnWidth="130">
        </insurance-button>
    </div>
    <div class="col-lg-12" *ngIf="showAnotherQuote">
        <div class="row">
            <h3 class="py-4">Do you like to quote another Auto ?</h3>
            <div class="col-lg-6 text-center">
                <insurance-button label="No" buttonId="resetAppraisalSubmit" (onClick)="close()" btnWidth="130">
                </insurance-button>
            </div>
            <div class="col-lg-6 text-center">
                <insurance-button label="Yes" buttonId="resetAppraisalSubmit" (onClick)="anotherAutoQuote()"
                    btnWidth="130">
                </insurance-button>
            </div>
        </div>
    </div>

</insurance-popup>