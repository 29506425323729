<main class="d-flex align-items-center mb-3">
    <div class="card formSection">
        <div class="card-header text-center">
            <h1 class="display-6">Order Page Preview</h1>
        </div>
        <div class="card-body">
            <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="row">
                                        <div class="col-lg-12 mb-3">
                                            <div class="contentCard mb-3">
                                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                    <li class="nav-item" role="presentation" (click)="reviewConfirmation[0] = true">
                                                        <button class="nav-link active" id="home-tab"
                                                            data-bs-toggle="tab" data-bs-target="#home" type="button"
                                                            role="tab" aria-controls="home" aria-selected="true">Quote
                                                            Preview</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation" (click)="reviewConfirmation[1] = true">
                                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                            data-bs-target="#profile" type="button" role="tab"
                                                            aria-controls="profile" aria-selected="false">Email
                                                            Preview</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation" (click)="reviewConfirmation[2] = true">
                                                        <button class="nav-link" id="sms-tab" data-bs-toggle="tab"
                                                            data-bs-target="#sms" type="button" role="tab"
                                                            aria-controls="sms" aria-selected="false">Sms
                                                            Preview</button>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="home" role="tabpanel"
                                                        aria-labelledby="home-tab">
                                                        <div class="previewStyles">
                                                            <table class="table table-bordered mb-0 pe-none">
                                                                <thead>
                                                                    <!-- <tr
                                                                        *ngFor="let data of quoteDatas; let first = first">
                                                                        <ng-container
                                                                            *ngFor="let item of data | keyvalue : returnZero; trackBy: trackByFn">
                                                                            <td *ngIf="first">
                                                                                <b> {{data[item.key]}} </b>
                                                                            </td>
                                                                        </ng-container>
                                                                    </tr> -->
                                                                    <th>Package</th>
                                                                    <th *ngFor="let column of quoteDatas[0]?.headers">
                                                                        {{column}}
                                                                    </th>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="firstCol">Select any one</td>
                                                                        <ng-container
                                                                            *ngFor="let item of quoteDatas[0]?.headers">
                                                                            <td>
                                                                                <button id="select"
                                                                                    class="btn btn-type-two w-100"
                                                                                    type="button">
                                                                                    <span>Select this quote</span>
                                                                                </button>
                                                                            </td>
                                                                        </ng-container>
                                                                        <!-- <ng-container
                                                                            *ngFor="let item of dataRes?.package">
                                                                            <td>
                                                                                <button id="select"
                                                                                    class="btn btn-type-two w-100"
                                                                                    type="button">
                                                                                    <span>Select this quote</span>
                                                                                </button>
                                                                            </td>
                                                                        </ng-container> -->
                                                                    </tr>
                                                                    <!-- <tr
                                                                        *ngFor="let data of quoteDatas; let first = first">
                                                                        <ng-container
                                                                            *ngFor="let item of data | keyvalue : returnZero; trackBy: trackByFn">
                                                                            <td *ngIf="!first">
                                                                                {{data[item.key]}}
                                                                            </td>
                                                                        </ng-container>
                                                                    </tr> -->
                                                                    <tr
                                                                        *ngFor="let row of quoteDatas[0]?.colPackage; index as i">
                                                                        <td>{{row}}</td>
                                                                        <td
                                                                            *ngFor="let column of quoteDatas[0]?.headers; index as j">
                                                                            {{quoteDatas[0]?.colValues[j][i]}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="profile" role="tabpanel"
                                                        aria-labelledby="profile-tab">
                                                        <div class="previewStyles">
                                                            <div class="col-lg-12" [formGroup]="orderPreview">
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-md-6 mb-3"
                                                                        formArrayName="emailCC"
                                                                        *ngFor="let data of emailCCType.controls;let i = index">
                                                                        <div class="row" [formGroupName]="i">
                                                                            <div class="col-sm-11 col-10">
                                                                                <input class="form-control w-100"
                                                                                    formControlName="email" type="text"
                                                                                    placeholder="Email CC {{i + 1}}">
                                                                            </div>
                                                                            <div class="col-sm-1 col-2 three">
                                                                                <i class="fa fa-minus"
                                                                                    id="removeEmail{{i}}"
                                                                                    (click)="removeEmail(i)"
                                                                                    *ngIf="i !== emailCCType.controls.length - 1"></i>
                                                                                <i class="fa fa-plus" id="addEmails"
                                                                                    (click)="addEmail(emailCCType?.controls, i)"
                                                                                    *ngIf="i === emailCCType.controls.length - 1"></i>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="data?.get('email')?.touched && data?.get('email')?.errors">
                                                                                <span class="p-error"
                                                                                    *ngIf="data?.get('email')?.hasError('email')">
                                                                                    Please enter a valid email
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 mb-3">
                                                                        <input class="form-control w-100"
                                                                            formControlName="proposal" type="text"
                                                                            placeholder="Subject">
                                                                        <div
                                                                            *ngIf="orderPreview?.get('proposal')?.touched && orderPreview?.get('proposal')?.errors">
                                                                            <span class="p-error"
                                                                                *ngIf="orderPreview?.get('proposal')?.hasError('required')">
                                                                                Subject is required
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <quill-editor [modules]="moduleConfig"
                                                                [(ngModel)]="emailContent">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="sms" role="tabpanel"
                                                        aria-labelledby="profile-tab">
                                                        <div class="previewStyles">
                                                            <div class="col-lg-12 mb-3" [formGroup]="orderPreview">
                                                                <input class="form-control w-100"
                                                                    formControlName="smsSubject" type="text"
                                                                    placeholder="Subject">
                                                                <div
                                                                    *ngIf="orderPreview?.get('smsSubject')?.touched && orderPreview?.get('smsSubject')?.errors">
                                                                    <span class="p-error"
                                                                        *ngIf="orderPreview?.get('smsSubject')?.hasError('required')">
                                                                        Subject is required
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12" [formGroup]="orderPreview">
                                                                <insurance-text-area rows="8" inputId="smsPreview"
                                                                    label="SMS Preview" formControlName="smsMessage">
                                                                </insurance-text-area>
                                                                <div
                                                                    *ngIf="orderPreview?.get('smsMessage')?.touched && orderPreview?.get('smsMessage')?.errors">
                                                                    <span class="p-error"
                                                                        *ngIf="orderPreview?.get('smsMessage')?.hasError('required')">
                                                                        SMS Content is required
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 pe-none" [formGroup]="orderPreview">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-12 mb-3">
                                            <div class="logoImgStyle float-end">
                                                <img src="assets/insurance.png">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input [readOnly]="true" inputId="firstName" label="firstName"
                                                formControlName="firstName">
                                            </insurance-input>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input [readOnly]="true" inputId="lastName" label="lastName"
                                                formControlName="lastName">
                                            </insurance-input>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input [readOnly]="true" inputId="email" label="email"
                                                formControlName="email">
                                            </insurance-input>
                                        </div>
                                        <div class="col-lg-6 col-md-6" formArrayName="driverPhone"
                                            *ngFor="let data of driverPhone.controls;let i = index">
                                            <div class="row" [formGroupName]="i">
                                                <div class="col-lg-12 mb-3">
                                                    <insurance-input-mask inputId="number"
                                                        label="Driver Phone {{i + 1}}" formControlName="number">
                                                    </insurance-input-mask>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input-date [readOnly]="true" inputId="policyStartDate"
                                                label="Effective Date" formControlName="policyStartDate">
                                            </insurance-input-date>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input [readOnly]="true" inputId="paymentMethod"
                                                label="Payment Method" formControlName="paymentMethod">
                                            </insurance-input>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input-number [readOnly]="true" inputId="paymentMode"
                                                label="Payment Mode" formControlName="paymentMode">
                                            </insurance-input-number>
                                        </div>
                                        <div class="col-lg-6 col-md-6 mb-3">
                                            <insurance-input-number [readOnly]="true" inputId="amount" label="amount"
                                                formControlName="amount">
                                            </insurance-input-number>
                                        </div>
                                        <div class="col-lg-12 mb-3 text-center">
                                            <insurance-button btnWidth="150" label="Pay" [disabled]="true">
                                            </insurance-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 text-center">
                            <div class="row">
                                <div class="col">
                                    <insurance-button class="float-md-start mb-3" btnWidth="150" label="Previous"
                                        (onClick)="action.emit()">
                                    </insurance-button>
                                </div>
                                <div class="col" [ngClass]="{'tooltipTest': reviewConfirmation.includes(false)}">
                                    <insurance-button class="float-md-end mb-3" btnWidth="150" label="Next"
                                        (onClick)="next();" [loading]="submitting"
                                        [disabled]="submitting || reviewConfirmation.includes(false)">
                                    </insurance-button>
                                    <span class="tooltiptext toolTipDashboard">
                                        Confirm each preview to enable Submit
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<insurance-popup header="Please Confirm" [displayModal]="showPopup" (closeAction)="showPopup = false">
    <h5>Would you like to send quote request to the client for approval ?</h5>
    <div class="col-lg-12 text-center mt-3">
        <div class="row">
            <div class="col-6">
                <insurance-button btnWidth="150" label="No" (onClick)="confirmWithoutMail()" [loading]="popupLoader.no"
                    [disabled]="popupLoader.no">
                </insurance-button>
            </div>
            <div class="col-6">
                <insurance-button btnWidth="150" label="Yes" (onClick)="confirmWithMail()" [loading]="popupLoader.yes"
                    [disabled]="popupLoader.yes">
                </insurance-button>
            </div>
        </div>
    </div>
</insurance-popup>