<main class="d-flex align-items-center mb-3">
    <div class="card formSection">
        <div class="card-header text-center">
            <h1 class="display-6">Order Page Preview</h1>
        </div>
        <div class="card-body">
            <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-12 mb-3">
                                            <div class="contentCard mb-3">
                                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                    <li class="nav-item" role="presentation"
                                                        (click)="reviewConfirmation[0] = true">
                                                        <button class="nav-link active" id="home-tab"
                                                            data-bs-toggle="tab" data-bs-target="#home" type="button"
                                                            role="tab" aria-controls="home" aria-selected="true">Quote
                                                            Preview</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation"
                                                        (click)="reviewConfirmation[1] = true">
                                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                            data-bs-target="#profile" type="button" role="tab"
                                                            aria-controls="profile" aria-selected="false">Email
                                                            Preview</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation"
                                                        (click)="reviewConfirmation[2] = true">
                                                        <button class="nav-link" id="sms-tab" data-bs-toggle="tab"
                                                            data-bs-target="#sms" type="button" role="tab"
                                                            aria-controls="sms" aria-selected="false">SMS
                                                            Preview</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation"
                                                        (click)="reviewConfirmation[3] = true">
                                                        <button class="nav-link" id="pdf-tab" data-bs-toggle="tab"
                                                            data-bs-target="#pdf" type="button" role="tab"
                                                            aria-controls="pdf" aria-selected="false">PDF
                                                            Preview</button>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="home" role="tabpanel"
                                                        aria-labelledby="home-tab">
                                                        <div class="previewStyles quoteList">
                                                            <ng-container
                                                                *ngIf="quoteData?.proposalList[0]?.quoteList?.length">
                                                                <app-quote-list
                                                                    [noOfQuotes]="quoteData?.proposalList[0]?.quoteList?.length"
                                                                    [quoteProposalData]="quoteData?.proposalList[0]"></app-quote-list>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="profile" role="tabpanel"
                                                        aria-labelledby="profile-tab">
                                                        <div class="previewStyles">
                                                            <div class="col-lg-12" [formGroup]="proposalPreview">
                                                                <div class="row">
                                                                    <div class="col-lg-6 col-md-6 mb-3"
                                                                        formArrayName="emailCC"
                                                                        *ngFor="let data of emailCCType.controls;let i = index">
                                                                        <div class="row" [formGroupName]="i">
                                                                            <div class="col-sm-11 col-10">
                                                                                <input class="form-control w-100"
                                                                                    formControlName="email" type="text"
                                                                                    placeholder="Email CC {{i + 1 || ''}}">
                                                                            </div>
                                                                            <div class="col-sm-1 col-2 three">
                                                                                <em class="fa fa-minus"
                                                                                    [ngClass]="{'px-2':emailCCType.controls.length > 1}"
                                                                                    id="removeEmail{{i}}"
                                                                                    (click)="removeEmail(i)"
                                                                                    *ngIf="emailCCType.controls.length > 1"></em>
                                                                                <em class="fa fa-plus" id="addEmails"
                                                                                    (click)="addEmail(emailCCType.controls, i)"
                                                                                    *ngIf="i === emailCCType.controls.length - 1"></em>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="data?.get('email')?.touched && data?.get('email')?.errors">
                                                                                <span class="p-error"
                                                                                    *ngIf="data?.get('email')?.hasError('email')">
                                                                                    Please enter a valid email
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 mb-3">
                                                                        <input class="form-control w-100"
                                                                            formControlName="emailSubject" type="text"
                                                                            placeholder="Subject">
                                                                        <div
                                                                            *ngIf="proposalPreview?.get('emailSubject')?.touched && proposalPreview?.get('proposal')?.errors">
                                                                            <span class="p-error"
                                                                                *ngIf="proposalPreview?.get('emailSubject')?.hasError('required')">
                                                                                Subject is required
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex flex-row mb-3" *ngIf="attachmentFileURL">
                                                                <div *ngIf="attachmentFileURL">
                                                                    <a class="btn btn-type-two px-2"
                                                                        href="{{attachmentFileURL}}" download
                                                                        type="button">
                                                                        <div><i class="fa fa-file-pdf-o"
                                                                                aria-hidden="true"></i> Marine
                                                                            Proposal.pdf</div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <quill-editor [modules]="moduleConfig"
                                                                [(ngModel)]="emailContent">
                                                            </quill-editor>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="sms" role="tabpanel"
                                                        aria-labelledby="profile-tab">
                                                        <div class="previewStyles smsView">
                                                            <div class="col-lg-12 mb-3" [formGroup]="proposalPreview">
                                                                <input class="form-control w-100"
                                                                    formControlName="smsSubject" type="text"
                                                                    placeholder="Subject">
                                                                <div
                                                                    *ngIf="proposalPreview?.get('smsSubject')?.touched && proposalPreview?.get('smsSubject')?.errors">
                                                                    <span class="p-error"
                                                                        *ngIf="proposalPreview?.get('smsSubject')?.hasError('required')">
                                                                        Subject is required
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12" [formGroup]="proposalPreview">
                                                                <insurance-text-area rows="8" inputId="smsPreview"
                                                                    label="SMS Preview" formControlName="smsMessage">
                                                                </insurance-text-area>
                                                                <div
                                                                    *ngIf="proposalPreview?.get('smsMessage')?.touched && proposalPreview?.get('smsMessage')?.errors">
                                                                    <span class="p-error"
                                                                        *ngIf="proposalPreview?.get('smsMessage')?.hasError('required')">
                                                                        SMS Content is required
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="pdf" role="tabpanel"
                                                        aria-labelledby="pdf-tab">
                                                        <div id="iframeContainer" class="pdfStyles"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 text-center">
                            <div class="row">
                                <div class="col">
                                    <insurance-button class="float-md-start mb-3" btnWidth="200"
                                        label="Edit Proposal Quote" [disabled]="loadingStatus" (onClick)="back()">
                                    </insurance-button>
                                </div>
                                <div class="col" [ngClass]="{'tooltipTest': reviewConfirmation.includes(false)}">
                                    <insurance-button class="float-md-end mb-3" btnWidth="150" label="Submit"
                                        (onClick)="next()" [loading]="loadingStatus"
                                        [disabled]="loadingStatus || reviewConfirmation.includes(false)">
                                    </insurance-button>
                                    <span class="tooltiptext toolTipDashboard">
                                        Confirm each preview to enable Submit
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<insurance-popup header="Please Confirm" [displayModal]="showPopup" (closeAction)="showPopup = false">
    <h5>Would you like to send quote request to the client for approval ?</h5>
    <div class="col-lg-12 text-center mt-3">
        <div class="row">
            <div class="col-6">
                <insurance-button btnWidth="150" label="No" (onClick)="confirmWithoutMail()" [loading]="popupLoader.no"
                    [disabled]="popupLoader.no">
                </insurance-button>
            </div>
            <div class="col-6">
                <insurance-button btnWidth="150" label="Yes" (onClick)="confirmWithMail()" [loading]="popupLoader.yes"
                    [disabled]="popupLoader.yes">
                </insurance-button>
            </div>
        </div>
    </div>
</insurance-popup>