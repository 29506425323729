import { DatePipe } from '@angular/common';
import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-setup',
  templateUrl: './order-setup.component.html',
  styleUrls: ['./order-setup.component.css'],
})
export class OrderSetupComponent implements OnInit {
  productDetail = this.fb.group({
    customer: this.fb.group({
      firstName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],
      ],
      lastName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
    }),
    address: this.fb.group({
      streetNumber: ['', [Validators.required]],
      streetName: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: [
        '', [Validators.required]
      ],
      country: [
        '', [Validators.required]
      ],
      zip: ['', [Validators.required]],
      latitude: [0],
      longitude: [0],
      address: ''
    }),
    // driverPhone: this.fb.array([[this.newDriverNumber()], [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)]]),
    driverPhone: this.fb.array([this.newDriverNumber()]),
    insuranceType: ['Auto'],
    proposal: '',
    packageDoc: this.fb.group({
      url: [''],
      name: [''],
      size: [''],
    }),
    agent: this.fb.group({
      firstName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],
      ],
      lastName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],
      ],
      number: [
        '(727) 498-5551',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
    }),
    policyStartDate: ['', [Validators.required]],
    updatedPolicyEffectiveDate: [''],
    carrier: ['', [Validators.required]],
    quoteType: ['Automated'],
    quoteIdType: ['Auto_Proposal']
  });
  startDate = new Date();
  environmentURL = environment.autoPortalURL;
  merchantId = '';
  campaignId = '';
  carrierValue = '';
  update = false;
  carrierSelected = false;
  targetOptionList = [
    {
      name: 'Auto',
      value: 'Auto',
      selected: false,
    },
    {
      name: 'Golf Cart',
      value: 'Golf Cart',
      selected: false,
    },
    {
      name: 'Motorcycle',
      value: 'Motorcycle',
      selected: false,
    },
    {
      name: 'Boat',
      value: 'Boat',
      selected: false,
    },
    {
      name: 'Umbrella',
      value: 'Umbrella',
      selected: false,
    },
    {
      name: 'House Owner',
      value: 'House Owner',
      selected: false,
    },
  ];
  carrierList = [
    {
      name: 'Mercury',
      value: 'Mercury',
      selected: false,
    },
    // {
    //   name: 'Metlife',
    //   value: 'Metlife',
    //   selected: false,
    // },
    {
      name: 'Progressive',
      value: 'Progressive',
      selected: false,
    },
    {
      name: 'Safeco',
      value: 'Safeco',
      selected: false,
    },
    {
      name: 'Vault',
      value: 'Vault',
      selected: false,
    },
    {
      name: 'Bristol West',
      value: 'Bristol West',
      selected: false,
    },
    {
      name: 'Hagerty',
      value: 'Hagerty',
      selected: false,
    },
    {
      name: 'Travelers',
      value: 'Travelers',
      selected: false,
    },
  ];
  parsedTableDatas: any[] = [];
  parsedTable: any[] = [];
  submitting = false;
  @Output() public getProductData = new EventEmitter<string>();
  agentList = [
    // {
    //   emailAddress: "dev@hhinsgroup.com",
    //   firstName: "Jake",
    //   lastName: "Holehouse",
    //   name: 'Jake Holehouse',
    //   phoneNumber: "7274985551",
    // },
    // {
    //   emailAddress: "dev@hhinsgroup.com",
    //   firstName: "Landon",
    //   lastName: "Cassidy",
    //   name: 'Landon Cassidy',
    //   phoneNumber: "7274985551",
    // },
    // {
    //   emailAddress: "scd@hhinsgroup.com",
    //   firstName: "Sean",
    //   lastName: "DeGraw",
    //   name: 'Sean DeGraw',
    //   phoneNumber: "7274985551",
    // }
  ]
  policyEffectiveDateUpdate = false;
  popupMessage = {
    question: 'Would you like to change the carrier?',
    note: 'Note: Carrier changes require confirmation from the customer'
  };
  agentAlert = {
    show: false,
    yesLoader: false,
    noLoader: false,
    value: '',
    field: ''
  }

  constructor(
    public fb: FormBuilder,
    private zone: NgZone,
    public activatedRoute: ActivatedRoute,
    public service: WizzipayService,
    private datepipe: DatePipe
  ) {
    this.getAgentList();
  }

  get customer(): FormGroup {
    return this.productDetail.get('customer') as FormGroup;
  }

  get driverPhone(): FormArray {
    return this.productDetail.get('driverPhone') as FormArray;
  }

  get packageDoc(): FormGroup {
    return this.productDetail.get('packageDoc') as FormGroup;
  }

  get agent(): FormGroup {
    return this.productDetail.get('agent') as FormGroup;
  }

  get address(): FormGroup {
    return this.productDetail?.get('address') as FormGroup;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      this.campaignId = params['campaignId'];
      if (this.merchantId && this.campaignId) {
        this.getPreviewData();
      }
    });
  }

  getAgentData() {
    this.service.getAgentList().subscribe({
      next: (agentRes) => {
        for (const agent of agentRes?.data) {
          agent['name'] = `${agent?.firstName} ${agent?.lastName}`;
        }
        this.agentList = agentRes?.data;
      },
      error: () => { }
    })
  }

  getAgentList() {
    this.service.getAgentList().subscribe({
      next: (agentList) => this.agentList = agentList?.agents,
      error: () => this.service.snackbar('Error')
    })
  }

  selectedAgent(agentName: any) {
    const agent: any = this.agentList?.filter((x: any) => x?.fullName === agentName)?.[0];
    agent['number'] = agent?.phoneNumber
      ? agent?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
    agent['email'] = agent?.emailAddress || '';
    this.agent.patchValue(agent);
  }

  selectMethod(val: any) {
    if (this.quoteData?.carrier && this.quoteData?.carrier !== val) {
      // this.popupMessage.question = 'Would you like to change the carrier?';
      // this.popupMessage.note = 'Note: Carrier changes require confirmation from the customer';
      this.popupMessage.question = 'Would you like to change the carrier?';
      this.popupMessage.note = 'Note: Carrier changes require confirmation from the customer';
      this.agentAlert.value = val;
      this.agentAlert.field = 'carrier';
      this.agentAlert.show = true;
      return;
    }
    this.carrierValue = val;
    val == null
      ? (this.carrierSelected = false)
      : (this.carrierSelected = true);
  }

  alertNo() {
    if (this.agentAlert.field === 'carrier') {
      this.productDetail.get('carrier')?.patchValue(this.quoteData?.carrier);
      this.carrierValue = this.quoteData?.carrier;
      this.quoteData?.carrier == null
        ? (this.carrierSelected = false)
        : (this.carrierSelected = true);
    } else {
      window.location.reload();
    }
    this.agentAlert.field = this.agentAlert.value = '';
    this.agentAlert.show = this.agentAlert.noLoader = false;
  }

  alertYes() {
    this.agentAlert.yesLoader = true;
    if (this.agentAlert.field === 'carrier') {
      this.carrierValue = this.agentAlert.value;
      this.agentAlert.value == null
        ? (this.carrierSelected = false)
        : (this.carrierSelected = true);
      this.productDetail.get('carrier')?.patchValue(this.agentAlert.value)
    }
    this.saveData();
  }

  saveData() {
    this.service
      .updateOrder({
        carrier: this.productDetail.get('carrier')?.value, paymentDetails: {
          selectedPackage: '',
          selectedQuoteAmount: '',
          paymentMode: this.quoteData.paymentDetails.paymentMode,
          paymentMethod: this.quoteData.paymentDetails.paymentMethod,
        }
      }, this.merchantId, this.campaignId)
      .subscribe(
        () => {
          if (this.agentAlert.field === 'package') {
            this.submit(false);
          } else {
            this.agentAlert.show = this.agentAlert.yesLoader = false;
          }
          this.agentAlert.field = this.agentAlert.value = '';
        },
        (err) => {
          this.agentAlert.yesLoader = false;
          this.service.snackbar(err.message);
        }
      );
  }

  addDriver(control: any, index: number) {
    control[index].get('number').markAllAsTouched();
    if (control[index].get('number').invalid) {
      this.service.snackbar('Please fill this number to add more');
      return;
    }
    const array = this.productDetail.get('driverPhone') as FormArray;
    array.push(this.newDriverNumber());
  }

  removeDriver(i: number) {
    const array = this.productDetail.get('driverPhone') as FormArray;
    array.removeAt(i);
  }

  newDriverNumber(): FormGroup {
    return this.fb.group({
      number: [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
    });
  }

  setAddress(addrObj: any) {
    this.address?.get('address')?.patchValue(addrObj.address);
    this.zone.run(() => {
      this.addressMaptoForm(addrObj);
    });
  }

  async addressMaptoForm(address: any) {
    this.address?.get('streetNumber')?.patchValue(address?.street_number);
    this.address?.get('city')?.patchValue(address?.city);
    address?.state == 'Florida'
      ? this.address?.get('state')?.patchValue('FL')
      : this.address?.get('state')?.patchValue(address?.state);
    this.address?.get('country')?.patchValue(address?.country);
    this.address?.get('zip')?.patchValue(address?.ZIP_code);
    this.address?.get('streetName')?.patchValue(address?.street_address);
  }

  quoteData: any;
  getPreviewData() {
    this.service.getdata(this.merchantId, this.campaignId).subscribe((res) => {
      this.service
        .getQuoteSelectionData(this.campaignId)
        .subscribe((selectionRes) => {
          // this.productDetail.controls['insuranceType'].disable();
          if (res.data) {
            this.quoteData = { ...res?.data, ...selectionRes?.data };
            if (this.quoteData?.effectiveDateUpdateRequest) {
              this.policyEffectiveDateUpdate = this.quoteData?.effectiveDateUpdateRequest;
              res.data.updatedPolicyEffectiveDate = this.policyEffectiveDateUpdate ? this.quoteData?.effectiveDateRequestList?.at(-1)?.requestedEffectiveDate : '';
            }
            res?.data?.commonCustomerId && this.getCustomer(res?.data?.commonCustomerId);
            if (res?.data?.policyStartDate) {
              res.data['policyStartDate'] = this.datepipe.transform(
                res?.data?.policyStartDate,
                'MM/dd/yyyy'
              );
            }
            this.update = true;
            this.productDetail.patchValue(res.data);
            if (res?.data?.driverPhone?.length > 0) {
              this.productDetail.setControl(
                'driverPhone',
                this.resDriverNumber(res?.data?.driverPhone)
              );
            }
            if (res.data.package.length > 0) {
              // QUOTE TABLE DATAS START
              this.parsedTableDatas = [];
              this.parsedTableDatas = res.data.package;
              // const payload: { [key: string]: any } = {};
              // var headers: string[] = [];
              // const colPackage: string[] = []; //Table Package Name
              // const colValues: string[][] = [];
              // res.data.package.forEach(
              //   (sub: { [key: string]: string }, index: number) => {
              //     const rowValues: string[] = [];
              //     for (const key in res.data.package[0]) {
              //       if (key == 'name') {
              //         headers.push(sub.name);
              //       } else if (index == 0) {
              //         colPackage.push(key);
              //       }
              //       if (key != 'name') {
              //         rowValues.push(sub[key]);
              //       }
              //     }
              //     colValues.push(rowValues);
              //   }
              // );
              // payload['headers'] = headers;
              // payload['colPackage'] = colPackage;
              // payload['colValues'] = colValues;
              // this.parsedTableDatas.push(payload);
              // let testObj:any = {
              //   head:headers
              // };
              // testObj.head = headers
              // console.table(this.parsedTableDatas)
              // console.log(testObj)
              // // QUOTE TABLE DATAS END
            }
          }
        });
    });
  }

  getCustomer(customerId: string) {
    this.service
      .getCustomerData(customerId)
      .subscribe({
        next: (customerRes) => {
          this.address.patchValue(customerRes?.address)
        },
        error: () => {
          this.submitting = false;
          this.service.snackbar('Error');
        }
      });
  }

  resDriverNumber(driverNum: Array<{ [key: string]: string }>): FormArray {
    const formArray = new FormArray([]);
    driverNum.forEach((element) => {
      formArray.push(
        this.fb.group({
          number: [
            element.number,
            [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
          ],
        })
      );
    });
    return formArray;
  }

  getTable() {
    if (this.parsedTable.length > 0) {
      setTimeout(() => {
        this.formatTable();
      }, 0);
    }
  }

  getPDFRes(value: any) {
    console.log({ value })
    if (value != 'uploading' && value?.response) {
      const cusName = value?.response?.customerName.split(' ');
      this.customer.patchValue({
        firstName: cusName[0] || '',
        lastName: cusName[1] || '',
        email: value?.response?.customerEmail,
      });
      const agName = value?.response?.agentName.split(' ');
      this.agent.patchValue({
        firstName: agName[0] || '',
        lastName: agName[1] || '',
        email: value?.response?.email,
        number: value?.response?.phone,
      });
      this.packageDoc.patchValue({
        // name: value?.fileName,
        size: value?.fileSize,
      });
      if (value?.response?.phone) {
        const driverPhone = [];
        const payload = {
          number: value?.response?.customerPhone,
        };
        driverPhone.push(payload);
        this.productDetail.setControl(
          'driverPhone',
          this.resDriverNumber(driverPhone)
        );
      }
      this.parsedTable = value?.response?.proposals;
      console.log('parseee out ', this.productDetail.getRawValue().insuranceType)
      if (this.productDetail.getRawValue().insuranceType) {
        console.log('parseee', this.parsedTable)
        // this.productDetail.controls['insuranceType'].disable();
        this.formatTable();
      }
    }
  }

  formatTable() {
    this.parsedTableDatas = [];
    this.parsedTable?.forEach((element) => {
      if (
        element.proposal
          .toLowerCase()
          .includes(
            'auto'
          ) //To check if insurance type exists in proposal name
      ) {
        this.productDetail.patchValue({ proposal: element.proposal });
        // QUOTE TABLE DATAS START
        const payload: { [key: string]: any } = {};
        const colPackage: string[] = []; //Table Package Name
        const colValues: string[][] = []; //Package Column Values
        payload['headers'] = element?.packages;
        //Getting Carrier Value Start
        // element[element?.packages[0]].forEach(
        //   (keyData: { key: string; value: string }) => {
        //     if (keyData.key == 'Carrier') {
        //       this.productDetail.patchValue({ carrier: keyData.value }); //Map Carrier Value
        //       if (this.productDetail.value.carrier) {
        //         element[element?.packages[0]].pop(); //Remove Carrier Value after patching
        //       }
        //     }
        //   }
        // );
        //Getting Carrier Value End
        element?.packages.forEach((header: string, index: number) => {
          const rowValues: string[] = [];
          element[header].forEach((data: { key: string; value: string }) => {
            if (index === 0) {
              colPackage.push(data.key);
            }
            payload['colPackage'] = colPackage;
            rowValues.push(data.value);
          });
          colValues.push(rowValues);
          payload['colValues'] = colValues;
        });
        console.log('parseee format payload', payload)
        this.parsedTableDatas.push(payload);
        // QUOTE TABLE DATAS END
      }
    });
    console.log('parseee format', this.parsedTable, this.parsedTableDatas)
  }

  getSecondRes(value: { data: { url: string, fileName: string } }) {
    this.packageDoc.patchValue({
      url: value?.data?.url,
      name: value?.data?.fileName,
    });
  }

  deletedImgRes() {
    this.parsedTableDatas = [];
    const control = <FormArray>this.productDetail.controls['driverPhone'];
    control.removeAt(0);
    this.productDetail.reset({
      insuranceType: 'Auto',
      quoteType: 'Automated',
      quoteIdType: 'Auto_Proposal',
      carrier: this.quoteData?.carrier,
      policyStartDate: this.quoteData?.policyStartDate,
      updatedPolicyEffectiveDate: this.quoteData?.effectiveDateUpdateRequest
        ? this.quoteData?.effectiveDateRequestList?.at(-1)?.requestedEffectiveDate
        : ''
    });
    control.push(this.newDriverNumber());
    // this.productDetail.controls['insuranceType'].enable();
  }

  returnZero() {
    return 0;
  }

  trackByFn(item: number) {
    return item;
  }


  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  saveDocument(customerId: string, campaignResponse: any, status: string) {
    this.service
      .getFiles(this.packageDoc.value?.name)
      .subscribe({
        next: (fileRes) => {
          const imageBlob = this.dataURItoBlob(fileRes?.data);
          const imageFile = new File([imageBlob], this.packageDoc.value?.name, { type: 'application/pdf' });
          const formData = new FormData();
          formData.append('file', imageFile);
          this.service
            .uploadFile(customerId, campaignResponse?.quoteId, formData, this.packageDoc.value?.name)
            .subscribe({
              next: () => {
                let datas: any = campaignResponse;
                datas['paymentDetails'] = this.quoteData?.paymentDetails;
                this.submitting = false;
                this.agentAlert.show = this.agentAlert.yesLoader = false;
                this.service.snackbar(status);
                this.getProductData.emit(datas);
              },
              error: () => {
                this.submitting = false;
                this.agentAlert.yesLoader = false;
                this.service.snackbar('Error');
              },
            });
        },
        error: () => {
          this.submitting = false;
          this.agentAlert.yesLoader = false;
          this.service.snackbar('Error');
        },
      });
  }

  submit(check: boolean) {
    this.productDetail.get('carrier')?.markAsTouched();
    if (this.productDetail.get('carrier')?.invalid) {
      this.service.snackbar('Please Select the Carrier Name');
      return;
    }
    this.productDetail.markAllAsTouched();
    if (this.parsedTableDatas.length == 0) {
      this.service.snackbar('Please upload Insurance Document');
      return;
    }
    if (this.productDetail.invalid) {
      this.service.snackbar('Please fill all the fields');
      return;
    }
    if (check && this.quoteData?.paymentDetails?.selectedPackage) {
      if (this.quoteData?.paymentDetails?.selectedPackage) {
        if (!this.parsedTableDatas?.[0]?.headers?.includes(this.quoteData?.paymentDetails?.selectedPackage)) {
          // this.popupMessage.question = 'Would you like to change the carrier?';
          // this.popupMessage.note = 'Note: Carrier changes require confirmation from the customer';
          this.popupMessage.question = 'Would you like to change the package ?';
          this.popupMessage.note = 'Note: Uploaded file has a different package name. Package changes require confirmation from the customer';
          this.agentAlert.field = 'package';
          this.agentAlert.show = true;
          return;
        }
      }
    }
    this.submitting = true;
    let payload = this.productDetail.getRawValue();
    payload['package'] = this.parsedTableDatas;
    const data = { ...payload }
    delete data.address;
    //payload['package'] = formatePackages;
    const customerPayload = {
      firstName: payload.customer.firstName,
      lastName: payload.customer.lastName,
      middleName: '',
      emailAddress: payload.customer.email,
      phoneNumber: payload.driverPhone?.at(0)?.number,
      dob: '',
      namePrefix: '',
      nameSuffix: '',
      gender: '',
      maritalStatus: '',
      educationLevel: '',
      industry: '',
      occupation: '',
      ssn: '',
      address: payload?.address,
      priorAddress: {
        streetNumber: '',
        streetName: '',
        city: '',
        country: '',
        zip: '',
        latitude: '',
        longitude: '',
        address: '',
        state: ''
      },
      dependents: [],
      emailAddressCC: [],
    }
    const agentPayload = [
      {
        firstName: payload.agent.firstName,
        lastName: payload.agent.lastName,
        emailAddress: payload.agent.email,
        phoneNumber: payload.agent.number,
        companyName: '',
        agentType: 'HH Agent'
      }
    ]
    this.service
      .saveCustomerData(customerPayload)
      .subscribe({
        next: (customerRes) => {
          this.service
            .saveAgentData({ agent: agentPayload })
            .subscribe({
              next: (agentRes) => {
                payload['commonCustomerId'] = customerRes?.customerId;
                payload['agentId'] = agentRes?.agentId;
                // payload['quoteType'] = 'Automated';
                if (this.campaignId) {
                  if (this.quoteData?.effectiveDateUpdateRequest) {
                    payload['effectiveDateUpdateRequest'] = this.quoteData?.effectiveDateUpdateRequest;
                    payload['effectiveDateRequestList'] = this.quoteData?.effectiveDateRequestList;
                    payload['effectiveDateRequestList'].push({
                      currentEffectiveDate: `${new Date(payload?.policyStartDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
                      requestedEffectiveDate: `${new Date(payload?.updatedPolicyEffectiveDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
                      updatedBy: 'Agent'
                    })
                  }
                  this.service
                    .updateOrder(payload, this.merchantId, this.campaignId)
                    .subscribe(
                      (res) => {
                        this.saveDocument(customerRes?.customerId, res?.data, 'Offer Updated');
                      },
                      (err) => {
                        this.submitting = false;
                        this.agentAlert.yesLoader = false;
                        this.service.snackbar(err.message);
                      }
                    );
                } else {
                  this.service.sumbitOrder(payload, this.merchantId).subscribe(
                    (res) => {
                      this.campaignId = res.data._id;
                      this.saveDocument(customerRes?.customerId, res?.data, 'Offer Created')
                    },
                    (err) => {
                      this.submitting = false;
                      this.agentAlert.yesLoader = false;
                      this.service.snackbar(err.message);
                    }
                  );
                }
              },
              error: () => {
                this.submitting = false;
                this.agentAlert.yesLoader = false;
                this.service.snackbar('Error');
              }
            });
        },
        error: () => {
          this.submitting = false;
          this.agentAlert.yesLoader = false;
          this.service.snackbar('Error');
        }
      });
  }
}


// Submit function 

// Updating new parsed data format start
// const formatePackages: { [key: string]: string }[] = [];
// this.parsedTableDatas.forEach((element, index: number) => {
//   if (index === 0) {
//     element.headers.forEach((head: string, headindex: number) => {
//       const obj: { [key: string]: string } = {};
//       obj['name'] = head;
//       element.colPackage.forEach((pack: string, packindex: number) => {
//         element.colValues[headindex].forEach(
//           (colValue: string, valueindex: number) => {
//             if (packindex === valueindex) {
//               obj[pack] = colValue;
//               if(obj[pack]){
//                 console.log('yes')
//               }
//               console.log('inside object', obj)
//             }
//           }
//         );
//       });
//       console.log('object', obj)
//       formatePackages.push(obj);
//     });
//   }
// });
// Updating new parsed data format end