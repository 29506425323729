import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  hide = true;
  registration: FormGroup = this.fb.group({
    firstName: [
      '',
      [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
    ],
    lastName: [
      '',
      [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)],
    ],
    password: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    code: ['', [Validators.required]],
    phone: ['', [Validators.required, Validators.pattern('[0-9]{10}$')]],
  });
  submitting = false;

  constructor(
    public fb: FormBuilder,
    public wizzipayServiceAPI: WizzipayService,
    public router: Router
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('email') && localStorage.getItem('password')) {
      this.registration.patchValue({
        email: localStorage.getItem('email'),
        password: localStorage.getItem('password'),
      });
    }
  }

  submit() {
    this.submitting = true;
    if (this.registration.invalid) {
      this.registration.markAllAsTouched();
      this.submitting = false;
      return;
    }
    const payload = {
      firstName: this.registration.value.firstName,
      lastName: this.registration.value.lastName,
      code: '+' + this.registration.value.code,
      password: this.registration.value.password,
      phone: this.registration.value.phone,
      email: this.registration.value.email,
    };
    this.wizzipayServiceAPI.register(payload).subscribe(
      (res) => {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        if (res.status == true) {
          window.location.href = res.data.url;
          this.submitting = false;
          this.registration.reset();
        }
      },
      (err) => {
        this.wizzipayServiceAPI.snackbar(err.error.message);
        this.submitting = false;
      }
    );
  }

  routeBack() {
    this.router.navigate(['/login']);
  }
}
