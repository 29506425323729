<form [formGroup]="quoteProposalData">
    <div class="pb-4">
        <div class="quoteTable">
            <div class="d-flex justify-content-end py-3">
                <div class="px-2 cp">
                    <a class="addQuote"
                        (click)="addQuote('onClick', getQuoteProposalData.value?.at(0)?.vehiclesCovered)"
                        *ngIf="getQuoteProposalData?.value?.length < 5"><i class="fa fa-plus faIcon"
                            id="addEmbryoDetails"></i> Add New
                        Quote</a>
                </div>
            </div>
            <table class="table table-bordered table-wid mb-5" formArrayName="quoteList">
                <tbody id="tableData">
                    <tr>
                        <td class="tableHeaders">Package</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-8 px-2 cp" *ngIf="i > 0">
                                            <a class="addQuote" (click)="cloneQuote(i)"><i class="cp fa fa-clone px-2"
                                                    id="addEmbryoDetails"></i> Clone
                                                Quote - 1</a>
                                        </div>
                                        <div class="text-end py-2" [ngClass]="i > 0 ? 'col-4' : 'col-12'">
                                            <i class="fa fa-trash-o px-2 faIcon cp" id="removeEmbryoDetails{{i}}"
                                                (click)="removeQuote(i)"></i>
                                        </div>
                                    </div>
                                </div>
                                <insurance-select customClass="jjStyles" selectId="educationLevel" label="Package"
                                    [dataList]="packageListData" dataLabel="name" dataValue="value"
                                    formControlName="packageName" dataId="id" (onClick)="testPackage($event, i);$event === 'Other'
                                ? this.setValidation(['otherPackageName'], i)
                                : this.removeValidation(['otherPackageName'], i);">
                                </insurance-select>
                                <ng-container
                                    *ngIf="data?.get('packageName')?.touched && data?.get('packageName')?.errors">
                                    <span class="p-error" *ngIf="data?.get('packageName')?.hasError('required')">
                                        Package Name is required
                                    </span>
                                </ng-container>
                                <div class="twoFieldTable" *ngIf="data.value.packageName == 'Other'">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="40"
                                        label="Other Package Name" formControlName="otherPackageName"
                                        (change)="testPackage('Other', i)">
                                    </insurance-input>
                                </div>
                                <span class="p-error" *ngIf="data?.get('packageName')?.hasError('incorrect')">
                                    Package Name already exists!
                                </span>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Vehicles Covered</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container formArrayName="vehiclesCovered">
                                    <div class="col-12">
                                        <div class="row py-3"
                                            *ngFor="let value of getVehicle(i)?.controls; let j = index">
                                            <div
                                                [ngClass]="(j===0) || (!(j === getVehicle(i).value?.length - 1) && (getVehicle(i).value?.length != 5)) || (getVehicle(i).value?.length === 5) ? 'col-10' : 'col-8'">
                                                <insurance-input customClass="jjStyles" inputId="premium1{{j}}"
                                                    maxlength="40" label="Vehicles Covered {{j + 1}}"
                                                    formControlName="{{j}}"
                                                    (change)="proposalLabelChange(data.value?.vehiclesCovered, i)">
                                                </insurance-input>
                                            </div>
                                            <div class="cp col-2 d-flex align-items-center"
                                                *ngIf="getVehicle(i)?.value?.length > 1">
                                                <i class="fa fa-trash-o px-2 faIcon" id="removeEmbryoDetails{{i}}"
                                                    (click)="deleteVehicle(i, j)"></i>
                                            </div>
                                            <div class="col-2 cp d-flex align-items-center"
                                                (click)="addVehiclesCovered(i)"
                                                *ngIf="(j === getVehicle(i)?.value?.length - 1) && (getVehicle(i)?.value?.length != 5)">
                                                <span><i class="fa fa-plus" aria-hidden="true"></i></span>
                                            </div>
                                            <ng-container *ngIf="value?.touched && value?.errors">
                                                <span class="p-error" *ngIf="value?.hasError('required')">
                                                    Vehicle is required
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Carrier Name</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <insurance-select selectId="educationLevel" label="Carrier Name"
                                    [dataList]="rulesData.autoCarrierList" dataLabel="name" dataValue="value"
                                    formControlName="carrierName" dataId="id"
                                    (onClick)="getCarrierData(data.value, i, 'onClick')">
                                </insurance-select>
                                <ng-container
                                    *ngIf="data?.get('carrierName')?.touched && data?.get('carrierName')?.errors">
                                    <span class="p-error" *ngIf="data?.get('carrierName')?.hasError('required')">
                                        Carrier Name is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Policy Term</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <insurance-select customClass="jjStyles" inputId="premium1" label=""
                                    formControlName="policyTerm" [readOnly]="false" label="Policy Term"
                                    [dataList]="dropDownData[i]?.policyTerm" dataLabel="name" dataValue="value">
                                </insurance-select>
                                <ng-container
                                    *ngIf="data?.get('policyTerm')?.touched && data?.get('policyTerm')?.errors">
                                    <span class="p-error" *ngIf="data?.get('policyTerm')?.hasError('required')">
                                        Policy Term is required
                                    </span>
                                </ng-container>
                                <span *ngIf="data?.value?.carrierName == 'Ski Safe'">100 mile wind restriction & 7 day
                                    wind
                                    exclusion. No named storm coverage for first 7 days
                                    of policy term</span>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Enrolled in MercuryGO</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Mercury';else setNa">
                                    <insurance-select customClass="jjStyles" inputId="premium1" label=""
                                        formControlName="enrolledInMercuryGo" [readOnly]="false"
                                        label="Enrolled in MercuryGO" [dataList]="dropDownData[i]?.enrolledInMercuryGo"
                                        dataLabel="name" dataValue="value">
                                    </insurance-select>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('enrolledInMercuryGo')?.touched && data?.get('enrolledInMercuryGo')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('enrolledInMercuryGo')?.hasError('required')">
                                        Enrolled in MercuryGO is required
                                    </span>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Enrolled in Snapshot</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Progressive';else setNa">
                                    <insurance-select customClass="jjStyles" inputId="premium1" label=""
                                        formControlName="enrolledInSnapshot" [readOnly]="false"
                                        label="Enrolled in Snapshot" [dataList]="dropDownData[i]?.enrolledInSnapshot"
                                        dataLabel="name" dataValue="value">
                                    </insurance-select>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('enrolledInSnapshot')?.touched && data?.get('enrolledInSnapshot')?.errors">
                                    <span class="p-error" *ngIf="data?.get('enrolledInSnapshot')?.hasError('required')">
                                        Enrolled in Snapshot is required
                                    </span>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Enrolled in RightTrack</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Safeco';else setNa">
                                    <insurance-select customClass="jjStyles" inputId="premium1" label=""
                                        formControlName="enrolledInRightTrack" [readOnly]="false"
                                        label="Enrolled in RightTrack"
                                        [dataList]="dropDownData[i]?.enrolledInRightTrack" dataLabel="name"
                                        dataValue="value">
                                    </insurance-select>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('enrolledInRightTrack')?.touched && data?.get('enrolledInRightTrack')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('enrolledInRightTrack')?.hasError('required')">
                                        Enrolled in RightTrack is required
                                    </span>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Enrolled in IntelliDrive</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Travelers';else setNa">
                                    <insurance-select customClass="jjStyles" inputId="premium1" label=""
                                        formControlName="enrolledInIntelliDrive" [readOnly]="false"
                                        label="Enrolled in IntelliDrive"
                                        [dataList]="dropDownData[i]?.enrolledInIntelliDrive" dataLabel="name"
                                        dataValue="value">
                                    </insurance-select>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('enrolledInIntelliDrive')?.touched && data?.get('enrolledInIntelliDrive')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('enrolledInIntelliDrive')?.hasError('required')">
                                        Enrolled in IntelliDrive is required
                                    </span>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Full Pay Monthly Premium</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data?.value?.carrierName === 'Bristol West'||data?.value?.carrierName === 'Hagerty';else setNa ">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="Full Pay Monthly Premium" formControlName="fullPayMonthlyPremium"
                                        [readOnly]="false"
                                        (change)="setCurrency('fullPayMonthlyPremium', data.value?.fullPayMonthlyPremium, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('fullPayMonthlyPremium')?.touched && data?.get('fullPayMonthlyPremium')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('fullPayMonthlyPremium')?.hasError('required')">
                                        Full Pay Monthly Premium is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('fullPayMonthlyPremium')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Pay Plan Monthly Premium Total</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Bristol West';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="Pay Plan Monthly Premium Total"
                                        formControlName="payPlanMonthlyPremiumTotal" [readOnly]="false"
                                        (change)="setCurrency('payPlanMonthlyPremiumTotal', data.value?.payPlanMonthlyPremiumTotal, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('payPlanMonthlyPremiumTotal')?.touched && data?.get('payPlanMonthlyPremiumTotal')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('payPlanMonthlyPremiumTotal')?.hasError('required')">
                                        Pay Plan Monthly Premium Total is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('payPlanMonthlyPremiumTotal')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Pay Plan Monthly Premium Downpayment</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Bristol West';else setNa ">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="Pay Plan Monthly Premium Downpayment"
                                        formControlName="payPlanMonthlyPremiumDownpayment" [readOnly]="false"
                                        (change)="setCurrency('payPlanMonthlyPremiumDownpayment', data.value?.payPlanMonthlyPremiumDownpayment, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('payPlanMonthlyPremiumDownpayment')?.touched && data?.get('payPlanMonthlyPremiumDownpayment')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('payPlanMonthlyPremiumDownpayment')?.hasError('required')">
                                        Pay Plan Monthly Premium Downpayment is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('payPlanMonthlyPremiumDownpayment')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Pay Plan Monthly Premium Per Month </td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Bristol West';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="Pay Plan Monthly Premium Per Month"
                                        formControlName="payPlanMonthlyPremiumPerMonth" [readOnly]="false"
                                        (change)="setCurrency('payPlanMonthlyPremiumPerMonth', data.value?.payPlanMonthlyPremiumPerMonth, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('payPlanMonthlyPremiumPerMonth')?.touched && data?.get('payPlanMonthlyPremiumPerMonth')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('payPlanMonthlyPremiumPerMonth')?.hasError('required')">
                                        Pay Plan Monthly Premium Per Month is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('payPlanMonthlyPremiumPerMonth')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Paid in Full</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data?.value?.carrierName==='Mercury' || data?.value?.carrierName==='Metlife (Foremost)' || data?.value?.carrierName==='Progressive with Snapshot'|| data?.value?.carrierName==='Safeco' ||data?.value?.carrierName === 'Travelers'||data?.value?.carrierName==='Vault'||data?.value?.carrierName==='Progressive';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" label="Paid in Full"
                                        formControlName="paidInFull" maxlength="14"
                                        (change)="setCurrency('paidInFull', data.value?.paidInFull, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('paidInFull')?.touched && data?.get('paidInFull')?.errors">
                                    <span class="p-error" *ngIf="data?.get('paidInFull')?.hasError('required')">
                                        Paid in Full is required
                                    </span>
                                    <span class="p-error" *ngIf="data?.get('paidInFull')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">EFT Pay Plan Monthly Premium</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data?.value?.carrierName==='Mercury'|| data?.value?.carrierName==='Metlife (Foremost)' || data?.value?.carrierName==='Progressive with Snapshot'|| data?.value?.carrierName==='Safeco' || data?.value?.carrierName === 'Travelers'||data?.value?.carrierName==='Progressive';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="EFT Pay Plan Monthly Premium" formControlName="eftPayPlanMonthlyPremium"
                                        (change)="setCurrency('eftPayPlanMonthlyPremium', data.value?.eftPayPlanMonthlyPremium, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('eftPayPlanMonthlyPremium')?.touched && data?.get('eftPayPlanMonthlyPremium')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('eftPayPlanMonthlyPremium')?.hasError('required')">
                                        EFT Pay Plan Monthly Premium** is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('eftPayPlanMonthlyPremium')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Initial EFT Installment</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data?.value?.carrierName==='Mercury'|| data?.value?.carrierName==='Metlife (Foremost)'|| data?.value?.carrierName==='Progressive with Snapshot'|| data?.value?.carrierName==='Safeco'|| data?.value?.carrierName === 'Travelers'||data?.value?.carrierName==='Progressive';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="Initial EFT Installment" formControlName="initialEftInstallment"
                                        (change)="setCurrency('initialEftInstallment', data.value?.initialEftInstallment, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('initialEftInstallment')?.touched && data?.get('initialEftInstallment')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('initialEftInstallment')?.hasError('required')">
                                        Initial EFT Installment is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('initialEftInstallment')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>

                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">EFT Total Cost Premium</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data?.value?.carrierName==='Mercury'|| data?.value?.carrierName==='Metlife (Foremost)'|| data?.value?.carrierName==='Progressive with Snapshot'|| data?.value?.carrierName==='Safeco'||data?.value?.carrierName === 'Travelers'||data?.value?.carrierName==='Progressive';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="EFT Total Cost Premium" formControlName="eftTotalCostPremium"
                                        [readOnly]="false"
                                        (change)="setCurrency('eftTotalCostPremium', data.value?.eftTotalCostPremium, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('eftTotalCostPremium')?.touched && data?.get('eftTotalCostPremium')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('eftTotalCostPremium')?.hasError('required')">
                                        EFT Total Cost Premium is required
                                    </span>
                                    <span class="p-error" *ngIf="data?.get('eftTotalCostPremium')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">CC Pay Plan Monthly Premium</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data?.value?.carrierName==='Mercury' || data?.value?.carrierName==='Progressive with Snapshot'|| data?.value?.carrierName==='Safeco' || data?.value?.carrierName === 'Travelers'||data?.value?.carrierName==='Progressive';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-input customClass="jjStyles" inputId="premium1"
                                            label="CC Pay Plan Monthly Premium" maxlength="14"
                                            formControlName="ccPayPlanMonthlyPremium"
                                            (change)="setCurrency('ccPayPlanMonthlyPremium', data.value?.ccPayPlanMonthlyPremium, i)">
                                        </insurance-input>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('ccPayPlanMonthlyPremium')?.touched && data?.get('ccPayPlanMonthlyPremium')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('ccPayPlanMonthlyPremium')?.hasError('required')">
                                        CC Pay Plan Monthly Premium** is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('ccPayPlanMonthlyPremium')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Initial CC Installment</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data.value?.carrierName==='Mercury'|| data.value?.carrierName==='Progressive with Snapshot'|| data.value?.carrierName==='Safeco'|| data.value?.carrierName === 'Travelers'||data.value?.carrierName==='Progressive';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="Initial CC Installment" formControlName="initialCcInstallment"
                                        (change)="setCurrency('initialCcInstallment', data.value?.initialCcInstallment, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('initialCcInstallment')?.touched && data?.get('initialCcInstallment')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('initialCcInstallment')?.hasError('required')">
                                        Initial CC Installment is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('initialCcInstallment')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">CC Total Cost Premium</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="data?.value?.carrierName==='Mercury' || data?.value?.carrierName==='Progressive with Snapshot'|| data?.value?.carrierName==='Safeco' || data?.value?.carrierName === 'Travelers'||data?.value?.carrierName==='Progressive';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                            label="CC Total Cost Premium" formControlName="ccTotalCostPremium"
                                            (change)="setCurrency('ccTotalCostPremium', data.value?.ccTotalCostPremium, i)">
                                        </insurance-input>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('ccTotalCostPremium')?.touched && data?.get('ccTotalCostPremium')?.errors">
                                    <span class="p-error" *ngIf="data?.get('ccTotalCostPremium')?.hasError('required')">
                                        CC Total Cost Premium is required
                                    </span>
                                    <span class="p-error" *ngIf="data?.get('ccTotalCostPremium')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">2-Pay Plan**</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Vault';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" label="2-Pay Plan**"
                                        formControlName="twoPayPlan" maxlength="14"
                                        (change)="setCurrency('twoPayPlan', data.value?.twoPayPlan, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('twoPayPlan')?.touched && data?.get('twoPayPlan')?.errors">
                                    <span class="p-error" *ngIf="data?.get('twoPayPlan')?.hasError('required')">
                                        2-Pay Plan** is required
                                    </span>
                                    <span class="p-error" *ngIf="data?.get('twoPayPlan')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">2-Pay Total Cost Premium</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Vault';else setNa">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="2-Pay Total Cost Premium" formControlName="twoPayTotalCostPremium"
                                        (change)="setCurrency('twoPayTotalCostPremium', data.value?.twoPayTotalCostPremium, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('twoPayTotalCostPremium')?.touched && data?.get('twoPayTotalCostPremium')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('twoPayTotalCostPremium')?.hasError('required')">
                                        2-Pay Total Cost Premium is required
                                    </span>
                                    <span class="p-error"
                                        *ngIf="data?.get('twoPayTotalCostPremium')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">4-Pay Plan**</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Vault';else setNa ">
                                    <insurance-input customClass="jjStyles" inputId="premium1" label="4-Pay Plan**"
                                        formControlName="fourPayPlan" maxlength="14"
                                        (change)="setCurrency('fourPayPlan', data.value?.fourPayPlan, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('fourPayPlan')?.touched && data?.get('fourPayPlan')?.errors">
                                    <span class="p-error" *ngIf="data?.get('fourPayPlan')?.hasError('required')">
                                        4-Pay Plan** is required
                                    </span>
                                    <span class="p-error" *ngIf="data?.get('fourPayPlan')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">4 Pay Total Premium</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Vault';else setNa ">
                                    <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                        label="4 Pay Total Premium" formControlName="fourPayTotalPremium"
                                        (change)="setCurrency('fourPayTotalPremium', data.value?.fourPayTotalPremium, i)">
                                    </insurance-input>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('fourPayTotalPremium')?.touched && data?.get('fourPayTotalPremium')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('fourPayTotalPremium')?.hasError('required')">
                                        4 Pay Total Premium is required
                                    </span>
                                    <span class="p-error" *ngIf="data?.get('fourPayTotalPremium')?.hasError('pattern')">
                                        Please enter a valid value
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders tableMainHead">Essential Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td class="removeBorder">
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage A - Bodily Injury (per person/per accident)</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <div class="col-lg-12">
                                    <insurance-select customClass="jjStyles" inputId="premium1"
                                        label="Coverage A - Bodily Injury (per person/per accident)"
                                        formControlName="coverageABodilyInjuryPerPersonPerAccident"
                                        [dataList]="dropDownData[i]?.coverageABodilyInjuryPerPersonPerAccident"
                                        dataLabel="name" dataValue="value"
                                        (onClick)="coverageABodilyInjuryChange($event, i)">
                                    </insurance-select>
                                    <ng-container
                                        *ngIf="data?.get('coverageABodilyInjuryPerPersonPerAccident')?.touched && data?.get('coverageABodilyInjuryPerPersonPerAccident')?.errors">
                                        <span class="p-error"
                                            *ngIf="data?.get('coverageABodilyInjuryPerPersonPerAccident')?.hasError('required')">
                                            Coverage A - Bodily Injury is required
                                        </span>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage A - Property Damage per accident</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <div class="col-lg-12">
                                    <insurance-select customClass="jjStyles" inputId="premium1"
                                        label="Coverage A -  Property Damage per accident"
                                        formControlName="coverageAPropoertyDamagePerAccident"
                                        [dataList]="dropDownData[i]?.coverageAPropoertyDamagePerAccident"
                                        dataLabel="name" dataValue="value"
                                        (onClick)="setCurrrencyValidation($event,'coverageAPropoertyDamagePerAccidentCurrency',i)">
                                    </insurance-select>
                                    <ng-container
                                        *ngIf="data?.get('coverageAPropoertyDamagePerAccident')?.touched && data?.get('coverageAPropoertyDamagePerAccident')?.errors">
                                        <span class="p-error"
                                            *ngIf="data?.get('coverageAPropoertyDamagePerAccident')?.hasError('required')">
                                            Coverage A - Property Damage is required
                                        </span>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage B - Medical Payments</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <div class="col-lg-12">
                                    <insurance-select customClass="jjStyles" inputId="premium1"
                                        label="Coverage B - Medical Payments" formControlName="coverageBMedicalPayments"
                                        [dataList]="dropDownData[i]?.coverageBMedicalPayments" dataLabel="name"
                                        dataValue="value"
                                        (onClick)="setCurrrencyValidation($event,'coverageBMedicalPaymentsCurrency',i)">
                                    </insurance-select>
                                    <ng-container
                                        *ngIf="data?.get('coverageBMedicalPayments')?.touched && data?.get('coverageBMedicalPayments')?.errors">
                                        <span class="p-error"
                                            *ngIf="data?.get('coverageBMedicalPayments')?.hasError('required')">
                                            Coverage B - Medical Payments is required
                                        </span>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders tableMainHead">Uninsured Motorist Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td class="removeBorder">
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage C - Uninsured Motorist (per person/per accident)</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <div class="col-lg-12">
                                    <insurance-select customClass="jjStyles" inputId="premium1"
                                        label="Coverage C - Uninsured Motorist (per person/per accident)"
                                        formControlName="coverageCUninsuredMotoristPerPersonPerAccident"
                                        [dataList]="dropDownData[i]?.coverageCUninsuredMotoristPerPersonPerAccident"
                                        dataLabel="name" dataValue="value" (onClick)="coverageCChange($event, i)">
                                    </insurance-select>
                                    <ng-container
                                        *ngIf="data?.get('coverageCUninsuredMotoristPerPersonPerAccident')?.touched && data?.get('coverageCUninsuredMotoristPerPersonPerAccident')?.errors">
                                        <span class="p-error"
                                            *ngIf="data?.get('coverageCUninsuredMotoristPerPersonPerAccident')?.hasError('required')">
                                            Coverage C - Uninsured Motorist (per person/per accident) is
                                            required
                                        </span>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage C - Uninsured Motorist</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <div class="col-lg-12">
                                    <insurance-select customClass="jjStyles" inputId="premium1"
                                        label="Coverage C -  Uninsured Motorist"
                                        formControlName="coverageCUninsuredMotorist"
                                        [dataList]="dropDownData[i]?.coverageCUninsuredMotorist" dataLabel="name"
                                        dataValue="value">
                                    </insurance-select>
                                    <ng-container
                                        *ngIf="data?.get('coverageCUninsuredMotorist')?.touched && data?.get('coverageCUninsuredMotorist')?.errors">
                                        <span class="p-error"
                                            *ngIf="data?.get('coverageCUninsuredMotorist')?.hasError('required')">
                                            Coverage C - Uninsured Motorist is
                                            required
                                        </span>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders tableMainHead">Physical Damage Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td class="removeBorder">
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Guaranteed Value</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName === 'Hagerty';else setNa">
                                    <ng-container formArrayName="guaranteedValue">
                                        <div class="col-12">
                                            <div class="row pb-3"
                                                *ngFor="let value of getFormArray('guaranteedValue',i)?.controls; let j = index">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12 pb-2">
                                                            <span><strong>Vehicle {{j+1}} -
                                                                    {{data?.value?.vehiclesCovered?.at(j)}}</strong></span>
                                                        </div>
                                                        <div class="col-12">
                                                            <insurance-input customClass="jjStyles" maxlength="14"
                                                                inputId="premium1{{j}}" label="Guaranteed Value"
                                                                formControlName="{{j}}"
                                                                (change)="setCurrencyArray('guaranteedValue', value.value, i, j)">
                                                            </insurance-input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="value?.touched && value?.errors">
                                                    <span class="p-error" *ngIf="value?.hasError('required')">
                                                        Guaranteed Value is required
                                                    </span>
                                                    <span class="p-error" *ngIf="value?.hasError('pattern')">
                                                        Please enter a valid Guaranteed Value
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage D - Comprehensive Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container formArrayName="coverageDComprehensiveCoverage">
                                    <div class="col-12">
                                        <div class="row pb-3"
                                            *ngFor="let value of getFormArray('coverageDComprehensiveCoverage',i)?.controls; let j = index">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <span><strong>Vehicle {{j+1}} -
                                                                {{data?.value?.vehiclesCovered?.at(j)}}</strong></span>
                                                    </div>
                                                    <div class="col-12">
                                                        <insurance-select customClass="jjStyles" inputId="premium1{{j}}"
                                                            label="Coverage D - Comprehensive Coverage"
                                                            formControlName="{{j}}"
                                                            [dataList]="dropDownData[i]?.coverageDComprehensiveCoverage"
                                                            dataLabel="name" dataValue="value">
                                                        </insurance-select>
                                                    </div>
                                                </div>

                                            </div>
                                            <ng-container *ngIf="value?.touched && value?.errors">
                                                <span class="p-error" *ngIf="value?.hasError('required')">
                                                    Coverage D - Comprehensive Coverage is required
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage D - Comprehensive Coverage Deductible</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container formArrayName="coverageDComprehensiveCoverageDeductible">
                                    <div class="col-12">
                                        <div class="row pb-3"
                                            *ngFor="let value of getFormArray('coverageDComprehensiveCoverageDeductible',i)?.controls; let j = index">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <span><strong>Vehicle {{j+1}} -
                                                                {{data?.value?.vehiclesCovered?.at(j)}}</strong></span>
                                                    </div>
                                                    <div class="col-12">
                                                        <insurance-select customClass="jjStyles" inputId="premium1{{j}}"
                                                            label="Coverage D - Comprehensive Coverage Deductible"
                                                            formControlName="{{j}}"
                                                            [dataList]="dropDownData[i]?.coverageDComprehensiveCoverageDeductible"
                                                            dataLabel="name" dataValue="value">
                                                        </insurance-select>
                                                    </div>
                                                </div>

                                            </div>
                                            <ng-container *ngIf="value?.touched && value?.errors">
                                                <span class="p-error" *ngIf="value?.hasError('required')">
                                                    Coverage D - Comprehensive Coverage Deductible is required
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage D - Collision Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container formArrayName="coverageDCollisionCoverage">
                                    <div class="col-12">
                                        <div class="row pb-3"
                                            *ngFor="let value of getFormArray('coverageDCollisionCoverage',i)?.controls; let j = index">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <span><strong>Vehicle {{j+1}} -
                                                                {{data?.value?.vehiclesCovered?.at(j)}}</strong></span>
                                                    </div>
                                                    <div class="col-12">
                                                        <insurance-select customClass="jjStyles" inputId="premium1{{j}}"
                                                            label="Coverage D - Collision Coverage"
                                                            formControlName="{{j}}"
                                                            [dataList]="dropDownData[i]?.coverageDCollisionCoverage"
                                                            dataLabel="name" dataValue="value">
                                                        </insurance-select>
                                                    </div>
                                                </div>

                                            </div>
                                            <ng-container *ngIf="value?.touched && value?.errors">
                                                <span class="p-error" *ngIf="value?.hasError('required')">
                                                    Coverage D - Collision Coverage is required
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Coverage D - Collision Coverage Deductible</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container formArrayName="coverageDCollisionCoverageDeductible">
                                    <div class="col-12">
                                        <div class="row pb-3"
                                            *ngFor="let value of getFormArray('coverageDCollisionCoverageDeductible',i)?.controls; let j = index">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <span><strong>Vehicle {{j+1}} -
                                                                {{data?.value?.vehiclesCovered?.at(j)}}</strong></span>
                                                    </div>
                                                    <div class="col-12">
                                                        <insurance-select customClass="jjStyles" inputId="premium1{{j}}"
                                                            label="Coverage D - Collision Coverage Deductible"
                                                            formControlName="{{j}}"
                                                            [dataList]="dropDownData[i]?.coverageDCollisionCoverageDeductible"
                                                            dataLabel="name" dataValue="value">
                                                        </insurance-select>
                                                    </div>
                                                </div>

                                            </div>
                                            <ng-container *ngIf="value?.touched && value?.errors">
                                                <span class="p-error" *ngIf="value?.hasError('required')">
                                                    Coverage D - Collision Coverage Deductible is required
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders tableMainHead">PIP Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td class="removeBorder">
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Personal Injury Protection (PIP) Limit</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container>
                                    <div class="col-lg-12">
                                        <insurance-input customClass="jjStyles" inputId="premium1" maxlength="14"
                                            label="Personal Injury Protection (PIP) Limit"
                                            formControlName="personalInjuryProtectionLimit"
                                            (change)="setCurrency('personalInjuryProtectionLimit', data.value?.personalInjuryProtectionLimit, i)">
                                        </insurance-input>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('personalInjuryProtectionLimit')?.touched && data?.get('personalInjuryProtectionLimit')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('personalInjuryProtectionLimit')?.hasError('required')">
                                        Personal Injury Protection (PIP) Limit is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">PIP Work Exclusion</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container>
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="PIP Work Exclusion" formControlName="pipWorkExclusion"
                                            [dataList]="dropDownData[i]?.pipWorkExclusion" dataLabel="name"
                                            dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('pipWorkExclusion')?.touched && data?.get('pipWorkExclusion')?.errors">
                                    <span class="p-error" *ngIf="data?.get('pipWorkExclusion')?.hasError('required')">
                                        PIP Work Exclusion is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">PIP Deductible</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="['Hagerty', 'Travelers'].includes(data?.value?.carrierName);else setInput">
                                    <div class="col-lg-12">
                                        <insurance-input customClass="jjStyles" inputId="pipDeductible" maxlength="14"
                                            label="PIP Deductible" formControlName="pipDeductible"
                                            (change)="setCurrency('pipDeductible', data.value?.pipDeductible, i)">
                                        </insurance-input>
                                    </div>
                                </ng-container>
                                <ng-template #setInput>
                                    <insurance-select customClass="jjStyles" inputId="premium1" label="PIP Deductible"
                                        formControlName="pipDeductible" [dataList]="dropDownData[i]?.pipDeductible"
                                        dataLabel="name" dataValue="value">
                                    </insurance-select>
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('pipDeductible')?.touched && data?.get('pipDeductible')?.errors">
                                    <span class="p-error" *ngIf="data?.get('pipDeductible')?.hasError('required')">
                                        PIP Deductible is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">PIP Applies to</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container>
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="PIP Applies to" formControlName="pipAppliesTo"
                                            [dataList]="dropDownData[i]?.pipAppliesTo" dataLabel="name"
                                            dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('pipAppliesTo')?.touched && data?.get('pipAppliesTo')?.errors">
                                    <span class="p-error" *ngIf="data?.get('pipAppliesTo')?.hasError('required')">
                                        PIP Applies to is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders tableMainHead">Additional Coverages</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td class="removeBorder">
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Ultra-Coverage (Accident Forgiveness)</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Safeco';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="Ultra-Coverage (Accident Forgiveness)"
                                            formControlName="ultraCoverage" [dataList]="dropDownData[i]?.ultraCoverage"
                                            dataLabel="name" dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('ultraCoverage')?.touched && data?.get('ultraCoverage')?.errors">
                                    <span class="p-error" *ngIf="data?.get('ultraCoverage')?.hasError('required')">
                                        Ultra-Coverage is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Accident & Minor Violation Forgiveness</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Travelers';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="Accident & Minor Violation Forgiveness"
                                            formControlName="accidentMinorViolationForgiveness"
                                            [dataList]="dropDownData[i]?.accidentMinorViolationForgiveness"
                                            dataLabel="name" dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('accidentMinorViolationForgiveness')?.touched && data?.get('accidentMinorViolationForgiveness')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('accidentMinorViolationForgiveness')?.hasError('required')">
                                        Accident & Minor Violation Forgiveness is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Responsible Driving Plan</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Travelers';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="Responsible Driving Plan" formControlName="responsibleDrivingPlan"
                                            [dataList]="dropDownData[i]?.responsibleDrivingPlan" dataLabel="name"
                                            dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('responsibleDrivingPlan')?.touched && data?.get('responsibleDrivingPlan')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('responsibleDrivingPlan')?.hasError('required')">
                                        Responsible Driving Plan is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Extended PIP</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container>
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1" label="Extended PIP"
                                            formControlName="extendedPip" [dataList]="dropDownData[i]?.extendedPip"
                                            dataLabel="name" dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="data?.get('extendedPip')?.touched && data?.get('extendedPip')?.errors">
                                    <span class="p-error" *ngIf="data?.get('extendedPip')?.hasError('required')">
                                        Extended PIP is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Additional PIP</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container
                                    *ngIf="['Progressive', 'Vault'].includes(data?.value?.carrierName);else setNa">
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="Additional PIP" formControlName="additionalPip"
                                            [dataList]="dropDownData[i]?.additionalPip" dataLabel="name"
                                            dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('additionalPip')?.touched && data?.get('additionalPip')?.errors">
                                    <span class="p-error" *ngIf="data?.get('additionalPip')?.hasError('required')">
                                        Additional PIP is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Rental Car Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container formArrayName="rentalCarCoverage"
                                    *ngIf="data?.value?.carrierName!=='Hagerty';else setNa">
                                    <div class="col-12">
                                        <div class="row pb-3"
                                            *ngFor="let value of getFormArray('rentalCarCoverage',i)?.controls; let j = index">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <span><strong>Vehicle {{j+1}} -
                                                                {{data?.value?.vehiclesCovered?.at(j)}}</strong></span>
                                                    </div>
                                                    <div class="col-12">
                                                        <insurance-select customClass="jjStyles" inputId="premium1{{j}}"
                                                            label="Rental Car Coverage" formControlName="{{j}}"
                                                            [dataList]="dropDownData[i]?.rentalCarCoverage"
                                                            dataLabel="name" dataValue="value">
                                                        </insurance-select>
                                                    </div>
                                                </div>

                                            </div>
                                            <ng-container *ngIf="value?.touched && value?.errors">
                                                <span class="p-error" *ngIf="value?.hasError('required')">
                                                    Rental Car Coverage is required
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Roadside Assistance and Towing</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container formArrayName="roadsideAssistanceAndTowing">
                                    <div class="col-12">
                                        <div class="row pb-3"
                                            *ngFor="let value of getFormArray('roadsideAssistanceAndTowing',i)?.controls; let j = index">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 pb-2">
                                                        <span><strong>Vehicle {{j+1}} -
                                                                {{data?.value?.vehiclesCovered?.at(j)}}</strong></span>
                                                    </div>
                                                    <div class="col-12">
                                                        <insurance-select customClass="jjStyles" inputId="premium1{{j}}"
                                                            label="Roadside Assistance and Towing"
                                                            formControlName="{{j}}"
                                                            [dataList]="dropDownData[i]?.roadsideAssistanceAndTowing"
                                                            dataLabel="name" dataValue="value">
                                                        </insurance-select>
                                                    </div>
                                                </div>

                                            </div>
                                            <ng-container *ngIf="value?.touched && value?.errors">
                                                <span class="p-error" *ngIf="value?.hasError('required')">
                                                    Roadside Assistance and Towing is required
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Gap Coverage</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName!=='Hagerty';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1" label="Gap Coverage"
                                            formControlName="gapCoverage" [dataList]="dropDownData[i]?.gapCoverage"
                                            dataLabel="name" dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('gapCoverage')?.touched && data?.get('gapCoverage')?.errors">
                                    <span class="p-error" *ngIf="data?.get('gapCoverage')?.hasError('required')">
                                        Gap Coverage is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Original Equipment Manufacturer</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Mercury';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="Original Equipment Manufacturer"
                                            formControlName="originalEquipmentManufacturer"
                                            [dataList]="dropDownData[i]?.originalEquipmentManufacturer" dataLabel="name"
                                            dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('originalEquipmentManufacturer')?.touched && data?.get('originalEquipmentManufacturer')?.errors">
                                    <span class="p-error"
                                        *ngIf="data?.get('originalEquipmentManufacturer')?.hasError('required')">
                                        Original Equipment Manufacturer is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td class="tableHeaders">Enhanced Package</td>
                        <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
                            <td [formGroupName]="i" class="tableInputs">
                                <ng-container *ngIf="data?.value?.carrierName==='Safeco';else setNa">
                                    <div class="col-lg-12">
                                        <insurance-select customClass="jjStyles" inputId="premium1"
                                            label="Enhanced Package" formControlName="enhancedPackage"
                                            [dataList]="dropDownData[i]?.enhancedPackage" dataLabel="name"
                                            dataValue="value">
                                        </insurance-select>
                                    </div>
                                </ng-container>
                                <ng-template #setNa>
                                    N.A
                                </ng-template>
                                <ng-container
                                    *ngIf="data?.get('enhancedPackage')?.touched && data?.get('enhancedPackage')?.errors">
                                    <span class="p-error" *ngIf="data?.get('enhancedPackage')?.hasError('required')">
                                        Enhanced Package is required
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-12 text-center mt-4">
            <div class="row">
                <div class="col-4">
                    <insurance-button class="float-md-start mb-3" btnWidth="150" label="Proposal Setup"
                        (onClick)="back()" [loading]="backLoader"
                        [disabled]="loader.saveLater || loadingStatus || backLoader">
                    </insurance-button>
                </div>
                <div class="col-4">
                    <insurance-button class="float-md-center mb-3" btnWidth="180" label="Save for Later"
                        (onClick)="saveForLater()" [loading]="loader.saveLater"
                        [disabled]="loader.saveLater || loadingStatus || backLoader">
                    </insurance-button>
                </div>
                <div class="col-4">
                    <insurance-button class="float-md-end mb-3" btnWidth="180" label="Proposal Preview"
                        (onClick)="next()" [loading]="loadingStatus"
                        [disabled]="loader.saveLater || loadingStatus || backLoader">
                    </insurance-button>
                </div>
            </div>
        </div>
    </div>
</form>

<insurance-popup [displayModal]="deleteAlert.show" (closeAction)="deleteAlert.show =  false">
    <h5>Please Confirm</h5>
    <p>This quote has been already selected by the client</p>
    <p>Would you like to delete this quote?</p>
    <div class="col-lg-12 text-center mt-3">
        <div class="row">
            <div class="col">
                <insurance-button btnWidth="150" label="No"
                    (onClick)="deleteAlert.show = deleteAlert.noLoader =  false">
                </insurance-button>
            </div>
            <div class="col">
                <insurance-button btnWidth="150" label="Yes" (onClick)="removeQuoteSelection()"
                    [loading]="deleteAlert.yesLoader" [disabled]="deleteAlert.yesLoader">
                </insurance-button>
            </div>
        </div>
    </div>
</insurance-popup>

<insurance-popup [displayModal]="agentAlert.show" [closable]="false">
    <h5>Please Confirm</h5>
    <p>This quote has been already selected by the client</p>
    <p>{{popupMessage.question}}</p>
    <p class="mt-2">{{popupMessage.note}}</p>
    <div class="col-lg-12 text-center mt-3">
        <div class="row">
            <div class="col">
                <insurance-button btnWidth="150" label="No" (onClick)="alertNo()">
                </insurance-button>
            </div>
            <div class="col">
                <insurance-button btnWidth="150" label="Yes" (onClick)="alertYes()" [loading]="agentAlert.yesLoader"
                    [disabled]="agentAlert.yesLoader">
                </insurance-button>
            </div>
        </div>
    </div>
</insurance-popup>




<!-- 







<tr>
    <td class="tableHeaders">Coverage A - Bodily Injury and Property Damage per accident PD</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <div class="col-lg-12">
                <ng-container *ngIf="data?.value?.carrierName === 'Bristol West'else setInput">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Coverage A - Bodily Injury and Property Damage per accident PD"
                        formControlName="bodilyInjuryandPropertyDamagePerAccidentPd"
                        [dataList]="dropDownData[i]?.bodilyInjuryandPropertyDamagePerPersonBi"
                        dataLabel="name" dataValue="value"
                        (onClick)="setCurrrencyValidation($event,'bodilyInjuryandPropertyDamagePerAccidentPdCurrency',i)">
                    </insurance-select>
                </ng-container>
                <ng-template #setInput>
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="Coverage A - Bodily Injury and Property Damage per accident PD"
                        formControlName="bodilyInjuryandPropertyDamagePerAccidentPd"
                        (change)="setCurrency('bodilyInjuryandPropertyDamagePerAccidentPd', data.value?.bodilyInjuryandPropertyDamagePerAccidentPd, i)">
                    </insurance-input>
                </ng-template>
                <ng-container
                    *ngIf="data?.get('bodilyInjuryandPropertyDamagePerAccidentPd')?.touched && data?.get('bodilyInjuryandPropertyDamagePerAccidentPd')?.errors">
                    <span class="p-error"
                        *ngIf="data?.get('bodilyInjuryandPropertyDamagePerAccidentPd')?.hasError('required')">
                        Coverage A - Bodily Injury and Property Damage per accident PD Currency is
                        required
                    </span>
                </ng-container>
            </div>

            <ng-container
                *ngIf="data?.value?.bodilyInjuryandPropertyDamagePerAccidentPd == 'CURRENCY'">
                <div class="mt-2">
                    <insurance-input customClass="jjStyles" inputId="premium1" label="Currency"
                        formControlName="bodilyInjuryandPropertyDamagePerAccidentPdCurrency"
                        (change)="setCurrency('bodilyInjuryandPropertyDamagePerAccidentPdCurrency', data.value?.bodilyInjuryandPropertyDamagePerAccidentPdCurrency, i)">
                    </insurance-input>

                </div>
            </ng-container>

        </td>
    </ng-container>

</tr>
<tr>
    <td class="tableHeaders">Coverage A- Bodily Injury and Property Damage per accident BI</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <div class="col-lg-12">
                <ng-container *ngIf="data?.value?.carrierName === 'Bristol West';else setInput ">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Coverage A- Bodily Injury and Property Damage per accident BI"
                        formControlName="bodilyInjuryandPropertyDamagePerAccidentBi"
                        [dataList]="dropDownData[i]?.bodilyInjuryandPropertyDamagePerPersonBi"
                        dataLabel="name" dataValue="value"
                        (onClick)="setCurrrencyValidation($event,'bodilyInjuryandPropertyDamagePerAccidentBiCurrency',i)">
                    </insurance-select>
                </ng-container>
                <ng-template #setInput>
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="Coverage A- Bodily Injury and Property Damage per accident BI"
                        formControlName="bodilyInjuryandPropertyDamagePerAccidentBi"
                        (change)="setCurrency('bodilyInjuryandPropertyDamagePerAccidentBi', data.value?.bodilyInjuryandPropertyDamagePerAccidentBi, i)">
                    </insurance-input>
                </ng-template>

                <ng-container
                    *ngIf="data?.get('bodilyInjuryandPropertyDamagePerAccidentBi')?.touched && data?.get('bodilyInjuryandPropertyDamagePerAccidentBi')?.errors">
                    <span class="p-error"
                        *ngIf="data?.get('bodilyInjuryandPropertyDamagePerAccidentBi')?.hasError('required')">
                        Coverage A- Bodily Injury and Property Damage per accident BI Currency is
                        required
                    </span>
                </ng-container>
            </div>
            <ng-container
                *ngIf="data?.value?.bodilyInjuryandPropertyDamagePerAccidentBi == 'CURRENCY'">
                <div class="mt-2">
                    <insurance-input customClass="jjStyles" inputId="premium1" label="Currency"
                        formControlName="bodilyInjuryandPropertyDamagePerAccidentBiCurrency"
                        (change)="setCurrency('bodilyInjuryandPropertyDamagePerAccidentBiCurrency', data.value?.bodilyInjuryandPropertyDamagePerAccidentBiCurrency, i)">
                    </insurance-input>
                </div>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Coverage B- Medical Payments</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <div class="col-lg-12">
                <ng-container
                    *ngIf="data?.value?.carrierName === 'Bristol West'|| data?.value?.carrierName === 'Progressive with Snapshot'||data?.value?.carrierName==='Progressive';else setInput">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Coverage B- Medical Payments"
                        formControlName="coverageBMedicalPayments"
                        [dataList]="dropDownData[i]?.coverageBMedicalPayments" dataLabel="name"
                        dataValue="value">
                    </insurance-select>
                </ng-container>
                <div class="mt-2">
                    <ng-container
                        *ngIf="(data?.value?.carrierName==='Progressive'&&data?.value?.coverageBMedicalPayments=='INCLUDED')||(data?.value?.carrierName==='Progressive with Snapshot'&&data?.value?.coverageBMedicalPayments=='INCLUDED')">
                        <insurance-input customClass="jjStyles" inputId="premium1" label="Currency"
                            formControlName="coverageBMedicalPaymentsCurrency"
                            (change)="setCurrency('coverageBMedicalPaymentsCurrency', data.value?.coverageBMedicalPaymentsCurrency, i)">
                        </insurance-input>
                    </ng-container>
                </div>
                <ng-template #setInput>
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="Coverage B- Medical Payments"
                        formControlName="coverageBMedicalPayments">
                    </insurance-input>
                </ng-template>
                <ng-container
                    *ngIf="data?.get('coverageBMedicalPayments')?.touched && data?.get('coverageBMedicalPayments')?.errors">
                    <span class="p-error"
                        *ngIf="data?.get('coverageBMedicalPayments')?.hasError('required')">
                        Coverage B- Medical Payments is required
                    </span>
                </ng-container>
            </div>
        </td>
    </ng-container>
</tr>

<tr>
    <td class="tableHeaders">Coverage C - Uninsured Motorist $ per person</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName != 'Bristol West';else setNa">
                <div class="col-lg-12">
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="Coverage C - Uninsured Motorist $ per person"
                        formControlName="coverageCUninsuredMotoristPerPerson"
                        (change)="setCurrency('coverageCUninsuredMotoristPerPerson', data.value?.coverageCUninsuredMotoristPerPerson, i)">
                    </insurance-input>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('coverageCUninsuredMotoristPerPerson')?.touched && data?.get('coverageCUninsuredMotoristPerPerson')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('coverageCUninsuredMotoristPerPerson')?.hasError('required')">
                    Coverage A- Bodily Injury and Property Damage per person BI Currency is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>

<tr>
    <td class="tableHeaders">Coverage C - Uninsured Motorist $ per accident</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName != 'Bristol West';else setNa ">
                <div class="col-lg-12">
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="Coverage C - Uninsured Motorist $ per accident"
                        formControlName="coverageCUninsuredMotoristPerAccident"
                        (change)="setCurrency('coverageCUninsuredMotoristPerAccident', data.value?.coverageCUninsuredMotoristPerAccident, i)">
                    </insurance-input>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('coverageCUninsuredMotoristPerAccident')?.touched && data?.get('coverageCUninsuredMotoristPerAccident')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('coverageCUninsuredMotoristPerAccident')?.hasError('required')">
                    Coverage C - Uninsured Motorist $ per accident is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Coverage C- Uninsured Motorist – Stacking or Non-Stacking</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName === 'Bristol West';else setNa ">
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Coverage C- Uninsured Motorist – Stacking or Non-Stacking"
                        formControlName="coverageCUninsuredMotoristStackingorNonStacking"
                        [dataList]="dropDownData[i]?.coverageCUninsuredMotoristStackingorNonStacking"
                        dataLabel="name" dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('coverageCUninsuredMotoristStackingorNonStacking')?.touched && data?.get('coverageCUninsuredMotoristStackingorNonStacking')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('coverageCUninsuredMotoristStackingorNonStacking')?.hasError('required')">
                    Coverage C- Uninsured Motorist – Stacking or Non-Stacking is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>



<tr>
    <td class="tableHeaders">Coverage D- Collision Coverage</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container>
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Coverage D- Collision Coverage"
                        formControlName="coverageDCollisionCoverage"
                        [dataList]="dropDownData[i]?.coverageDCollisionCoverage" dataLabel="name"
                        dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-container
                *ngIf="data?.get('coverageDCollisionCoverage')?.touched && data?.get('coverageDCollisionCoverage')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('coverageDCollisionCoverage')?.hasError('required')">
                    Coverage D- Collision Coverage is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Coverage D - Comprehensive Coverage Deductible</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName != 'Bristol West';else setNa">
                <div class="col-lg-12">
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="Coverage D - Comprehensive Coverage Deductible"
                        formControlName="coverageDComprehensiveCoverageDeductible"
                        (change)="setCurrency('coverageDComprehensiveCoverageDeductible', data.value?.coverageDComprehensiveCoverageDeductible, i)">
                    </insurance-input>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('coverageDComprehensiveCoverageDeductible')?.touched && data?.get('coverageDComprehensiveCoverageDeductible')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('coverageDComprehensiveCoverageDeductible')?.hasError('required')">
                    Coverage D - Comprehensive Coverage Deductible is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Coverage D - Collision Coverage Deductible</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName != 'Bristol West';else setNa">
                <div class="col-lg-12">
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="Coverage D - Collision Coverage Deductible"
                        formControlName="coverageDCollisionCoverageDeductible"
                        (change)="setCurrency('coverageDCollisionCoverageDeductible', data.value?.coverageDCollisionCoverageDeductible, i)">
                    </insurance-input>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('coverageDCollisionCoverageDeductible')?.touched && data?.get('coverageDCollisionCoverageDeductible')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('coverageDCollisionCoverageDeductible')?.hasError('required')">
                    Coverage D - Collision Coverage Deductible is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>



<tr>
    <td class="tableHeaders">PIP Deductible</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container>
                <div class="col-lg-12">
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="PIP Deductible" formControlName="pipDeductible">
                    </insurance-input>
                </div>
            </ng-container>
            <ng-container
                *ngIf="data?.get('pipDeductible')?.touched && data?.get('pipDeductible')?.errors">
                <span class="p-error" *ngIf="data?.get('pipDeductible')?.hasError('required')">
                    PIP Deductible is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">PIP Deductible Applies to </td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container>
                <div class="col-lg-12">
                    <insurance-input customClass="jjStyles" inputId="premium1"
                        label="PIP Deductible Applies to" formControlName="pipApplies">
                    </insurance-input>
                </div>
            </ng-container>
            <ng-container
                *ngIf="data?.get('pipApplies')?.touched && data?.get('pipApplies')?.errors">
                <span class="p-error" *ngIf="data?.get('pipApplies')?.hasError('required')">
                    PIP Deductible Applies to is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>

<tr>
    <td class="tableHeaders">Ultra-Coverage (Accident Forgiveness) </td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName === 'Safeco';else setNa">
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Ultra-Coverage (Accident Forgiveness)"
                        formControlName="ultraCoverage" [dataList]="dropDownData[i]?.ultraCoverage"
                        dataLabel="name" dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('ultraCoverage')?.touched && data?.get('ultraCoverage')?.errors">
                <span class="p-error" *ngIf="data?.get('ultraCoverage')?.hasError('required')">
                    Ultra-Coverage (Accident Forgiveness) is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Accident & Minor Violation Forgiveness </td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container
                *ngIf="data?.value?.carrierName === 'Travelers';else setNa">
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Accident & Minor Violation Forgiveness "
                        formControlName="accidentMinorViolationForgiveness"
                        [dataList]="dropDownData[i]?.accidentMinorViolationForgiveness"
                        dataLabel="name" dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('accidentMinorViolationForgiveness')?.touched && data?.get('accidentMinorViolationForgiveness')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('accidentMinorViolationForgiveness')?.hasError('required')">
                    Accident & Minor Violation Forgiveness is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Responsible Driving Plan</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container
                *ngIf="data?.value?.carrierName === 'Travelers';else setNa">
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Responsible Driving Plan" formControlName="responsibleDrivingPlan"
                        [dataList]="dropDownData[i]?.responsibleDrivingPlan" dataLabel="name"
                        dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('responsibleDrivingPlan')?.touched && data?.get('responsibleDrivingPlan')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('responsibleDrivingPlan')?.hasError('required')">
                    Responsible Driving Plan is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Extended Personal Injury Protection</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container>
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Extended Personal Injury Protection"
                        formControlName="extendedPersonalInjuryProtection"
                        [dataList]="dropDownData[i]?.extendedPersonalInjuryProtection"
                        dataLabel="name" dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-container
                *ngIf="data?.get('extendedPersonalInjuryProtection')?.touched && data?.get('extendedPersonalInjuryProtection')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('extendedPersonalInjuryProtection')?.hasError('required')">
                    Extended Personal Injury Protection is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Additional Personal Injury Protection</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container
                *ngIf="data?.value?.carrierName==='Vault'||data?.value?.carrierName==='Progressive';else setNa">
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Additional Personal Injury Protection"
                        formControlName="additionalPersonalInjuryProtection"
                        [dataList]="dropDownData[i]?.additionalPersonalInjuryProtection"
                        dataLabel="name" dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-container
                *ngIf="data?.get('additionalPersonalInjuryProtection')?.touched && data?.get('additionalPersonalInjuryProtection')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('additionalPersonalInjuryProtection')?.hasError('required')">
                    Additional Personal Injury Protection is required
                </span>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Rental Car Coverage</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName!='Hagerty';else setNa">
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Rental Car Coverage" formControlName="rentalCarCoverage"
                        [dataList]="dropDownData[i]?.rentalCarCoverage" dataLabel="name"
                        dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('rentalCarCoverage')?.touched && data?.get('rentalCarCoverage')?.errors">
                <span class="p-error" *ngIf="data?.get('rentalCarCoverage')?.hasError('required')">
                    Rental Car Coverage is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Roadside Assistance and Towing</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container>
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1"
                        label="Roadside Assistance and Towing"
                        formControlName="roadsideAssistanceandTowing"
                        [dataList]="dropDownData[i]?.roadsideAssistanceandTowing" dataLabel="name"
                        dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-container
                *ngIf="data?.get('roadsideAssistanceandTowing')?.touched && data?.get('roadsideAssistanceandTowing')?.errors">
                <span class="p-error"
                    *ngIf="data?.get('roadsideAssistanceandTowing')?.hasError('required')">
                    Roadside Assistance and Towing is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr>
<tr>
    <td class="tableHeaders">Gap Coverage</td>
    <ng-container *ngFor="let data of getQuoteProposalData.controls; let i = index">
        <td [formGroupName]="i" class="tableInputs">
            <ng-container *ngIf="data?.value?.carrierName!='Hagerty';else setNa">
                <div class="col-lg-12">
                    <insurance-select customClass="jjStyles" inputId="premium1" label="Gap Coverage"
                        formControlName="gapCoverage" [dataList]="dropDownData[i]?.gapCoverage"
                        dataLabel="name" dataValue="value">
                    </insurance-select>
                </div>
            </ng-container>
            <ng-template #setNa>
                N.A
            </ng-template>
            <ng-container
                *ngIf="data?.get('gapCoverage')?.touched && data?.get('gapCoverage')?.errors">
                <span class="p-error" *ngIf="data?.get('gapCoverage')?.hasError('required')">
                    Gap Coverage is required
                </span>
            </ng-container>
        </td>
    </ng-container>
</tr> -->