import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppHelper } from 'src/app/app.helper';
import { JwtHelperService } from '@auth0/angular-jwt';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  email = this.fb.group({
    emailAddress: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
    password: ['', [Validators.required]],
  });
  hide = true;
  submitting = false;

  constructor(
    public fb: FormBuilder,
    public service: WizzipayService,
    public router: Router,
    public helper: AppHelper,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    if (
      this.helper.secureStorage('get', 'token') &&
      this.helper.secureStorage('get', 'merchantId')
    ) {
      this.helper.secureStorage('remove', 'token');
      this.helper.secureStorage('remove', 'merchantId');
    }
  }

  keyDownFunction(event: { keyCode: number }) {
    if (event.keyCode === 13) {
      this.submit();
    }
  }

  submit() {
    this.hide = false;
    this.submitting = true;
    if (this.email.invalid) {
      this.submitting = false;
      this.email.markAllAsTouched();
      return;
    }
    const payload = {
      emailAddress: this.email.value.emailAddress,
      password: this.email.value.password,
    }
    this.service.login(payload).subscribe(
      (res): void => {
        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(res.token);
        this.submitting = false;
        this.helper.secureStorage('set', 'token', res.token);
        this.helper.secureStorage('set', 'role', decoded.role);
        this.helper.secureStorage('set', 'expiryTime', decoded?.exp);
        this.helper.secureStorage('set', 'merchantId', this.service.environment.merchantId);
        this.router.navigate([
          `/merchant/campaignList/${this.service.environment.merchantId}/`,
        ]);
        this.email.reset();
      },
      (err): void => {
        this.submitting = false;
        this.service.snackbar(err.error.message);
      }
    );
  }
}
