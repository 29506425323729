import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-details',
  templateUrl: './auto-details.component.html',
  styleUrls: ['./auto-details.component.css'],
})
export class AutoDetailsComponent implements OnInit, OnChanges {
  @Input() autoData: any = {};

  listType = 'autoList';
  autoFieldsData: any = {
    autoPolicy: [
      {
        fieldName: 'Bodily Injury',
        key: 'bodilyInjury',
        value: '',
      },
      {
        fieldName: 'Current Annual Premium',
        key: 'currentAnnualPremium',
        value: '',
      },
      {
        fieldName: 'Current Carrier',
        key: 'currentCarrier',
        value: '',
      },
      {
        fieldName: 'Current Expiration Date',
        key: 'currentExpirationDate',
        value: '',
      },
      {
        fieldName: 'Currently Insured',
        key: 'currentlyInsured',
        value: '',
      },
      {
        fieldName: 'Current Policy Term',
        key: 'currentPolicyTerm',
        value: '',
      },
      {
        fieldName: 'Effective Date',
        key: 'effectiveDate',
        value: '',
      },
      {
        fieldName: 'Own Or Rent Home',
        key: 'ownOrRentHome',
        value: '',
      },
      {
        fieldName: 'Prior Liability Limits',
        key: 'priorLiabilityLimits',
        value: '',
      },
      {
        fieldName: 'Property Damage',
        key: 'propertyDamage',
        value: '',
      },
      {
        fieldName: 'Uninsured Motorist',
        key: 'uninsuredMotorist',
        value: '',
      },
      {
        fieldName: 'Uninsured Motorists Property Damage',
        key: 'uninsuredMotoristsPropertyDamage',
        value: '',
      },
      {
        fieldName: 'Years At Current Residence',
        key: 'yearsAtCurrentResidence',
        value: '',
      },
      {
        fieldName: 'Years Continuously Insured',
        key: 'yearsContinuouslyInsured',
        value: '',
      },
      {
        fieldName: 'Years With Current Carrier',
        key: 'yearsWithCurrentCarrier',
        value: '',
      },
      {
        fieldName: 'Medical Payments',
        key: 'medicalPayments',
        value: '',
      },
      {
        fieldName: 'PIP Deductible',
        key: 'pipdeductible',
        value: '',
      },
      {
        fieldName: 'PIP Coverge',
        key: 'pipcoverge',
        value: '',
      },
      {
        fieldName: 'Wage Loss',
        key: 'wageLoss',
        value: '',
      },
      {
        fieldName: 'AAA Member',
        key: 'aaamember',
        value: '',
      },
      {
        fieldName: 'Notes',
        key: 'notes',
        value: '',
      },
      {
        fieldName: 'Stacked Coverage',
        key: 'stackedCoverage',
        value: '',
      },
      {
        fieldName: 'PIP Medical Deductible',
        key: 'pipmedicalDeductible',
        value: '',
      },
      {
        fieldName: 'PIP Medical Coverage',
        key: 'pipmedicalCoverage',
        value: '',
      },
      {
        fieldName: 'Combat Theft',
        key: 'combatTheft',
        value: '',
      },
      {
        fieldName: 'Spousal Liability',
        key: 'spousalLiability',
        value: '',
      },
      {
        fieldName: 'OBEL',
        key: 'obel',
        value: '',
      },
      {
        fieldName: 'PIP Addl. Coverage',
        key: 'pipaddlCoverage',
        value: '',
      },
      {
        fieldName: 'Garage State',
        key: 'garageState',
        value: '',
      },
    ],
  };
  autoListField: any = [
    {
      fieldName: 'VIN',
      key: 'vin',
      value: '',
    },
    {
      fieldName: 'Year',
      key: 'year',
      value: '',
    },
    {
      fieldName: 'Make',
      key: 'make',
      value: '',
    },
    {
      fieldName: 'Model',
      key: 'model',
      value: '',
    },
    {
      fieldName: 'Model Details',
      key: 'modelDetails',
      value: '',
    },
    {
      fieldName: 'Body Style',
      key: 'bodyStyle',
      value: '',
    },
    {
      fieldName: 'Drive',
      key: 'drive',
      value: '',
    },
    {
      fieldName: 'Engine Info',
      key: 'engineInfo',
      value: '',
    },
    {
      fieldName: 'Fuel',
      key: 'fuel',
      value: '',
    },
    {
      fieldName: 'Transmission',
      key: 'transmission',
      value: '',
    },
    {
      fieldName: 'Anti Lock Brakes',
      key: 'antiLockBrakes',
      value: '',
    },
    {
      fieldName: 'ABS',
      key: 'abs',
      value: '',
    },
    {
      fieldName: 'License Plate Number',
      key: 'licensePlateNumber',
      value: '',
    },
    {
      fieldName: 'Odometer Reading',
      key: 'odometerReading',
      value: '',
    },
    {
      fieldName: 'Day Lights',
      key: 'dayLights',
      value: '',
    },
    {
      fieldName: 'Anti Theft',
      key: 'antiTheft',
      value: '',
    },
    {
      fieldName: 'Passive Restraints',
      key: 'passiveRestraints',
      value: '',
    },
    {
      fieldName: 'Ownership Status',
      key: 'ownershipStatus',
      value: '',
    },
    {
      fieldName: 'Annual Mileage',
      key: 'annualMileage',
      value: '',
    },
    {
      fieldName: 'Length Of Ownership',
      key: 'lengthOfOwnership',
      value: '',
    },
    {
      fieldName: 'Primary Driver',
      key: 'primaryDriver',
      value: '',
    },
    {
      fieldName: 'Use Type',
      key: 'useType',
      value: '',
    },
    {
      fieldName: 'Miles One Way',
      key: 'milesOneWay',
      value: '',
    },
    {
      fieldName: 'Days Per Week',
      key: 'daysPerWeek',
      value: '',
    },
    {
      fieldName: 'Weeks Per Month',
      key: 'weeksPerMonth',
      value: '',
    },
    {
      fieldName: 'Daytime Running Lights',
      key: 'daytimeRunningLights',
      value: '',
    },
    {
      fieldName: 'Comprehensive',
      key: 'comprehensive',
      value: '',
    },
    {
      fieldName: 'Collision',
      key: 'collision',
      value: '',
    },
    {
      fieldName: 'UMPD Limit',
      key: 'umpdlimit',
      value: '',
    },
    {
      fieldName: 'UMPD Ded',
      key: 'umpdded',
      value: '',
    },
    {
      fieldName: 'Towing',
      key: 'towing',
      value: '',
    },
    {
      fieldName: 'Rental',
      key: 'rental',
      value: '',
    },
    {
      fieldName: 'Cost New Value',
      key: 'costNewValue',
      value: '',
    },
    {
      fieldName: 'Garage Location',
      key: 'garageLocation',
      value: '',
    },
    {
      fieldName: 'Garage Index',
      key: 'garageIndex',
      value: '',
    },
    {
      fieldName: 'Notes',
      key: 'notes',
      value: '',
    },
    {
      fieldName: 'EAP',
      key: 'eap',
      value: '',
    },
    {
      fieldName: 'Deleted',
      key: 'deleted',
      value: '',
    },
  ];

  driverListField: any = [
    {
      fieldName: 'Name Prefix',
      key: 'namePrefix',
      value: '',
    },
    {
      fieldName: 'First Name',
      key: 'nameFirst',
      value: '',
    },
    {
      fieldName: 'Middle Name',
      key: 'nameMiddle',
      value: '',
    },
    {
      fieldName: 'Last Name',
      key: 'nameLast',
      value: '',
    },
    {
      fieldName: 'Name Suffix',
      key: 'nameSuffix',
      value: '',
    },
    {
      fieldName: 'Gender',
      key: 'gender',
      value: '',
    },
    {
      fieldName: 'maritalStatus',
      key: 'maritalStatus',
      value: '',
    },
    {
      fieldName: 'Date Of Birth',
      key: 'dateOfBirth',
      value: '',
    },
    {
      fieldName: 'Relationship',
      key: 'relationship',
      value: '',
    },
    {
      fieldName: 'SSN',
      key: 'ssn',
      value: '',
    },
    {
      fieldName: 'Education Level',
      key: 'educationLevel',
      value: '',
    },
    {
      fieldName: 'Occupation',
      key: 'occupation',
      value: '',
    },
    {
      fieldName: 'Occupation Title',
      key: 'occupationTitle',
      value: '',
    },
    {
      fieldName: 'Occupation Years',
      key: 'occupationYears',
      value: '',
    },
    {
      fieldName: 'Rated Driver',
      key: 'ratedDriver',
      value: '',
    },
    {
      fieldName: 'License Number',
      key: 'licenseNumber',
      value: '',
    },
    {
      fieldName: 'License Status',
      key: 'licenseStatus',
      value: '',
    },
    {
      fieldName: 'Date First Licensed',
      key: 'dateFirstLicensed',
      value: '',
    },
    {
      fieldName: 'Age First Licensed',
      key: 'ageFirstLicensed',
      value: '',
    },
    {
      fieldName: 'License State',
      key: 'licenseState',
      value: '',
    },
    {
      fieldName: 'Defensive Driver Course Date',
      key: 'defensiveDriverCourseDate',
      value: '',
    },
    {
      fieldName: 'Points',
      key: 'points',
      value: '',
    },
    {
      fieldName: 'Notes',
      key: 'notes',
      value: '',
    },
    {
      fieldName: 'Suspend Revoked 5',
      key: 'suspendRevoked5',
      value: '',
    },
    {
      fieldName: 'SR22FR44',
      key: 'sr22FR44',
      value: '',
    },
    {
      fieldName: 'Good Student',
      key: 'goodStudent',
      value: '',
    },
    {
      fieldName: 'Training',
      key: 'training',
      value: '',
    },
    {
      fieldName: 'Student Over 100 Miles Away',
      key: 'studentOver100MilesAway',
      value: '',
    },
    {
      fieldName: 'Deleted',
      key: 'deleted',
      value: '',
    },
    {
      fieldName: 'Driver Violations List',
      key: 'driverViolationsList',
      value: '',
    },
    {
      fieldName: 'Mature Driver',
      key: 'matureDriver',
      value: '',
    },
    {
      fieldName: 'Good Driver',
      key: 'goodDriver',
      value: '',
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    console.log('auto child', this.autoData);
    if (this.autoData?.autoDetails) {
      this.autoFieldsData['autoList'] = [];
      this.autoFieldsData['autoList'] = this.patchData(
        this.autoData?.autoDetails?.autosList,
        this.autoListField
      );
      this.autoFieldsData['driverList'] = [];
      this.autoFieldsData['driverList'] = this.patchData(
        this.autoData?.autoDetails?.driversList,
        this.driverListField
      );
      console.log('tsee', this.autoFieldsData);
      this.autoFieldsData.autoPolicy.forEach((element: any) => {
        element.value = this.autoData?.autoDetails?.autoPolicy[
          element?.key
        ]?.toString()
          ? this.autoData?.autoDetails?.autoPolicy[element?.key]
          : '';
      });
    }
  }

  patchData(val: any, field: any) {
    let fieldsData: any = [];
    val?.forEach((data: any, index: number) => {
      let arrayData: any = [];
      field?.forEach((element: any) => {
        const payload = { ...element };
        payload.value = data[element?.key]?.toString()
          ? data[element?.key]
          : '';
        arrayData.push(payload);
      });
      fieldsData.push(arrayData);
    });
    console.log('fielddd data', fieldsData);
    return fieldsData;
  }
}
