import { DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  NgZone,
  EventEmitter,
  Output,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
// import { UmbrellaQuoteService } from 'src/app/shared/providers/umbrella-quote.service';
// import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
// import { v4 as uuid } from 'uuid';

const emailMatcher: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  // console.log('contttrol vallll', control.value)
  const customerEmail = control.get('customer')?.get('email');
  const agentEmail = control.get('agent')?.get('email');
  return customerEmail &&
    agentEmail &&
    agentEmail.value.length &&
    customerEmail.value.length &&
    customerEmail.value === agentEmail.value
    ? { emailDoNotMatch: true }
    : null;
};
const phoneMatcher: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  //this.productDetail.get('customer')?.get('email')
  const customerPhone = control.get('customer')?.get('phoneNumber');
  //console.log(customerEmail?.value,"dbdnbd");
  const agentPhone = control.get('agent')?.get('phoneNumber');

  return customerPhone &&
    agentPhone &&
    agentPhone.value.length &&
    customerPhone.value.length &&
    customerPhone.value === agentPhone.value
    ? { phoneDoNotMatch: true }
    : null;
};

@Component({
  selector: 'app-set-up',
  templateUrl: './set-up.component.html',
  styleUrls: ['./set-up.component.css']
})
export class SetUpComponent implements OnInit {
  productDetail = this.fb.group(
    {
      salesForce: [''],
      customer: this.fb.group({
        firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],],
        lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],],
        middleName: [''],
        email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),],],
        phoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)]],
        dob: [''],
        namePrefix: '',
        nameSuffix: '',
        gender: '',
        maritalStatus: '',
        educationLevel: '',
        industry: '',
        occupation: '',
        ssn: '',
        address: this.fb.group({
          streetNumber: ['', [Validators.required]],
          streetName: ['', [Validators.required]],
          city: ['', [Validators.required]],
          state: [
            '', [Validators.required]
          ],
          country: [
            '', [Validators.required]
          ],
          zip: ['', [Validators.required]],
          latitude: [0],
          longitude: [0],
          address: ''
        }),
        priorAddress: this.fb.group({
          streetNumber: '',
          streetName: '',
          city: '',
          country: '',
          zip: '',
          latitude: '',
          longitude: '',
          address: '',
          state: ''
        }),
        dependents: this.fb.array([]),
        emailAddressCC: this.fb.array([]),
      }),
      customerId: [''],
      // driverPhone: this.fb.array([[this.newDriverNumber()], [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)]]),
      agent: this.fb.group({
        firstName: [
          '',
          [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],
        ],
        lastName: [
          '',
          [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],
        ],
        phoneNumber: [
          '(727) 498-5551',
          [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
        ],
        email: ['', [Validators.required, Validators.email]],
        companyName: [''],
        agentType: ['HH Agent']
      }),
      quoteType: ['Manual'],
      noOfQuotes: ['', [Validators.required]],
      policyStartDate: ['', [Validators.required]],
      updatedPolicyEffectiveDate: [''],
      quoteIdType: ['Auto_Proposal'],
      insuranceType: ['Auto']
    },
  );
  quoteOptionList = [
    {
      name: 'One',
      value: 1,
      selected: false,
    },
    {
      name: 'Two',
      value: 2,
      selected: false,
    },
    {
      name: 'Three',
      value: 3,
      selected: false,
    },
    {
      name: 'Four',
      value: 4,
      selected: false,
    },
    {
      name: 'Five',
      value: 5,
      selected: false,
    },
  ];
  targetOptionList = [
    {
      name: 'Auto',
      value: 'Auto',
      selected: false,
    },
    {
      name: 'Golf Cart',
      value: 'Golf Cart',
      selected: false,
    },
    {
      name: 'Motorcycle',
      value: 'Motorcycle',
      selected: false,
    },
    {
      name: 'Boat',
      value: 'Marine',
      selected: false,
    },
    {
      name: 'Umbrella',
      value: 'Umbrella',
      selected: false,
    },
    {
      name: 'House Owner',
      value: 'House Owner',
      selected: false,
    },
  ];
  startDate = new Date();
  submitting = false;
  merchantId = '';
  agentList = []
  policyEffectiveDateUpdate = false;

  constructor(
    private zone: NgZone,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private stepper: MatStepper,
    private apiService: WizzipayService,
    private createCamelCase: TitleCasePipe,
    private datepipe: DatePipe
  ) {
    this.getAgentList();
  }

  get customer(): FormGroup {
    return this.productDetail.get('customer') as FormGroup;
  }

  get agent(): FormGroup {
    return this.productDetail.get('agent') as FormGroup;
  }

  get address(): FormGroup {
    return this.productDetail.get('customer')?.get('address') as FormGroup;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      params['campaignId'] && sessionStorage.setItem('campaignId', params['campaignId'])
    });
    // this.productDetail.patchValue({
    //   insuranceType: 'Auto',
    // });
    // this.productDetail.controls['insuranceType'].disable();
    const campaignId = sessionStorage.getItem('campaignId')
    campaignId && this.getData(campaignId);
  }

  getAgentData() {
    this.apiService.getAgentList().subscribe({
      next: (agentRes) => {
        for (const agent of agentRes?.data) {
          agent['name'] = `${agent?.firstName} ${agent?.lastName}`;
        }
        this.agentList = agentRes?.data;
      },
      error: () => { }
    })
  }

  getAgentList() {
    this.apiService.getAgentList().subscribe({
      next: (agentList) => this.agentList = agentList?.agents,
      error: () => this.apiService.snackbar('Error')
    })
  }

  quoteData: any;

  getData(campaignId: string) {
    this.apiService.getdata(this.merchantId, campaignId).subscribe((res) => {
      // res.data['policyStartDate'] = this.datepipe.transform(
      //   res?.data?.policyStartDate,
      //   'MM/dd/yyyy'
      // );
      this.quoteData = res?.data;
      if (this.quoteData?.effectiveDateUpdateRequest) {
        this.policyEffectiveDateUpdate = this.quoteData?.effectiveDateUpdateRequest;
        res.data.updatedPolicyEffectiveDate = this.policyEffectiveDateUpdate ? this.quoteData?.effectiveDateRequestList?.at(-1)?.requestedEffectiveDate : '';
      }
      res?.data?.commonCustomerId && this.getCustomer(res?.data?.commonCustomerId);
      res.data.customer.phoneNumber = res?.data?.driverPhone?.[0]?.number;
      res.data.agent.phoneNumber = res?.data?.agent?.number;
      if (res?.data?.noOfQuotes) {
        res.data.noOfQuotes = res?.data?.noOfQuotes + '';
        this.productDetail.controls['noOfQuotes'].disable();
      }
      res.data.customer.phoneNumber = res?.data?.customer?.phoneNumber
        ? res?.data?.customer?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
      res.data.agent.phoneNumber = res?.data?.agent?.phoneNumber
        ? res?.data?.agent?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
      this.productDetail.patchValue(res?.data);
    });
  }

  selectedAgent(agentName: any) {
    const agent: any = this.agentList?.filter((x: any) => x?.fullName === agentName)?.[0];
    agent.phoneNumber = agent?.phoneNumber
      ? agent?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
    agent['email'] = agent?.emailAddress || '';
    this.agent.patchValue(agent);
  }

  getCustomer(customerId: string) {
    this.apiService
      .getCustomerData(customerId)
      .subscribe({
        next: (customerRes) => {
          this.address.patchValue(customerRes?.address)
        },
        error: () => {
          this.submitting = false;
          this.apiService.snackbar('Error');
        }
      });
  }

  resDriverNumber(driverNum: Array<{ [key: string]: string }>): FormArray {
    const formArray = new FormArray([]);
    driverNum.forEach((element) => {
      formArray.push(
        this.fb.group({
          number: [
            element.number,
            [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
          ],
        })
      );
    });
    return formArray;
  }

  setAddress(addrObj: any) {
    this.address?.get('address')?.patchValue(addrObj.address);
    this.zone.run(() => {
      this.addressMaptoForm(addrObj);
    });
  }

  async addressMaptoForm(address: any) {
    this.address?.get('streetNumber')?.patchValue(address?.street_number);
    this.address?.get('city')?.patchValue(address?.city);
    address?.state == 'Florida'
      ? this.address?.get('state')?.patchValue('FL')
      : this.address?.get('state')?.patchValue(address?.state);
    this.address?.get('country')?.patchValue(address?.country);
    this.address?.get('zip')?.patchValue(address?.ZIP_code);
    this.address?.get('streetName')?.patchValue(address?.street_address);
  }

  submit() {
    this.submitting = true;
    if (this.productDetail.invalid) {
      this.productDetail.markAllAsTouched();
      this.submitting = false;
      document
        .getElementById('targetRed')
        ?.scrollIntoView({ behavior: 'smooth' });
      // this.scroller.scrollToAnchor("targetRed");
      this.apiService.snackbar('Fill all the Mandatory Fields');
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth'
      // });
      return;
    }
    let payload: any = this.productDetail.getRawValue();
    payload.customer.firstName = this.createCamelCase.transform(payload.customer.firstName);
    payload.customer.lastName = this.createCamelCase.transform(payload.customer.lastName);
    payload.agent.firstName = this.createCamelCase.transform(payload.agent.firstName);
    payload.agent.lastName = this.createCamelCase.transform(payload.agent.lastName);
    payload.customer.phoneNumber = payload.customer.phoneNumber?.replace(/\D/g, "");
    payload.agent.phoneNumber = payload.agent.phoneNumber?.replace(/\D/g, "");

    const customerPayload = {
      firstName: payload.customer.firstName,
      lastName: payload.customer.lastName,
      middleName: '',
      emailAddress: payload.customer.email,
      phoneNumber: payload.customer.phoneNumber,
      dob: '',
      namePrefix: '',
      nameSuffix: '',
      gender: '',
      maritalStatus: '',
      educationLevel: '',
      industry: '',
      occupation: '',
      ssn: '',
      address: payload.customer?.address,
      priorAddress: {
        streetNumber: '',
        streetName: '',
        city: '',
        country: '',
        zip: '',
        latitude: '',
        longitude: '',
        address: '',
        state: ''
      },
      dependents: [],
      emailAddressCC: [],
    }
    const agentPayload = [
      {
        firstName: payload.agent.firstName,
        lastName: payload.agent.lastName,
        emailAddress: payload.agent.email,
        phoneNumber: payload.agent.phoneNumber,
        companyName: '',
        agentType: 'HH Agent'
      }
    ]
    this.apiService
      .saveCustomerData(customerPayload)
      .subscribe({
        next: (customerRes) => {
          this.apiService
            .saveAgentData({ agent: agentPayload })
            .subscribe({
              next: (agentRes) => {
                let quote: any = {
                  customer: {
                    firstName: payload.customer.firstName,
                    lastName: payload.customer.lastName,
                    email: payload.customer.email
                  },
                  insuranceType: payload?.insuranceType,
                  quoteType: payload?.quoteType,
                  quoteIdType: payload?.quoteIdType,
                  agent: {
                    firstName: payload.agent.firstName,
                    lastName: payload.agent.lastName,
                    number: payload.agent.phoneNumber,
                    email: payload.agent.email
                  },
                  policyStartDate: `${new Date(payload?.policyStartDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
                  driverPhone: [
                    {
                      number: payload.customer.phoneNumber
                    }
                  ],
                  noOfQuotes: +payload?.noOfQuotes,
                  noOfProposals: 1,
                  commonCustomerId: customerRes?.customerId,
                  agentId: agentRes?.agentId
                }
                if (sessionStorage.getItem('campaignId')) {
                  if (this.quoteData?.effectiveDateUpdateRequest) {
                    quote['effectiveDateUpdateRequest'] = this.quoteData?.effectiveDateUpdateRequest;
                    quote['effectiveDateRequestList'] = this.quoteData?.effectiveDateRequestList;
                    quote['effectiveDateRequestList'].push({
                      currentEffectiveDate: `${new Date(payload?.policyStartDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
                      requestedEffectiveDate: `${new Date(payload?.updatedPolicyEffectiveDate)?.toLocaleDateString('en-CA')}T00:00:00.000Z`,
                      updatedBy: 'Agent'
                    })
                  }
                  this.apiService
                    .updateOrder(quote, this.merchantId, sessionStorage.getItem('campaignId'))
                    .subscribe(
                      () => {
                        this.submitting = false;
                        this.apiService.snackbar('Offer Updated');
                        this.stepper.next();
                      },
                      (err) => {
                        this.submitting = false;
                        this.apiService.snackbar(err.message);
                      }
                    );
                } else {
                  this.apiService.sumbitOrder(quote, this.merchantId).subscribe(
                    (res) => {
                      this.submitting = false;
                      sessionStorage.setItem('campaignId', res.data._id);
                      this.apiService.snackbar('Offer Created');
                      this.stepper.next();
                    },
                    (err) => {
                      this.submitting = false;
                      this.apiService.snackbar(err.message);
                    }
                  );
                }
              },
              error: () => {
                this.submitting = false;
                this.apiService.snackbar('Error');
              }
            });
        },
        error: () => {
          this.submitting = false;
          this.apiService.snackbar('Error');
        }
      });
  }

}
