export const carrierDatas = [
  {
    carrierNumber: 1,
    carrierName: 'American Integrity',
    fields: [
      'bodilyInjuryPropertyDamage',
      'medicalPayments',
      'pollutionLiability',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'namedStormDeductible',
      'electronicsDeductible',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'hurricaneHaulOut',
      'newBoatReplacement',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: ['hullMotorCashValue'],
    percentageFields: ['deductible'],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'deductibleValue',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'fuelSpillLiability',
      'personalEffectsValue',
      // 'personalEffectsDeductible',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'propulsionPlus',
      'propulsionPlusDeductible',
      'disappearingDeductible',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'rentalReimbursement',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'consequentialLosses',
      // 'namedStormDeductibleValue',
      // 'valuationOfTrailerValue',
      // 'valuationOfTrailerDeductibleValue'
    ],
    other: [
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
      'valuationOfTrailerDeductibleValue',
    ],
    defaultValues: {
      medicalPayments: '$5,000',
      // namedStormDeductible: '5%',
      policyTerm: '12 MONTHS',
      // personalEffects: 'STANDARD DEDUCTIBLE - $250 DEDUCTIBLE AVAILABLE',
      electronicsDeductible: 'STANDARD DEDUCTIBLE - $250 DEDUCTIBLE AVAILABLE',
      hurricaneHaulOut: '50% UP TO $1,250 INCLUDED',
    },
  },
  {
    carrierNumber: 2,
    carrierName: 'American Modern',
    fields: [
      'bodilyInjuryPropertyDamage',
      'medicalPayments',
      'pollutionLiability',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'hurricaneHaulOut',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: ['hullMotorCashValue'],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'electronicsDeductible',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'fuelSpillLiability',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'propulsionPlus',
      'propulsionPlusDeductible',
      'disappearingDeductible',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'rentalReimbursement',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'consequentialLosses',
      'newBoatReplacement',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
      'personalEffectsValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      onWaterTowing: 'INCLUDED TO $1,000',
      pollutionLiability: '$997,100',
      medicalPayments: '$10,000',
      personalEffects: '$5,000',
      personalEffectsDeductible: '$200',
    },
  },
  {
    carrierNumber: 3,
    carrierName: 'Concept',
    fields: [
      'bodilyInjuryPropertyDamage',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPayments',
      'medicalPaymentsDeductible',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: ['hullMotorCashValue'],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'pollutionLiability',
      'electronicsDeductible',
      'fuelSpillLiability',
      'propulsionPlus',
      'propulsionPlusDeductible',
      'disappearingDeductible',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'rentalReimbursement',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'consequentialLosses',
      'hurricaneHaulOut',
      'newBoatReplacement',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      hullMotorCashType: 'AGREED VALUE',
    },
  },
  {
    carrierNumber: 4,
    carrierName: 'Global Marine',
    fields: [
      'bodilyInjuryPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'valuationOfTrailer',
      'personalEffects',
      'personalEffectsDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'propulsionPlus',
      'disappearingDeductible',
      'hurricaneHaulOut',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
    ],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'valuationOfTrailerDeductible',
      'propulsionPlusDeductible',
      'fuelSpillLiability',
      'rentalReimbursement',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'consequentialLosses',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      medicalPayments: '$10,000',
      onWaterTowing: 'INCLUDED',
      disappearingDeductible: 'INCLUDED',
    },
  },
  {
    carrierNumber: 5,
    carrierName: 'Legacy',
    fields: [
      'bodilyInjuryPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'onWaterTowing',
      'offWaterTowing',
      'rentalReimbursement',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: ['hullMotorCashValue', 'personalEffects'],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'namedStormDeductible',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
      'fuelSpillLiability',
      'personalEffectsDeductible',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'consequentialLosses',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'propulsionPlus',
      'propulsionPlusDeductible',
      'disappearingDeductible',
      'hurricaneHaulOut',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    other: ['deductibleValue'],
    defaultValues: {
      policyTerm: '12 MONTHS',
      medicalPayments: '$5,000',
      onWaterTowing: '$5,000 LIMIT INCLUDED',
      offWaterTowing: 'NOT INCLUDED',
      rentalReimbursement: '$10,000 LIMIT INCLUDED',
    },
  },
  {
    carrierNumber: 6,
    carrierName: 'Markel',
    fields: [
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'valuationOfTrailer',
      'personalEffects',
      'personalEffectsDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: ['hullMotorCashValue'],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'valuationOfTrailerDeductible',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'consequentialLosses',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'propulsionPlus',
      'propulsionPlusDeductible',
      'disappearingDeductible',
      'hurricaneHaulOut',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      medicalPayments: '$5,000',
      onWaterTowing: '$1,000 LIMIT INCLUDED',
      // coastalAndInland: '50 MILES OFFSHORE INCLUDED',
      // extendedNavigationOffshoreAndBahamasCoverage: 'NOT INCLUDED',
    },
  },
  {
    carrierNumber: 7,
    carrierName: 'Safeco',
    fields: [
      'bodilyInjuryPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'fishingEquipment',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: ['hullMotorCashValue'],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'fuelSpillLiability',
      'deductibleValue',
      'pollutionLiability',
      'electronicsDeductible',
      'namedStormDeductible',
      'namedStormDeductibleValue',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'consequentialLosses',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'propulsionPlus',
      'propulsionPlusDeductible',
      'fishingEquipmentDeductible',
      'disappearingDeductible',
      'hurricaneHaulOut',
    ],
    other: [
      'deductibleValue',
      'valuationOfTrailerValue',
      'personalEffectsValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      medicalPayments: '$2,000',
      onWaterTowing: 'INCLUDED',
      offWaterTowing: 'NOT INCLUDED',
      // extendedNavigationOffshoreAndBahamasCoverage: 'NOT INCLUDED',
    },
  },
  {
    carrierNumber: 8,
    carrierName: 'Ski Safe',
    fields: [
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'valuationOfTrailer',
      'personalEffects',
      'personalEffectsDeductible',
      'disappearingDeductible',
      'hurricaneHaulOut',
      'consequentialLosses',
      'onWaterTowing',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: ['hullMotorCashValue'],
    removeFields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'valuationOfTrailerDeductible',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      fuelSpillLiability: '$997,100',
      medicalPayments: '$25,000',
      onWaterTowing: 'INCLUDED',
      offWaterTowing: 'NOT INCLUDED',
      hurricaneHaulOut: '50% UP TO $1,250',
      hullMotorCashType: 'AGREED VALUE',
      // coastalAndInland: 'INCLUDED',
      // extendedNavigationOffshoreAndBahamasCoverage: 'NOT INCLUDED',
    },
  },
  {
    carrierNumber: 9,
    carrierName: 'Chubb',
    lengthOfBoat: '35ft or less',
    ageOfBoat: '0-3 yrs',
    coverageType: 'Standard',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'hurricaneHaulOut',
      'consequentialLosses',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'personalEffectsDeductible',
      'disappearingDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      hullMotorCashType: 'AGREED VALUE',
      personalEffects: '$2,500 LIMIT INCLUDED',
      onWaterTowing: '$1,500 LIMIT INCLUDED',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - ACTUAL CASH VALUE',
      tenderDinghyDeductible: '$250',
      consequentialLosses: 'EXCLUDED',
      hurricaneHaulOut: '$1,000 / OCCURRENCE INCLUDED',
      // coastalAndInland: 'INCLUDED',
    },
  },
  {
    carrierNumber: 10,
    carrierName: 'Chubb',
    lengthOfBoat: '35ft or less',
    ageOfBoat: '0-3 yrs',
    coverageType: 'Enhanced',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'hurricaneHaulOut',
      'consequentialLosses',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'personalEffectsDeductible',
      'disappearingDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      hullMotorCashType: 'EXTENDED REPLACEMENT COST',
      personalEffects: '$5,000 LIMIT INCLUDED',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - REPLACEMENT COST',
      tenderDinghyDeductible: '$0',
      consequentialLosses: 'INCLUDED',
      hurricaneHaulOut: '$1,500 / OCCURRENCE INCLUDED',
      // coastalAndInland: 'INCLUDED',
    },
  },
  {
    carrierNumber: 11,
    carrierName: 'Chubb',
    lengthOfBoat: '35ft or less',
    ageOfBoat: '3+ yrs',
    coverageType: 'Standard',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'hurricaneHaulOut',
      'consequentialLosses',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'personalEffectsDeductible',
      'disappearingDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      hullMotorCashType: 'AGREED VALUE',
      personalEffects: '$2,500 LIMIT INCLUDED',
      onWaterTowing: '$1,500 LIMIT INCLUDED',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - ACTUAL CASH VALUE',
      tenderDinghyDeductible: '$250',
      consequentialLosses: 'EXCLUDED',
      hurricaneHaulOut: '$1,000 / OCCURRENCE INCLUDED',
      // coastalAndInland: 'INCLUDED',
      extendedNavigationOffshoreAndBahamasCoverage: 'INCLUDED',
    },
  },
  {
    carrierNumber: 12,
    carrierName: 'Chubb',
    lengthOfBoat: '35ft or less',
    ageOfBoat: '3+ yrs',
    coverageType: 'Enhanced',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'hurricaneHaulOut',
      'consequentialLosses',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'onWaterTowing',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'personalEffectsDeductible',
      'disappearingDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      hullMotorCashType: 'EXTENDED REPLACEMENT COST',
      personalEffects: '$5,000 LIMIT INCLUDED',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - REPLACEMENT COST',
      tenderDinghyDeductible: '$0',
      consequentialLosses: 'INCLUDED',
      hurricaneHaulOut: '$1,500 / OCCURRENCE INCLUDED',
      // coastalAndInland: 'INCLUDED',
      extendedNavigationOffshoreAndBahamasCoverage: 'INCLUDED',
    },
  },
  {
    carrierNumber: 13,
    carrierName: 'Chubb',
    lengthOfBoat: 'greater than 35ft',
    ageOfBoat: '0-3 yrs',
    coverageType: 'Standard',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'hurricaneHaulOut',
      'consequentialLosses',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'disappearingDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowing',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      personalEffects: '$10,000 LIMIT INCLUDED',
      personalEffectsDeductible: '$250',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - ACTUAL CASH VALUE',
      tenderDinghyDeductible: '$250',
      consequentialLosses: 'EXCLUDED',
      hurricaneHaulOut: '$1,000 / OCCURRENCE INCLUDED',
      hullMotorCashType: 'AGREED VALUE',
      // coastalAndInland: 'INCLUDED',
      extendedNavigationOffshoreAndBahamasCoverage: 'INCLUDED',
    },
  },
  {
    carrierNumber: 14,
    carrierName: 'Chubb',
    lengthOfBoat: 'greater than 35ft',
    ageOfBoat: '0-3 yrs',
    coverageType: 'Enhanced',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'hurricaneHaulOut',
      'consequentialLosses',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'offWaterTowing',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'disappearingDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowing',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      personalEffects: '$25,000 LIMIT INCLUDED',
      personalEffectsDeductible: '$0',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - REPLACEMENT COST',
      tenderDinghyDeductible: '$0',
      consequentialLosses: 'INCLUDED',
      hurricaneHaulOut: '$2,500 / OCCURRENCE INCLUDED',
      hullMotorCashType: 'EXTENDED REPLACEMENT COST',
      // coastalAndInland: 'INCLUDED',
      extendedNavigationOffshoreAndBahamasCoverage: 'INCLUDED',
    },
  },
  {
    carrierNumber: 15,
    carrierName: 'Chubb',
    lengthOfBoat: 'greater than 35ft',
    ageOfBoat: '3+ yrs',
    coverageType: 'Standard',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'hurricaneHaulOut',
      'consequentialLosses',
      'onWaterTowing',
      'offWaterTowing',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'disappearingDeductible',
      'electronicsDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      personalEffects: '$10,000 LIMIT INCLUDED',
      personalEffectsDeductible: '$250',
      onWaterTowing: '$1,500 LIMIT INCLUDED',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - ACTUAL CASH VALUE',
      tenderDinghyDeductible: '$250',
      fishingEquipment: '$10,000 LIMIT INCLUDED',
      fishingEquipmentDeductible: '$500',
      consequentialLosses: 'EXCLUDED',
      hurricaneHaulOut: '$1,000 / OCCURRENCE INCLUDED',
      hullMotorCashType: 'AGREED VALUE',
      // coastalAndInland: 'INCLUDED',
      extendedNavigationOffshoreAndBahamasCoverage: 'INCLUDED',
    },
  },
  {
    carrierNumber: 16,
    carrierName: 'Chubb',
    lengthOfBoat: 'greater than 35ft',
    ageOfBoat: '3+ yrs',
    coverageType: 'Enhanced',
    fields: [
      'liabilityBodilyInjury',
      'liabilityPropertyDamage',
      'medicalPayments',
      'uninsuredBoatersBodilyInjury',
      'hullMotorCashType',
      'deductible',
      'namedStormDeductible',
      'personalEffects',
      'personalEffectsDeductible',
      'hurricaneHaulOut',
      'consequentialLosses',
      'onWaterTowing',
      'offWaterTowing',
      'tenderDinghy',
      'tenderDinghyDeductible',
      'fishingEquipment',
      'fishingEquipmentDeductible',
      'coastalAndInland',
      'extendedNavigationOffshoreAndBahamasCoverage',
    ],
    currencyFields: [
      'hullMotorCashValue',
      'valuationOfTrailer',
      'valuationOfTrailerDeductible',
    ],
    removeFields: [
      'monthlyPayPremium',
      'monthlyPayPremiumPerMonth',
      'bodilyInjuryPropertyDamage',
      'fuelSpillLiability',
      'bodilyInjuryPropertyDamageDeductible',
      'medicalPaymentsDeductible',
      'pollutionLiability',
      'electronicsDeductible',
      'rentalReimbursement',
      'newBoatReplacement',
      'disappearingDeductible',
      'valuationOfTrailerDeductibleValue',
      'personalEffectsValue',
      'personalEffectsDeductibleValue',
      'onWaterTowingValue',
      'offWaterTowingValue',
      'propulsionPlus',
      'propulsionPlusDeductible',
    ],
    other: [
      'deductibleValue',
      'namedStormDeductibleValue',
      'valuationOfTrailerValue',
    ],
    defaultValues: {
      policyTerm: '12 MONTHS',
      personalEffects: '$25,000 LIMIT INCLUDED',
      personalEffectsDeductible: '$0',
      onWaterTowing: '$1,100,000 LIMIT INCLUDED',
      offWaterTowing: 'INCLUDED',
      tenderDinghy: 'INCLUDED - REPLACEMENT COST',
      tenderDinghyDeductible: '$0',
      fishingEquipment: '$25,000 LIMIT INCLUDED',
      fishingEquipmentDeductible: '$500',
      consequentialLosses: 'INCLUDED',
      hurricaneHaulOut: '$2,500 / OCCURRENCE INCLUDED',
      hullMotorCashType: 'EXTENDED REPLACEMENT COST',
      // coastalAndInland: 'INCLUDED',
      extendedNavigationOffshoreAndBahamasCoverage: 'INCLUDED',
    },
  },
];

export const paymentMethodOption = [
  {
    name: 'Electronic Funds Transfer',
    value: 'Electronic Funds Transfer',
    selected: false,
  },
  {
    name: 'Credit Card',
    value: 'Credit Card',
    selected: false,
  },
];

export const paymentModeOption = [
  {
    name: 'Full Pay Premium',
    value: 'Full Pay Premium',
    selected: false,
  },
  // {
  //   name: 'Installment',
  //   value: 'Installment',
  //   selected: false,
  // },
  // {
  //   name: 'Installment',
  //   value: 'Installment',
  //   selected: false,
  // },
  // {
  //   name: 'Installment',
  //   value: 'Installment',
  //   selected: false,
  // },
];

export const autoCarrierList = [
  {
    name: 'Bristol West',
    value: 'Bristol West',
  },
  {
    name: 'Hagerty',
    value: 'Hagerty',
  },
  {
    name: 'Mercury',
    value: 'Mercury',
  },
  // {
  //   name: 'Metlife (Foremost)',
  //   value: 'Metlife (Foremost)',
  // },
  {
    name: 'Progressive',
    value: 'Progressive',
  },
  // {
  //   name: 'Progressive with Snapshot',
  //   value: 'Progressive with Snapshot',
  // },
  {
    name: 'Safeco',
    value: 'Safeco',
  },
  {
    name: 'Travelers',
    value: 'Travelers',
  },
  {
    name: 'Vault',
    value: 'Vault',
  },
];
export const autoCarrierData = [
  {
    carrierNumber: 1,
    carrierName: 'Bristol West',
    //input
    fields: [
      'policyTerm',
      'coverageABodilyInjuryPerPersonPerAccident',
      'coverageAPropoertyDamagePerAccident',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPersonPerAccident',
      'coverageCUninsuredMotorist',
      'personalInjuryProtectionLimit',
      'pipWorkExclusion',
      'pipDeductible',
      'pipAppliesTo',
      'extendedPip',
      'gapCoverage'
    ],
    currencyFields: [
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
    ],
    removeFields: [
      'enrolledInMercuryGo',
      'enrolledInSnapshot',
      'enrolledInRightTrack',
      'enrolledInIntelliDrive',
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'ultraCoverage',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'additionalPip',
      'originalEquipmentManufacturer',
      'enhancedPackage'
    ],
    //dropdown
    dropDownData: {
      carrierName: 'Bristol West',
      policyTerm: [
        { name: '12 Months', value: '12 Months' },
        { name: '6 Months', value: '6 Months' },
      ],
      coverageABodilyInjuryPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
      ],
      coverageAPropoertyDamagePerAccident: [
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
        { name: '$100,000', value: '$100,000' },
      ],
      coverageBMedicalPayments: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$2,000', value: '$2,000' },
        { name: '$5,000', value: '$5,000' },
      ],
      coverageCUninsuredMotoristPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
      ],
      coverageCUninsuredMotorist: [
        { name: 'N/A', value: 'N/A' },
        { name: 'Stacking', value: 'Stacking' },
        { name: 'Non-Stacking', value: 'Non-Stacking' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDComprehensiveCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$2,500', value: '$2,500' },
      ],
      coverageDCollisionCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDCollisionCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$2,500', value: '$2,500' },
      ],
      pipWorkExclusion: [
        { name: 'Workloss Excluded', value: 'Workloss Excluded' },
        { name: 'Workloss Included', value: 'Workloss Included' },
      ],
      pipDeductible: [
        { name: '$0', value: '$0' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' }
      ],
      pipAppliesTo: [
        { name: 'Named Insured Only', value: 'Named Insured Only' },
        { name: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)', value: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)' },
      ],
      extendedPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      rentalCarCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Included $30/day (30day max)', value: 'Included $30/day (30day max)' },
        { name: 'Included $40/day (30day max)', value: 'Included $40/day (30day max)' },
        { name: 'Included $50/day (30day max)', value: 'Included $50/day (30day max)' },
        { name: 'Included $60/day (30day max)', value: 'Included $60/day (30day max)' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceAndTowing: [
        { name: 'Included', value: 'Included' },
        { name: 'Not Included', value: 'Not Included' },
      ],
      gapCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Not Included', value: 'Not Included' },
      ],
    },
    defaultValues: {
      coverageCUninsuredMotorist: 'Non-Stacking',
      personalInjuryProtectionLimit: '$10,000',
      pipWorkExclusion: 'Workloss Included',
      pipDeductible: '$1,000',
      extendedPip: 'Available',
      gapCoverage: 'Not Included',
      rentalCarCoverage: 'Included $40/day (30day max)',
      roadsideAssistanceAndTowing: 'Included',
    },
  },
  {
    carrierNumber: 2,
    carrierName: 'Hagerty',
    fields: [
      'policyTerm',
      'coverageABodilyInjuryPerPersonPerAccident',
      'coverageAPropoertyDamagePerAccident',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPersonPerAccident',
      'coverageCUninsuredMotorist',
      'personalInjuryProtectionLimit',
      'pipWorkExclusion',
      'pipDeductible',
      'pipAppliesTo',
      'extendedPip',
    ],
    currencyFields: [
      'fullPayMonthlyPremium',
    ],
    removeFields: [
      'enrolledInMercuryGo',
      'enrolledInSnapshot',
      'enrolledInRightTrack',
      'enrolledInIntelliDrive',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'ultraCoverage',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'additionalPip',
      'gapCoverage',
      'originalEquipmentManufacturer',
      'enhancedPackage'
    ],
    dropDownData: {
      policyTerm: [
        { name: '12 Months', value: '12 Months' },
        { name: '6 Months', value: '6 MONTHS' },
      ],
      coverageABodilyInjuryPerPersonPerAccident: [
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$300,000/$300,000', value: '$300,000/$300,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: '$30,000 CSL', value: '$30,000 CSL' },
        { name: '$50,000 CSL', value: '$50,000 CSL' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageAPropoertyDamagePerAccident: [
        { name: 'Declined', value: 'Declined' },
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
        { name: '$100,000', value: '$100,000' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageBMedicalPayments: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$1,000', value: '$1,000' },
        { name: '$5,000', value: '$5,000' },
      ],
      coverageCUninsuredMotoristPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$300,000/$300,000', value: '$300,000/$300,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: '$30,000 CSL', value: '$30,000 CSL' },
        { name: '$50,000 CSL', value: '$50,000 CSL' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageCUninsuredMotorist: [
        { name: 'N/A', value: 'N/A' },
        { name: 'Stacking', value: 'Stacking' },
        { name: 'Non-Stacking', value: 'Non-Stacking' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDComprehensiveCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$0', value: '$0' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,500', value: '$2,500' },
        { name: '$5,000', value: '$5,000' },
      ],
      coverageDCollisionCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDCollisionCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$0', value: '$0' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,500', value: '$2,500' },
        { name: '$5,000', value: '$5,000' },
      ],
      pipWorkExclusion: [
        { name: 'Workloss Excluded', value: 'Workloss Excluded' },
        { name: 'Workloss Included', value: 'Workloss Included' },
      ],
      pipAppliesTo: [
        { name: 'Named Insured Only', value: 'Named Insured Only' },
        { name: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)', value: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)' },
      ],
      extendedPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      roadsideAssistanceAndTowing: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
    },
    defaultValues: {
      coverageBMedicalPayments: '$5,000',
      coverageCUninsuredMotorist: 'Non-Stacking',
      coverageDComprehensiveCoverage: 'Included',
      coverageDCollisionCoverage: 'Included',
      pipDeductible: '$0',
      personalInjuryProtectionLimit: '$10,000',
      pipWorkExclusion: 'Workloss Included',
      extendedPip: 'Available',
      roadsideAssistanceAndTowing: 'Included',
    },
  },
  {
    carrierNumber: 3,
    carrierName: 'Mercury',
    fields: [
      'policyTerm',
      'enrolledInMercuryGo',
      'coverageABodilyInjuryPerPersonPerAccident',
      'coverageAPropoertyDamagePerAccident',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPersonPerAccident',
      'coverageCUninsuredMotorist',
      'personalInjuryProtectionLimit',
      'pipWorkExclusion',
      'pipDeductible',
      'pipAppliesTo',
      'extendedPip',
      'gapCoverage',
      'originalEquipmentManufacturer'
    ],
    currencyFields: [
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
    ],
    removeFields: [
      'enrolledInSnapshot',
      'enrolledInRightTrack',
      'enrolledInIntelliDrive',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'ultraCoverage',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'additionalPip',
      'enhancedPackage'
    ],
    dropDownData: {
      enrolledInMercuryGo: [
        { name: 'Yes', value: 'Yes' },
        { name: 'No', value: 'No' },
      ],
      policyTerm: [
        { name: '12 Months', value: '12 Months' },
        { name: '6 Months', value: '6 MONTHS' },
      ],
      coverageABodilyInjuryPerPersonPerAccident: [
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$300,000/$300,000', value: '$300,000/$300,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
      ],
      coverageAPropoertyDamagePerAccident: [
        { name: '$10,000', value: '$10,000' },
        { name: '$20,000', value: '$20,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
        { name: '$100,000', value: '$100,000' },
        { name: '$250,000', value: '$250,000' },
      ],
      coverageBMedicalPayments: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$1,000', value: '$1,000' },
        { name: '$2,000', value: '$2,000' },
        { name: '$5,000', value: '$5,000' },
        { name: '$10,000', value: '$10,000' },
      ],
      coverageCUninsuredMotoristPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$100,000', value: '$100,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$300,000/$300,000', value: '$300,000/$300,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: 'Waived', value: 'Waived' },
      ],
      coverageCUninsuredMotorist: [
        { name: 'N/A', value: 'N/A' },
        { name: 'Stacking', value: 'Stacking' },
        { name: 'Non-Stacking', value: 'Non-Stacking' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDComprehensiveCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' }
      ],
      coverageDCollisionCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDCollisionCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
      ],
      pipWorkExclusion: [
        { name: 'Workloss Excluded', value: 'Workloss Excluded' },
        { name: 'Workloss Included', value: 'Workloss Included' },
      ],
      pipDeductible: [
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' }
      ],
      pipAppliesTo: [
        { name: 'Named Insured Only', value: 'Named Insured Only' },
        { name: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)', value: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)' },
      ],
      extendedPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      rentalCarCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Included $40/day (30day max)', value: 'Included $40/day (30day max)' },
        { name: 'Included $50/day (30day max)', value: 'Included $50/day (30day max)' },
        { name: 'Included $70/day (30day max)', value: 'Included $70/day (30day max)' },
        { name: 'Included $100/day (30day max)', value: 'Included $100/day (30day max)' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceAndTowing: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      gapCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      originalEquipmentManufacturer: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
    },
    defaultValues: {
      enrolledInMercuryGo: 'Yes',
      coverageCUninsuredMotorist: 'Non-Stacking',
      coverageDComprehensiveCoverage: 'Included',
      coverageDCollisionCoverage: 'Included',
      personalInjuryProtectionLimit: '$10,000',
      pipWorkExclusion: 'Workloss Included',
      extendedPip: 'Available',
      gapCoverage: 'Available',
      rentalCarCoverage: 'Included $40/day (30day max)',
      roadsideAssistanceAndTowing: 'Included',
    },
  },
  {
    carrierNumber: 4,
    carrierName: 'Metlife (Foremost)',
    fields: [
      'vehiclesCovered',
      'coverageABodilyInjury',
      'bodilyInjuryandPropertyDamagePerAccidentPd',
      'bodilyInjuryandPropertyDamagePerAccidentBi',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPerson',
      'coverageCUninsuredMotoristPerAccident',
      'coverageDComprehensiveCoverageDeductible',
      'coverageDCollisionCoverageDeductible',
      'personalInjuryProtectionLimit',
      'pipDeductible',
      'pipApplies',
      'uninsuredMotorist',
      'coverageDComprehensiveCoverage',
      'coverageDCollisionCoverage',
      'pipWorkExclusion',
      'extendedPersonalInjuryProtection',
      'rentalCarCoverage',
      'roadsideAssistanceandTowing',
      'gapCoverage',
    ],
    currencyFields: [
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialInstallmentEftInstallment',
      'eftTotalCostPremium',
    ],
    removeFields: [
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'ccPayPlanMonthlyPremium',
      'ccTotalCostPremium',
      'initialCcInstallment',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremimPerMonth',
      'coverageCUninsuredMotoristStackingorNonStacking',
      'guaranteedValue',
      'ultraCoverage',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'additionalPersonalInjuryProtection',
    ],
    dropDownData: {
      carrierName: 'Metlife (Foremost)',
      policyTerm: [
        { name: '12 MONTHS', value: '12 MONTHS' },
        { name: '6 MONTHS', value: '6 MONTHS' },
      ],
      uninsuredMotorist: [
        { name: 'STACKING', value: 'STACKING' },
        { name: 'NON STACKING', value: 'NON STACKING' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'INCLUDED', value: 'INCLUDED' },
        { name: 'EXCLUDED', value: 'EXCLUDED' },
      ],
      coverageDCollisionCoverage: [
        { name: 'INCLUDED', value: 'INCLUDED' },
        { name: 'EXCLUDED', value: 'EXCLUDED' },
      ],
      pipWorkExclusion: [
        { name: 'WORKLOSS INCLUDED', value: 'WORKLOSS INCLUDED' },
        { name: 'WORKLOSS EXCLUDED', value: 'WORKLOSS EXCLUDED' },
      ],
      extendedPersonalInjuryProtection: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        { name: 'INCLUDED', value: 'INCLUDED' },
      ],
      rentalCarCoverage: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        {
          name: 'INCLUDED $40/day (30day max)',
          value: 'INCLUDED $40/day (30day max)',
        },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceandTowing: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        { name: 'INCLUDED', value: 'INCLUDED' },
      ],
      gapCoverage: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        { name: 'INCLUDED', value: 'INCLUDED' },
      ],
    },
    defaultValues: {
      pipApplies: 'NAMED INSURED, SPOUSE, DEPENDENT, AND/OR RELATIVES',
      uninsuredMotorist: 'NON STACKING',
      coverageDComprehensiveCoverage: 'INCLUDED',
      coverageDCollisionCoverage: 'INCLUDED',
      pipWorkExclusion: 'WORKLOSS INCLUDED',
      extendedPersonalInjuryProtection: 'AVAILABLE',
      roadsideAssistanceandTowing: 'INCLUDED',
      personalInjuryProtectionLimit: '$10,000.00',
      coverageBMedicalPayments: '$10,000.00',
      pipDeductible: '$0',
      rentalCarCoverage: 'INCLUDED $40/day (30day max)',
      gapCoverage: 'AVAILABLE',
    },
  },
  {
    carrierNumber: 5,
    carrierName: 'Progressive',
    fields: [
      'policyTerm',
      'enrolledInSnapshot',
      'coverageABodilyInjuryPerPersonPerAccident',
      'coverageAPropoertyDamagePerAccident',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPersonPerAccident',
      'coverageCUninsuredMotorist',
      'personalInjuryProtectionLimit',
      'pipWorkExclusion',
      'pipDeductible',
      'pipAppliesTo',
      'extendedPip',
      'additionalPip',
      'gapCoverage'
    ],
    currencyFields: [
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
    ],
    removeFields: [
      'enrolledInMercuryGo',
      'enrolledInRightTrack',
      'enrolledInIntelliDrive',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'ultraCoverage',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'originalEquipmentManufacturer',
      'enhancedPackage'
    ],
    dropDownData: {
      enrolledInSnapshot: [
        { name: 'Yes', value: 'Yes' },
        { name: 'No', value: 'No' },
      ],
      policyTerm: [
        { name: '12 Months', value: '12 Months' },
        { name: '6 Months', value: '6 MONTHS' },
      ],
      coverageABodilyInjuryPerPersonPerAccident: [
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageAPropoertyDamagePerAccident: [
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
        { name: '$100,000', value: '$100,000' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageBMedicalPayments: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$2,000', value: '$2,000' },
        { name: '$5,000', value: '$5,000' },
        { name: '$10,000', value: '$10,000' },
      ],
      coverageCUninsuredMotoristPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$500,000 CSL', value: '$500,000 CSL' }
      ],
      coverageCUninsuredMotorist: [
        { name: 'N/A', value: 'N/A' },
        { name: 'Stacking', value: 'Stacking' },
        { name: 'Non-Stacking', value: 'Non-Stacking' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDComprehensiveCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$750', value: '$750' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,000', value: '$2,000' }
      ],
      coverageDCollisionCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDCollisionCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$750', value: '$750' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,000', value: '$2,000' }
      ],
      pipWorkExclusion: [
        { name: 'Workloss Excluded', value: 'Workloss Excluded' },
        { name: 'Workloss Included', value: 'Workloss Included' },
      ],
      pipDeductible: [
        { name: '$0', value: '$0' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' }
      ],
      pipAppliesTo: [
        { name: 'Named Insured Only', value: 'Named Insured Only' },
        { name: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)', value: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)' },
      ],
      extendedPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      additionalPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      rentalCarCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Included $40 per day ($1,200 max)', value: 'Included $40 per day ($1,200 max)' },
        { name: 'Included $50 per day ($1,500 max)', value: 'Included $50 per day ($1,500 max)' },
        { name: 'Included $60 per day ($1,800 max)', value: 'Included $60 per day ($1,800 max)' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceAndTowing: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      gapCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ]
    },
    defaultValues: {
      enrolledInSnapshot: 'Yes',
      coverageCUninsuredMotorist: 'Non-Stacking',
      coverageDComprehensiveCoverage: 'Included',
      coverageDCollisionCoverage: 'Included',
      personalInjuryProtectionLimit: '$10,000',
      pipWorkExclusion: 'Workloss Included',
      extendedPip: 'Available',
      additionalPip: 'Available',
      rentalCarCoverage: 'Included $40 per day ($1,200 max)',
      roadsideAssistanceAndTowing: 'Included',
      gapCoverage: 'Available'
    },
  },
  {
    carrierNumber: 6,
    carrierName: 'Progressive with Snapshot',
    fields: [
      'vehiclesCovered',
      'coverageABodilyInjury',
      'bodilyInjuryandPropertyDamagePerAccidentPd',
      'bodilyInjuryandPropertyDamagePerAccidentBi',
      'coverageCUninsuredMotoristPerPerson',
      'coverageCUninsuredMotoristPerAccident',
      'coverageDComprehensiveCoverageDeductible',
      'coverageDCollisionCoverageDeductible',
      'personalInjuryProtectionLimit',
      'pipDeductible',
      'pipApplies',
      'policyTerm',
      'coverageBMedicalPayments',
      'uninsuredMotorist',
      'coverageDComprehensiveCoverage',
      'coverageDCollisionCoverage',
      'pipWorkExclusion',
      'extendedPersonalInjuryProtection',
      'rentalCarCoverage',
      'roadsideAssistanceandTowing',
      'gapCoverage',
    ],
    currencyFields: [
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialInstallmentEftInstallment',
      'initialCcInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'ccTotalCostPremium',
    ],
    removeFields: [
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremimPerMonth',
      'coverageCUninsuredMotoristStackingorNonStacking',
      'guaranteedValue',
      'ultraCoverage',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'additionalPersonalInjuryProtection'
    ],
    dropDownData: {
      policyTerm: [
        { name: '12 MONTHS', value: '12 MONTHS' },
        { name: '6 MONTHS', value: '6 MONTHS' },
      ],
      coverageBMedicalPayments: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        { name: 'INCLUDED', value: 'INCLUDED' },
      ],
      uninsuredMotorist: [
        { name: 'STACKING', value: 'STACKING' },
        { name: 'NON STACKING', value: 'NON STACKING' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'INCLUDED', value: 'INCLUDED' },
        { name: 'EXCLUDED', value: 'EXCLUDED' },
      ],
      coverageDCollisionCoverage: [
        { name: 'INCLUDED', value: 'INCLUDED' },
        { name: 'EXCLUDED', value: 'EXCLUDED' },
      ],
      pipWorkExclusion: [
        { name: 'WORKLOSS INCLUDED', value: 'WORKLOSS INCLUDED' },
        { name: 'WORKLOSS EXCLUDED', value: 'WORKLOSS EXCLUDED' },
      ],
      extendedPersonalInjuryProtection: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        { name: 'INCLUDED', value: 'INCLUDED' },
      ],
      rentalCarCoverage: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        {
          name: 'INCLUDED $40/day (30day max)',
          value: 'INCLUDED $40/day (30day max)',
        },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceandTowing: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        { name: 'INCLUDED', value: 'INCLUDED' },
      ],
      gapCoverage: [
        { name: 'AVAILABLE', value: 'AVAILABLE' },
        { name: 'INCLUDED', value: 'INCLUDED' },
      ],
    },
    defaultValues: {
      pipApplies: 'NAMED INSURED, SPOUSE, DEPENDENT, AND/OR RELATIVES',
      policyTerm: '12 MONTHS',
      coverageBMedicalPayments: 'INCLUDED',
      uninsuredMotorist: 'NON STACKING',
      coverageDComprehensiveCoverage: 'INCLUDED',
      coverageDCollisionCoverage: 'INCLUDED',
      pipWorkExclusion: 'WORKLOSS INCLUDED',
      extendedPersonalInjuryProtection: 'AVAILABLE',
      roadsideAssistanceandTowing: 'INCLUDED',
      personalInjuryProtectionLimit: '$10,000.00',
      pipDeductible: '$0',
      rentalCarCoverage: 'INCLUDED $40/day (30day max)',
      gapCoverage: 'AVAILABLE',
    },
  },
  {
    carrierNumber: 7,
    carrierName: 'Safeco',
    fields: [
      'policyTerm',
      'enrolledInRightTrack',
      'coverageABodilyInjuryPerPersonPerAccident',
      'coverageAPropoertyDamagePerAccident',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPersonPerAccident',
      'coverageCUninsuredMotorist',
      'personalInjuryProtectionLimit',
      'pipWorkExclusion',
      'pipDeductible',
      'pipAppliesTo',
      'ultraCoverage',
      'extendedPip',
      'gapCoverage',
      'enhancedPackage'
    ],
    currencyFields: [
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
    ],
    removeFields: [
      'enrolledInMercuryGo',
      'enrolledInSnapshot',
      'enrolledInIntelliDrive',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'additionalPip',
      'originalEquipmentManufacturer'
    ],
    dropDownData: {
      enrolledInRightTrack: [
        { name: 'Yes', value: 'Yes' },
        { name: 'No', value: 'No' },
      ],
      policyTerm: [
        { name: '12 Months', value: '12 Months' },
        { name: '6 Months', value: '6 MONTHS' },
      ],
      coverageABodilyInjuryPerPersonPerAccident: [
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageAPropoertyDamagePerAccident: [
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
        { name: '$100,000', value: '$100,000' },
        { name: '$300,000', value: '$300,000' },
        { name: '$500,000', value: '$500,000' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageBMedicalPayments: [
        { name: 'No Coverage', value: 'No Coverage' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$2,000', value: '$2,000' },
        { name: '$5,000', value: '$5,000' }
      ],
      coverageCUninsuredMotoristPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$100,000', value: '$100,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$300,000/$300,000', value: '$300,000/$300,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
        { name: 'Waived', value: 'Waived' }
      ],
      coverageCUninsuredMotorist: [
        { name: 'N/A', value: 'N/A' },
        { name: 'Stacking', value: 'Stacking' },
        { name: 'Non-Stacking', value: 'Non-Stacking' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDComprehensiveCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$750', value: '$750' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' }
      ],
      coverageDCollisionCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDCollisionCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$750', value: '$750' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' }
      ],
      pipWorkExclusion: [
        { name: 'Workloss Excluded', value: 'Workloss Excluded' },
        { name: 'Workloss Included', value: 'Workloss Included' },
      ],
      pipDeductible: [
        { name: '$0', value: '$0' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' }
      ],
      pipAppliesTo: [
        { name: 'Named Insured Only', value: 'Named Insured Only' },
        { name: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)', value: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)' },
      ],
      extendedPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Unavailable', value: 'Unavailable' },
        { name: 'Included', value: 'Included' },
      ],
      ultraCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      rentalCarCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Included $25 per day (30day max)', value: 'Included $25 per day (30day max)' },
        { name: 'Included $35 per day (30day max)', value: 'Included $35 per day (30day max)' },
        { name: 'Included $50 per day (30day max)', value: 'Included $50 per day (30day max)' },
        { name: 'Included $75 per day (30day max)', value: 'Included $75 per day (30day max)' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceAndTowing: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      gapCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Unavailable', value: 'Unavailable' },
        { name: 'Included', value: 'Included' }
      ],
      enhancedPackage: [
        { name: 'Available', value: 'Available' },
        { name: 'Unavailable', value: 'Unavailable' },
        { name: 'Included', value: 'Included' }
      ],
    },
    defaultValues: {
      enrolledInRightTrack: 'Yes',
      coverageCUninsuredMotorist: 'Non-Stacking',
      coverageDComprehensiveCoverage: 'Included',
      coverageDCollisionCoverage: 'Included',
      personalInjuryProtectionLimit: '$10,000',
      pipWorkExclusion: 'Workloss Included',
      extendedPip: 'Available',
      ultraCoverage: 'Available',
      rentalCarCoverage: 'Included $35 per day (30day max)',
      roadsideAssistanceAndTowing: 'Included',
      gapCoverage: 'Available'
    },
  },
  {
    carrierNumber: 8,
    carrierName: 'Travelers',
    fields: [
      'policyTerm',
      'enrolledInIntelliDrive',
      'coverageABodilyInjuryPerPersonPerAccident',
      'coverageAPropoertyDamagePerAccident',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPersonPerAccident',
      'coverageCUninsuredMotorist',
      'personalInjuryProtectionLimit',
      'pipWorkExclusion',
      'pipDeductible',
      'pipAppliesTo',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'extendedPip',
      'gapCoverage'
    ],
    currencyFields: [
      'paidInFull',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
    ],
    removeFields: [
      'enrolledInMercuryGo',
      'enrolledInSnapshot',
      'enrolledInRightTrack',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
      'ultraCoverage',
      'additionalPip',
      'originalEquipmentManufacturer',
      'enhancedPackage'
    ],
    dropDownData: {
      enrolledInIntelliDrive: [
        { name: 'Yes', value: 'Yes' },
        { name: 'No', value: 'No' },
      ],
      policyTerm: [
        // { name: '12 Months', value: '12 Months' },
        { name: '6 Months', value: '6 MONTHS' },
      ],
      coverageABodilyInjuryPerPersonPerAccident: [
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageAPropoertyDamagePerAccident: [
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
        { name: '$100,000', value: '$100,000' },
        { name: '$250,000', value: '$250,000' },
        { name: '$300,000', value: '$300,000' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
      ],
      coverageBMedicalPayments: [
        { name: 'No Coverage', value: 'No Coverage' },
        { name: '$1,000', value: '$1,000' },
        { name: '$2,000', value: '$2,000' },
        { name: '$5,000', value: '$5,000' },
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
      ],
      coverageCUninsuredMotoristPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$25,000/$50,000', value: '$25,000/$50,000' },
        { name: '$50,000/$100,000', value: '$50,000/$100,000' },
        { name: '$100,000/$300,000', value: '$100,000/$300,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: '$100,000 CSL', value: '$100,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' }
      ],
      coverageCUninsuredMotorist: [
        { name: 'N/A', value: 'N/A' },
        { name: 'Stacking', value: 'Stacking' },
        { name: 'Non-Stacking', value: 'Non-Stacking' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDComprehensiveCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,500', value: '$2,500' },
        { name: '$5,000', value: '$5,000' },
      ],
      coverageDCollisionCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDCollisionCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$100', value: '$100' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,500', value: '$2,500' },
        { name: '$5,000', value: '$5,000' },
      ],
      pipWorkExclusion: [
        { name: 'Workloss Excluded', value: 'Workloss Excluded' },
        { name: 'Workloss Included', value: 'Workloss Included' },
      ],
      pipAppliesTo: [
        { name: 'Named Insured Only', value: 'Named Insured Only' },
        { name: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)', value: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)' },
      ],
      extendedPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Unavailable', value: 'Unavailable' },
        { name: 'Included', value: 'Included' },
      ],
      accidentMinorViolationForgiveness: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      responsibleDrivingPlan: [
        { name: 'Available', value: 'Available' },
        { name: 'Included', value: 'Included' },
      ],
      rentalCarCoverage: [
        { name: 'Included $30 per day ($900 max)', value: 'Included $30 per day ($900 max)' },
        { name: 'Included $40 per day ($1,200 max)', value: 'Included $40 per day ($1,200 max)' },
        { name: 'Included $50 per day ($1,500 max)', value: 'Included $50 per day ($1,500 max)' },
        { name: 'Included $75 per day ($2,250 max)', value: 'Included $75 per day ($2,250 max)' },
        { name: 'Included $100 per day ($3,000 max)', value: 'Included $100 per day ($3,000 max)' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceAndTowing: [
        { name: 'Included', value: 'Included' },
        { name: 'Included - Basic', value: 'Included - Basic' },
        { name: 'Included - Premier', value: 'Included - Premier' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      gapCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Unavailable', value: 'Unavailable' },
        { name: 'Included', value: 'Included' }
      ]
    },
    defaultValues: {
      enrolledInIntelliDrive: 'Yes',
      coverageCUninsuredMotorist: 'Non-Stacking',
      coverageDComprehensiveCoverage: 'Included',
      coverageDCollisionCoverage: 'Included',
      pipWorkExclusion: 'Workloss Included',
      personalInjuryProtectionLimit: '$10,000',
      pipDeductible: '$0',
      extendedPip: 'Available',
      accidentMinorViolationForgiveness: 'Available',
      responsibleDrivingPlan: 'Available',
      rentalCarCoverage: 'Included $30 per day ($900 max)',
      roadsideAssistanceAndTowing: 'Included',
      gapCoverage: 'Available'
    },
  },
  {
    carrierNumber: 9,
    carrierName: 'Vault',
    fields: [
      'policyTerm',
      'coverageABodilyInjuryPerPersonPerAccident',
      'coverageAPropoertyDamagePerAccident',
      'coverageBMedicalPayments',
      'coverageCUninsuredMotoristPerPersonPerAccident',
      'coverageCUninsuredMotorist',
      'personalInjuryProtectionLimit',
      'pipWorkExclusion',
      'pipDeductible',
      'pipAppliesTo',
      'extendedPip',
      'additionalPip',
      'gapCoverage'
    ],
    currencyFields: [
      'paidInFull',
      'twoPayPlan',
      'twoPayTotalCostPremium',
      'fourPayPlan',
      'fourPayTotalPremium',
    ],
    removeFields: [
      'enrolledInMercuryGo',
      'enrolledInSnapshot',
      'enrolledInRightTrack',
      'enrolledInIntelliDrive',
      'fullPayMonthlyPremium',
      'payPlanMonthlyPremiumTotal',
      'payPlanMonthlyPremiumDownpayment',
      'payPlanMonthlyPremiumPerMonth',
      'eftPayPlanMonthlyPremium',
      'initialEftInstallment',
      'eftTotalCostPremium',
      'ccPayPlanMonthlyPremium',
      'initialCcInstallment',
      'ccTotalCostPremium',
      'ultraCoverage',
      'accidentMinorViolationForgiveness',
      'responsibleDrivingPlan',
      'originalEquipmentManufacturer',
      'enhancedPackage'
    ],
    dropDownData: {
      carrierName: 'Vault',
      policyTerm: [
        { name: '12 Months', value: '12 Months' },
        { name: '6 Months', value: '6 MONTHS' },
      ],
      coverageABodilyInjuryPerPersonPerAccident: [
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: '$500,000/$1,000,000', value: '$500,000/$1,000,000' },
        { name: '$1,000,000/$1,000,000', value: '$1,000,000/$1,000,000' },
        { name: '$30,000 CSL', value: '$30,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
        { name: '$1,000,000 CSL', value: '$1,000,000 CSL' },
      ],
      coverageAPropoertyDamagePerAccident: [
        { name: '$10,000', value: '$10,000' },
        { name: '$50,000', value: '$50,000' },
        { name: '$100,000', value: '$100,000' },
        { name: '$250,000', value: '$250,000' },
        { name: '$500,000', value: '$500,000' },
        { name: '$30,000 CSL', value: '$30,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
        { name: '$1,000,000 CSL', value: '$1,000,000 CSL' },
      ],
      coverageBMedicalPayments: [
        { name: 'Available', value: 'Available' },
        { name: '$5,000', value: '$5,000' }
      ],
      coverageCUninsuredMotoristPerPersonPerAccident: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$10,000/$20,000', value: '$10,000/$20,000' },
        { name: '$250,000/$500,000', value: '$250,000/$500,000' },
        { name: '$500,000/$500,000', value: '$500,000/$500,000' },
        { name: '$500,000/$1,000,000', value: '$500,000/$1,000,000' },
        { name: '$1,000,000/$1,000,000', value: '$1,000,000/$1,000,000' },
        { name: '$30,000 CSL', value: '$30,000 CSL' },
        { name: '$300,000 CSL', value: '$300,000 CSL' },
        { name: '$500,000 CSL', value: '$500,000 CSL' },
        { name: '$1,000,000 CSL', value: '$1,000,000 CSL' }
      ],
      coverageCUninsuredMotorist: [
        { name: 'N/A', value: 'N/A' },
        { name: 'Stacking', value: 'Stacking' },
        { name: 'Non-Stacking', value: 'Non-Stacking' },
      ],
      coverageDComprehensiveCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDComprehensiveCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,000', value: '$2,000' },
        { name: '$2,500', value: '$2,500' },
        { name: '$3,500', value: '$3,500' },
        { name: '$5,000', value: '$5,000' },
        { name: '$7,500', value: '$7,500' },
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
      ],
      coverageDCollisionCoverage: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      coverageDCollisionCoverageDeductible: [
        { name: 'Rejected', value: 'Rejected' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' },
        { name: '$1,500', value: '$1,500' },
        { name: '$2,000', value: '$2,000' },
        { name: '$2,500', value: '$2,500' },
        { name: '$3,500', value: '$3,500' },
        { name: '$5,000', value: '$5,000' },
        { name: '$7,500', value: '$7,500' },
        { name: '$10,000', value: '$10,000' },
        { name: '$25,000', value: '$25,000' },
        { name: '$50,000', value: '$50,000' },
      ],
      pipWorkExclusion: [
        { name: 'Workloss Excluded', value: 'Workloss Excluded' },
        { name: 'Workloss Included', value: 'Workloss Included' },
      ],
      pipDeductible: [
        { name: '$0', value: '$0' },
        { name: '$250', value: '$250' },
        { name: '$500', value: '$500' },
        { name: '$1,000', value: '$1,000' }
      ],
      pipAppliesTo: [
        { name: 'Named Insured Only', value: 'Named Insured Only' },
        { name: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)', value: 'Named Insured, Spouse, Dependent(s), and/or Relative(s)' },
      ],
      extendedPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Unavailable', value: 'Unavailable' },
        { name: 'Included', value: 'Included' },
      ],
      additionalPip: [
        { name: 'Available', value: 'Available' },
        { name: 'Unavailable', value: 'Unavailable' },
        { name: 'Included', value: 'Included' },
      ],
      rentalCarCoverage: [
        { name: 'Included - $5,000', value: 'Included - $5,000' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      roadsideAssistanceAndTowing: [
        { name: 'Included', value: 'Included' },
        { name: 'Excluded', value: 'Excluded' },
      ],
      gapCoverage: [
        { name: 'Available', value: 'Available' },
        { name: 'Not Included', value: 'Not Included' },
        { name: 'Included', value: 'Included' }
      ]
    },
    defaultValues: {
      coverageCUninsuredMotorist: 'Non-Stacking',
      coverageDComprehensiveCoverage: 'Included',
      coverageDCollisionCoverage: 'Included',
      pipWorkExclusion: 'Workloss Included',
      personalInjuryProtectionLimit: '$10,000',
      extendedPip: 'Available',
      additionalPip: 'Available',
      rentalCarCoverage: 'Included - $5,000',
      roadsideAssistanceAndTowing: 'Included',
      gapCoverage: 'Not Included'
    },
  },
];


export const statusList = [
  'Quote Process Initiated',
  'Quote Data Submitted',
  'Quote Selection Email Sent',
  'Quote Selection Finalized',
  'Quote Payment Ready',
  'Quote Completed'
];

const form = [
  'policyTerm',
  'enrolledInMercuryGo',
  'enrolledInSnapshot',
  'enrolledInRightTrack',
  'enrolledInIntelliDrive',
  'fullPayMonthlyPremium',
  'payPlanMonthlyPremiumTotal',
  'payPlanMonthlyPremiumDownpayment',
  'payPlanMonthlyPremiumPerMonth',
  'paidInFull',
  'eftPayPlanMonthlyPremium',
  'initialEftInstallment',
  'eftTotalCostPremium',
  'ccPayPlanMonthlyPremium',
  'initialCcInstallment',
  'ccTotalCostPremium',
  'twoPayPlan',
  'twoPayTotalCostPremium',
  'fourPayPlan',
  'fourPayTotalPremium',
  //Other
  'coverageABodilyInjuryPerPersonPerAccident',
  'coverageAPropoertyDamagePerAccident',
  'coverageBMedicalPayments',
  'coverageCUninsuredMotoristPerPersonPerAccident',
  'coverageCUninsuredMotorist',
  'guaranteedValue',
  'coverageDComprehensiveCoverage',
  'coverageDComprehensiveCoverageDeductible',
  'coverageDCollisionCoverage',
  'coverageDCollisionCoverageDeductible',
  'personalInjuryProtectionLimit',
  'pipWorkExclusion',
  'pipDeductible',
  'pipAppliesTo',
  'ultraCoverage',
  'accidentMinorViolationForgiveness',
  'responsibleDrivingPlan',
  'extendedPip',
  'additionalPip',
  'rentalCarCoverage',
  'roadsideAssistanceAndTowing',
  'gapCoverage',
  'originalEquipmentManufacturer',
  'enhancedPackage'
]