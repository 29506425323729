import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  generateQuote: any;
  min = new Date();
  driverCacheData: any;
  autoCacheData: any;
  customerId = '';
  quoteSubmit = false;
  readOnly = false;
  showAnotherQuote = false;
  currentDate: any;
  btnLabel = 'Submit Auto Quote';
  quoteId = '';
  autoQuoteIP = environment.autoURL;
  loadingStatus = false;
  quoteExist = false;
  renewal = false;
  EducationLevelData: any = [
    {
      name: 'High School Diploma',
      value: 'High School Diploma',
      id: 'highSchoolDiploma',
    },
    {
      name: 'Bachelors Degree',
      value: 'Bachelors Degree',
      id: 'bachelorsDegree',
    },
    { name: 'Other', value: 'Other', id: 'Other' },
  ];
  OccupationData: any = [
    {
      name: 'Engineer/Architect/Science/Math',
      value: 'Engineer/Architect/Science/Math',
      id: 'Engineer/Architect/Science/Math',
    },
    {
      name: 'Executive',
      value: 'Executive',
      id: 'Executive',
    },
    { name: 'Other', value: 'Other', id: 'Other' },
  ];
  quoteIdData: any;
  newQuote = false;

  get priorDecPage(): FormGroup {
    return this.generateQuote.get('priorDecPage') as FormGroup;
  }

  // get driversList(): FormArray {
  //   return this.generateQuote.get('driversList') as FormArray;
  // }

  // get autosList(): FormArray {
  //   return this.generateQuote.get('autosList') as FormArray;
  // }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private autoQuoteService: WizzipayService,
    private activatedRoute: ActivatedRoute
  ) {
    this.getFormattedDate();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((url) => {
      if (url['id']) {
        this.customerId = url['id'];
        this.quoteId = url['qid'];
        // url['renewal'] === 'true' && (this.renewal = true);
        this.generateQuote = this.fb.group({
          driversList: this.fb.array([this.driverList(0)]),
          autosList: this.fb.array([this.autoList()]),
          priorDecPage: this.fb.group({
            url: [''],
            name: [''],
            size: [''],
          }),
        });
        // this.checkQuoteCount();
        this.autoQuoteService.getLeadData(this.customerId, this.quoteId).subscribe({
          next: (res) => {
            this.driverCacheData = res;
            this.driverCacheData?.driversList.forEach(
              (val: any, index: any) => {
                index != 0 && this.addDriver(0);
              }
            );
            this.generateQuote
              .get('driversList')
              .patchValue(this.driverCacheData?.driversList);
            if (this.driverCacheData?.driversList[0]?.phone) {
              var USNumber = this.driverCacheData?.driversList[0]?.phone.split(
                /(\d{3})(\d{3})(\d{4})/
              );
              USNumber =
                '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3];
              this.generateQuote
                .get('driversList')
                .at(0)
                .get('phone')
                .patchValue(USNumber);
            }
            this.driverCacheData?.driversList.forEach(
              (val: any, index: any) => {
                val?.dateOfBirth &&
                  this.generateQuote
                    .get('driversList')
                    .at(index)
                    .get('dateOfBirth')
                    .patchValue(new Date(val?.dateOfBirth));
              }
            );
            this.driverCacheData?.autosList.forEach((val: any, index: any) => {
              if (index != 0) {
                this.addAuto(0);
              }
            });
            this.generateQuote
              .get('autosList')
              .patchValue(this.driverCacheData?.autosList);
            this.generateQuote
              .get('priorDecPage')
              .patchValue(this.driverCacheData?.priorDecPage);
            this.readOnly = res?.quoteStatus === 'Create Quote Proposal';
            // this.router.navigate([`/driverInfo/1`], {
            //   queryParams: { id: this.customerId, qid: this.quoteId },
            // });
          },
          error: () => {
            this.patchPrimaryDriver();
          },
        });
      } else {
        this.newQuote = true;
        this.generateQuote = this.fb.group({
          driversList: this.fb.array([this.driverList(0)]),
          autosList: this.fb.array([this.autoList()]),
          priorDecPage: this.fb.group({
            url: [''],
            name: [''],
            size: [''],
          }),
        });
      }
    });
    // this.readOnly = this.autoQuoteService.getCache('submitted');
    // if (this.autoQuoteService.getCache('driverInfo')) {
    //   let cacheData: any = sessionStorage.getItem('driverInfo');
    //   this.driverCacheData = JSON.parse(cacheData);
    //   this.driverCacheData?.driverInfo.forEach((val: any, index: any) => {
    //     index != 0 && this.addDriver(0);
    //   });
    //   this.generateQuote
    //     .get('driversList')
    //     .patchValue(this.driverCacheData?.driverInfo);
    //   this.driverCacheData?.driverInfo.forEach((val: any, index: any) => {
    //     this.generateQuote
    //       .get('driversList')
    //       .at(index)
    //       .get('dateOfBirth')
    //       .patchValue(new Date(val?.dateOfBirth));
    //   });
    // }
    // if (this.autoQuoteService.getCache('autoInfo')) {
    //   let Data: any = sessionStorage.getItem('autoInfo');
    //   this.autoCacheData = JSON.parse(Data);
    // }
  }

  patchPrimaryDriver() {
    if (!this.renewal && !this.quoteIdData) {
      if (
        sessionStorage.getItem('quoteId') &&
        sessionStorage.getItem('quoteIdData')
      ) {
        //
        let idData: any = sessionStorage.getItem('quoteIdData');
        this.quoteIdData = JSON.parse(idData);
      } else {
        this.anotherAutoQuote();
        return;
      }
    }
    this.autoQuoteService
      // .getClientInfo(this.customerId, this.quoteId)
      .getClientInfo(this.customerId)
      .subscribe({
        next: (res) => {
          var USNumber = res?.client?.PhoneNumber.split(
            /(\d{3})(\d{3})(\d{4})/
          );
          USNumber = '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3];
          const payload = {
            nameFirst: res?.client?.NameFirst,
            nameLast: res?.client?.NameLast,
            dateOfBirth: res?.client?.DateOfBirth
              ? new Date(res?.client?.DateOfBirth)
              : '',
            educationLevel: res?.client?.EducationLevel,
            occupation: res?.client?.Occupation,
            email: res?.client?.EmailAddress,
            phone: USNumber, // convert
            streetNumber: parseInt(res?.client?.Address)?.toString(),
            streetName: res?.client?.Address,
            city: res?.client?.City,
            state: res?.client?.State,
            country: res?.client?.Country,
            zip: res?.client?.Zip,
          };
          let test: any = [];
          test.push(payload);
          this.driverCacheData = {
            driversList: test,
          };
          // this.driverCacheData['driversList'].push(payload);
          this.generateQuote
            .get('driversList')
            .patchValue(this.driverCacheData?.driversList);
        },
        error: (err) => {
          this.autoQuoteService.snackbar('Network Error');
        },
      });
  }

  licensePlateNumberValidation(index: any, length: any) {
    if (length > 1) {
      const checkValue = this.generateQuote
        .get('autosList')
        ?.at(index)
        ?.get('licensePlateNumber')?.value;
      checkValue != ''
        ? this.generateQuote
          .get('autosList')
          ?.value?.forEach((element: any, i: any) => {
            if (index != i) {
              if (element?.licensePlateNumber == checkValue) {
                this.setValidationError(
                  'autosList',
                  'licensePlateNumber',
                  index,
                  true
                );
                this.setValidationError(
                  'autosList',
                  'licensePlateNumber',
                  i,
                  true
                );
              } else {
                this.setValidationError(
                  'autosList',
                  'licensePlateNumber',
                  index,
                  false
                );
                this.setValidationError(
                  'autosList',
                  'licensePlateNumber',
                  i,
                  false
                );
              }
            }
          })
        : this.setMandatory('autosList', 'licensePlateNumber', index);
    }
  }

  licenseNumberValidation(index: any, length: any) {
    if (length > 1) {
      const checkValue = this.generateQuote
        .get('driversList')
        ?.at(index)
        ?.get('licenseNumber')?.value;
      checkValue != ''
        ? this.generateQuote
          .get('driversList')
          ?.value?.forEach((element: any, i: any) => {
            if (index != i) {
              if (element?.licenseNumber == checkValue) {
                this.setValidationError(
                  'driversList',
                  'licenseNumber',
                  index,
                  true
                );
                this.setValidationError(
                  'driversList',
                  'licenseNumber',
                  i,
                  true
                );
              } else {
                this.setValidationError(
                  'driversList',
                  'licenseNumber',
                  index,
                  false
                );
                this.setValidationError(
                  'driversList',
                  'licenseNumber',
                  i,
                  false
                );
              }
            }
          })
        : this.setMandatory('driversList', 'licenseNumber', index);
    }
  }

  vinValidation(index: any, length: any) {
    if (length > 1) {
      const checkValue = this.generateQuote
        .get('autosList')
        ?.at(index)
        ?.get('vin')?.value;
      checkValue != ''
        ? this.generateQuote
          .get('autosList')
          ?.value?.forEach((element: any, i: any) => {
            if (index != i) {
              if (element?.vin == checkValue) {
                this.setValidationError('autosList', 'vin', index, true);
                this.setValidationError('autosList', 'vin', i, true);
              } else {
                this.setValidationError('autosList', 'vin', index, false);
                this.setValidationError('autosList', 'vin', i, false);
              }
            }
          })
        : this.setMandatory('autosList', 'vin', index);
    }
  }

  setMandatory(controlArray: any, controlName: any, index: any) {
    const control = this.generateQuote.get(controlArray) as FormArray;
    control['controls']
      ?.[index]
      ?.get(controlName)
      ?.setValidators([Validators.required]);
    control['controls']?.[index]?.get(controlName)?.updateValueAndValidity();
  }

  setValidationError(
    controlArray: any,
    controlName: any,
    index: any,
    setError: any
  ) {
    const control = this.generateQuote.get(controlArray) as FormArray;
    setError
      ? control['controls']
        ?.[index]
        ?.get(controlName)
        ?.setErrors({ incorrect: setError })
      : control['controls']?.[index]?.get(controlName)?.setErrors(null);
  }

  // async checkQuoteCount() {
  //   this.autoQuoteService.getQuoteCount(this.customerId).subscribe({
  //     next: (res) => {
  //       parseInt(this.quoteId) + 1 <= (res?.count || res?.Data) &&
  //         (this.quoteExist = true);
  //     },
  //     error: (err) => {
  //       this.autoQuoteService.openSnackBar('Network Error');
  //     },
  //   });
  // }

  checkMileage(val: any, index: number) {
    let mileage = '';
    val == true ? (mileage = 'Low Mileage') : (mileage = '');
    this.generateQuote
      .get('autosList')
      .at(index)
      .get('annualMileage')
      .patchValue(mileage);
  }

  getFormattedDate() {
    const todayTime = new Date();
    const year = todayTime.getFullYear();
    const LeapYear = year % 4;
    if (LeapYear === 0) {
      // This is Leap Year
      this.currentDate = new Date().setDate(todayTime.getDate() - 6575);
    } else {
      // This is not leap year
      this.currentDate = new Date().setDate(todayTime.getDate() - 6574);
    }
    const date = new Date(this.currentDate);
    this.min = date;
  }

  driverList(val: number): FormGroup {
    let group: any = {
      nameFirst: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)]],
      nameLast: ['', [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)]],
      dateOfBirth: ['', [Validators.required]],
      licenseNumber: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]{1,30}$/)]],
      educationLevel: ['', [Validators.required]],
      occupation: ['', [Validators.required]],
    };
    if (val == 0) {
      group['email'] = [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
          ),
        ],
      ];
      group['phone'] = [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ];
      group['streetNumber'] = ['', [Validators.required, Validators.pattern(/^-?\d*\.?\d*$/)]];
      group['streetName'] = ['', [Validators.required]];
      group['city'] = ['', [Validators.required, Validators.pattern('[a-z A-Z.s]+')]];
      group['state'] = ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)]];
      group['country'] = ['', [Validators.required, Validators.pattern(/^[a-zA-Z ]{1,30}$/)]];
      group['zip'] = ['', [Validators.required, Validators.pattern(/^[0-9 ]{1,7}$/)]];
    }
    // return this.fb.group({
    //   nameFirst: ['', [Validators.required]],
    //   nameLast: ['', [Validators.required]],
    //   dateOfBirth: ['', [Validators.required]],
    //   licenseNumber: ['', [Validators.required]],
    //   educationLevel: ['', [Validators.required]],
    //   occupation: ['', [Validators.required]],
    // });
    return this.fb.group(group);
  }

  autoList(): FormGroup {
    return this.fb.group({
      vin: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]{1,30}$/)]],
      licensePlateNumber: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]{1,30}$/)]],
      annualMileage: [''],
    });
  }

  addDriver(val: number) {
    if (this.generateQuote.get('driversList').invalid && val == 1) {
      this.generateQuote.get('driversList').markAllAsTouched();
      return;
    }
    const items = this.generateQuote.get('driversList') as FormArray;
    items.push(this.driverList(val));
  }

  removeDriver(index: number) {
    const items = this.generateQuote.get('driversList') as FormArray;
    items.removeAt(index);
  }

  addAuto(val: number) {
    if (this.generateQuote.get('autosList').invalid && val == 1) {
      this.generateQuote.get('autosList').markAllAsTouched();
      return;
    }
    const items = this.generateQuote.get('autosList') as FormArray;
    items.push(this.autoList());
  }

  removeAuto(index: any) {
    const items = this.generateQuote.get('autosList') as FormArray;
    items.removeAt(index);
  }

  getRes(val: any) {
    let fileSize = '';
    if (!val?.fileSize) {
      if (val?.response?.fileSize_KB) {
        let bytes = val?.response?.fileSize_KB * 1000;
        if (bytes === 0) {
          fileSize = '0 Bytes';
        }
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        fileSize =
          parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
      }
    }
    this.generateQuote.get('priorDecPage').patchValue({
      name: val?.fileName,
      size: val?.fileSize ? val?.fileSize : fileSize,
      url: 'val?.response?.s3URL',
    });
  }

  delRes() {
    this.generateQuote.get('priorDecPage').reset('');
  }

  createNewAutoQuote(payload: any) {
    this.autoQuoteService.submitNewAutoQuote(payload).subscribe({
      next: (res) => {
        this.quoteSubmit = true;
        this.loadingStatus = false;
      },
      error: (err) => {
        this.autoQuoteService.snackbar('Network Error');
        this.loadingStatus = false;
      },
    });
  }

  next() {
    // this.autoQuoteService.saveCache('hello', 'test');
    // console.log(this.autoQuoteService.getCacheData('hello'));
    if (this.generateQuote.invalid) {
      this.generateQuote.markAllAsTouched();
      return;
    }
    this.loadingStatus = true;
    let payload = { ...this.generateQuote.value };
    payload.driversList[0].phone = payload.driversList[0].phone.replace(
      /[^\d]/g,
      ''
    );
    payload?.driversList?.forEach((element: any, index: any) => {
      payload.driversList[index].dateOfBirth = new Date(
        element.dateOfBirth
      ).toISOString();
    });
    payload['quoteStatus'] = 'Process Auto Quote';
    if (this.newQuote) {
      this.createNewAutoQuote(payload);
      return;
    }
    this.submitSummaryData(payload);
    return;
    // this.renewal
    //   ? this.submitSummaryData(payload)
    //   : this.submitNewLenderData(payload);
    //test

    // this.autoQuoteService
    //   .submitSummary(payload, this.customerId, this.quoteId)
    //   .subscribe({
    //     next: (res) => {
    //       this.quoteSubmit = true;
    //       this.loadingStatus = false;
    //       console.log('submit res', res);
    //       // const statusPayload = {
    //       //   quoteStatus: 'Process Auto Quote',
    //       //   quoteId: this.quoteId,
    //       // };
    //       // this.autoQuoteService
    //       //   .updateQuoteStatus(statusPayload, this.customerId)
    //       //   .subscribe({
    //       //     next: (res) => {
    //       //       console.log('update res', res);
    //       //       this.quoteSubmit = true;
    //       //     },
    //       //     error: (err) => {
    //       //       console.log(err);
    //       //     },
    //       //   });
    //     },
    //     error: (err) => {
    //       this.autoQuoteService.openSnackBar('Network Error');
    //       this.loadingStatus = false;
    //       console.log('submit err', err);
    //     },
    //   });
    // this.quoteSubmit = true;
    console.log('Final Valueeee', this.generateQuote.value, payload);
    // sessionStorage.setItem('submitted', 'true');

    // const driverPayload = {
    //   driverNumber: parseInt(this.generateQuote.value.driver.length),
    //   driverInfo: this.generateQuote.value.driver,
    // };
    // sessionStorage.setItem('driverInfo', JSON.stringify(driverPayload));
    // const autoPayload = {
    //   autoNumber: parseInt(this.generateQuote.value.auto.length),
    //   autoInfo: this.generateQuote.value.auto,
    // };
    // sessionStorage.setItem('autoInfo', JSON.stringify(autoPayload));
    // this.router.navigate([`/policyInfo`], {
    //   queryParams: { id: this.customerId },
    // });
  }

  anotherAutoQuote() {
    this.loadingStatus = true;
    // this.generateQuote.get('priorDecPage').reset('');
    // this.generateQuote.reset();
    this.quoteSubmit =
      this.showAnotherQuote =
      this.readOnly =
      this.quoteExist =
      this.renewal =
      this.loadingStatus =
      false;
    if (this.newQuote) {
      this.generateQuote.reset();
      this.router.navigate([`/createquote`]);
      return;
    }
    this.autoQuoteService.getLenderData(this.customerId).subscribe({
      next: (res) => {
        console.log('response of new data', res);
        this.quoteIdData = res;
        sessionStorage.setItem('quoteId', res?.quoteId);
        sessionStorage.setItem('quoteIdData', JSON.stringify(this.quoteIdData));
        this.router.navigate([`/createquote`], {
          queryParams: {
            id: this.customerId,
            qid: res?.quoteId,
          },
        });
      },
      error: () => {
        this.autoQuoteService.snackbar('Error');
      },
    });
  }

  submitNewLenderData(payload: any) {
    this.autoQuoteService
      .saveLenderData(this.customerId, this.quoteIdData)
      .subscribe({
        next: () => {
          this.submitSummaryData(payload);
        },
        error: () => {
          this.autoQuoteService.snackbar('Error');
          this.loadingStatus = false;
        },
      });
  }

  submitSummaryData(payload: any) {
    this.autoQuoteService
      .submitSummary(payload, this.customerId, this.quoteId)
      .subscribe({
        next: (res) => {
          const autoStatusPayload = {
            status: 'Create Quote Proposal',
          };
          this.autoQuoteService
            .updateAutoStatus(this.customerId, this.quoteId, autoStatusPayload)
            .subscribe({
              next: () => {
                this.quoteSubmit = true;
                this.loadingStatus = false;
              },
              error: () => {
                this.autoQuoteService.snackbar('Network Error');
                this.loadingStatus = false;
              },
            });
        },
        error: () => {
          this.autoQuoteService.snackbar('Network Error');
          this.loadingStatus = false;
        },
      });
  }

  close() {
    this.quoteSubmit = this.showAnotherQuote = false;
    // this.showAnotherQuote = false;
    this.readOnly = true;
    // this.btnLabel = 'Add new Auto Quote';
  }
}
