<div class="min-vh-100">
    <div class="container">
        <mat-horizontal-stepper linear #stepper>
            <mat-step matStepContent *ngFor="let data of step; let i = index">
                <ng-template matStepLabel>{{data?.heading}}</ng-template>
                <ng-container *ngIf="i === stepper.selectedIndex">
                    <ng-container *ngComponentOutlet="data.component">
                    </ng-container>
                </ng-container>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>